import React from 'react';
import Loading from './../assets/loading.gif';
import OtherFilesTypes from './../assets/otherFilesTypes.svg';
import '../style.css';
import {formatBytes} from './../assets/helpers'


const regExp = /([\/,!\\^${}\[\]().*+?|<>\-& ])/g;
const createIdentificationByName = (fileName) => `img-${fileName.replace(regExp, '-')}`;
export default class Thumbnail extends React.Component{

    static defaultProps = {
        className: '',
        onDelete: () => {},
        icon: undefined,
    };

    fileName = createIdentificationByName(this.props.file.name);

    previewFile(file) {
        let reader  = new FileReader();

        if (file.type.includes('image')) {
            reader.onloadend = () => {
                let node = document.querySelector(`img.${this.fileName}`);
                if(node)
                    node.src = reader.result;
            };

            if (file) {
                reader.readAsDataURL(file);
            }
        }

        else if (!file.type.includes('image'))
            setTimeout(() => {
                let node = document.querySelector(`img.${this.fileName}`);
                if(node)
                    node.src = OtherFilesTypes
            }, 200);

        return Loading;
    }


    render() {
        const {file, className, icon, onDelete} = this.props;

        return(
            <div className={`${className} Thumbnail-container`}>
                <div className="Thumbnail-trash" onClick={(e)=>onDelete(e,file)}>
                    {icon ? icon : 'X'}
                </div>
                <img
                    className={`${this.fileName} Thumbnail-img`}
                    src={this.previewFile(file)}
                    alt={`Thumbnail for ${this.fileName}`}
                />
                <p className={'Thumbnail-title'}>{file.name}</p>
                <p className={'Thumbnail-size'}>{formatBytes(file.size)}</p>
            </div>
        )
    }

}