import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { MoreIcon } from "../../configuration/icons";
import RoundButton from "../../elements/RoundButton";

class DropDownMenu extends React.Component {
    openComponentReference = null;
    state = {
        open: this.props.open,
        top: 0,
        left: 0,
    };

    componentDidMount() {
        this.recalculateChildPosition();
        window.addEventListener('resize', this.recalculateChildPosition, true);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.recalculateChildPosition, true);
    }

    recalculateChildPosition = () => {
        const position = ReactDOM.findDOMNode(this.openComponentReference).getBoundingClientRect();
        const { top, left } = position;
        this.setState({ top, left })
    };

    handleLister = () => {
        this.setState({ open: false });
        window.removeEventListener('click', this.handleLister, true);
    };

    handleEvent = () => {
        if (this.state.open) window.addEventListener('click', this.handleLister, false);
    };

    handleOpen = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ open: !this.state.open },() => {
            this.handleEvent();
        });
    };

    render() {
        const { open, top, left } = this.state;
        const {
            children,
            className,
        } = this.props;
        const Container = document.getElementsByTagName('body')[0];

        return (
            <div className={'drop-down-menu'}>
                <RoundButton
                    icon={ MoreIcon}
                    ref={ node => this.openComponentReference = node }
                    onClick={ this.handleOpen }
                />
                { open && ReactDOM.createPortal(
                    <div
                        style={{
                            position: 'absolute',
                            top:top + this.props.top,
                            left:left + this.props.left,
                        }}
                        className={`${className ? className : '' } ${open ? 'isOpen' : 'isClose'} drop-down-content`}
                    >
                        {children}
                    </div>
                    ,Container) }
            </div>
        );
    }
}

DropDownMenu.defaultProps = {
    open: false,
    top: -16,
    left: -220,
};

export default DropDownMenu;