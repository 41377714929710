import { FindInArray as SearchInRedux } from "./GeneralFindInRedux";
import { getTaxonomies } from './../../redux/taxonomies/action';
import { getIncidents } from './../../redux/incidents/action';
import { incident } from "../../configuration/entitiesNames";

const FIND_BY = 'id';

const getIncidentFromMongo = (equalTo, TYPE) => {
    return new Promise(resolve => getIncidents({[FIND_BY]: equalTo, type: TYPE}, (response) => {
        if(response.status === 200) resolve(response.data[0]);
        resolve(undefined)
    }))
};

const getTaxonomyFromMongo = (equalTo, TYPE) => {
    return new Promise(resolve => getTaxonomies({[FIND_BY]: equalTo, type: TYPE}, (response) => {
        if(response.status === 200) resolve(response.data[0]);
        resolve(undefined)
    }))
};

export default (equalTo, TYPE = incident) => {
    const REDUCER = TYPE === incident ? 'incidents' : 'taxonomies';
    if(equalTo) {
        const TaxFromRedux = SearchInRedux(equalTo, {reducer: REDUCER, property: TYPE, findBy: FIND_BY});
        if (TaxFromRedux) return new Promise(resolve => resolve(TaxFromRedux));
        else return TYPE === incident ? getIncidentFromMongo(equalTo, TYPE) : getTaxonomyFromMongo(equalTo, TYPE);
    }
    return new Promise(resolve => resolve(undefined));
}