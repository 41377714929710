import React from 'react';
import PropTypes from 'prop-types';
import './index.scss'

class Loading extends React.Component {

    static defaultProps = {
        text: "Cargando..."
    };

    static propTypes = {
        text: PropTypes.string
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps.text !== this.props.text
    }

    render() {
        const {text} = this.props;
        return(
            <div className={'loading-wrapper'}>
                <div className={'pill'}>
                    <div className="spinner">
                        <div/>
                        <div/>
                    </div>
                    <p>{text}</p>
                </div>
            </div>
        )
    }
}

export default Loading