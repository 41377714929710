import React from 'react';
import Chip from '@material-ui/core/Chip';

const PrintingSubfamilies = ({ id }) => {
    return (
        <div>
            {id.map(user => (
                <div>
                <Chip
                className="mt-1"
                label={user}
                />
                <br/>
                </div>
            ))}
        </div>
    )
}
export default PrintingSubfamilies
