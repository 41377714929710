import React from 'react';
import Context from './Context';

const Consumer = (Component) => {
    class withContext extends React.Component {
        render() {
            /** Prop Value passed from Provider*/
            const value = this.context;
            const {
                openFilterSidebar,
                closeFilterSidebar,
                filterSidebarIsOpen,
                setInFilter,
                filters,
                clearFilter
            } = value;
            return (
                <Component
                    {...this.props}
                    openFilterSidebar={openFilterSidebar}
                    closeFilterSidebar={closeFilterSidebar}
                    filterSidebarIsOpen={filterSidebarIsOpen}
                    setInFilter={setInFilter}
                    filters={filters}
                    clearFilter={clearFilter}
                />
            )
        }
    }

    withContext.contextType = Context;

    return withContext;
};

export default Consumer