import React from 'react';
import { MenuIcon, CloseIcon } from './../../configuration/icons';
import MainMenu from './../../components/MainMenu';
import TopBar from '../../components/TopBar';
import Main from '../../components/Main';
import RoundButton from '../../elements/RoundButton';
import InitialRequests from '../../componentsScreens/InitialRequests'
import './index.scss';
import {getUsers} from "../../redux/users/action";
// import {filterIncidents} from "../../redux/incidents/action";
// import {incident} from "../../configuration/entitiesNames";


const onInit = () => {
    // filterIncidents({
    //     id:undefined,
    //     type: incident,
    //     limit: 1,
    //     page: 0
    // })

    // getRoles();
    getUsers();
};

class Layout extends React.Component{

    constructor(props) {
        super(props);
        this.state={
            menuIsOpen: false,
        }
    }

    componentDidMount() {
        onInit();
    }

    openMenu = () => {
        if(!this.state.menuIsOpen)
            this.setState({
                menuIsOpen: true,
            })
    };

    closeMenu = () => {
        if(this.state.menuIsOpen)
            this.setState({
                menuIsOpen: false,
            })
    };

    toggleMenu = () => this.state.menuIsOpen ? this.closeMenu() : this.openMenu();

    render() {
        const { children } = this.props;
        const { menuIsOpen } = this.state;
        const {openMenu, closeMenu} = this;
        const OpenMenuIcon = menuIsOpen ? CloseIcon: MenuIcon;
        const OpenMenuTooltip = menuIsOpen ? 'Cerrar Menú': 'Abrir Menú';
        return(
            <>
                <InitialRequests/>
                <MainMenu
                    open = {menuIsOpen}
                    openMenu = {openMenu}
                    closeMenu = {closeMenu}
                />

                <TopBar>
                    <div className={`open-main-menu ${menuIsOpen ? 'menu-is-open' : ''}`}>
                    <RoundButton onClick={this.toggleMenu} icon={OpenMenuIcon} tooltip={OpenMenuTooltip}/>
                    {/*<h1 className={'m-0 p-0 pl-1'} style={{color: "white", display: "inline"}}>The Cornery</h1>*/}
                    </div>
                    <div id={'right-actions-icons-zone'}/>
                </TopBar>

                <Main className={menuIsOpen ? 'menu-is-open' : ''}>
                    {children}
                </Main>
            </>
        )
    }
}

export default Layout;