const TIME_TO_ABORT_REQUEST = 3000;

export default async (address, signal, controller) => {
    const url = new URL(`https://sgi.larinconada.es/nominatim/search`);

    const params = {
        format: 'json',
        street: address,
        country: "España",
        country_code: "es",
        county: "Sevilla",
        state: "Andalucía"
    };

    url.search = new URLSearchParams(params).toString();
    controller && setTimeout(() => controller.abort(), TIME_TO_ABORT_REQUEST);

    const response = await fetch(url, {
        method: 'GET',
        signal
    });

    if( response.status >= 200 && response.status < 300) {
        const status = response.status;
        const data = await response.json();
        return {status, data}
    }
    return {status: 400}
};