"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Status = void 0;
var Status;

(function (Status) {
  Status["close"] = "close";
  Status["open"] = "open";
  Status["pending"] = "pending";
  Status["rejected"] = "rejected";
  Status["irrelevant"] = "irrelevant";
})(Status = exports.Status || (exports.Status = {}));