import React from 'react';
import './index.scss';

export default class StatusFilter extends React.PureComponent{
    render() {
        const { children, selected, onClick } = this.props;
        return (
            <div className={ 'address-option' } onClick={ onClick }>
                {children}
            </div>
        )
    }
};

