import React from "react";
import { LogOut } from '../../redux/auth/action';
import { connect } from 'react-redux';
import UserIsLogged  from './../../helpers/UserLogged';
import { whitoutValidation } from './../../configuration/mode';
import { Redirect} from "@reach/router";
import { paths } from "../../configuration/paths";
import { setPreviousRoute } from "../../redux/router/action";
import HasAllowedRole from './../../helpers/redux-helpers/HasAllowedRoleHOC';

class PrivateRoute extends React.PureComponent {

    static defaultProps = {
        elseComponent: <Redirect to={`/${paths.Home}`} noThrow/>,
    };

    render() {
        const { Component, allowedRoles, navigate, path, elseComponent, ...rest } = this.props;

        if(!UserIsLogged() && !whitoutValidation) {
            if(!rest.location.pathname.includes(paths.Auth)) setPreviousRoute(rest.location.pathname);
            LogOut();
            return  <Redirect to={`/${paths.Auth}`} noThrow/>;
        }

        if(allowedRoles){
            return (
                <HasAllowedRole allowedRoles={allowedRoles} elseComponent={elseComponent}>
                    <Component {...rest} navigate={navigate} path={path}/>
                </HasAllowedRole>
            )
        }

        return(
            <Component {...rest} navigate={navigate} path={path}/>
        )
    }
}


const mapStatToProps = ({auth})=>({
    me: auth.me
});
export default connect(mapStatToProps)(PrivateRoute)
