export default (object) => {
    if(object) {
        if (object.lat && object.lng) {
            const {lat, lng} = object;
            return {lat, lng}
        }

        if (object.latitude && object.longitude) {
            const {latitude, longitude} = object;
            return {lat: latitude, lng: longitude}
        }
    }
    return {lat: undefined, lng: undefined}
}