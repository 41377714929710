import React from 'react';
// import {ListPage, UserIsAllowed} from "../helpers";
// import DangerouslyGetParamFromParent from "../helpers/DangerouslyGetParamFromParent";
// import {administratorRole, coordinatorRole} from "../constants/Config";


class Utils extends React.Component{
    // static doIfIsAdminOrCitizen = (ifAdmin, ifCitizen) => {
    //     UserIsAllowed([administratorRole, coordinatorRole])
    //         .then( isAllowed => isAllowed ? ifAdmin() : ifCitizen())
    //         .catch( err => console.error('Error create incidence', err) )
    // };

    // getType = () => this.props.navigation.state.params.type;
    //
    // setModalVisible(visible) {
    //     this.setState({ modalVisible: visible });
    // }

    handleOnChange = (key, value) => {
        this.setState({
            [key]:value,
            errors: this.sanitizeErrors(key)
        });
    };

    sanitizeErrors = (key, errorState = 'errors') =>{
        let errors  = this.state[errorState];
        if( errors && errors[key] )
            delete errors[key];
        return errors;
    };

    handleOnPickerChange = (value,name) => {
        this.setState({
            [name]: value,
            errors: this.sanitizeErrors(name)
        });
    };

    setErrors = (errors, errorState = 'errors') =>{
        this.setState({
            [errorState]: errors
        })
    };

    // goBack = () => {
    //     const {navigation} = this.props;
    //     if(navigation) {
    //         const Page = ListPage(navigation.getParam('type') || DangerouslyGetParamFromParent(navigation, 'type'));
    //         Page && navigation.navigate(Page);
    //     }
    // };
    //
    // openSidebar =(sidebarName) => {
    //     if(!this.state[sidebarName])
    //         this.setState({
    //             [sidebarName]: true
    //         })
    // };
    //
    // closeSidebar = (sidebarName) => {
    //     if(this.state[sidebarName])
    //         this.setState({
    //             [sidebarName]: false
    //         })
    // };

}

export default Utils;