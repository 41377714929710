import React from 'react';
import Divider  from './../../../../elements/Divider';
import { incident } from "../../../../configuration/entitiesNames";
import { filterTaxonomies } from "../../../../api/taxonomies";
import MergeList from '../../../../helpers/MergeList';
import Option from "./Option";
import Consumer from "../../Context/Consumer";
import IncidentSelected from './IncidentSelected';

class Finder extends React.Component {
    constructor(props){
        super(props);
        this.state={
            finder: '',
            inRange: []
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextState !== this.state || nextProps.filters.entitiesIncludes !== this.props.filters.entitiesIncludes;
    }

    handleFinderInput = (e) => {
        const value = e.target.value;
        this.setState({
            finder: value
        }, this.checkOptionsInRange)
    };

    checkOptionsInRange = async() => {
        if(this.state.finder.length >= 1) {
            let inRange = [];
            const resC = await filterTaxonomies(incident, `q=code==${this.state.finder}`);
            const resD = await filterTaxonomies(incident, `q=description~=${this.state.finder}`);
            const resA = await filterTaxonomies(incident, `q=address~=${this.state.finder}`);

            if( resC.status === 200 && resC.total > 0 )
                inRange = MergeList(inRange,resC.data);

            if( resD.status === 200 && resD.total > 0 )
                inRange = MergeList(inRange,resD.data);

            if( resA.status === 200 && resA.total > 0 )
                inRange = MergeList(inRange,resA.data);

            this.setState({ inRange });
        }
        else this.setState({ inRange: [] });
    };

    appendEntity = (opt) => this.props.setIn("entitiesIncludes", [...this.props.filters.entitiesIncludes, opt]);
    extractEntity = (opt) => this.props.setIn("entitiesIncludes", [...this.props.filters.entitiesIncludes.filter(ent => ent !== opt) ]);

    handleOnSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.onEnter(this.state.finder);
    };

    optionClicked = (e, opt) => {
        e && e.preventDefault();
        e && e.stopPropagation();
        const { filters } = this.props;
        const { entitiesIncludes } = filters;
        if(entitiesIncludes.includes(opt)) this.extractEntity(opt);
        else this.appendEntity(opt);
    };

    render() {
        const { finder, inRange } = this.state;
        const { filters } = this.props;
        const { entitiesIncludes } = filters;
        return (
            <form onSubmit={this.handleOnSubmit}>


                <input type="text" value={finder} onChange={this.handleFinderInput}/>

                <IncidentSelected />

                { finder.length > 0 && inRange.filter(ent =>
                    !entitiesIncludes.includes(ent)).map(ent =>
                    <Option
                        selected = { entitiesIncludes.includes(ent) }
                        data = { ent }
                        onClick={ this.optionClicked }
                    />
                )}

            </form>
        )
    }
}


const FinderWithConsumer = Consumer(Finder);

class FinderInput extends React.PureComponent{

    render() {
        const { withOutFinder } = this.props;

        return(
            !withOutFinder && (
                <>
                    <h6 className={'label'}>Buscar por Código, Descripción o Dirección</h6>
                    <FinderWithConsumer id={'Input-filter-by-code'}/>
                    <Divider/>
                </>)
        )
    }
}

export default Consumer(FinderInput);