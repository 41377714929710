import React, { Fragment } from 'react';
import '../index.scss';
import Button from "../../../../elements/Button";
import { update } from "../../../../configuration/operationNames";
import {incident, comment} from "../../../../configuration/entitiesNames";
import HeaderSidebar from "./HeaderSidebar";
import BodySidebar from "./../../../../components/Sidebar/Body";
import FooterSidebar from "./../../../../components/Sidebar/Footer";
import Consumer from '../../../Taxonomies/SidebarContext/Consumer';
import { removeTaxonomies } from "../../../../redux/taxonomies/action";
import { getIncidents } from "../../../../redux/incidents/action";
import Divider from './../../../../elements/Divider';
import CodeAndStatusHeader from './CodeAndStatusHeader';
import ReactDOM from 'react-dom';
import TemplateToPrint from '../../../../componentsScreens/TemplateToPrint'
import UserPopulated from "../../../../helpers/redux-helpers/PopulatedClass/UserPopulated";
import Dropview from "../../../../components/DropView";
import ActivityBlock from "./ActivityBlock";
import TaxonomiesBlock from "./TaxonomiesBlock";
import { getTaxonomies as getTaxonomiesFromApi } from '../../../../api/taxonomies';
import Visualization from "../../../../components/Comment/visualization";
import Loading from '../../../../elements/Loading';
import { FindInArray as GeneraFinderInRedux } from './../../../../helpers/redux-helpers/GeneralFindInRedux';
import MainInformation from './MainInformation'
import RelatedIncident from "./RelatedIncident";
import ExpandedAddress from "./ExpandedAddress";
import ImagesBlock from "./ImagesBlock";
import UserDataBlock from "./UserDataBlock";
import HasAllowedRole from '../../../../helpers/redux-helpers/HasAllowedRoleHOC';
import GmapSection from './GmapsSection/index';
import EmailBlock from './EmailBlock';

const ENTITY = incident;

class View extends React.Component{

    state = {
        data: (this.props.location && this.props.location.state) ? { ...this.props.location.state, comments: this.props.location.state.comments ? [...this.props.location.state.comments] : [] } : {},
        printing: false,
    };

    componentDidMount() {
        this.getIncidents();
        this.getComments();
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextState !== this.state ||
            JSON.stringify(this.state.data) !== JSON.stringify(this.props.lastElementSelected) ||
            this.props.id !== nextProps.id
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if((this.props.location && prevProps.location.state !== this.props.location.state))
            if(Object.keys(this.props.location.state).length <= 1) this.getIncidents();
            else this.props.setLastElementSelected(this.props.location.state);

        if( this.props.lastElementSelected && JSON.stringify(this.state.data) !== JSON.stringify(this.props.lastElementSelected) ){
            this.setState({data: {...this.props.lastElementSelected} }, this.checkComments)
        }
    }

    checkComments = () => {
        if(!this.state.data.comments){
            this.getComments();
        }
    };

    getComments = () => {
        const incidentId = this.state.data.id;
        getTaxonomiesFromApi(comment, undefined,{ incidentId })
            .then(res => {
                if(res.status === 200)
                    this.props.setLastElementSelected({...this.state.data, comments: [...res.data]})
            })
            .catch(e => console.log(e))
    };

    getIncidents = () => {
        const IncidentInRedux = this.findIncidentInReduxStore();
        if(!IncidentInRedux) {
            if (this.props.id && (!this.props.location.state || Object.keys(this.props.location.state).length <= 1))
                getIncidents({type: ENTITY, id: this.props.id}, this.callbackGetIncident);
            else this.props.openSidebar();
        } else this.props.setLastElementSelected({...IncidentInRedux})
    };

    findIncidentInReduxStore = () => {
        return GeneraFinderInRedux(this.props.id, {
            reducer: 'incidents',
            property: incident,
            findBy: 'id'
        })
    };

    callbackGetIncident = (res) => {
        if(res.status === 200 && res.data.length > 0) this.props.setLastElementSelected({...res.data[0]}, this.props.openSidebar);
        else this.props.closeSidebar()
    };

    goToUpdate = () => {
        const { openSidebar, sidebarIsOpen, sidebarMode, closeSidebar } = this.props;
        this.props.setLastElementSelected(this.state.data, () => this.props.setMode(update));
    };

    onPressDeleteButton = () => {
        removeTaxonomies([this.props.id], ENTITY, this.callbackDeleteFamily)
    };

    callbackDeleteFamily = (response) =>{
        if(response.status === 204) {
            this.props.closeSidebar();
            const URL = this.props.uri.replace(this.props.id, '');
            this.props.navigate(URL)
        }
    };

    onPrintStart = () => {
        this.setState({
            printing: true
        })
    };

    onPrintEnd = () => {
        this.setState({ printing: false })
    };

    render() {
        const { data, printing } = this.state;
        /** About taxonomies **/
        const { executor, comments } = data;
        const { navigate, id } = this.props;

        return(
            <Fragment>
                { printing && ReactDOM.createPortal(<TemplateToPrint id={this.props.id}/>, document.querySelector('#print-area-single')) }
                { printing && <Loading text={'Creando impresión'}/> }

                <HeaderSidebar
                    onPressDeleteButton={this.onPressDeleteButton}
                    onPrintStart={ this.onPrintStart }
                    onPrintEnd={ this.onPrintEnd }
                    navigate={navigate}
                />

                <BodySidebar className={'bottom-button'}>
                    <div>
                        <CodeAndStatusHeader className={'pl-1 pr-1 mb-1'} id={this.props.id}/>
                        <GmapSection incident={this.props.id} />
                        <MainInformation id={this.props.id} />
                        <RelatedIncident id={this.props.id} />
                        <Divider/>
                        <ExpandedAddress id={this.props.id}/>
                        <Divider/>
                        <TaxonomiesBlock id={this.props.id}/>


                        <Divider/>

                        <Dropview header={<h4 className={'m-0'}>Comentarios</h4>}>
                            <div className={'pl-1 pt-1 pr-1'}>
                                {
                                    (comments && comments.length > 0) ? comments.map((c, index) => <Visualization key={c.id} last={index === comments.length - 1} comment={c}/>):
                                        <p><i>Aún no se ha añadido ningún comentario</i></p>
                                }
                            </div>
                        </Dropview>

                        <Divider/>
                        <UserDataBlock id={this.props.id}/>
                        <Divider/>

                        <Dropview header={<h4 className={'m-0'}>Información adicional</h4>}>
                            <div className={'pl-1 pr-1'}>
                                <h6 className={"label"}>Aprobada por</h6><p><UserPopulated id={executor}/></p>
                            </div>
                        </Dropview>

                        <Divider/>

                        <ImagesBlock id={this.props.id}/>

                        <Divider/>
                        <HasAllowedRole allowedRoles={['administrador', 'sac']}>
                            <Dropview header={<h4 className={'m-0'}>Actividad</h4>}>
                                <div className={"pl-1 pr-1"}>
                                    <ActivityBlock id={id} />
                                </div>
                            </Dropview>
                        </HasAllowedRole>
                        <Divider/>
                        <HasAllowedRole allowedRoles={['administrador', 'sac']}>
                            <Dropview header={<h4 className={'m-0'}>Mensajes</h4>}>
                                <div className={"pl-1 pr-1"}>
                                    <EmailBlock id={id} />
                                </div>
                            </Dropview>
                        </HasAllowedRole>
                        <Divider/>
                    </div>
                </BodySidebar>
                <FooterSidebar>
                    <Button className={'native-base-button center-text'}  onClick={this.goToUpdate} fullWidth >
                        Editar Incidencia
                    </Button>
                </FooterSidebar>
            </Fragment>
        )
    }
}

export default Consumer(View)
