import * as TYPES from './types';
import { store } from '../store';


/** Get Users From DataBase **/
export const getUsers = (filters, callback) => store.dispatch({
    type: TYPES.GET_USERS_REQUEST,
    filters,
    callback
});

export const getUserRole = (filters) => store.dispatch({
    type: TYPES.GET_USER_ROLES_REQUEST,
    filters: filters,
});

export const createUser = (user, callback) => store.dispatch({
    type: TYPES.CREATE_USER_REQUEST,
    user,
    callback
});

export const updateUsers = (users, values, callback) => {
    store.dispatch({
        type: TYPES.UPDATE_USERS_REQUEST,
        payload: users,
    });

    users.forEach(user => store.dispatch({
        type: TYPES.UPDATE_USER_REQUEST,
        user,
        values,
        callback
    }));
};

export const deleteUsers = (users, callback) => {
    store.dispatch({
        type: TYPES.DELETE_USERS_REQUEST,
        payload: users,
    });

    users.forEach(user => store.dispatch({
        type: TYPES.DELETE_USER_REQUEST,
        user,
        callback
    }));
};

export const updateUserPassword = (passwordOptions, callback) => {
    store.dispatch({
        type: TYPES.UPDATE_USER_PASSWORD_REQUEST,
        payload: passwordOptions,
        callback
    });
};

export const updateUserRolesSuccess = (userId, roles) => {
    store.dispatch({
        type: TYPES.UPDATE_USER_ROLES_SUCCESS,
        payload: {id: userId, roles: roles},
    });
};

export const getUserOrionInforSuccess = (userId, orionData) => {
    store.dispatch({
        type: TYPES.GET_USER_ORION_DATA_SUCCESS,
        payload: {id: userId, data:orionData},
    });
};