import {create, update, view} from "../../../../configuration/operationNames";
import RoundButton from "../../../../elements/RoundButton";
import Divider from "../../../../elements/Divider";
import { AddIcon, CancelIcon, DeleteIcon } from "../../../../configuration/icons";
import React from "react";
import Consumer from '../../../Taxonomies/SidebarContext/Consumer';
import Swal from "sweetalert2";

class HeaderSidebar extends React.Component {

    handleCancel = () => {
        const paths = window.location.pathname.split('/');
        const { setMode, closeSidebar } = this.props;
        if(paths[paths.length -1] === 'list' || !paths[paths.length -1]) closeSidebar();
        else setMode(view);
    };

    handleClose = () => {
        const { closeSidebar } = this.props;

        const paths = window.location.pathname.split('/');

        // console.log('paths[paths.length -1] !== \'list\'', paths[paths.length -1] !== 'list')
        // console.log(' paths[paths.length -1]',  paths[paths.length -1])

        if(paths[paths.length -1] !== 'list') this.props.navigate('../');
        closeSidebar();

        //TODO: in update and create mode unmount list component (WHY?!?!?!)

    };

    preventDeleteFamily = () => {
        const { onPressDeleteButton } = this.props;

        Swal.fire( {
            title: '¡Cuidado!',
            text: "¿Estás seguro de que quieres borrar esta familia?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            confirmButtonText: 'Borrar',
            cancelButtonText: 'Cancelar',
            focusCancel: true,
            preConfirm: onPressDeleteButton
        });
    };

    render() {
        /** Functions **/
        const { setMode, sidebarMode } = this.props;

        switch ( sidebarMode ) {
            case update:
            case create:
                return (
                    <div className={'header-sidebar'}>
                        <div className={'header-sidebar-content'}>
                            <RoundButton icon={CancelIcon} onClick={this.handleClose} tooltip={"Cerrar Panel"}/>
                            <p onClick={this.handleCancel} className={'link m-0 p-0'}>Cancelar</p>
                        </div>
                        <Divider/>
                    </div>
                );
            default:
                return (
                    <div className={'header-sidebar'}>
                        <div className={'header-sidebar-content'}>
                            <RoundButton icon={CancelIcon} onClick={this.handleClose} tooltip={"Cerrar Panel"}/>
                            <RoundButton icon={DeleteIcon} onClick={this.preventDeleteFamily} tooltip={"Borrar Familia"}/>
                            <RoundButton icon={AddIcon} onClick={()=>setMode(create)} tooltip={"Añadir nueva familia"}/>
                        </div>
                        <Divider/>
                    </div>
                );
        }
    }
}

export default Consumer(HeaderSidebar);