import React, { Fragment } from 'react';
import DatePicker from "../../../../elements/DatePicker";
import Divider from "../../../../elements/Divider";
import Consumer from "../../Context/Consumer";

class DateBlock extends React.PureComponent{
    render() {
        const { setIn, filters } = this.props;
        const { endDateFilter, startDateFilter } = filters;

        return(
            <Fragment>
                <p className={'Filter-sidebar-subtitle'}>Filtrar por fecha de creación</p>
                <div className={'DateFilter-Block'}>
                    <DatePicker
                        name={'StartDate'}
                        selectsStart
                        onChange={(name, value)=> setIn("startDateFilter", value)}
                        value={startDateFilter}
                        startDate={startDateFilter}
                        endDate={endDateFilter}
                        maxDate={new Date()}
                        showDisabledMonthNavigation
                        // showMonthYearDropdown
                        label={'Desde'}
                    />

                    <DatePicker
                        name={'EndDate'}
                        selectsEnd
                        onChange={(name, value)=> setIn("endDateFilter", value)}
                        value={endDateFilter}
                        endDate={endDateFilter}
                        maxDate={new Date()}
                        minDate={startDateFilter}
                        showDisabledMonthNavigation
                        // showMonthYearDropdown
                        label={'Hasta'}
                    />
                </div>
                <Divider/>
            </Fragment>
        )
    }
}


export default Consumer(DateBlock)