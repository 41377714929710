import React from 'react';
import './index.scss';
import ParseTimeToMoment from '../../../../../../helpers/moment/parseTimeToMoment'
import UserPopulated from '../../../../../../helpers/redux-helpers/PopulatedClass/UserPopulated';
import {Link} from '@reach/router';

const EXCLUDE_ENTRIES = ['modDate', 'validated', 'printed'];

const TRANSLATIONS = {
    description : 'descripción',
    images: 'imágenes',
    address: 'dirección',
    number: 'número',
    zipCode: 'código postal',
    zone: 'zona',
    street: 'calle',
    printed: 'imprimida',
    family: 'la familia',
    subfamily: 'la subfamilia',
    orderType: 'el tipo de orden',
};

const translateEntry = (entry) => TRANSLATIONS[entry] ? TRANSLATIONS[entry] : entry;

const parseOperationToString = (operation, operationChanges) => {
    if(operationChanges.includes('printed')) return "ha impreso la incidencia";
    if(operation.includes('modificar')) return 'ha actualizado';
    if(operation.includes('crear')) return 'ha creado una incidencia';
    return "ha operado sobre";
};

const parseOperationChangesToString = (operationChanges) => {
    const updates = JSON.parse(operationChanges).map(e => {
        if(!EXCLUDE_ENTRIES.includes(e))
            return translateEntry(e);
            return null
    }).filter(e => e);

    const lastElement =  updates.pop();
    if(updates.length < 1 && !lastElement) return '';
    if(updates.length < 1 && lastElement) return lastElement;
    return  updates.join(', ') + " y " + lastElement;
};

class HistoryRow extends React.Component{

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.props.data !== nextProps.data
    }

    render() {

        const { data } = this.props;
        let {operator, operationChanges, operation, modDate} = data;
        operation = parseOperationToString(operation, operationChanges);
        operationChanges = parseOperationChangesToString(operationChanges);

        return (
            <div className={'historical-row-wrapper'}>
                <p className={'mb-0'}>
                    <Link to={`../../../users/list/${operator}`} className={'link'}><UserPopulated id={operator}/> <UserPopulated id={operator} show={'surname'}/></Link> {operation} {operationChanges}
                </p>
                <p className={'m-0 additional-information'}>{modDate ? ParseTimeToMoment(modDate):''}</p>
            </div>
        );
    }
}

export default HistoryRow;