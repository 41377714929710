import mapboxgl from 'mapbox-gl'
import STATUS from '../../../../configuration/statusOptions';


const AllStatus = Object.keys(STATUS);

let PopUpShowed = [];

const AddPopup = (e, Map) => {
    const data = e.features[0].properties;
    data.images = JSON.parse(data.images);
    data.coords = JSON.parse(data.coords);
    var coordinates = e.features[0].geometry.coordinates.slice();
    var description = e.features[0].properties.description;

// Ensure that if the map is zoomed out such that multiple
// copies of the feature are visible, the popup appears
// over the copy being pointed to.
//     while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
//         coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
//     }
    const PopUp = new mapboxgl.Popup().setLngLat(coordinates).setHTML(description);
    PopUpShowed.push(PopUp);
    PopUp.addTo(Map);

};
const OnMouseEnter = (e, Map) => {
    Map.getCanvas().style.cursor = 'pointer';
    AddPopup(e, Map)

};
const OnMouseLeave = (e, Map) => {
    Map.getCanvas().style.cursor = '';

    if(PopUpShowed.length > 0)
        PopUpShowed.forEach(popup => {
            popup.remove();
            PopUpShowed.splice(popup, 1)
        });

};
const AddAllEvents = (Map, Name, onClickDot) => {
    Map.on('click', Name, (e)=> onClickDot(e, Map));
    Map.on('mouseenter', Name, (e) => OnMouseEnter(e, Map));
    Map.on('mouseleave', Name, (e) => OnMouseLeave(e, Map));

};
const RemoveAllEvents = (Map, Name, onClickDot) => {
    Map.off('click', Name, (e)=>onClickDot(e, Map));
    Map.off('mouseenter', Name, (e)=>OnMouseEnter(e, Map));
    Map.off('mouseleave', Name, (e)=>OnMouseLeave(e, Map));



};
const Get = (Map, Name) => Map.getLayer(Name);
const Add = (Map, SourceName, Name, onClickDot, Before) => {
    Map.addLayer({
        id: Name,
        type: "circle",
        source: SourceName,
        filter: ["!", ["has", "point_count"]],
        paint: {
            "circle-color": STATUS[Name].color,
            "circle-radius": 14,
            "circle-stroke-width": 1,
            "circle-stroke-color": "#fff",
            'circle-opacity': 0.7
        }
    }, Before);

    Map.setFilter(Name, ['==', 'status', STATUS[Name].name]);

    AddAllEvents(Map, Name, onClickDot);
};

export const RemoveDots = (Map, Name) => {
    if(Get(Map,Name)) {
        RemoveAllEvents(Map, Name);
        Map.removeLayer(Name)
    }
};

export const RemoveAllDots = (Map) => {
    AllStatus.forEach(key => {
        const Name = key;
        if (Get(Map, Name)) {
            RemoveAllEvents(Map, Name);
            Map.removeLayer(Name)
        }
    });
};

export default (Map, SourceName, onClickDot, Before = undefined) => {

    AllStatus.forEach(key => {
        const Name = key;
        const OldLayer = Get(Map,Name);
        try {
            if(!OldLayer) Add(Map, SourceName, Name, onClickDot, Before);
            else{
                RemoveDots(Map, Name, onClickDot);
                Add(Map, SourceName, Name, onClickDot, Before)
            }
        }
        catch (e) {
            console.log(e, 'OldLayer', OldLayer);
        }
    });
};