const oneSecondInMilliseconds = 1000;
const oneMinuteInMilliseconds = 60 * oneSecondInMilliseconds;
const oneHourInMilliseconds = 60 * oneMinuteInMilliseconds;
const oneDayInMilliseconds = 24 * oneHourInMilliseconds;

const howManySecondsDiffer = (time) => Math.floor(time / oneSecondInMilliseconds);
const howManyMinutesDiffer = (time) => Math.floor(time / oneMinuteInMilliseconds);
const howManyHoursDiffer = (time) => Math.floor(time / oneHourInMilliseconds);
const howManyDaysDiffer = (time) => Math.floor(time / oneDayInMilliseconds);

const secondsToString = (seconds) => `${seconds} segundos`;
const minutesToString = (minutes) => `${minutes} minutos`;
const hoursToString = (hours) => `${hours} horas`;
const daysToString = (days) => `${days} días`;

export default (time) => {
    // How it was done.
    if(isNaN(parseInt(time))) return 'invalid-date';
    const date = new Date(parseInt(time));
    const nowInMilliseconds = new Date().getTime();
    const dateInMilliseconds = date.getTime();
    const differenceInMilliseconds = nowInMilliseconds - dateInMilliseconds;

    let seconds = howManySecondsDiffer(differenceInMilliseconds);
    let minutes = howManyMinutesDiffer(differenceInMilliseconds);
    let hours = howManyHoursDiffer(differenceInMilliseconds);
    let days = howManyDaysDiffer(differenceInMilliseconds);

    seconds = seconds - minutes * oneMinuteInMilliseconds / oneSecondInMilliseconds;
    minutes = minutes - hours * oneHourInMilliseconds / oneMinuteInMilliseconds;
    hours = hours - days * oneDayInMilliseconds / oneHourInMilliseconds;

    return date.toLocaleString();
}