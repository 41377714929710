export default async (coords) =>{
    const url = new URL('https://sgi.larinconada.es/nominatim/reverse');
    const params = {
        format: 'json',
        lat: coords.lat,
        lon: coords.lng,
        country: "España",
        country_code: "es",
        county: "Sevilla",
        state: "Andalucía"
    };
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
    const response = await fetch(url);
    if( response.status >= 200 && response.status < 300) {
        const status = response.status;
        const data = await response.json();
        return {status, data}
    }
    return {status: 400}
};