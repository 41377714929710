"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Zone = void 0;
var Zone;

(function (Zone) {
  Zone["LR"] = "LR";
  Zone["SLR"] = "SLR";
  Zone["OTR"] = "OTR";
})(Zone = exports.Zone || (exports.Zone = {}));