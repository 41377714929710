import * as TYPES from './types'

const initialState = () => {
    let ME = { id: null, name: '', surname: '', email: '', telephone: '', address: '' };
    let AUTH = { access_token: null, expires_in: null, refresh_token: null };


    const getMe = () => {
        const me = localStorage.getItem('me');
        if(me)
            try { ME = JSON.parse(me) }
            catch (e){console.log('Error recover me from localstorage')}
    };

    const getAuth = () => {
        const auth = localStorage.getItem('auth');
        if(auth)
            try { AUTH = JSON.parse(auth) }
            catch (e){console.log('Error recover auth from localstorage')}
    };

    getMe();
    getAuth();

    return ({
        success: false,
        error: null,
        ...AUTH,
        me: {...ME}
    });
};

export default (state = initialState(), { type, payload }) => {

    switch (type) {
        /** REQUEST **/
        case TYPES.UPDATE_ME_REQUEST:
        case TYPES.LOGIN_REQUEST:
        case TYPES.GET_ME_REQUEST:
            return { ...state, fetching:true };


        /** SUCCESS **/
        case TYPES.LOGIN_SUCCESS:
            const Credentials = payload;
            Credentials.expires_in = new Date( new Date().getTime() + Credentials.expires_in * 1000);
            localStorage.setItem('auth', JSON.stringify(Credentials));
            return { ...state, fetching:false, success: true, ...payload  };

        case TYPES.LOGOUT:
            localStorage.removeItem('auth');
            localStorage.removeItem('me');
            return {};

        case TYPES.UPDATE_ME_SUCCESS: {
            localStorage.setItem('me', JSON.stringify(payload));
            return { ...state, fetching:false, success: true, me: {...payload}};
        }

        case TYPES.SET_CREDENTIALS:
            return {...payload};

        case TYPES.GET_ME_SUCCESS:
            localStorage.setItem('me', JSON.stringify(payload.user));
            return { ...state, fetching:false, success: true, me: {...payload.user}};

        /** FAILED **/
        case TYPES.LOGIN_FAILED:
        case TYPES.GET_ME_FAILED:
        case TYPES.UPDATE_ME_FAILED:
            console.log(type, payload);
            return { ...state, fetching:false, error: payload };

        default:
            return state
    }
};