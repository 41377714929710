import React from 'react';
import { UserConsumer } from './../Context'
import Header from '../Components/HeaderSidebar';
import Footer from '../Components/FooterSidebar';
import Body from './../../../components/Sidebar/Body';
import PropTypes from "prop-types";
import USER_FIELDS from "../Components/UsersFields";
import { Input } from "../../../components/Form";
import Utils from "../../../helpers/UtilClassWithCommonFunctions";
import { User } from "@secmotic/models";
import { createUser } from "../../../redux/users/action";
import Select from "react-select";
import { getRoles } from "../../../redux/roles/action";
import { parseRolesToIDM, parseToOrionData, validateUser, cleanRoles, parseRolesToArray, setOrionInformation } from '../functions';
import { user } from "../../../configuration/entitiesNames";
import { view } from "../../../configuration/operationNames";
import { navigate } from '@reach/router';
import Swal from "sweetalert2";
import {paths} from "../../../configuration/paths";

class Create extends Utils{

    static defaultProps = { lastElementSelected: undefined };
    static propTypes = { lastElementSelected: PropTypes.object };

    state = {
        errors:[]
    };

    componentDidMount() {
        getRoles({}, this.callbackGetRoles);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const {lastElementSelected} = this.props;
        if(nextState !== this.state) return true;
        if(lastElementSelected !== nextProps.lastElementSelected) return true;
        return false;
    }

    getValue = (key, elseValue = '') => {
        return this.state[key] ? this.state[key] : elseValue;
    };

    callbackAfterCreateUser = async(response, orionData) => {
        if(response.status === 201 && response.data.user)
            orionData = {...orionData, userId: response.data.user.id};

        const orionResponse = await setOrionInformation({
            type: user,
            ...orionData
        });

        if(response.status === 201 && response.data)
            Swal.fire("la contraseña del usuario es: " + response.data.password);

        if(response.status === 201 && response.data.user && orionResponse.status === 201)
            navigate('/' + paths.Home + '/' + paths.Users + '/list/' + response.data.user.id).then(()=>this.props.setMode(view))
    };

    createUser = () => {
        const newState = new User(this.state);
        const orionData = parseToOrionData(newState);
        newState.roles = parseRolesToArray(newState.roles);
        const validation = validateUser(newState);
        if(newState.roles) newState.roles = parseRolesToIDM(newState.roles);
        if( validation && Object.keys(validation).length > 0) this.setErrors(validation);
        else createUser( newState.getJSON(), (response) => this.callbackAfterCreateUser(response, orionData));
    };

    callbackGetRoles = (response) => {
        if(response.status === 200) {
            const availableRoles = cleanRoles(response.data);
            this.setState({ availableRoles })
        }
    };

    render() {
        const { roles, availableRoles, errors } = this.state;

        return (
            <>
                <Header/>

                <Body>
                    <div className={'pl-1 pr-1'}>
                        { USER_FIELDS.map(field => (
                                <>
                                    <h6 className={'label'}>{ field.label }</h6>
                                    <Input
                                        keepFeedbackSpace
                                        noLabel
                                        key={ field.key }
                                        name={ field.key }
                                        value={ this.getValue(field.key) }
                                        onChange={ (e) => this.handleOnChange(field.key, e.target.value)}
                                        errorMsg={errors[field.key]}
                                    />
                                </>
                            )
                        )}
                        <h6 className={'label'}> Rol del Usuario </h6>
                        <Select
                            placeholder={'Selecciona un rol'}
                            value={ roles }
                            onChange={ (value) => this.setState({ roles : value }) }
                            options={ availableRoles }
                        />
                        {errors["roles"] && <p className={'error-msg mb-1 mt-1'}>{errors["roles"]}</p>}

                    </div>
                </Body>

                <Footer onClickFooterButton={this.createUser} buttonLabel={'Crear Usuario'} />
            </>
        )
    }
}

export default UserConsumer(Create);