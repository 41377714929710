const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0
};

export default (callback)=>{
    if (navigator.geolocation)
        navigator.geolocation.getCurrentPosition(callback, undefined, options)
    
}