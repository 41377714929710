import React from 'react';
import LogoLaRinconada from '../../../assets/images/Full-Logotype.svg'

class RinconadaLogoSection extends React.Component {
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return false
    }

    render() {
        return (
            <section className="pdf-section-logo">
                <img src={LogoLaRinconada} alt="Logo Rinconada"/>
            </section>
        )
    }
}

export default RinconadaLogoSection