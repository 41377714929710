import React from 'react';
import InfoRow from './InfoRow';
import FamilyPopulated from '../../../helpers/redux-helpers/PopulatedClass/FamilyPopulated';
import OrderTypePopulated from '../../../helpers/redux-helpers/PopulatedClass/OrderTypePopulated';
import SubfamilyPopulated from '../../../helpers/redux-helpers/PopulatedClass/SubfamilyPopulated';

export default ({family, subfamily, orderType})=>{
    return(
        <section className="pdf-section pdf-section-taxonomies">
            <InfoRow title="Familia" content={<FamilyPopulated id={family}/>}/>
            <InfoRow title="SubFamilia" content={<SubfamilyPopulated id={subfamily}/>}/>
            <InfoRow title="Tipo de Orden" content={<OrderTypePopulated id={orderType}/>}/>
        </section>
    )
}

