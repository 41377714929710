const GetPointsForCluster = (incidents) => {
    return incidents.map((incident, key) => ({
        type: "Feature",
        properties: { cluster: false, id: incident.id, category: incident.status },
        incident: incident,
        geometry: {
            type: "Point",
            coordinates: [
                parseFloat(incident.coords.lng),
                parseFloat(incident.coords.lat),
            ]
        }
    }));
}

export default GetPointsForCluster
