import React from 'react';
import ReactDOM from 'react-dom';
import { extractCommonClasses } from './functions'
import './index.scss';
import Body from './Body';
import Footer from './Footer';
import Header from './Header';

class Sidebar extends React.PureComponent{
    sidebarContent = null;

    closeSidebar = () => {
        this.props.closeSidebarFn && this.props.closeSidebarFn()
    };

    openSidebar = () => {
        this.props.openSidebarFn && this.props.openSidebarFn()
    };

    render(){
        const { position, open, children } = this.props;
        const commonClasses = extractCommonClasses({open, position});

        return(
            <div className={`Sidebar-Wrapper ${commonClasses}`}>
                <div className={`Sidebar-Content`} ref={ node => this.sidebarContent = node }>
                    { children }
                </div>
            </div>
        )
    }
}

Sidebar.defaultProps = {
    position: 'right',
    closeSidebarFn: ()=>{},
    openSidebarFn: ()=>{}
};



class SidebarPortal extends React.Component {
    render(){
        const container = document.getElementById('root');
        return container ? ReactDOM.createPortal(<Sidebar {...this.props} />, container) : null
    }
}

export {
    Header as HeaderSidebar,
    Body as BodySidebar,
    Footer as FooterSidebar,
}

export default SidebarPortal