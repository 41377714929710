import React from 'react';
import './index.scss';
import Logotype from '../../assets/images/Logotype.png';
import EuropeanLogotype from './../../assets/images/EuropeanLogotype.png';
import BackgroundAuth from './../../assets/images/BackgroundAuth.jpeg';
import BackgroundImage from '@oubli/react-background-image';
import Card from '../../elements/Card';

class Auth extends React.PureComponent{
    render(){
        const {children} = this.props;
        return(
            <div className={'auth-wrapper'}>
                <section>
                    <div><img src={Logotype} alt={'Logotipo ayuntamiento de la rinconada'} /></div>
                    <div><img src={EuropeanLogotype} alt={'Logotipo europeo'} />
                    </div>
                </section>

                <section className={'full-section'}>
                    <div className={'dark-mask'}/>
                    <BackgroundImage
                        url={BackgroundAuth}
                        alt={'Login-BackGround'}
                    />
                    <div className={'section-content'}>
                        <div className={'left-content'}>
                            <h2 className={'title'}>Sistema de gestión de incidencias</h2>
                            <p className={'description'}>
                                Sed erat justo, auctor at consequat sed, lacinia id urna. Nullam ac nisi sed tortor venenatis facilisis. Donec tincidunt pharetra mauris id viverra. In sagittis erat feugiat, cursus arcu vitae, viverra odio.
                            </p>
                        </div>
                        <div className={'right-content'}>
                            <Card>
                                { children }
                            </Card>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default Auth;