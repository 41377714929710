const Get = (Map, Name) => Map.getSource(Name);
const Add = (Map, Name, Data, WithCluster) => {
    Map.addSource(Name, {
        type: "geojson",
        // data: {},
        data: {
            "type": "FeatureCollection",
            "name": Name,
            "features": Data,
        },
        cluster: WithCluster,
        clusterMaxZoom: 15, // Max zoom to cluster points on
        clusterRadius: 30 // Radius of each cluster when clustering points (defaults to 50)
    });
};
export const RemoveSource = (Map, Name) => Map.removeSource(Name);
export default (Map, Name, Data, WithCluster=true) => {
    const OldSource = Get(Map, Name);
    try {
        if(!OldSource) Add(Map, Name, Data, WithCluster);
        else {
            RemoveSource(Map, Name);
            Add(Map, Name, Data, WithCluster);
        }
    }
    catch (e) {
        console.log(e, 'OldSource', OldSource)
    }


}