import {User} from "@secmotic/models";
import {
    createTaxonomy as createUserOrionInformation,
    patchTaxonomy as updateUserOrionInformation
} from "../../api/taxonomies";
import {user} from "../../configuration/entitiesNames";
import {resetPasswordForUser} from "../../api/user";
import Alert from "sweetalert2";

export const parseRolesToIDM = (roles) => roles.map(role => ({name: role.label, id: role.value}));
export const parseRolesFromIDM = (roles) => roles.map(role => ({label: role.name, value: role.id}));

export const parseToOrionData = (state) => ({
    userId: state.id,
    telephone: state.telephone,
    address: state.address
});

export const validateUser = (userData) => {
    const user = new User({...userData});
    const validation = user.validate();

    if(validation && !userData.telephone) delete validation.telephone;
    if(validation && !userData.address) delete validation.address;

    return validation;
} ;

export const cleanRoles = (data) => data.roles.map(role => {
    if (['COORDINADOR', 'ADMINISTRADOR', 'CIUDADANO', 'SAC'].includes(role.name.toUpperCase()))
        return {value: role.id, label: role.name};
    return null;
}).filter(e => e);

export const parseRolesToArray = (roles) => roles && Array.isArray(roles) ? roles : roles ? [roles] : null;

export const setOrionInformation = (orionData, orionId = null) => {
    if(!orionId) return createUserOrionInformation({
        type: user,
        ...orionData
    });
    else return updateUserOrionInformation({
        id: orionId,
        ...orionData
    })
};

export const resetUserPassword = (e, userId) => {
    e && e.preventDefault();
    e && e.stopPropagation();

    resetPasswordForUser(userId)
        .then(resp => {
            console.log(resp.data.password);
            Alert.fire({
                title: "Contraseña cambiada",
                text: `La nueva contraseña es: ${resp.data.password}`,
                showConfirmButton: 'OK'
            });

        }).catch(e => console.error(e))
};

export const UserAgreeWithString = (u, string) => (u.name + ' ' + u.surname + ' ' + u.email).toUpperCase().replace(' ', '').includes(string.toUpperCase().toUpperCase().replace(' ', ''));
