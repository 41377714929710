import {sortBy, ascend,descend, sortWith, compose, prop} from 'ramda';

export default (array, key, type = "string") => {
    const castingType = (value) => {
        if(type === 'number') return parseInt(value);
        return value
    };

    // console.log('listado', array);
    // console.log(sortWith([ x => descend(compose(prop(key)))(castingType(x))])(array));
    // return  sortBy( compose( castingType, prop(key) ) )( array );





    return sortWith([ x => ascend(compose(prop(key)))(x)])(array)

}

