import React from 'react';
// import EmptyString from './../../EmptyString';

const EmptyString = React.memo(({ children, keepFeedbackSpace, className, ...rest }) => {
    if(!children) children = keepFeedbackSpace ? "\u200B" : undefined;
    return Array.isArray(children) ?
        children.map(child => <p {...rest} className={`${className ? className : ''}`}>{child}</p> ) :
        <p {...rest} className={`${className ? className : ''}`}>{children}</p>
});

export default class Bar extends React.PureComponent{
    render(){
        const{ text, type, keepFeedbackSpace } = this.props;
        const Component = EmptyString;
        return(
            <div className="feedback-msg">
                <Component keepFeedbackSpace={keepFeedbackSpace} className={ type }>{ text }</Component>
            </div>
        )
    }
}