import React from 'react';
import './index.scss';
import {Link} from '@reach/router';
import {paths} from "../../configuration/paths";
import {Form, InputStyled as Input} from "../../components/Form";
import Button from './../../elements/Button';
import Utils from "../../helpers/UtilClassWithCommonFunctions";
import { SuccessAnimation, WarningAnimation } from '../../components/SweetAlert/Animations';
import {getUserNoConfirmated, validateUser} from './../../api/auth';
import Logotype from "../../assets/images/Logotype.png";
import EuropeanLogotype from "../../assets/images/EuropeanLogotype.png";
import BackgroundImage from "@oubli/react-background-image";
import BackgroundConfirmation from "../../assets/images/BackgroundConfirmation.jpeg";
import Card from "../../elements/Card";
import {User} from '@secmotic/models';

const BASE_STATE = {
  errors:[],
  password: '',
  user:{},
  warningAlert: false,
  errorAlert: false,
  successAlert: false
};

class Confirmation extends Utils {

  constructor(props){
    super(props);
    this.state = {...BASE_STATE};
    //     // this.redirectToHomeIfLogged();
  }
  //
  componentDidMount(){
    this.validateUser();
  }
  //
  // // redirectToHomeIfLogged = async () => {
  // //     const res = await HandleLocalStorage.getItem('auth');
  // //     if(res && JSON.parse(res).access_token){
  // //         UserIsAllowed([coordinatorRole, administratorRole])
  // //         .then(res => {
  // //             if (res) this.props.navigation.navigate('Dashboard');
  // //             else this.props.navigation.navigate('Home');
  // //         })
  // //     }
  // // };
  //
  validateUser = () => {
    getUserNoConfirmated(this.props.id)
      .then(({data, status}) => {
        if(status === 200) this.setState({ user: {...data} });
        else this.setState({userIsExpired: true});
      })
      .catch(err => {
        this.setState({userIsExpired: true});
        console.error('HA OCURRIDO UN ERROR INNESPERADO', err)
      })
  };
  //
  handleValidation = () => {
    let errors = { password : [], confirmPassword : [],};
    const hash = this.props.id;
    const { confirmPassword, password } = this.state;

    if(!password) errors.password.push('Debes añadir una contraseña antes de continuar');
    if(!confirmPassword) errors.confirmPassword.push('Debes confirmar la contraseña');
    if(confirmPassword !== password) errors.confirmPassword.push('Las contraseñas no coinciden');

    const user = new User({password});
    const val = user.validate({password: user.Constraints.password});
    if(val) errors.password.push(val.password);

    if(errors.password.length > 0 || errors.confirmPassword.length > 0) this.setErrors(errors);

    if(errors.password.length === 0 && errors.confirmPassword.length === 0)
      validateUser({password}, hash )
        .then(this.callBackValidation)
        .catch(this.callBackValidationError)
  };

  callBackValidation = ({status, data}) => {
    if(status === 201) this.setState({ successAlert: true }, this.goToLoginAfterTimeOut);
    else{
      this.setState({ warningAlert: true });
    }
  };
  callBackValidationError = (err) => {
    console.error('HA OCURRIDO UN ERROR', err);
    this.setState({ warningAlert: true })
  };

  goToLoginAfterTimeOut = () => {
    setTimeout(()=>{
      this.setState({successAlert: false}, () => window.location.href = paths.AppLogin)
    }, 3000)
  };

  handleOnChange = ( e ) => {
    const key = e.target.name;
    this.sanitizeErrors(key);
    this.setState({
      [key]: e.target.value
    });
  };

  render(){

    /** MISC **/
    const {user, userIsExpired, warningAlert, errorAlert, successAlert} = this.state;

    /** VARS **/
    const { password, confirmPassword } = this.state;

    /** FEEDBACK **/
    const { errors } = this.state;

    return (
      <div className={'auth-wrapper'}>
        <section>
          <div><img src={Logotype} alt={'Logotipo ayuntamiento de la rinconada'} /></div>
          <div><img src={EuropeanLogotype} alt={'Logotipo europeo'} /></div>
        </section>

        <section className={'full-section'}>
          <div className={'dark-mask'}/>
          <BackgroundImage
            url={BackgroundConfirmation}
            alt={'Confirmation-BackGround'}
          />
          <div className={'section-content'}>
            <div className={'left-content'}>
              {userIsExpired ?
                <>
                  <h2 className={'title'}>El tiempo de validación ha expirado o el usuario ya ha sido
                    validado</h2>
                  <p className={'description'}>Puedes acceder a la plataforma o registrarte nuevamente
                    en ella a través de <Link className={'link'} to={`/${paths.Auth}`}>este enlace</Link></p>
                </>
                :
                <>
                  <h2 className={'title'}>Bienvenido {user.name}</h2>
                  <h2 className={'subtitle'}>Estás a un paso de completar tu registro</h2>
                  <p className={'description'}>Indícanos la contraseña que usarás para entrar en la
                    plataforma</p>
                </>
              }
            </div>
            { !userIsExpired && <div className={'right-content'}>
              <Card>
                <div className="login-wrapper">

                  {
                    successAlert &&
                    <div className="login-form-wrapper">
                      <div className="flex-content-bottom flex-1 ">
                        <div className="flex-1 align-center-vertical pb-1">
                          <SuccessAnimation>
                            <p><strong>Hemos validado correctamente tu email.</strong></p>
                            <p>Ya puedes acceder a la plataforma a través de tu correo y la contraseña que has especificado</p>
                          </SuccessAnimation>
                        </div>
                      </div>
                      <div className="pt-1 pl-1 pr-1">
                        <Button primary fullWidth onClick={this.goToLoginAfterTimeOut}>
                          Entendido
                        </Button>
                      </div>
                    </div>
                  }
                  {
                    warningAlert &&
                    <div className="login-form-wrapper">
                      <div className="flex-content-bottom flex-1 ">
                        <div className="flex-1 align-center-vertical pb-1">
                          <WarningAnimation>
                            <p><strong>No hemos podido validar tu email.</strong></p>
                            <p>Por favor, inténtalo de nuevo. Si el problema persiste, ponte en contacto con nosotros.</p>
                          </WarningAnimation>
                        </div>
                      </div>
                      <div className="pt-1 pl-1 pr-1">
                        <Button primary fullWidth onClick={()=>this.setState({warningAlert: false})}>
                          Entendido
                        </Button>
                      </div>
                    </div>
                  }
                  { (!warningAlert && !errorAlert && !successAlert) && (
                    <>
                      <div className="login-form-wrapper">
                        <div className="flex-content-bottom flex-1">
                          <Form onSubmit={this.handleLogin} className={'flex-1 form-auth'}>
                            <h2>Añade una contraseña</h2>

                            <h6 className={'label'}>Contraseña</h6>
                            <Input
                              noLabel
                              groupClassName={'validation-inputs'}
                              keepFeedbackSpace
                              name={'password'}
                              type={'password'}
                              value={password}
                              // label={'Contraseña'}
                              onChange={this.handleOnChange}
                              // isValid={true} isInvalid={false}
                              infoMsg={'Añade una contraseña de acceso'}
                              errorMsg={errors['password']}
                            />

                            <h6 className={'label'}>Confirma la contraseña</h6>
                            <Input
                              noLabel
                              groupClassName={'validation-inputs'}
                              keepFeedbackSpace
                              name={'confirmPassword'}
                              type={'password'}
                              value={confirmPassword}
                              // label={'Confirma tu contraseña'}
                              onChange={this.handleOnChange}
                              // isValid={true} isInvalid={false}
                              infoMsg={'Confirma la contraseña de acceso'}
                              errorMsg={errors['confirmPassword']}
                            />

                            {/* <EmptyString style={{color: LRRed}}>{loginError}</EmptyString> */}
                          </Form>
                        </div>
                      </div>
                      <div className="pl-1 pr-1">
                        <Button primary fullWidth onClick={this.handleValidation}>
                          Confirmar Contraseña
                        </Button>
                      </div>
                    </>)
                  }
                </div>
              </Card>
            </div> }
          </div>
        </section>
      </div>
    )
  }
}

export default Confirmation;