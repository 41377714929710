import React from 'react';
import { orderType } from "../../../configuration/entitiesNames";
import EntityPopulated from "./EntityPopulated";

const ENTITY = orderType;

class OrderTypePopulated extends React.PureComponent{
    render() {
        return <EntityPopulated {...this.props} ENTITY={ENTITY}/>
    }
}

export default OrderTypePopulated