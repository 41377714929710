import React from 'react';
import FooterSidebar from '../Components/FooterSidebar';
import HeaderSidebar from './Components/HeaderSidebar';
import BodySidebar from  './../../../components/Sidebar/Body';
import Utils from "../../../helpers/UtilClassWithCommonFunctions";
import { Input } from  "../../../components/Form";
import { FilterConsumer } from './Context/index';
import ShowUsersFiltered from './Components/ShowUsersFiltered';
import ShowUsersSelected from './Components/ShowUsersSelected';

class Filter extends Utils {
    timeOut = null;

    state = {
        string: '',
        roles:[]
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextState !== this.state;
    }


    onStringChange = (e) => {
        this.timeOut && clearTimeout(this.timeOut);
        this.setState({ string: e.target.value }, this.setTimeOutToFilterPotentialUser)
    };

    setTimeOutToFilterPotentialUser = ( ) => {
        this.timeOut = setTimeout(this.filterPotentialUser, 300)
    };

    filterPotentialUser = () => {
      const { setInFilter } = this.props;
        setInFilter('string', this.state.string);
    };

    clearFilter = () => {
        this.setState({
            string: '',
            roles: []
        }, this.props.clearFilter);
    };

    render() {
        const { string } = this.state;
        return(
            <>
                <HeaderSidebar/>
                <BodySidebar>
                <div className={'pl-1 pr-1'}>
                    <h6 className={'label'}>Buscar por nombre, apellido o email</h6>
                    <Input onChange={this.onStringChange} value={string}/>
                </div>
                    <ShowUsersSelected/>
                    <ShowUsersFiltered/>
                </BodySidebar>
                <FooterSidebar buttonLabel={'Limpiar Filtro'} onClickFooterButton={this.clearFilter}/>
            </>
        )
    }
}

export default FilterConsumer(Filter);