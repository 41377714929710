import { family, orderType, subfamily } from "../configuration/entitiesNames";

export default (childType) => {
    switch (childType) {
        case orderType:
            return subfamily;
        case subfamily:
            return family;
        default:
            return null
    }
};