import {put, call, all, fork, takeLatest} from 'redux-saga/effects';
import * as TYPES from './types';
import * as USER_TYPES from './../users/types';
import * as API from './../../api/auth';
import * as API_USER from './../../api/user';
import * as API_ORION from './../../api/taxonomies';
import {user} from "../../configuration/entitiesNames";

function* loginThroughtApi({ credentials, callback }){
    try{
        /** Get Token from IDM **/
        const response = yield call(API.DoLogin, credentials);
        if(response.status === 200 || response.status === 304) {
            /** Get my user profile data **/
            yield put({type: TYPES.GET_ME_REQUEST});
            const responseMe = yield call(API.GetMe, response.body);

            /** Get my roles **/
            if(responseMe.status === 200 || response.status === 304) {
                /******************************* Function in Api, Access_Token, User_Id ******/
                const responseRoles = yield call(API.GetMeRole, response.body, responseMe.data.user);

                if(responseRoles.status === 200 || responseRoles.status === 304) {
                    callback && callback(response);
                    yield put({type: TYPES.LOGIN_SUCCESS, payload: response.body});
                    yield put({type: USER_TYPES.GET_USER_SUCCESS, payload: responseMe});
                    yield put({type: USER_TYPES.GET_USER_ROLES_SUCCESS, payload: response.body});

                    responseMe.data.user.roles = responseRoles.data.roles;
                    yield put({type: TYPES.GET_ME_SUCCESS, payload: {...responseMe.data}});
                }
                else {
                    callback && callback (responseMe);
                    yield put({ type: USER_TYPES.GET_USER_ROLES_FAILED, payload: responseMe})
                }
            }
            else {
                callback && callback (responseMe);
                yield put({ type: TYPES.GET_ME_FAILED, payload: responseMe})
            }
        }
        else {
            callback && callback (response);
            yield put({ type: TYPES.LOGIN_FAILED, payload: response})
        }
    }
    catch (e) {
        callback && callback (e);
        yield put({ type: TYPES.LOGIN_FAILED, payload: e})
    }
}

function* registrationThroughtApi({ userData, callback }){
    let response = null;
    try{
        response = yield call(API.setUserNoConfirmated, userData);
        if(response.status === 200){
            callback && callback(response);
            yield put({ type: TYPES.REGISTRATION_SUCCESS, payload: response.data})
        }
        else{
            callback && callback (response);
            yield put({ type: TYPES.REGISTRATION_FAILED, payload: response.data})
        }
    }
    catch (e){

        callback && callback(e);
        yield put({ type: TYPES.REGISTRATION_FAILED, payload: e})

    }
}

function * updateMeThroughtApi({payload, callback}) {
    let {id, ...userData} = payload;
    let {telephone, address, ...userNoOrion} = userData;
    try {
        const MyRolesResponse = yield call(API_USER.getUserRoles, id);
        const MyFromOrion = yield call (API_ORION.getMeFromOrion, id);

        if(MyRolesResponse.status === 200 && MyFromOrion.status === 200) {
            userNoOrion.roles = MyRolesResponse.data.roles.map(role => role.id);
            const UpdateMeResponse = yield call(API.UpdateMe, userNoOrion);
            if(UpdateMeResponse.status === 200) {
                let UpdateMeOrionResponse;
                if(MyFromOrion.data && MyFromOrion.data[0]) {
                    const OrionId = MyFromOrion.data[0].id;
                    UpdateMeOrionResponse = yield call(API_ORION.patchTaxonomy, {
                        type: user,
                        id: OrionId,
                        telephone,
                        address
                    });
                } else {
                    UpdateMeOrionResponse = yield call(API_ORION.createTaxonomy, {
                        type: user,
                        userId: id,
                        telephone,
                        address
                    })
                }

                if (UpdateMeOrionResponse.status === 204 || UpdateMeOrionResponse.status === 201) {
                    const rebuildUser = {
                        ...UpdateMeResponse.data.user,
                        id: id,
                        roles: userNoOrion.roles,
                        telephone,
                        address
                    };

                    yield put({type: TYPES.UPDATE_ME_SUCCESS, payload: {...rebuildUser}});
                    callback && callback({
                        ...UpdateMeResponse,
                        data: {...UpdateMeResponse.data, telephone, address}
                    });

                } else {
                    yield put({type: TYPES.UPDATE_ME_FAILED, payload: UpdateMeOrionResponse.data});
                    callback && callback(UpdateMeOrionResponse);
                }
            }
            else {
                callback && callback (UpdateMeResponse);
                yield put({ type: TYPES.UPDATE_ME_FAILED, payload: UpdateMeResponse.data})
            }
        }
        else{
            callback && callback (MyRolesResponse);
            yield put({ type: TYPES.UPDATE_ME_FAILED, payload: MyRolesResponse.data})
        }

    }
    catch (e){
        callback && callback(e);
        yield put({ type: TYPES.UPDATE_ME_FAILED, payload: e})

    }
}

function* watcherLogin() {
    yield takeLatest(TYPES.LOGIN_REQUEST, loginThroughtApi);
}

function* watcherRegistration() {
    yield takeLatest(TYPES.REGISTRATION_REQUEST, registrationThroughtApi);
}

function* watcherUpdateMe() {
    yield takeLatest(TYPES.UPDATE_ME_REQUEST, updateMeThroughtApi);
}

export default function* rootSaga() {
    yield all([
        fork(watcherLogin),
        fork(watcherRegistration),
        fork(watcherUpdateMe)
    ])
}

