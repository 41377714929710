import React from 'react';
import { ViewPassword, HidePassword } from '../../../configuration/icons.js'
import './index.scss'
import InputWithIcon from "../InputWithIcon";

class PasswordInput extends React.PureComponent {

    state = {
        viewPassword: false
    };

    togglePasswordType = (e) => {
        e && e.preventDefault();
        e && e.stopPropagation();
        this.setState({
            viewPassword: !this.state.viewPassword
        })
    };

    render() {

        let { type, ...rest } = this.props;
        const { viewPassword } = this.state;
        const icon = viewPassword ? ViewPassword : HidePassword;
        type = viewPassword ? 'text' : type;
        const tooltip = viewPassword ? 'Ocultar contraseña' : 'Mostrar contraseña';
        return(
            <InputWithIcon
                {...rest}
                type={type}
                icon={icon}
                tooltip={tooltip}
                onIconClick={this.togglePasswordType}
            />
        )
    }
}

export default PasswordInput