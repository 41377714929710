    import React from 'react';
import PropTypes from 'prop-types';
import { HighLight, Bar, Group, FeedbackMsg, InputLoadingBar } from './Elements'
import Textarea from "./Textarea";
import PasswordInput from "./PasswordInput";
    import InputWithIcon from "./InputWithIcon";

export default class Input extends React.PureComponent{
    render() {
        const {type, custom, name, id, noLabel, label, className, infoMsg, errorMsg, groupClassName,
            keepFeedbackSpace, textarea, readOnly, loading, ...rest} = this.props;

        const LabelToDOM = label || name;
        const IdToDOM = id || name;
        let Component = textarea ? Textarea : 'input';
        Component = type === 'password' ? PasswordInput : Component;
        Component = custom ? InputWithIcon : Component;

        return(
            <Group className={groupClassName}>
                {loading && <InputLoadingBar/>}
                <Component className={className ? className : ''} type={ type } name={ name } id={ IdToDOM } readOnly={readOnly} {...rest} required/>
                {!readOnly && <HighLight/>}
                {!readOnly && <Bar/> }
                {!noLabel && <label htmlFor={ IdToDOM }>{LabelToDOM}</label> }
                {errorMsg ?
                    <FeedbackMsg text={errorMsg} type={'error'} keepFeedbackSpace={keepFeedbackSpace}/> :
                    <FeedbackMsg text={infoMsg} type={'info'} keepFeedbackSpace={keepFeedbackSpace}/>}
            </Group>
        )
    }
}


Input.defaultProps = {
    type : 'text',
    keepFeedbackSpace: false,
    value: undefined,
    noLabel: false,
};

Input.propTypes = {
    type : PropTypes.string,
    readOnly: PropTypes.bool,
    name: PropTypes.string,
    label: PropTypes.string,
    keepFeedbackSpace: PropTypes.bool,
    noLabel: PropTypes.bool,
    value: PropTypes.string,
    groupClassName: PropTypes.string,
    infoMsg: PropTypes.string,
    errorMsg: PropTypes.string,
};