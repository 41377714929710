import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { role } from "../../configuration/entitiesNames";
import { getRoles } from "../../redux/roles/action";

class HasAllowedRole extends React.Component {

    static defaultProps = {
        allowedRoles: [],
        allRoles: [],
        elseComponent: null
    };

    static propsTypes = {
        allowedRoles: PropTypes.arrayOf(PropTypes.string),
        allRoles: PropTypes.arrayOf(PropTypes.shape({
            key: PropTypes.string,
            name: PropTypes.string
        })),
        elseComponent: PropTypes.element
    };

    componentDidMount() {
        getRoles();
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps.allRoles !== this.props.allRoles ||
            JSON.stringify(nextProps.allowedRoles) !== JSON.stringify(this.props.allowedRoles) ||
            nextProps.userRoles !== this.props.userRoles ||
            nextProps.children !== this.props.children
    }

    render() {
        const { allowedRoles, elseComponent, allRoles, children, userRoles } = this.props;

        const allowedRolesUppercase = allowedRoles.map(role => role.toUpperCase());
        const fullAllowedRoles = allRoles.filter(role => allowedRolesUppercase.includes(role.name.toUpperCase()));
        const userRolesKeys = userRoles.map(role => role.id);

        let allowed = false;
        fullAllowedRoles.forEach(role => { if(userRolesKeys.includes(role.id)) allowed = true } );

        return allowed ? children : elseComponent
    }
}

const mapStateToProps = ({ roles, auth }) => ({
    allRoles: roles[role],
    userRoles: (auth && auth.me) ? auth.me.roles : []
});

export default connect(mapStateToProps)(HasAllowedRole);