import React from 'react';
import { UserConsumer } from './../Context'
import RoundButton from "../../../elements/RoundButton";
import { CancelIcon } from "../../../configuration/icons";
import './GoBackInMobile.scss';

class GoBackInMobile extends React.Component{

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return false;
    }

    onClick = (e) =>{
        e.preventDefault();
        e.stopPropagation();

        const { closeSidebar } = this.props;
        closeSidebar();
    };

    render(){
        return(
            <RoundButton className={'go-back-in-mobile'} icon={CancelIcon} onClick={this.onClick}/>
        )
    }
}
export default UserConsumer(GoBackInMobile);