import { requestApi } from "./requestApi";
import { patchTaxonomy } from './taxonomies';

const concatEntityWithFile = (entity, file) => `${entity.id}_${file.name.trim().replace(" ", "").replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '')}`;

export const deleteFile = async (imageName) => {
    return await requestApi({
        method: 'GET',
        url: `presign/delete?name=${imageName}`
    });
};

const signFileForUpload = async (FileName) => {
    return await requestApi({
        method: 'GET',
        url: `presign/put?name=${FileName}`
    });
};

export const signFileForDownload = async (FileName) => {
    return await requestApi({
        method: 'GET',
        url: `presign/get?name=${FileName}`
    });
};

const uploadFile = async ( signedUrl, file ) => {
    return await fetch(signedUrl, {
        method: 'PUT',
        headers: {
            'Content-Type': file.type
        },
        body: file,
    });
};

const patchEntityImages = async (entity, fileNames) => {
    const res = await patchTaxonomy({id:entity.id, images: [...entity.images, ...fileNames.map(fileName => fileName) ]})
    .catch(err => console.error('Error Update Entities in Minio.js', err));
    if(res.status === 204) return {...entity, images: [...entity.images, ...fileNames.map(fileName => fileName) ]};
    console.error('Error Update Entities in Minio.js', res);
};

export const uploadFiles = async (entity, files, callbackOnCompleteUpload=()=>{}, callbackOnSingleFileUploaded=()=>{}) => {
    const promiseArray = [];

    files.forEach((file) => {
        const FileName = concatEntityWithFile(entity, file);
        promiseArray.push(new Promise(async (resolve) => {
            let res = await signFileForUpload(FileName);
            if (res.status === 200) {
                const renamedFile = new File([file], FileName, {type: file.type});
                res = await uploadFile(res.data, renamedFile);
                if(res.status === 200) {
                    console.log('FILENAME', FileName);
                    callbackOnSingleFileUploaded({...res,FileName});
                    resolve(FileName)
                }
            }
            resolve(null);
        }))
    });

    return await Promise.all(promiseArray)
        .then(async(responses) => await patchEntityImages(entity, responses))
        .then((res)=>callbackOnCompleteUpload(res))
        .catch(e => e);
};