"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var Taxonomy_1 = require("../Enums/Taxonomy");

var Commons_1 = require("./Commons");

var TaxonomyContstraints = {
  "name": {
    presence: Commons_1.presence(),
    type: "string",
    length: __assign(__assign({}, Commons_1.minimum(3)), Commons_1.maximum(100))
  },
  "parent": {
    presence: Commons_1.presence(),
    type: "string"
  },
  "type": {
    presence: Commons_1.presence(),
    type: "string",
    inclusion: Commons_1.inclusion(Taxonomy_1.Taxonomies)
  }
};
exports.default = TaxonomyContstraints;