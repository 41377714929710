import { FindInArray } from "./GeneralFindInRedux";
import {incident} from "../../configuration/entitiesNames";

const FIND_BY = 'id';

export default (equalTo, PROPERTY) => {
    const REDUCER = PROPERTY === incident ? 'incidents' : 'taxonomies';
    PROPERTY = PROPERTY === incident ? 'filtered' : PROPERTY;

    return FindInArray( equalTo,
        {
            reducer: REDUCER,
            property: PROPERTY,
            findBy: FIND_BY,
        }
    );
}