import React from 'react';
import './index.scss';
import { Link } from '@reach/router';
import {paths} from "../../configuration/paths";
import {Form, InputStyled as Input} from "../../components/Form";
import PropTypes from 'prop-types';
import Button from './../../elements/Button';
import { User } from "@secmotic/models";
import { Login as DoLogin } from '../../redux/auth/action'
import Utils from "../../helpers/UtilClassWithCommonFunctions";
import { SuccessAnimation, WarningAnimation } from '../../components/SweetAlert/Animations';
import { store } from "../../redux/store";
import Alert from "../../elements/Alert";

const ADMIN_EMAIL = 'administrador@test.com';

const BASE_STATE = {
    errors:[],
    password: '',
    email: '',
    loginError: null,
    loginSuccess: false,
    showForgottenPassword: false
};

class Login extends Utils {

    static propTypes = {
        email: PropTypes.string,
        password: PropTypes.string,
        errors: PropTypes.array,
    };

    static defaultProps = {...BASE_STATE};

    callbackLogin = (response) => {
        if(response.status === 200)
            this.setState({
                loginSuccess: true
            }, ()=> setTimeout(this.goIntoTheApp, 1000));
        else {
            if (response.status === 400)
                this.setState({ loginError: 'El usuario y la contraseña no coinciden' });
            else {
                this.setState({ loginError: 'Ha ocurrido un error inesperado' })
            }
        }
    };

    goIntoTheApp = () => {
        const {navigate} = this.props;
        const previousRoute = store.getState().router.previousRoute;
        if(previousRoute) {
            navigate(previousRoute);
        }
        else navigate(`/${paths.Home}`)
    };

    handleLogin = (e) => {
        e && e.preventDefault();
        e && e.stopPropagation();
    };

    constructor(props){
        super(props);
        this.state = {
            email: this.props.email,
            password: this.props.password,
            errors: this.props.errors
        }
    }

    submit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { email, password } = this.state;
        const user = new User({ email, password });

        const val = user.validate({
            email: user.Constraints.email,
            password: user.Constraints.password
        });

        (val && (val.password || val.email)) ?
            this.setErrors({email: val.email, password: val.password}, 'errors') :
            DoLogin({ username: this.state.email, password: this.state.password }, this.callbackLogin)
    };

    handleChange = (e) => {
        if(this.state.loginError || this.state.loginSuccess)
        this.setState({ loginError: false, loginSuccess: false });
        this.handleOnChange([e.target.name],e.target.value);
    };

    toggleForgottenPasswordAlert = () => {
        this.setState({
            showForgottenPassword: !this.state.showForgottenPassword
        })
    };

    render(){
        /** VARS **/
        const { email, password } = this.state;

        /** FEEDBACK **/
        const { errors, loginError, loginSuccess, showForgottenPassword} = this.state;

        return (
            <div className="login-wrapper">
                <Alert
                  title="Contacta con un administrador"
                  text={`Por favor, para obtener una nueva contraseña, envíe un correo a ${ADMIN_EMAIL} indicando el correo electrónico con el que se registró en la aplicación. Muchas gracias`}
                  open={showForgottenPassword}
                  onClose={this.closeDeleteProfileAlert}
                  onConfirm={this.closeDeleteProfileAlert}
                  showCancelButton={false}
                  confirmButtonText={'Entendido'}
                />
                <div className="login-form-wrapper">
                    <Form onSubmit={this.submit} className="flex-1 form-auth">
                        <h2>Inicio de Sesión</h2>

                        <h6 className={'label'}>Email de usuario</h6>
                        <Input
                            noLabel
                            // groupClassName={"validation-inputs"}
                            keepFeedbackSpace
                            name={'email'}
                            type={'text'}
                            value={email}
                            // label={'Email de usuario'}
                            onChange={this.handleChange}
                            // isValid={true} isInvalid={false}
                            infoMsg={''}
                            errorMsg={errors['email']}
                        />

                        <h6 className={'label'}>Contraseña</h6>
                        <Input
                            noLabel
                            // groupClassName={"validation-inputs"}
                            keepFeedbackSpace
                            name={'password'}
                            type={'password'}
                            value={password}
                            // label={'Contraseña'}
                            onChange={this.handleChange}
                            // isValid={true} isInvalid={false}
                            infoMsg={''}
                            errorMsg={errors['password']}
                        />
                        <div className={'fix-space-for-animation-feedback'}>
                            {
                                (loginSuccess && !loginError ) &&
                                <SuccessAnimation>
                                    <h3>Acceso garantizado</h3>
                                </SuccessAnimation>
                            }
                            {
                                (loginError && !loginSuccess) &&
                                <WarningAnimation>
                                    <h3>{loginError}</h3>
                                </WarningAnimation>
                            }
                        </div>
                        <p className={'link m-0 p-0'} onClick={this.toggleForgottenPasswordAlert}>He olvidado mi contraseña</p>

                        {/*    <EmptyString style={{color: LRRed}}>{loginError}</EmptyString>*/}
                    </Form>
                    <Link to={"../" + paths.Registry} className={'link mb-1 pl-1 pr-1 mt-0'}>Aún no estoy registrado</Link>
                </div>
                <div className="pl-1 pr-1">
                    <Button fullWidth onClick={this.submit}>Acceder</Button>
                </div>
            </div>
        );
    }
}

export default Login;