import React from 'react';
import { navigate, Location } from "@reach/router";
import './index.scss';

const  MenuItem = ({closeMenu, to, children, location, preText, postText}) => {
    const handleClick = () => { navigate(to).then(closeMenu) };
    const active = location.pathname.split('/')[1] === `${to.replace('/', '')}`;

    return(
        <div onClick={handleClick} className={`menu-item p-1 ${active ? 'active' : ''}`}>
            {preText && <div className={'pre'}>{preText}</div>}
            {children && <div className={`center ${preText ? 'ml-1': ''} ${postText ? 'mr-1': ''}`}>{children}</div>}
            {postText && <div className={'post'}>{postText}</div>}
        </div>
    )
};

export default (props) => {
    return (
        <Location>
            {(localProps)=>MenuItem({...props,...localProps})}
        </Location>
    )
}