import React from 'react';
import { FilterConsumer } from '../Context';
import { user } from "../../../../configuration/entitiesNames";
import { connect } from "react-redux";
import Divider from './../../../../elements/Divider'
import RoundButton from "../../../../elements/RoundButton";
import { CloseIcon } from "../../../../configuration/icons";
import RowInFilterSidebar from "../../../../components/RowInFilterSidebar/RowInFilterSidebar";

class ShowUsersSelected extends React.Component {
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps.usersSelected !== this.props.usersSelected;
    }

    deleteUserFromSelectedFilter = (userId) => {
        const {setInFilter, filters} = this.props;
        const {selected} = filters;
        setInFilter('selected', selected.filter(u=> u !== userId) )
    };

    render() {
        const { usersSelected } = this.props;
        return(
            <>
                <RowInFilterSidebar>
                    { usersSelected.length > 0 && <h6 className={'label pl-1 pr-1'}>Usuarios seleccionados</h6> }
                    {
                        usersSelected.map(u => {
                            return (
                                <div className={'item'} key={u.id}>
                                    <div>
                                        <p className={'p-0 m-0'}>{u.name + ' ' + u.surname}</p>
                                        <p className={'p-0 m-0'}><i>{u.email}</i></p>
                                    </div>
                                    <div className={'right'}>
                                        <RoundButton onClick={()=>this.deleteUserFromSelectedFilter(u.id)} icon={CloseIcon} tooltip={'Quitar de la selección'}/>
                                    </div>
                                </div>
                            )
                        })
                    }
                </RowInFilterSidebar>
                <Divider/>
            </>
        )
    }
}

const mapStateToProps = ({users}, {filters}) => ({
    usersSelected: users[user].filter(u => filters.selected.includes(u.id))
});

export default FilterConsumer(connect(mapStateToProps)(ShowUsersSelected))