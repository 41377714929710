import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ParseTimeToMoment from "../../../../../helpers/moment/parseTimeToMoment";
import { connect } from 'react-redux';
import { incident } from "../../../../../configuration/entitiesNames";

class MainInformation extends React.Component {

    static defaultProps = {
        creDate: undefined,
        modDate: undefined,
        description: 'No se ha encontrado descripción',
        address: 'No se ha encontrado dirección'
    };

    static propTypes = {
        creDate: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.instanceOf(Date)
        ]),
        modDate: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.instanceOf(Date)
        ]),
        description: PropTypes.string,
        address: PropTypes.string
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        for (const prop in MainInformation.defaultProps){
            if(nextProps[prop] !== this.props[prop])
                return true
        }
        return false;
    }

    render() {
        const {creDate, modDate, description, address} = this.props;
        return(
            <Fragment>
                <h6 className={"label pl-1"}>Fecha de Creación</h6>
                <p className={'mt-0 ml-1'}> {creDate ? ParseTimeToMoment(creDate) : 'Sin fecha de creación'} </p>

                <h6 className={"label pl-1"}>Última modificación</h6>
                <p className={'mt-0 ml-1'}> {modDate ? ParseTimeToMoment(modDate) : 'Sin fecha de modificación'} </p>

                <h6 className={"label pl-1"}>Descripción</h6>
                <p className={'mt-0 ml-1'}> {description} </p>

                <h6 className={"label pl-1"}>Úbicación</h6>
                <p className={'mt-0 ml-1'}> {address} </p>
            </Fragment>
        )
    }
}

const mapStateToProps = ({ incidents }, {id}) => {
    const incidentInIndents = incidents[incident].find(inc => inc.id === id);
    const foundIncident = incidentInIndents ? incidentInIndents : incidents.filtered.find(inc => inc.id === id);

    if(foundIncident)
        return {
            creDate: foundIncident.creDate,
            modDate: foundIncident.modDate,
            description: foundIncident.description,
            address: foundIncident.address
        };
    
    return MainInformation.defaultProps;
};

export default connect(mapStateToProps)(MainInformation)