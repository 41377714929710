import React, { useState, useCallback } from 'react';
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import { Combobox, ComboboxInput, ComboboxPopover, ComboboxList, ComboboxOption } from "@reach/combobox";
import ObtainAddress from './ObtainAddress';

import "@reach/combobox/styles.css";
import "./Search.css";
import Geocode from "react-geocode";
import { API_KEY } from '../../../../../configuration/mode';


const Search = (props) => {
  
  const [currentLoc, setCurrentLoc] = useState({});
  const [addressBool, setAddressBool] = useState(false);

  // Handle current location option.
  const [addressIfCurrentLoc, setAddressIfCurrentLoc] = useState();
  const panTo = useCallback(({ lat, lng }) => { setCurrentLoc({lat, lng}) }, []);

  if(props.state.currentLocationIsReady){
    navigator.geolocation.getCurrentPosition((position) => {
      panTo({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      })
    })
  }

  // Substracting specific info of the address from a given lat, lng.
  if(currentLoc.lat && addressBool === false){
    setAddressBool(true);
    Geocode.setApiKey(API_KEY);
    Geocode.fromLatLng(currentLoc.lat, currentLoc.lng).then(
      (response) => {
        setAddressIfCurrentLoc(response.results[0].formatted_address);

          let lat = currentLoc.lat;
          let lng = currentLoc.lng;
          let results = response.results;


          const {street, city, zone, number, zipCode} = ObtainAddress({results}, lat, lng);

          // Updating state of the parent class (Create.jsx)
          props.setState({
            ...props.state,
            number: number.toString(),
            zipCode,
            street,
            city,
            coords: {lat, lng},
            address: response.results[0].formatted_address,
            zone
          })

          },
          (error) => {
            console.error(error);
          }
          );
        }

  const { ready, value, suggestions: { status, data }, setValue, clearSuggestions }
   = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 37.4857500, lng: () => -5.9420500 },
      radius: 100 * 1000,
    },
  });
  
  const handleInput = (e) => {
    setValue(e.target.value);
  };
  
  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();
    
    try {
          // Substracting specific info of the address from a given address.
          const results = await getGeocode({ address });
          const { lat, lng } = await getLatLng(results[0]);
          const {street, city, zone, number, zipCode} = ObtainAddress({results}, lat, lng);

          // Updating state of the parent class (Create.jsx)
          props.setState({
            ...props.state,
            number: number.toString(),
            zipCode,
            street,
            city,
            coords: {lat, lng},
            address: results[0].formatted_address,
            zone
          })

        } catch (error) {
        }
      };

    return (
        <div>
            <Combobox onSelect={handleSelect}>
                <ComboboxInput
                value={value}
                onChange={handleInput}
                disabled={!ready}
                placeholder={addressIfCurrentLoc}
                />
                <ComboboxPopover>
                <ComboboxList>
                    {status === "OK" &&
                    data.map(({ id, description }) => (
                      <div className="search" key={id}>
                        <ComboboxOption className="combo" key={id} value={description} />
                      </div>
                    ))}
                </ComboboxList>
                </ComboboxPopover>
            </Combobox>
        </div>
    )
}

export default Search

