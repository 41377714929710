import React from 'react';
import {signFileForDownload} from '../../../api/minio';

class FullImage extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            uri : props.name
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.name !== this.props.name)
            this.getUrlByName();
    }

    componentDidMount() {
        this.getUrlByName()
    }

    getUrlByName = () => {
        signFileForDownload(this.props.name)
            .then(res => this.setState({ uri: res.data }))
    };

    closeModal = () =>{
        this.setState({
            openModal: false
        })
    };

    render(){
        const { uri } = this.state;
        const { name } = this.props;
        return(
            <img style={{maxWidth: '50%', maxHeight: 250, display: 'inline'}} src={uri} alt={name}/>
        )
    }
}

export default ({images}) => {
    return (
        <div className = "pdf-row pdf-row-photos" >
            <h3>Imágenes de la incidencia</h3>
            {/*{ images ? images.map(image => <MinioPicture name={image} />) :*/}
            { images ? images.map(image => <FullImage name={image} />) :
                <i>No se han añadido imágenes</i>
            }
        </div>
    )
}