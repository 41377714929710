import { create, update, view } from "../../../../../configuration/operationNames";
import RoundButton from "../../../../../elements/RoundButton";
import { AddIcon, CancelIcon, PrintIcon, EditIcon } from "../../../../../configuration/icons";
import { AVERAGE_PRINT_TIME } from "../../../../../configuration/mode";
import React from "react";
import Consumer from '../../../../Taxonomies/SidebarContext/Consumer';
import Header from './../../../../../components/Sidebar/Header';
import './index.scss';
import Alert from "../../../../../elements/Alert";
import { getEmailsByIncidenceId, printIncidents } from "../../../../../redux/incidents/action";
import RenderAppropiateStatusHandle from "./RenderAppropiateStatusHandle";
import Swal from 'sweetalert2';
import { getUsers, sendEmailToUser } from "../../../../../api/user";
import { getHistorical } from "../../../../../api/taxonomies";
import { notifications } from '../../../../../configuration/notificationMail';

class HeaderSidebar extends React.Component {

    state={
        stateIsOpen: false,
        printAlertIsOpen: false,
    };

    handleCancel = () => {
        const paths = window.location.pathname.split('/');
        const { setMode, closeSidebar } = this.props;
        if(paths[paths.length -1] === 'list' || !paths[paths.length -1]) closeSidebar();
        else setMode(view);
    };

    handleClose = () => {
        const { closeSidebar } = this.props;
        closeSidebar();

        const paths = window.location.pathname.split('/');
        if(!(paths[paths.length -1] === 'list' || !paths[paths.length -1]))
            this.props.navigate('../');
    };

    /***************** PRINT FUNCTIONS *******************/
    openPrintedAlert = () =>  this.setState({ printAlertIsOpen: true });
    closePrintedAlert = () => this.setState({ printAlertIsOpen: false });
    confirmIncidentIsPrinted = async () => {
        if(this.props.lastElementSelected) {
            const { id } = this.props.lastElementSelected;
            printIncidents([{ id }]);
        }
    };

    handlePrint = () => {
        const {onPrintStart, onPrintEnd} = this.props;
        //
        const PluralPrintBlock = document.getElementById('print-area-plural');
        PluralPrintBlock.classList.remove('show');

        const SinglePrintBlock = document.getElementById('print-area-single');
        SinglePrintBlock.classList.add('show');
        //
        onPrintStart();
        setTimeout(async ()=>{
            await window.print();
            if (window.onafterprint) { //check if browser supports window.onafterprint event handler (Firefox and IE)
                window.onafterprint = this.openPrintedAlert;
            }
            else { //Use setTimeout solution if onafterprint is not supported (Chrome)
                setTimeout(this.openPrintedAlert, 100);
            }
            onPrintEnd();
        }, AVERAGE_PRINT_TIME)

    };
    /**************** END PRINT FUNCTIONS ****************/

    renderAppropriateContent = () => {
        const { setMode, lastElementSelected, sidebarMode } = this.props;

        switch ( sidebarMode ) {
            case update:
            case create:
                return (
                    <>
                        <RoundButton icon={CancelIcon} onClick={this.handleClose} tooltip={"Cerrar Panel"} className={'close-panel'}/>
                        <p onClick={this.handleCancel} className={'link m-0 p-0'}>Cancelar</p>
                    </>
                );
            default:
                return (
                    <>
                        <RoundButton icon={CancelIcon} onClick={this.handleClose} tooltip={"Cerrar Panel"} className={'close-panel'}/>
                        <RoundButton icon={PrintIcon} onClick={this.handlePrint} tooltip={"Imprimir Incidencia"} />
                        <RoundButton icon={EditIcon} onClick={() => {
                            return (
                                Swal.fire({
                                    title: 'Introduzca su texto',
                                    input: 'textarea',
                                    text: 'Este texto se mandará en el cuerpo del email',
                                    showCancelButton: true,
                                    confirmButtonText: 'Enviar',
                                    cancelButtonText: 'Cancelar'
                                  }).then((result) => {
                                    if (result.dismiss === Swal.DismissReason.cancel || result.dismiss === 'backdrop' || result.dismiss === 'esc') {
                                        Swal.fire('Cancelado', 'No se ha enviado ningún email', 'error')
                                    } else {
                                        try {
                                            if (result.value === '') {
                                                return Swal.fire('Cuidado', 'El cuerpo del mensaje no puede estar vacío', 'warning')
                                            }
                                            getHistorical(this.props.lastElementSelected.id, 1, 0, 'asc')
                                                .then(({ status, data: { historicals } }) => {
                                                    if (status !== 200 && historicals && historicals.length === 0) return Promise.reject('Error');
                                                    return getUsers(historicals[0].executor, null);
                                                })
                                                .then(({ status, data: { user: { email, name }}}) => {
                                                    if (status === 200) {
                                                        Swal.fire({
                                                            title: '¿Está seguro de que quiere enviar el email?',
                                                            text: result.value,
                                                            showCancelButton: true,
                                                            confirmButtonText: 'Enviar',
                                                            cancelButtonText: 'Cancelar'  
                                                        }).then(async (rsp) => {
                                                            if (rsp.dismiss === Swal.DismissReason.cancel || rsp.dismiss === 'backdrop' || rsp.dismiss === 'esc') {
                                                                Swal.fire('Cancelado', 'No se ha enviado ningún email', 'error')
                                                            } else {
                                                                await sendEmailToUser(notifications.CUSTOM, email ? email : 'chema@secmotic.com', this.props.lastElementSelected.id, name ? name : 'Ciudadano', result.value.replaceAll('\n', '</p><p>'), this.props.lastElementSelected.description);
                                                                Swal.fire('Email enviado!', 'El ciudadano recibirá su email pronto.', 'success')
                                                                getEmailsByIncidenceId({id: this.props.lastElementSelected.id})
                                                            }
                                                        })
                                                    } else {
                                                        return Swal.fire('Ha ocurrido algún error', 'Intenta de nuevo el envío del email', 'error')
                                                    }
                                                })
                                                .catch((e) => {
                                                    console.error(e);
                                                    return Swal.fire('Ha ocurrido algún error', 'Intenta de nuevo el envío del email', 'error');
                                                });
                                        } catch (error) {
                                            Swal.fire('Ha ocurrido algún error', 'Intenta de nuevo el envío del email', 'error')
                                        }
                                    }
                                  })
                            )
                        }} tooltip={"Enviar mail personalizado"} />
                        {/* <IncidentApprovalButtonSAC data={this.props}/> */}
                        { lastElementSelected && <RenderAppropiateStatusHandle status={lastElementSelected.status} arrayOfIds={[lastElementSelected.id]}/> }
                        <RoundButton icon={AddIcon} onClick={()=>setMode(create)} tooltip={"Añadir nueva incidencia"}/>
                    </>
                )
        }
    };


    render() {
        /** Functions **/
        const { printAlertIsOpen } = this.state;
        return (
            <Header>
                <Alert
                    title="¿Ha impreso la incidencia?"
                    open={printAlertIsOpen}
                    onClose={this.closePrintedAlert}
                    onConfirm={this.confirmIncidentIsPrinted}
                    showCancelButton={true}
                    confirmButtonText={'Si'}
                    cancelButtonText={'No'}
                />
                { this.renderAppropriateContent() }
            </Header>
        )
    }
}

export default Consumer(HeaderSidebar);