import React from 'react';
import './index.scss';
import { Link } from '@reach/router';
import { paths } from "../../configuration/paths";
import { Form, InputStyled as Input } from "../../components/Form";
import PropTypes from 'prop-types';
import Button from './../../elements/Button';
import { User } from '@secmotic/models';
import { Registration } from '../../redux/auth/action';
import Utils from "../../helpers/UtilClassWithCommonFunctions";
import { WarningAnimation, SuccessAnimation } from '../../components/SweetAlert/Animations'


class Registry extends Utils {

    static propTypes = {
        email: PropTypes.string,
        password: PropTypes.string,
        errors: PropTypes.array,
    };

    static defaultProps = {
        email: '',
        password: '',
        errors: [],
        successRegistration: false,
        errorRegistration: false,
        warningRegistration: false
    };

    constructor(props){
        super(props);
        this.state = {
            email: this.props.email,
            errors: this.props.errors,
            successRegistration: this.props.successRegistration,
            errorRegistration: this.props.errorRegistration,
            warningRegistration: this.props.warningRegistration
        }
    }

    submit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const user = new User({ ...this.state });

        const val = user.validate({
            email: user.Constraints.email,
            name: user.Constraints.name,
            surname: user.Constraints.surname,
            address: user.Constraints.address,
            telephone: user.Constraints.telephone,
        });

        val ? this.setErrors(val, 'errors') :
            Registration({
                email: this.state.email,
                name: this.state.name,
                surname: this.state.surname,
                address: this.state.address,
                telephone: this.state.telephone,
            },this.callbackRegistry)
    };

    callbackRegistry = (res) => {
        if(res.status === 200)
            this.setState({ successRegistration: true });
        else if(res.status === 409)
            this.setState({ errorRegistration: true });
        else
            this.setState({ warningRegistration: true});
    };

    handleChange = (e) => this.setState({ [e.target.name]: e.target.value });

    hideAlerts = () => {
        this.state.successRegistration ? this.props.navigate("../" + paths.Login) :
        this.setState({
            successRegistration: false,
            errorRegistration: false,
            warningRegistration: false
        });
    };

    render(){
        const { email,
            name,
            surname,
            telephone,
            errors
        } = this.state;

        /** Alert States **/
        const {
            successRegistration,
            errorRegistration,
            warningRegistration
        } = this.state;

        return (
            <div className="login-wrapper">
                <div className="login-form-wrapper">
                    {
                        errorRegistration &&
                        <>
                            <div className="flex-1 align-center-vertical pb-1">
                                <WarningAnimation>
                                    <p>¡Vaya! Parece que ese correo <strong>ya está registrado</strong> en nuestra aplicación o pendiente de validación. <strong>Confirma tu correo</strong> mediante el mail de validación que encontrarás en la bandeja de entrada.</p>
                                </WarningAnimation>
                            </div>
                            <Button fullWidth onClick={this.hideAlerts}>Entendido</Button>
                        </>

                    }
                    {
                        warningRegistration &&
                        <>
                            <div className="flex-1 align-center-vertical pb-1">
                                <WarningAnimation>
                                    <p>Ha ocurrido un error inesperado. Por favor vuelve a intentarlo o contacta con nosotros.</p>
                                </WarningAnimation>
                            </div>
                            <Button fullWidth onClick={this.hideAlerts}>Entendido</Button>
                        </>
                    }
                    {
                        successRegistration &&
                        <>
                            <div className="flex-1 align-center-vertical pb-1">
                                <SuccessAnimation>
                                    <p>Registro completado con éxito. Debes confirmar tu correo electrónico mediante el email que encontrarás en tu bandeja de entrada.</p>
                                </SuccessAnimation>
                            </div>
                            <Button fullWidth onClick={this.hideAlerts}>Entendido</Button>
                        </>
                    }
                    {
                        (!successRegistration && !errorRegistration && !warningRegistration) && (
                            <>
                                <Form onSubmit={this.submit} className="flex-1 form-auth">
                                    <h2>Registro</h2>
                                    <h6 className={'label'}>Email</h6>
                                    <Input
                                        noLabel
                                        name={'email'}
                                        type={'text'}
                                        value={email}
                                        onChange={this.handleChange}
                                        errorMsg={errors['email']}
                                    />
                                    <h6 className={'label'}>Nombre</h6>
                                    <Input
                                        noLabel
                                        name={'name'}
                                        type={'text'}
                                        value={name}
                                        onChange={this.handleChange}
                                        errorMsg={errors['name']}
                                    />
                                    <h6 className={'label'}>Apellidos</h6>
                                    <Input
                                        noLabel
                                        name={'surname'}
                                        type={'text'}
                                        value={surname}
                                        onChange={this.handleChange}
                                        errorMsg={errors['surname']}
                                    />
                                    {/*<h6 className={'label'}>Dirección</h6>*/}
                                    {/*<Input*/}
                                    {/*    noLabel*/}
                                    {/*    name={'address'}*/}
                                    {/*    value={address}*/}
                                    {/*    onChange={this.handleChange}*/}
                                    {/*    errorMsg={errors['address']}*/}
                                    {/*/>*/}
                                    <h6 className={'label'}>Teléfono de contacto</h6>
                                    <Input
                                        noLabel
                                        name={'telephone'}
                                        value={telephone}
                                        onChange={this.handleChange}
                                        errorMsg={errors['telephone']}
                                    />
                                </Form>
                                <Link to={"../" + paths.Login} className={'link p-1'}>Ya estoy registrado</Link>
                            </>)
                    }
                </div>
                {
                    (!successRegistration && !errorRegistration && !warningRegistration) &&
                    <div className="pl-1 pr-1">
                        <Button fullWidth onClick={this.submit}>Registrarse</Button>
                    </div>
                }
            </div>
        );
    }
}

export default Registry;