"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var IncidenceContstraints = {
  code: {
    presence: {
      message: "Debe introducir un código"
    }
  },
  zone: {
    presence: {
      message: "Debes introducir una ubicación"
    },
    inclusion: {
      within: ["LR", "SLR", "OTR"],
      message: "La zona introducida no es válida"
    }
  },
  executor: {},
  status: {
    inclusion: {
      within: ["close", "open", "pending", "rejected", "irrelevant"],
      message: "%{value} no es un estado válido"
    }
  },
  presenterName: {},
  presenterSurname: {},
  presenterEmail: {},
  presenterTelephone: {},
  description: {
    presence: {
      message: "Debe introducir una descripción"
    },
    length: {
      minimum: 6,
      message: "La descripción debe ser un poco más larga"
    }
  },
  city: {},
  number: {},
  street: {},
  zipCode: {},
  "coords.lng": {
    presence: {
      message: "Debe introducir una ubicación"
    },
    numericality: {
      message: "Longitud debe ser un número"
    }
  },
  "coords.lat": {
    presence: {
      message: "Debe introducir una ubicación"
    },
    numericality: {
      message: "Latitud debe ser un número"
    }
  },
  images: {},
  creDate: {},
  modDate: {},
  printed: {
    presence: {
      message: "Debe introducir información acerca de si la incidencia ha sido ya impresa o no"
    },
    type: "boolean"
  }
};
exports.default = IncidenceContstraints;