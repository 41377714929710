import React from 'react';
import ReactDOM from "react-dom";
import TemplateToPrint from "../../../../componentsScreens/TemplateToPrint";
import PropTypes from 'prop-types';

class FindIncidentForPrint extends React.Component {

    static defaultProps = {
        id: null
    };

    static propTypes = {
        id: PropTypes.string
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.props.id !== nextProps.id
    }

    render() {
        const {id} = this.props;
        if(!id) return null;
        return ReactDOM.createPortal(<TemplateToPrint id={id} key={`Printed-${id}`}/>, document.querySelector('#print-area-plural'))
    }

}

export default FindIncidentForPrint;
