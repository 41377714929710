import React from "react";
import Consumer from "../../Context/Consumer";
import TaxonomyFinder from "./TaxonomyFinder";
import TaxonomyFilter from "./TaxonomyFilter";
import Divider from "../../../../elements/Divider";

class TaxonomyBlock extends React.PureComponent {

    handleTaxFilterChange = (e) => {
        const { setIn, type } = this.props;
        const nameInContextState = `${type}Finder`;
        setIn(nameInContextState, e.target.value);
    };

    generateTaxonomyFilter = (e, data) =>{
        const { filters, setIn, type } = this.props;
        const nameInContextState = `${type}Filters`;
        const taxonomyFilters = filters[nameInContextState];
        if(taxonomyFilters.includes(data.id)) setIn(nameInContextState,taxonomyFilters.filter(z => z !== data.id ));
        else setIn(nameInContextState, [...taxonomyFilters, data.id]);
    };

    render() {

        const { data, type, filters } = this.props;
        const nameInContextStateForFinderValue = filters[`${type}Finder`];
        const nameInContextStateForFilterValue = filters[`${type}Filters`];
        return (
            <>
                <TaxonomyFinder
                    onChange={this.handleTaxFilterChange}
                    value={nameInContextStateForFinderValue}
                    label={`Buscar por ${type}`}
                />

                <div className={'Taxonomy-Filter-Wrapper'}>
                    { (data && data.length > 0)
                        ? data.map(tax => tax.name.toUpperCase().includes(nameInContextStateForFinderValue.toUpperCase()) ?
                            <TaxonomyFilter
                                selected={ nameInContextStateForFilterValue.includes(tax.id) }
                                key={ tax.id }
                                onClick={ this.generateTaxonomyFilter }
                                data={ tax }/>
                            : null)
                        : <i> No se han encontrado datos para mostrar </i>
                    }
                </div>
                <Divider/>
            </>
        )
    }
}

export default Consumer(TaxonomyBlock);