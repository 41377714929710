import React from 'react';
import ReactDOM from 'react-dom';
import Map from './../Map';
import './style.scss';
import { MaximizeIcon, MinimizeIcon } from '../../configuration/icons';
import RoundButton from "../../elements/RoundButton";

class MapContainer extends React.Component{

    maxMap = null;

    state = {
        maximized: false
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return (
            nextProps.userCenter !== this.props.userCenter ||
            nextProps.center !== this.props.center ||
            nextState.maximized !== this.state.maximized
        )
    }

    handleMaximizeMap = () => {
        this.setState({ maximized: true });
        setTimeout(this.maxMap.repaintMap, 200)
    };

    handleMinimizeMap = () => {
        this.setState({ maximized: false })
    };

    render() {
        const MaxMapContainer = document.querySelector('body');
        const { style, ...rest}  = this.props;
        const { maximized } = this.state;
        return(
            <>
                <div className={'map-container mb-1'} style={style}>
                    <Map {...rest} style={style}/>
                </div>
                {
                    ReactDOM.createPortal(
                        <div className={`max-map-container ${maximized ? 'show' : ''}`}>
                            <div className={'min-max-button-container'}>
                                <RoundButton icon={MinimizeIcon} tooltip={"Minimizar mapa"} onClick={this.handleMinimizeMap}/>
                            </div>
                            <Map {...rest} className={"map-canvas"} containerId={`max-${rest.containerId}`} ref={node => this.maxMap = node}/>
                        </div>
                        , MaxMapContainer)
                }
            </>
        )
    }
}

export default MapContainer;