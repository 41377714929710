import React from 'react';
import { Edition, Visualization } from '../../../../../components/Comment';
import Divider from '../../../../../elements/Divider';


class CommentsBlock extends React.PureComponent {
    static prevProps = {
        updateComments: ()=>{}
    };

    constructor(props){
        super(props);
        this.state = {
            comments: this.props.newComments
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.newComments !== prevProps.newComments && this.props.newComments !== this.state.comments)
            this.setState({comments: this.props.newComments});

        if(this.state.comments !== prevState.comments)
            this.props.updateComments(this.state.comments)
    }

    showAddNewComment = () => {
        const newComment = {content: ''};
        this.setState({
            comments: [newComment, ...this.state.comments]
        })
    };

    onChange = (e, comment) => {
        const Comments = [...this.state.comments];
        let index = Comments.findIndex(item => item === comment);
        if (index > -1) {
            Comments[index].content = e.target.value;
            this.setState({ comments: [...Comments] })
        }
    };

    onDelete = (e, comment) => {
        const Comments = [...this.state.comments];
        let index = Comments.findIndex(item => item === comment);
        if (index > -1) {
            Comments.splice(index,1);
            this.setState({ comments: [...Comments] })
        }
    };

    render() {
        const { comments } = this.state;
        const { oldComments } = this.props;
        return(
            <>
                <p className={'link'} onClick={this.showAddNewComment}>Agregar un nuevo comentario</p>
                {
                    (comments && comments.length > 0) && (
                        <>
                            {
                                comments.map((comment, index) => (
                                    <Edition
                                        key={index}
                                        onDelete={(e) => this.onDelete(e, comment)}
                                        onChange={(e)=>this.onChange(e, comment)}
                                        value={comment.content}
                                    />
                                ))
                            }
                            <Divider/>
                        </>)
                }
                {
                    oldComments.map((comment, index) => (
                            <Visualization
                                last = { index === oldComments.length - 1}
                                comment={comment}
                                key={comment.id}
                            />
                        )
                    )
                }
            </>
        )
    }
}

export default CommentsBlock;