import React, {Fragment} from 'react';
import Button from "../../../../elements/Button";
import HeaderSidebar from './HeaderSidebar';
import { Form, Input } from '../../../../components/Form';
import { create, update, view } from "../../../../configuration/operationNames";
import { updateTaxonomy, createTaxonomy, getTaxonomies } from "../../../../redux/taxonomies/action";
import { family, subfamily } from "../../../../configuration/entitiesNames";
import Consumer from '../../../Taxonomies/SidebarContext/Consumer';
import { connect } from 'react-redux';
import AsyncSelect from 'react-select/async';
import FindTaxonomyByIdInRedux from "../../../../helpers/redux-helpers/FindTaxonomyByIdInRedux";
import FooterSidebar from "../../../../components/Sidebar/Footer";
import BodySidebar from "../../../../components/Sidebar/Body";
import { Taxonomy } from '@secmotic/models';
import Utils from "./../../../../helpers/UtilClassWithCommonFunctions";

class Create extends Utils {
    constructor(props){
        super(props);
        this.state = {
            familyOptions:[],
            errors: [],
            assignedTo: this.getAssignedTo(),
            parent: this.getParent(),
            name: this.getName(),
            id: this.props.id || null
        }
    }

    componentDidMount() {
        getTaxonomies({type:family});
    }

    componentDidUpdate(prevProps, prevState, prevSnap) {
        if(this.props.lastElementSelected !== prevProps.lastElementSelected){
            this.setState({
                parent: this.getParent(),
                name: this.getName(),
                id: this.props.id,
                assignedTo: this.getAssignedTo(),
            });
        }
    }

    getName = () => {
        const {sidebarMode, lastElementSelected} = this.props;
        if(sidebarMode === update && lastElementSelected)
            return lastElementSelected.name || '';
        return  '';
    };
    
    getAssignedTo = () => {
        const {sidebarMode, lastElementSelected} = this.props;
        if(sidebarMode === update && lastElementSelected)
            if (lastElementSelected.assignedTo) {
                return this.props.usersOptions.filter((user) => lastElementSelected.assignedTo.indexOf(user.label) > -1);
            } else {
                return []
            }
        return  [];
    };

    getParent = () => {
        const { sidebarMode, lastElementSelected } = this.props;
        if(sidebarMode === update && lastElementSelected) {
            const FamilyFounded = FindTaxonomyByIdInRedux(lastElementSelected.parent, family);
            return FamilyFounded ? { value: FamilyFounded.id, label: FamilyFounded.name } : lastElementSelected.parent
        }
        return  '';
    };

    onChangeName = ({ target }) => {
        this.handleOnChange(target.name, target.value)
    };

    submit = (e) => {
        e && e.preventDefault();
        e && e.stopPropagation();
        ( this.props.sidebarMode === update && this.props.id ) ? this.updateSubfamily() : this.createSubfamily();
    };

    updateSubfamily = () => this.handleCreationUpdationInOrion(updateTaxonomy);
    createSubfamily = () => this.handleCreationUpdationInOrion(createTaxonomy);

    handleCreationUpdationInOrion(functionToAction){
        const TaxonomyFromModel = new Taxonomy({ ...this.state, type: subfamily, assignedTo: this.state.assignedTo.map(v => v.value) });
        if(this.state.parent.value !== "none") TaxonomyFromModel.parent = this.state.parent.value;
        const validation = TaxonomyFromModel.validate();
        if(TaxonomyFromModel.parent === "none") validation.parent = ["Debes seleccionar una familia vinculada"];
        if( validation && Object.keys(validation).length > 0) this.setErrors(validation);
        else functionToAction(TaxonomyFromModel.getJSON(), this.callbackOrionTaxonomy)
    }

    callbackOrionTaxonomy = (response) => {
        if(response.status === 200) {
            this.props.navigate(response.data[0].id, {state:response.data[0]});
            this.props.setMode(view);
        }
    };

    loadFamilies = (inputValue, callback) => {
        getTaxonomies({type: family, name: inputValue}, (response) => this.callbackGetParents(response, callback));
    };

    callbackGetParents = (response, selectCallback) => {
        if(response.status === 200)
            return selectCallback(response.data.map(family => ({ value: family.id, label: family.name })));

        return selectCallback([])
    };

    onSelectChange = (value) => {
        this.setState({
            parent: value,
            errors: this.sanitizeErrors('parent')
        });
        return value;
    };

    render() {
        /** Miscellaneous**/
        const { sidebarMode, familiesOptions, navigate, usersOptions } = this.props;
        const { parent, name, errors, assignedTo } = this.state;

        return(
            <Fragment>
                <HeaderSidebar navigate={navigate}/>
                <BodySidebar>
                    <Form onSubmit={this.submit}>
                        <h4 className={'mt-0'}>Añade un nombre a la subfamilia</h4>
                        <Input
                            noLabel
                            onChange={this.onChangeName}
                            name={'name'}
                            value={name}
                            infoMsg={'Añade un nombre descriptivo'}
                            errorMsg={errors['name']}
                        />

                        <h4 className={'mt-3'}>Vincula a una familia</h4>
                        <AsyncSelect
                            loadOptions={this.loadFamilies}
                            value={parent}
                            defaultOptions={familiesOptions}
                            cacheOptions
                            placeholder={'Añade la familia a la que pertenece'}
                            onChange={this.onSelectChange}
                            isSearchable={false}
                        />

                        <h4 className={'mt-3'}>Vincula a un usuario</h4>
                        <AsyncSelect
                            value={assignedTo}
                            isMulti
                            defaultOptions={usersOptions}
                            cacheOptions
                            placeholder={'Usuarios que verán estas incidencias por defecto'}
                            onChange={(value) => 
                                value ? this.setState({...this.state, assignedTo: value}) : this.setState({...this.state, assignedTo: []})
                            }
                            isSearchable={false}
                        />
                        {errors["parent"] && <p className={'error-msg mb-1 mt-1'}>{errors["parent"]}</p>}
                    </Form>
                </BodySidebar>
                <FooterSidebar>
                    <Button className={'native-base-button center-text'}  onClick={this.submit} fullWidth >
                        {sidebarMode === create ? "Crear subfamilia" : "Guardar subfamilia"}
                    </Button>
                </FooterSidebar>
            </Fragment>
        )
    }
}


const mapStateToProps = ({taxonomies, users}) => ({
    familiesOptions: taxonomies[family].map(tax => ({label: tax.name, value: tax.id })),
    usersOptions: users.usuario.map(user => ({label: user.email, value: user.email})),
});

export default Consumer(connect(mapStateToProps)(Create))