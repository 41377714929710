import React from 'react';
import RoundButton from "../RoundButton";
import STATUS from "../../configuration/statusOptions";
import './index.scss';

export default class RoundStatusIndicator extends React.PureComponent{
    render(){
        const { className, status, ...rest } = this.props;
        const tooltip = `El estado de esta incidencia es ${STATUS[status] ? STATUS[status].translated : STATUS['pending'].translated}`;
        const statusClassName = `round-status-indicator ${status || 'pending'}`;
        const icon = STATUS[status] ? STATUS[status].icon : STATUS['pending'].icon;

        return(
            <RoundButton
                {...rest}
                icon={ icon }
                className={`${ className ? className : '' } ${ statusClassName }`}
                tooltip={tooltip}
            />
        )
    }
}