import React, { Fragment } from 'react';
import ReactDOM from "react-dom";

/** Config Vars **/
import { incident } from "../../../configuration/entitiesNames";
import { AddIcon, CloseIcon, PrintIcon, RechargeIcon, SearchIcon } from "../../../configuration/icons";
import { view, update, create } from "../../../configuration/operationNames";

/** Styles **/
import './index.scss'

/** Table Component connected with Redux special Taxonomies **/
import TaxonomyListView from './../../Taxonomies/List';

/** Content for render Views except List **/
import Sidebar from "../../../components/Sidebar";

/** General Family state context **/
import Consumer from '../../Taxonomies/SidebarContext/Consumer';
/** Filter Incident Consumer and Sidebar **/
import FilterIncidentSidebar from '../../../componentsScreens/FilterIncidentsSidebar';
import FilterConsumer from '../../../componentsScreens/FilterIncidentsSidebar/Context/Consumer';

/** Components **/
import RoundButton from "../../../elements/RoundButton";
import RenderAppropiateStatusHandle from "./Components/HeaderSidebar/RenderAppropiateStatusHandle";
import Alert from "../../../elements/Alert";
import Loading from '../../../elements/Loading';

/** Helpers **/
import StatusIsTheSame from './../../../helpers/redux-helpers/StatusIsTheSame';

/** CRUD Views **/
import Create from "./Components/Create";
import Update from "./Components/Update";
import { printIncidents } from "../../../redux/incidents/action";
import { paths } from "../../../configuration/paths";
import { AVERAGE_PRINT_TIME } from "../../../configuration/mode";
import IncidentsFilterButton from './Components/IncidentsFilterButton';
import SacApprovedButton from './Components/SacApprovedButton';

const HEADERS = [
    {key: 'code', title: 'Código', width: 100},
    {key: 'description', title: 'Descripción'},
    {key: 'address', title: 'Dirección'},
    // {key: 'executor', title: 'Modificada por'}, // TODO: Maybe undo
    {key: 'creDate', title: 'Creada En'},
    {key: 'status', title: 'Est.', width: 55 },
    {key: 'printed', title: 'Impr.', width: 55 },
];

class TopListButtons extends React.PureComponent {
    render() {
        const { synchronizeData, printSeveralTaxonomies, selected } = this.props;
        const UniqueStatusInSelected = StatusIsTheSame(selected);
        return (
            <div
                className={'round-buttons-group'}
                style={{
                    display: 'flex',
                    position: 'inherit',
                }}
            >
                {/* TODO: create button to indicate SAC filter */}
                <SacApprovedButton />
                <IncidentsFilterButton />
                <RoundButton icon={RechargeIcon} tooltip={"Sincronizar datos"} onClick={synchronizeData}/>
                { UniqueStatusInSelected && <RenderAppropiateStatusHandle status={UniqueStatusInSelected} arrayOfIds={selected}/> }
                { selected.length > 0 && <RoundButton icon={PrintIcon} tooltip={"Imprimir las seleccionadas"} onClick={printSeveralTaxonomies}/> }
            </div>
        )
    }
}

/** Main Class **/
class List extends React.PureComponent {
    constructor(props) {
        super(props);
        if(this.props.location.search === "?create") this.goToCreateIncidence();
    }
    TaxsInPrintArea = [];
    state = {
        printing: false,
        RightActionsIconsZone: null,
        printAlertIsOpen: false,
    };

    componentDidMount() {
        this.getTopRightLayoutContainer();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps['*'] !== this.props["*"] && this.props['*'] === ""){
            this.props.closeSidebar()
        }
    }

    onPressUpdateButton = (tax) => {
        this.props.navigate(tax.id, {state: tax}).then(()=> {
            this.props.setLastElementSelected(tax);
            this.props.setMode(update);
            this.props.openSidebar()
        });
    };

    openPrintedAlert = () =>  this.setState({ printAlertIsOpen: true });
    closePrintedAlert = () => this.setState({ printAlertIsOpen: false, printing: false });
    confirmIncidentIsPrinted = async () => {
        if(this.TaxsInPrintArea.length > 0) {
            const ids = this.TaxsInPrintArea.map(id=>({id}));
            printIncidents(ids);
        }
    };


    onPressPrintButton = (taxs) => {
        this.TaxsInPrintArea = taxs;
        const SinglePrintBlock = document.getElementById('print-area-single');
        SinglePrintBlock.classList.remove('show');
        const PluralPrintBlock = document.getElementById('print-area-plural');
        PluralPrintBlock.classList.add('show');

        this.setState({ printing: true }, this.continueWithPrintProcess);
    };

    continueWithPrintProcess = () => {
        setTimeout(()=> {
            window.print();
            if (window.onafterprint) { //check if browser supports window.onafterprint event handler (Firefox and IE)
                window.onafterprint = this.openPrintedAlert;
            }
            else { //Use setTimeout solution if onafterprint is not supported (Chrome)
                setTimeout(this.openPrintedAlert, 100);
            }
        }, AVERAGE_PRINT_TIME * this.TaxsInPrintArea.length);
    };

    goToCreateIncidence = ( ) => {
        this.props.setMode(create);
        this.props.openSidebar()
    };

    closeSidebar = () => {
      this.props.navigate(`/app/${paths.Incidents}/list`);
      this.props.closeSidebar();
    };

    topNavButtons = () => {
        const { openFilterSidebar, closeFilterSidebar, sidebarIsOpen, filterSidebarIsOpen } = this.props;
        return (
            <div className={'top-var-buttons'}>
                <RoundButton
                    className={`${filterSidebarIsOpen ? 'sidebar-is-open' : ''} mr-1`}
                    icon={filterSidebarIsOpen ? CloseIcon : SearchIcon}
                    tooltip={filterSidebarIsOpen ? "Cerrar Buscador" : "Abrir Buscador"}
                    onClick={filterSidebarIsOpen ? closeFilterSidebar : openFilterSidebar}/>
                <RoundButton
                    className={`${sidebarIsOpen ? 'sidebar-is-open' : ''} mr-1 create-incident`}
                    icon={sidebarIsOpen ? CloseIcon : AddIcon}
                    tooltip={sidebarIsOpen ? "Cerrar panel lateral" : "Crear nueva incidencia"}
                    onClick={sidebarIsOpen ? this.closeSidebar : this.goToCreateIncidence}/>
            </div>
        )
    };

    getTopRightLayoutContainer = () => {
        this.setState({ RightActionsIconsZone: document.getElementById('right-actions-icons-zone') });
    };

    render() {
        /** Print Alert Status **/
        const { printAlertIsOpen, printing } = this.state;

        /** Portals Areas **/
        const { RightActionsIconsZone } = this.state;

        /** PROPS-VARS FROM SIDEBAR CONTEXT **/
        let { sidebarIsOpen, sidebarMode } = this.props;

        /** PROPS-VARS FROM FILTER CONTEXT **/
        let { filterSidebarIsOpen } = this.props;

        /** View Screen from Route **/
        const { children, navigate } = this.props;

        return(
            <Fragment>
                { RightActionsIconsZone && ReactDOM.createPortal(this.topNavButtons(), RightActionsIconsZone) }
                { printing && ReactDOM.createPortal(<Loading text={'Creando impresión...'}/>, document.getElementById('root')) }

                <Alert
                    title="¿Has impreso las incidencias?"
                    open={printAlertIsOpen}
                    onClose={this.closePrintedAlert}
                    onConfirm={this.confirmIncidentIsPrinted}
                    showCancelButton={true}
                    confirmButtonText={'Si'}
                    cancelButtonText={'No'}
                />
                <TaxonomyListView
                    {...this.props}
                    limit={50}
                    IconZone={TopListButtons}
                    headers={HEADERS}
                    title={'Listado de Incidencias'}
                    entity={incident}
                    onPressDeleteButton={this.onPressDeleteButton}
                    onPressUpdateButton={this.onPressUpdateButton}
                    onPressPrintButton={this.onPressPrintButton}
                />

                <Sidebar open={ sidebarIsOpen }>
                    <Fragment>
                        { sidebarMode === view && children }
                        { sidebarMode === create &&  <Create navigate={navigate} /> }
                        { sidebarMode === update && <Update navigate={navigate} id={this.props['*']}/> }
                    </Fragment>
                </Sidebar>

                <Sidebar open={ filterSidebarIsOpen }>
                    <FilterIncidentSidebar />
                </Sidebar>

            </Fragment>
        )
    }
}

export default Consumer(FilterConsumer(List));