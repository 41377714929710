export default (object) => {
    if(object) {
        if (object.lat && object.lng) {
            const {lat, lng} = object;
            return {latitude: lat, longitude: lng}
        }

        if (object.latitude && object.longitude) {
            const {latitude, longitude} = object;
            return {latitude, longitude}
        }
    }
    return {latitude: undefined, longitude: undefined}
}