import React from 'react';
import FilterConsumer from '../../Context/Consumer';
import Divider from './../../../../elements/Divider'
import RoundButton from "../../../../elements/RoundButton";
import { CloseIcon } from "../../../../configuration/icons";
import RowInFilterSidebar from "./../../../../components/RowInFilterSidebar/RowInFilterSidebar";

class IncidentSelected extends React.Component {
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.props.filters.entitiesIncludes !== nextProps.filters.entitiesIncludes;
    }

    deleteIncidentFromSelectedFilter = (incident) => {
        const { setIn, filters } = this.props;
        setIn('entitiesIncludes', filters.entitiesIncludes.filter(u => u !== incident) )
    };

    render() {
        const { filters } = this.props;
        const { entitiesIncludes } = filters;
        return(
            <>
                <RowInFilterSidebar>
                    { entitiesIncludes.length > 0 && <h6 className={'label pl-1 pr-1'}>Incidencias seleccionadas</h6> }
                    {
                        entitiesIncludes.map(u => {
                            return (
                                <div className={'item'} key={u.code}>
                                    <div>
                                        <p className={'p-0 m-0'}>Código: {u.code}</p>
                                        <p className={'p-0 m-0'}>{u.description}</p>
                                        <p className={'p-0 m-0'}><i>{u.address}</i></p>
                                    </div>
                                    <div className={'right'}>
                                        <RoundButton onClick={()=>this.deleteIncidentFromSelectedFilter(u)} icon={CloseIcon} tooltip={'Quitar de la selección'}/>
                                    </div>
                                </div>
                            )
                        })
                    }
                </RowInFilterSidebar>
                <Divider/>
           </>
        )
    }
}

export default FilterConsumer(IncidentSelected)