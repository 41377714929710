import React from 'react';
import './style.scss'
import parseTimeToMoment from "../../helpers/moment/parseTimeToMoment";

class Visualization extends React.PureComponent{

    render() {
        const { comment, last } = this.props;
        const { content, creDate } = comment;
        return (
            <div className={`visualization-comment-wrapper ${last ? 'last' : ''}`}>
                <p>{content}</p>
                <p className={'additional-information'}>{ creDate ? parseTimeToMoment(creDate) : "Sin fecha de creación" }</p>
            </div>
        )
    }
}

export default Visualization;