import React from 'react';
import STATUS from "../../../../../configuration/statusOptions";
import OpenStatus from "../../../../../componentsScreens/StatusAlerts/Open";
import CloseIrrelevantAndRejectedStatus from "../../../../../componentsScreens/StatusAlerts/CloseIrrelevantOrRejected";
import PendingStatus from "../../../../../componentsScreens/StatusAlerts/Pending";

class RenderAppropiateStatusHandle extends React.PureComponent{
    render(){
        const {status, arrayOfIds} = this.props;
        const isPlural = arrayOfIds.length > 1;

        switch (status) {
            case STATUS.open.name:
                return <OpenStatus ids={arrayOfIds} isPlural={isPlural}/>;
            case STATUS.close.name:
                return <CloseIrrelevantAndRejectedStatus ids={arrayOfIds} title={isPlural ? "cerradas" : "cerrada"} isPlural={isPlural}/>;
            case STATUS.irrelevant.name:
                return <CloseIrrelevantAndRejectedStatus ids={arrayOfIds} title={isPlural ? "que no proceden" :  "que no procede"} isPlural={isPlural}/>;
            case STATUS.pending.name:
                return <PendingStatus ids={arrayOfIds} isPlural={isPlural}/>;
            case STATUS.rejected.name:
                return <CloseIrrelevantAndRejectedStatus ids={arrayOfIds} title={isPlural ? "rechazadas" :  "rechazada"} isPlural={isPlural}/>;
            default:
                return <PendingStatus ids={arrayOfIds} isPlural={isPlural}/>
        }
    }
}

export default RenderAppropiateStatusHandle