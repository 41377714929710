import React from 'react';
import { HeaderSidebar as OriginalHeaderSidebar } from '../../../components/Sidebar'
import CancelButton from "./CancelButton";
import GoBackInMobile from "./GoBackInMobile";

class HeaderSidebar extends React.Component {
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return false;
    }
    
    render(){
        return(
            <OriginalHeaderSidebar>
                <GoBackInMobile/>
                <CancelButton/>
            </OriginalHeaderSidebar>
        )
    }
}

export default HeaderSidebar;