import React from 'react';
import './index.scss';

class SuccessAnimation extends React.PureComponent{
    render() {
        const { children, className } = this.props;

        return(
            <div className={`${className ? className : ''} sa`}>
                <div className="sa-success">
                    <div className="sa-success-tip"/>
                    <div className="sa-success-long"/>
                    <div className="sa-success-placeholder"/>
                    <div className="sa-success-fix"/>
                </div>
                <div className={'sa-content'}>
                    {children}
                </div>
            </div>
        )
    }
}

export default SuccessAnimation;