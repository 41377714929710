import React from 'react';
import PropTypes from 'prop-types';
import { FilterConsumer } from '../Context';
import { user } from "../../../../configuration/entitiesNames";
import { connect } from "react-redux";
import { UserAgreeWithString } from './../../functions';
import RowInFilterSidebar from "../../../../components/RowInFilterSidebar/RowInFilterSidebar";

class ShowUsersFiltered extends React.Component {

    static defaultProps = {
        usersFiltered: []
    };

    static propTypes = {
        usersFiltered: PropTypes.array
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps.usersFiltered !== this.props.usersFiltered;
    }

    addToSelectedInFilter = (user) => {
        const { setInFilter, filters } = this.props;
        const { selected } = filters;
        !selected.includes(user.id) && setInFilter('selected', [...selected, user.id])
    };

    render() {
        const { usersFiltered } = this.props;
        return(
            <RowInFilterSidebar>
                {
                    usersFiltered.map(u => (
                        <div className={'pl-1 pr-1 item pointer'} onClickCapture={() => this.addToSelectedInFilter(u)} key={u.id}>
                            <div>
                                <p className={'p-0 m-0'}>{u.name + ' ' + u.surname}</p>
                                <p className={'p-0 m-0'}><i>{u.email}</i></p>
                            </div>
                        </div>
                    ))
                }
            </RowInFilterSidebar>
        )
    }
}


const mapStateToProps = ({users}, {filters}) => {
    let usersFiltered = [];


    if(filters.string.length > 0 && filters.selected.length > 0) {
        usersFiltered = users[user].filter(u => UserAgreeWithString(u, filters.string) && !filters.selected.includes(u.id));
    }

    else if(filters.string.length > 0)
        usersFiltered = users[user].filter(u => UserAgreeWithString(u, filters.string));

    return { usersFiltered }

};

export default FilterConsumer(connect(mapStateToProps)(ShowUsersFiltered))
