import { requestApi } from './requestApi';
import BuildFiwareQuery from './../helpers/BuildFiwareQuery';

const orionRegexRestricctions = /[&/\\#,+()$~%'":;*?<>{}]/g;


export const getSingleEntity = async ( id ) =>{
    let url = `v2/entities/${id}?options=keyValues`;
    return await requestApi({
        method: 'GET',
        url: url
    });
};

// TODO Change by GetEntities
export const getTaxonomies = async ( type, id, attr, page=0, limit=10, orderBy='desc' ) => {
    orderBy = orderBy === 'desc' ? '!dateCreated' : 'dateCreated';
    let url = `v2/entities?options=keyValues,count&type=${type}&orderBy=${orderBy}`;
    url+=`&offset=${page*limit}`;
    url+=`&limit=${limit}`;
    if (id) url+= `&id=${id}`;
    if (attr && Object.keys(attr).length > 0) url+= BuildFiwareQuery(attr);

    return await requestApi({
        method: 'GET',
        url: url
    });
};

export const filterTaxonomies = async ( type, query, page=0, limit=10, orderBy='desc' ) => {
    orderBy = orderBy === 'desc' ? '!dateCreated' : 'dateCreated';
    let url = `v2/entities?options=keyValues,count&type=${type}&orderBy=${orderBy}`;
    url+=`&offset=${page*limit}`;
    url+=`&limit=${limit}`;
    if(query) url+='&'+query;

    return await requestApi({
        method: 'GET',
        url: url
    });
};

export const getHistorical = async ( id, size = 100, page = 1, sortRecvTime = "desc" ) => {
    let Token = localStorage.getItem('auth');
    Token = Token && JSON.parse(Token).access_token;
    return await requestApi({
        method: 'GET',
        headers:{
            'x-fiware-service': 'events_rinconada' ,
            'x-fiware-servicepath': 'events_/' ,
            'x-nick-name': Token,
            'x-query-sort-': 'desc',
            'x-query-size': size,
            'x-query-page': page,
        },
        url: `api/v1/historical/search?entityId=${id}&x-query-page=${page}&x-query-size=${size}&x-query-sort-recvTime=${sortRecvTime}`
    });
};

export const createTaxonomy = async (taxonomy) => {
    Object.keys(taxonomy).forEach(key => {
        if(typeof taxonomy[key] === "string") taxonomy[key] = taxonomy[key].replace(orionRegexRestricctions, '');
    });
    return await requestApi({
        method: 'POST',
        url: 'v2/entities?options=keyValues',
        body: JSON.stringify(taxonomy),
        headers: {'Content-Type': 'application/json'},
    });
};

export const appendTaxonomy = async (taxonomy) => {
    Object.keys(taxonomy).forEach(key => {
        if(typeof taxonomy[key] === "string") taxonomy[key] = taxonomy[key].replace(orionRegexRestricctions, '');
    });
    return await requestApi({
        method: 'POST',
        url: `v2/entities/${taxonomy.id}/attrs?options=keyValues`,
        body: JSON.stringify({actionType: 'append', entities: [{...taxonomy, type: 'incidencia'}]}),
        headers: {'Content-Type': 'application/json'},
    });
};

export const updateTaxonomy = async( {id, type, ...taxonomy} ) => {
    Object.keys(taxonomy).forEach(key => {
        if(typeof taxonomy[key] === "string") taxonomy[key] = taxonomy[key].replace(orionRegexRestricctions, '');
    });
    return await requestApi({
        method: 'PUT',
        url: `v2/entities/${id}/attrs?options=keyValues`,
        // body: JSON.stringify(taxonomy),
        body: JSON.stringify({ telephone: taxonomy.telephone }),
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

export const patchTaxonomy = async( {id, type,...taxonomy} ) => {
    Object.keys(taxonomy).forEach(key => {
        if(typeof taxonomy[key] === "string") taxonomy[key] = taxonomy[key].replace(orionRegexRestricctions, '');
    });
    return await requestApi({
        method: 'PATCH',
        url: `v2/entities/${id}/attrs?options=keyValues`,
        body: JSON.stringify(taxonomy),
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

export const removeTaxonomy = async( taxonomyID, type ) =>
    await requestApi({
        method: 'DELETE',
        url: `v2/entities/${taxonomyID}?type=${type}`,
    });

export const getMeFromOrion = async(userId) =>
    await requestApi({
        method: 'GET',
        url: `/v2/entities?options=keyValues&type=usuario&attrs=telephone,address,userId&q=userId==${userId}`
    });