import React from 'react';
import Alert from "../../../elements/Alert";
import { StatusIcon } from "../../../configuration/icons";
import RoundButton from "../../../elements/RoundButton";
import { createIncident, getIncidents } from "../../../redux/incidents/action";
import { incident } from "../../../configuration/entitiesNames";
import STATUS from "../../../configuration/statusOptions";
import { navigate } from "@reach/router";
import { Incident } from "@secmotic/models";

class CloseIrrelevantAndRejectedStatus extends React.PureComponent{

    state = {
        alertIsOpen: false
    };

    openAlert = () => {
        if(!this.state.alertIsOpen) this.setState({alertIsOpen: true})
    };

    closeAlert = () =>{
        if(this.state.alertIsOpen) this.setState({alertIsOpen: false})
    };

    createRelatedIncident = () => {
        const { ids } = this.props;

        ids.forEach( id => {
            this.getSingleRelatedIncident(id)
        });

    };

    getSingleRelatedIncident = (id) => {
        getIncidents({id:id, type: incident}, this.createSingleRelatedIncident)

    };

    createSingleRelatedIncident = async (response) => {
        if(response.status === 200 && response.data && response.data[0]){
            const originalIncident = response.data[0];
            const newIncident = new Incident({...originalIncident});
            newIncident.relatedIncident = originalIncident.id;
            newIncident.creDate = new Date().getTime();
            newIncident.modDate = new Date().getTime();
            newIncident.printed = false;
            newIncident.validated = false;
            newIncident.status = STATUS.open.name;

            delete newIncident.id;
            createIncident({...newIncident.getJSON()}, this.goToTheNewIncident);
        }
    };

    goToTheNewIncident = (response) => {
        if(response.status === 200) {
            const CreatedIncident = response.data[0];
            navigate(CreatedIncident.id, {state: {...CreatedIncident}});
        }
    };

    render() {
        const { title } = this.props;
        const { alertIsOpen } = this.state;
        return(
            <>
                <RoundButton icon={StatusIcon} onClick={this.openAlert} tooltip={"Cambiar Estado"} />
                <Alert
                    title={`Incidencia marcada como ${title}`}
                    text={"¿Qué desea hacer?"}
                    open={alertIsOpen}
                    onClose={this.closeAlert}
                    showCancelButton={true}
                    cancelButtonText={'Cancelar'}
                    extraButtons={Buttons(this.createRelatedIncident)}
                />
            </>
        )
    }
}

const Buttons = (onClick) => {
    return [
        {
            label: "Crear nueva incidencia a partir de esta",
            onClick: onClick
        }
    ]

};

export default CloseIrrelevantAndRejectedStatus