import React from 'react';
import RoundButton from "../RoundButton";
import { PrintedIcon, NotPrintedIcon } from "../../configuration/icons";
import './index.scss'

export default class RoundPrintedIndicator extends React.PureComponent{
    render(){

        const { className, printed, ...rest } = this.props;
        return(
            <RoundButton
                {...rest}
                icon={ !!printed ? PrintedIcon : NotPrintedIcon }
                className={`${className ? className : ''}  ${ printed ? 'is-printed' : 'is-not-printed' } printed-indicator`}
                tooltip={printed ? 'Esta incidencia ya ha sido impresa' : 'Esta incidencia aún no ha sido impresa'}
            />
        )
    }
}
