import React from 'react';
import './FilterOpenerAndIndicator.scss'
import {FilterConsumer} from '../Filter/Context'
import RoundButton from "../../../elements/RoundButton";
import { CloseIcon, SearchIcon } from "../../../configuration/icons";

class FilterOpenerAndIndicator extends React.Component{

    render() {
        const {filterSidebarIsOpen,  closeFilterSidebar,  openFilterSidebar, filters} = this.props;

        const thereIsAnyFilterApply = filters.selected.length > 0 || filters.string.length > 0 || filters.roles.length > 0;

        return (
            <div className={'filter-opener-and-indicator-wrapper'}>
                {thereIsAnyFilterApply && <p className={'p-0 m-0'} onClick={filterSidebarIsOpen ? closeFilterSidebar : openFilterSidebar}><i>Existen filtros aplicados</i></p>}
                <RoundButton
                    className={`${filterSidebarIsOpen ? 'sidebar-is-open' : ''} mr-1`}
                    icon={filterSidebarIsOpen ? CloseIcon : SearchIcon}
                    tooltip={filterSidebarIsOpen ? "Cerrar Buscador" : "Abrir Buscador"}
                    onClick={filterSidebarIsOpen ? closeFilterSidebar : openFilterSidebar}/>
            </div>
        );
    }
}

export default FilterConsumer(FilterOpenerAndIndicator)