import React from 'react';
import ViewIncidentProvider from "../Taxonomies/SidebarContext/Provider";
import FilterIncidentProvider from "../../componentsScreens/FilterIncidentsSidebar/Context/Provider";

class Incidents extends React.PureComponent{
    render() {
        const { children } = this.props;
        return (
            <ViewIncidentProvider>
                {/* <FilterIncidentProvider> */}
                    {children}
                {/* </FilterIncidentProvider> */}
            </ViewIncidentProvider>
        )}
}

export default Incidents;