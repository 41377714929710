import './ContentModalEditionPictures.css';
import {deletePicturesIncident, addPicturesToIncident} from '../../../../../../redux/incidents/action';
import React from 'react';
import DropZone from '../../../../../../components/Dropzone';
import ImageWrapper from './Components/ImageWrapper';
import Divider from '../../../../../../elements/Divider';
import Button from "../../../../../../elements/Button";

class ContentModalEditionPictures extends React.Component{

    state = {
        images: [],
        loading: false
    };

    handleFiles = (files) => {
        let error = false;
        if(this.state.images !== files) {
            const fileList = [];

            //TODO: Uncomment to control repeated names

            // const includesInMedia = this.props.multimedia.map(media => media.name);

            files.forEach(file => {
                //     if (!includesInMedia.includes(file.name))
                fileList.push(file);
                //     else
                //         error = 'name'
            });

            this.setState({
                images: [...fileList],
                error:error
            })
        }
    };

    updateImageInStateAfterUploadSinglePicture = (pictureName) => {
        const newImageSet = this.state.images;
        newImageSet.splice(this.state.images.indexOf(pictureName), 1);
        this.setState({
            images: [...newImageSet]
        });
    };

    uploadFile = async () => {
        addPicturesToIncident(
            { incident:this.props.incidentId, images:this.state.images },
            this.updateImageInStateAfterUploadSinglePicture
        );
    };

    removeUploadedImage = (pictureName) => {
        deletePicturesIncident({
            incident: this.props.incidentId,
            image: pictureName
        });
    };


    render(){
        const { images, error, loading } = this.state;
        const {imagesFromIncident} = this.props;

        return(
            <div className={'wrapper-edition-pictures'}>
                <h3>Añadir nuevas imágenes</h3>

                <DropZone
                    errorFileExtension={"Solo puedes subir archivos con las siguientes extensiones"}
                    errorFileName={"Ya hay uno o varios archivos con el mismo nombre"}
                    error={error} //error
                    loading={false} //loading
                    onFilesChanged={this.handleFiles} //this.handleFiles
                    fileList={images} //fileList
                    accept={['.png', '.jpg', '.jpeg', '.mp4', '.mov']}
                    title={"Añadir imágenes"}
                />

                <Button
                    className={'mt-1'}
                    onClick={this.uploadFile}
                    disabled={(images && images.length === 0) || loading}
                >
                    Subir imágenes
                </Button>

                <Divider/>

                <h3>Imágenes subidas</h3>

                {
                    imagesFromIncident ? imagesFromIncident.map(image => <ImageWrapper image={image} key={image} onDelete={this.removeUploadedImage}/>) :
                    <i>Aún no se han añadido imágenes</i>
                }

            </div>

        )
    }
}

export default ContentModalEditionPictures;