import React from "react";
import Modal from './../../components/Modal';
import RoundButton from './../../elements/RoundButton';
import { DeleteIcon } from "../../configuration/icons";
import './index.scss';
import Alert from '../../elements/Alert';

class ConfirmationModal extends React.Component{
    state = { open: false };
    OpenModal = () => this.setState({ open: true });
    CloseModal = () => this.setState({ open: false });

    RemoveImage = () => {
        const {image, onDelete} = this.props;
        onDelete && onDelete(image);
        this.CloseModal();
    };

    render(){
        const {open} = this.state;
        return(
            <>
                {/*<Modal*/}
                {/*    id={'confirmation-modal'}*/}
                {/*    modalContainer={'#root'}*/}
                {/*    closeModal={this.CloseModal}*/}
                {/*    dismissOnBlank={true}*/}
                {/*    dismissOnEscape={true}*/}
                {/*    open={open}*/}
                {/*>*/}
                {/*    /!*<Card>*!/*/}
                {/*    /!*    <CardTitle>*!/*/}
                {/*    /!*        <h2>Atención!</h2>*!/*/}
                {/*    /!*    </CardTitle>*!/*/}
                {/*    /!*    <Divider/>*!/*/}
                {/*    /!*    <CardBody>*!/*/}
                {/*    /!*        <p>¿Estás seguro de que quieres eliminar la imagen seleccionada?</p>*!/*/}
                {/*    /!*    </CardBody>*!/*/}
                {/*    /!*    <Divider/>*!/*/}
                {/*    /!*    <CardFooter>*!/*/}
                {/*    /!*        <Button danger onPress={this.RemoveImage} title={"Borrar"}>*!/*/}
                {/*    /!*            <Text>Borrar</Text>*!/*/}
                {/*    /!*        </Button>*!/*/}
                {/*    /!*        <Button bordered danger onPress={this.CloseModal} title={"Cancelar"}>*!/*/}
                {/*    /!*            <Text>Cancelar</Text>*!/*/}
                {/*    /!*        </Button>*!/*/}
                {/*    /!*    </CardFooter>*!/*/}
                {/*    /!*</Card>*!/*/}
                {/*    Include SweetAlert*/}
                {/*</Modal>*/}

                <Alert
                    open={open}
                    title={'¡Atención!'}
                    text={"¿Estás seguro de que deseas borrar esta imágen?"}
                    onClose={this.CloseModal}
                    onConfirm={this.RemoveImage}
                    showCancelButton={true}
                    showConfirmButton={true}
                    cancelButtonText={'No Borrar'}
                    confirmButtonText={'Borrar'}
                />
                <RoundButton className={'remove-image-button'} onClick={this.OpenModal} icon={DeleteIcon}/>
            </>
        )
    }
}

{/*<Alert*/}
{/*    title="Incidencia Pendiente"*/}
{/*    text={"¿Qué desea hacer?"}*/}
{/*    open={alertIsOpen}*/}
{/*    onClose={this.closeAlert}*/}
{/*    showCancelButton={true}*/}
{/*    cancelButtonText={'Cancelar'}*/}
{/*    extraButtons={Buttons(ids)}*/}
{/*/>*/}

export default ConfirmationModal;