import React from "react";

class List extends React.Component {
    static defaultProps = {
        limit: 15,
    };
    
    constructor(props){
        super(props);
        this.entity = props.entity;
        this.state = {
            page: 0,
            allChecked: false,
            selected: [],
            lastRowSelected: null,
            errors:[],
            data: [],
            limit: this.props.limit,
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps[this.entity] !== this.props[this.entity])
            if(this.state.lastRowSelected)
                this.setState({ lastRowSelected: this.props[this.entity].find(user => user.id === this.state.lastRowSelected.id) });
    }

    /** This function add one new item to selected array of the list **/
    decreaseSelected = (item) =>
        this.setState({ selected: [...this.state.selected.filter(i => i !== item)], allChecked: false });

    /** This function remove one new item to selected array of the list **/
    increaseSelected = (item) => {
        const selected = [...this.state.selected, item];
        this.setState({ selected: selected, allChecked: selected.length === this.props[this.entity].length })
    };

    /** This function add all items in array to selected array of the list **/
    handleCheckAll = () => {
        let nextSelected = (!this.state.allChecked && this.props[this.entity]) ? this.props[this.entity].map(e => e.id) : [];
        this.setState({
            allChecked : !this.state.allChecked,
            selected: nextSelected
        })
    };

    /** Include the last last selected row data in the state **/
    setLastRowSelected = (content) => this.setState({lastRowSelected: { ...content } });

    /** Handle check in one row **/
    onSingleChecked = ( e, id ) => {
        this.state.selected.includes(id) ?
            this.decreaseSelected(id) :
            this.increaseSelected(id)
    };

}

export default List;