import * as TYPES from './types'
import { role } from "../../configuration/entitiesNames";
// import mergeList from './../../helpers/MergeList'

const initialState = {
    [role]: [],
    fetching: false,
    pending: 0,
    success: false,
    error: null,
    total: 0,
};

export default (state = initialState, { type, payload }) => {

    // let index = -1, aux, singleUser, pending = 0;

    switch (type) {

        //All Request
        case TYPES.GET_ROLES_REQUEST:
            return { ...state, fetching:true, };


        // All Success
        case TYPES.GET_ROLES_SUCCESS:
            return { ...state, fetching:false, [role]: payload.data, total: payload.total, success: true };

        // case TYPES.GET_ROLE_SUCCESS:
        //     return { ...state, fetching:false, [role]: mergeList( state[user], payload.data ), success: true };


        //All Errors
        case TYPES.GET_ROLES_FAILED:
            console.log(TYPES.GET_ROLES_FAILED, payload);
            return { ...state, fetching:false, error: payload };

        default:
            return state
    }
};