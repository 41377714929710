import React from 'react';
import {paths} from '../../configuration/paths';

class NotFound extends React.PureComponent{

    render(){
        return(
            <p>Vaya, parece que no hemos encontrado nada aquí. Puedes volver al inicio haciendo click <a href={paths.Home}>aquí</a></p>
        )
    }
}

export default NotFound