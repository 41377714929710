import React, { useRef, useState, useMemo, useCallback, useEffect } from 'react';
import './Map.css';

/* Google Maps */
import GoogleMapReact from 'google-map-react';
import useSupercluster from "use-supercluster";

import { LocationMarker } from './LocationMarker';
import { getMarkerCategory } from './functions/GetMarkerColor';
import GetPointsForCluster from './functions/GetPointsForCluster';

import { API_KEY } from '../../../configuration/mode';

import { useSelector } from 'react-redux';
import { getUserRoles } from '../../../api/user';

const Marker = ({ children }) => children;

const Gmap = ({ incidents, grouped, props }) => {

    const me = useSelector(state => state.auth.me);
    const taxs = useSelector(state => state.taxonomies.subfamilia);
    const incs = useSelector(state => state.incidents);
    const [incID, setIncID] = useState([]);

    useEffect(() => {
        const checkIfRolSAC = async() => {
            incs.filtered.map((inc) => getUserRoles(inc.executor).then((res) => {
                if (res.data.roles[0] && res.data.roles[0].name === 'Ciudadano') {
                    setIncID(((oldId) => [...oldId, inc.id]));
                };
            }))
        }
        checkIfRolSAC();
    }, []);

    useEffect(() => {
        if(props.filters.initialValueSetted === false){
            props.setIn('subfamilyFilters', taxs.filter((tax) => tax.assignedTo && tax.assignedTo.includes(me)).map(tax => tax.id));
            if (me && me.roles[0].name === 'SAC') props.setIn('sacApprovedFilter', false);
            props.applyFilter(true, () => console.info('Filtered apllied in map'));
            props.setFlagsAs(true);
        }
    }, [props.filters.initialValueSetted, taxs, me?.roles])
    
    // Map References.
    const mapRef = useRef();
    const [zoom, setZoom] = useState(10);
    const [bounds, setBounds] = useState(null);

    const [currentLoc, setCurrentLoc] = useState({});

    // Options passed to current position.
    var options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      };
            
    // Obtaining current position.
    const panTo = useCallback(() => {
        navigator.geolocation.getCurrentPosition((position) => {
            setCurrentLoc({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
            })

        });
    }, []);
    panTo();

    // Prepare points for clustering and clusters. 
    const points = useMemo(() => {
        return GetPointsForCluster(incidents);
    }, [incidents]);
    
    const { clusters, supercluster } = useSupercluster({
        points,
        zoom,
        bounds
    })

    // Enable sidebar when click on marker.
    const onClickMarker = ( properties ) => {
        const {uri, navigate, setLastElementSelected, closeSidebar, sidebarMode, sidebarIsOpen, setMode, openSidebar, openFilterSidebar, filterSidebarIsOpen, closeFilterSidebar } = props;

        const data = properties;
        
        if (sidebarIsOpen) { 
            setLastElementSelected(data)
            navigate(`/app/${properties.id}`, { data });
            // openFilterSidebar();

        } else {
            setLastElementSelected(data)
            navigate(`/app/${properties.id}`, { data });
            // openFilterSidebar();
            openSidebar();
        }
    }


    return (
        <div style={{height: "92vh", width: "100%", position: 'relative'}}>
            <GoogleMapReact
                bootstrapURLKeys={{key: API_KEY}}
                defaultCenter={{ lat: 37.4857500, lng: -5.9420500 }}
                defaultZoom={13}
                options={options}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map }) => {
                    mapRef.current = map;
                }}
                onChange={({ zoom, bounds }) => {
                    setZoom(zoom);
                    setBounds([
                        bounds.nw.lng,
                        bounds.se.lat,
                        bounds.se.lng,
                        bounds.nw.lat
                    ]);
                }}
                >

                {/* Current location marker. */}
                {currentLoc.lat && <LocationMarker lat={currentLoc.lat} lng={currentLoc.lng} color='orange' />}

                {!grouped ? (
                    incidents.map(incident => {
                        return <LocationMarker 
                                key={incident.id}
                                lat={incident.coords.lat} 
                                lng={incident.coords.lng} 
                                color={getMarkerCategory(incident.status)}  
                                onClick={() => {
                                    onClickMarker(incident)
                                }
                        }/>
                    })
                ) : clusters.map(cluster => {
                    const [longitude, latitude] = cluster.geometry.coordinates;
                    const {
                        cluster: isCluster,
                        point_count: pointCount
                    } = cluster.properties;
                    
                    if (isCluster) {
                        return (
                        <Marker
                            key={`cluster-${cluster.id}`}
                            lat={latitude}
                            lng={longitude}
                        >
                            <div
                                className="cluster-marker"
                                onClick={() => {
                                    const expansionZoom = Math.min(
                                        supercluster.getClusterExpansionZoom(cluster.id),
                                        20
                                    );
                                    mapRef.current.setZoom(expansionZoom);
                                    mapRef.current.panTo({ lat: latitude, lng: longitude });
                                }}
                            >
                                {pointCount}
                            </div>
                        </Marker>
                        )
                    }

                    return (
                        <LocationMarker 
                        key={cluster.properties.id} 
                        lat={latitude} 
                        lng={longitude} 
                        color={getMarkerCategory(cluster.properties.category)} 
                        onClick={() => {
                            onClickMarker(cluster.incident)
                          }
                        }
                        />
                    )
                })}

            </ GoogleMapReact>

        </div>
    )
}

export default Gmap
