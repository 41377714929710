export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";

export const REGISTRATION_REQUEST = "REGISTRATION_REQUEST";
export const REGISTRATION_SUCCESS = "REGISTRATION_SUCCESS";
export const REGISTRATION_FAILED = "REGISTRATION_FAILED";

export const LOGOUT = "LOGOUT";

export const SET_CREDENTIALS = "SET_CREDENTIALS";

export const GET_ME_REQUEST = "GET_ME_REQUEST";
export const GET_ME_SUCCESS = "GET_ME_SUCCESS";
export const GET_ME_FAILED = "GET_ME_FAILED";

export const UPDATE_ME_REQUEST = "UPDATE_ME_REQUEST";
export const UPDATE_ME_SUCCESS = "UPDATE_ME_SUCCESS";
export const UPDATE_ME_FAILED = "UPDATE_ME_FAILED";
