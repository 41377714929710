import {put, call, all, fork, takeLatest, takeEvery} from 'redux-saga/effects';
import * as TYPES from './types';
import * as API from './../../api/taxonomies';

function* getTaxonomiesThroughApi({ filters, callback }) {
    const {type, id, page, limit, orderBy, ...attr} = filters;
    try {
        const response = yield call(API.getTaxonomies, type, id, attr, page, limit, orderBy);
        if(response.status === 200 || response.status === 304) {
            if(response.data.length < response.total){
                const retrieveAll = yield call(API.getTaxonomies, type, id, attr, page, response.total, orderBy)
                yield put({type: TYPES.GET_TAXONOMIES_SUCCESS, payload: {data: retrieveAll.data, type: type, total: retrieveAll.total}});
                callback && callback(retrieveAll)
            }
            else {
                yield put({type: TYPES.GET_TAXONOMIES_SUCCESS, payload: {data: response.data, type: type, total: response.total}});
                callback && callback(response)
            }
        }
        else {
            yield put({type: TYPES.GET_TAXONOMIES_FAILED, payload: response});
            callback && callback(response)
        }
    }
    catch (e) {
        yield put({ type: TYPES.GET_TAXONOMIES_FAILED, payload: e});
        callback && callback(e)
    }
}

function* createTaxonomyThroughApi({ taxonomy, callback }) {
    try {
        const response = yield call(API.createTaxonomy, taxonomy);
        if(response.status === 201) {
            const resTaxonomy = yield call(API.getTaxonomies, taxonomy.type, undefined, undefined, 0, 1 );
            if(resTaxonomy.status === 200) {
                yield put({
                    type: TYPES.CREATE_TAXONOMY_SUCCESS,
                    payload: {data: resTaxonomy.data, type: taxonomy.type, total: resTaxonomy.total}
                });
                callback && callback(resTaxonomy)
            }
            else {
                yield put({type: TYPES.CREATE_TAXONOMY_FAILED, payload: resTaxonomy});
                callback && callback(resTaxonomy)
            }
        }
        else {
            yield put({type: TYPES.CREATE_TAXONOMY_FAILED, payload: response});
            callback && callback(response)
        }
    }
    catch (e) {
        yield put({ type: TYPES.CREATE_TAXONOMY_FAILED, payload: e})
    }
}

function* updateTaxonomyThroughApi({ taxonomy, callback }) {
    try {
        const response = yield call(API.patchTaxonomy, taxonomy);
        if(response.status === 204) {
            const resTaxonomy = yield call(API.getTaxonomies, taxonomy.type, taxonomy.id, undefined, 0, 1 );
            if(resTaxonomy.status === 200 && resTaxonomy.data.length > 0) {
                yield put({type: TYPES.UPDATE_TAXONOMY_SUCCESS, payload: {...resTaxonomy.data[0]}});
                callback && callback(resTaxonomy)
            }
            else {
                yield put({type: TYPES.UPDATE_TAXONOMY_FAILED, payload: resTaxonomy});
                callback && callback(resTaxonomy)
            }
        }
        else {
            yield put({type: TYPES.UPDATE_TAXONOMY_FAILED, payload: response});
            callback && callback(response)
        }
    }
    catch (e) {
        yield put({ type: TYPES.UPDATE_TAXONOMY_FAILED, payload: e});
        callback && callback(e)
    }
}

function* removeTaxonomyThroughApi({ taxonomy, callback }) {
    const { type, id } = taxonomy;
    try {
        const response = yield call(API.removeTaxonomy, id, type);
        if(response.status === 204) {
            yield put({type: TYPES.REMOVE_TAXONOMY_SUCCESS, payload: id});
            callback && callback({...response, taxonomy});
        }
        else {
            yield put({type: TYPES.REMOVE_TAXONOMY_FAILED, payload: response});
            callback && callback({...response, taxonomy});
        }
    }
    catch (e) {
        yield put({ type: TYPES.REMOVE_TAXONOMY_FAILED, payload: e});
        callback && callback(e)

    }
}

function* watcherRemoveTaxonomies() {
    yield takeEvery(TYPES.REMOVE_TAXONOMY_REQUEST, removeTaxonomyThroughApi);
}

function* watcherUpdateTaxonomy() {
    yield takeEvery(TYPES.UPDATE_TAXONOMY_REQUEST, updateTaxonomyThroughApi);
}

function* watcherCreateTaxonomy() {
    yield takeLatest(TYPES.CREATE_TAXONOMY_REQUEST, createTaxonomyThroughApi);
}

function* watcherGetTaxonomies() {
    yield takeEvery(TYPES.GET_TAXONOMIES_REQUEST, getTaxonomiesThroughApi);
}

export default function* rootSaga() {
    yield all([
        fork(watcherGetTaxonomies),
        fork(watcherCreateTaxonomy),
        fork(watcherUpdateTaxonomy),
        fork(watcherRemoveTaxonomies),
    ])
}

