import React, { Fragment } from 'react';
import '../index.scss';
import Button from "../../../../elements/Button";
import { update } from "../../../../configuration/operationNames";
import { family } from "../../../../configuration/entitiesNames";
import HeaderSidebar from ".././Components/HeaderSidebar";
import { Input } from '../../../../components/Form';
import Consumer from '../../../Taxonomies/SidebarContext/Consumer';
import { getTaxonomies, removeTaxonomies } from "../../../../redux/taxonomies/action";
import BodySidebar from '../../../../components/Sidebar/Body';
import FooterSidebar from '../../../../components/Sidebar/Footer';

class View extends React.PureComponent{

    constructor(props){
        super(props);
        this.state = this.props.location ? {...this.props.location.state} : {};
    }

    componentDidMount() {
        this.getFamily();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.location && prevProps.location.state !== this.props.location.state)
            this.setState({...this.props.location.state});
    }

    getFamily = () => {
        if( this.props.id && !this.props.location.state )
            getTaxonomies({type: family, id: this.props.id}, this.callbackGetFamily);
        else this.props.openSidebar();
    };

    callbackGetFamily = (res) => {
        if(res.status === 200 && res.data.length > 0) {
            this.props.setLastElementSelected({...res.data[0]});
            this.setState({...res.data[0]}, this.props.openSidebar );
        }
        else {
            this.props.closeSidebar();
        }
    };

    goToUpdateFamily = () => {
        this.props.setLastElementSelected(this.state, () => this.props.setMode(update));
    };

    onPressDeleteButton = () => {
        removeTaxonomies([this.props.id], family, this.callbackDeleteFamily)
    };

    callbackDeleteFamily = (response) =>{
        if(response.status === 204) {
            this.props.closeSidebar();
            const URL = this.props.uri.replace(this.props.id, '');
            this.props.navigate(URL)
        }
    };

    render() {
        /** Miscellaneous**/
        const { color, name } = this.state;
        const { navigate } = this.props;
        return(
            <Fragment>
                <HeaderSidebar onPressDeleteButton={this.onPressDeleteButton} navigate={navigate}/>
                <BodySidebar>
                    <h3 className={"pl-1"}>Visualizando familia</h3>
                    <div className={'color-viewer p-2 mt-5'} style={{backgroundColor: color ? '#'+color.replace('#', '') : '#fff'}}/>
                    <Input className={'mt-0 ml-0 text-center'} value={name} name={'name'} readOnly noLabel/>
                </BodySidebar>
                <FooterSidebar>
                    <Button className={'native-base-button center-text'}  onClick={this.goToUpdateFamily} fullWidth >
                        Editar Familia
                    </Button>
                </FooterSidebar>
            </Fragment>
        )
    }
}

export default Consumer(View)
