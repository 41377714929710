import * as TYPES from './types'
import { family, subfamily, orderType } from '../../configuration/entitiesNames';
import ToCapitalize from './../../helpers/ToCapitalize'
import mergeList from "../../helpers/MergeList";
const initialState = {
    [family]:[],
    [orderType]:[],
    [subfamily]:[],
    [`total${ToCapitalize(family)}`]:0,
    [`total${ToCapitalize(subfamily)}`]:0,
    [`total${ToCapitalize(orderType)}`]:0,
    [`filtered${family}`]:[],
    [`filtered${subfamily}`]:[],
    [`filtered${orderType}`]:[],
    fetching: false,
    pending: 0,
    success: false,
    error: null,
};

export default (state = initialState, { type, payload }) => {

    let index = -1, aux, pending = 0, taxType, total;

    switch (type) {

        //All Request
        case TYPES.GET_TAXONOMIES_REQUEST:
        case TYPES.CREATE_TAXONOMY_REQUEST:
        case TYPES.UPDATE_TAXONOMY_REQUEST:
            return { ...state, fetching:true, };

        case TYPES.REMOVE_TAXONOMIES_REQUEST:
            return { ...state, fetching:true, pending: payload.length };


        case TYPES.GET_TAXONOMIES_SUCCESS:
            return {
                ...state,
                fetching:false,
                [payload.type]: mergeList(payload.data, state[payload.type], true),
                [`filtered${payload.type}`]: payload.data,
                // [payload.type]: payload.data, // Get Taxonomies devuelve todas las taxonomías
                [`total${ToCapitalize(payload.type)}`] : payload.total
            };

        case TYPES.CREATE_TAXONOMY_SUCCESS:
            return {
                ...state,
                fetching: false,
                [payload.type]: mergeList(payload.data,state[payload.type]),
                [`total${ToCapitalize(payload.type)}`] : payload.total,
                success: true
            };

        case TYPES.REMOVE_TAXONOMY_SUCCESS:
            taxType = family;
            aux = state[taxType];
            index = aux.indexOf(aux.find(tax => tax.id === payload));

            if(index === -1){
                taxType = subfamily;
                aux = state[taxType];
                index = aux.indexOf(aux.find(tax => tax.id === payload));
            }

            if(index === -1){
                taxType = orderType;
                aux = state[taxType];
                index = aux.indexOf(aux.find(tax => tax.id === payload));
            }

            if(index !== -1) {
                aux.splice(index,1);
                pending = state.pending !== 0 ? --state.pending : 0;
                return {...state, [taxType]: [...aux], [`total${ToCapitalize(taxType)}`]: total = state[`total${ToCapitalize(taxType)}`] - 1, fetching: pending !== 0}
            }
            else
                return { ...state, [`total${ToCapitalize(taxType)}`]: total = state[`total${ToCapitalize(taxType)}`] - 1, fetching: pending !== 0};


        case TYPES.UPDATE_TAXONOMY_SUCCESS:
            taxType = family;
            aux = state[taxType];
            index = aux.indexOf(aux.find(tax => tax.id === payload.id));

            if(index === -1){
                taxType = subfamily;
                aux = state[taxType];
                index = aux.indexOf(aux.find(tax => tax.id === payload.id));
            }

            if(index === -1){
                taxType = orderType;
                aux = state[taxType];
                index = aux.indexOf(aux.find(tax => tax.id === payload.id));
            }

            if(index !== -1) {
                aux[index]= payload;
                return {...state, [taxType]: [...aux], fetching:false, success: true}
            }
            else
                return { ...state, fetching:false, success: true};



        //All Errors
        case TYPES.GET_TAXONOMIES_FAILED:
        case TYPES.CREATE_TAXONOMY_FAILED:
        case TYPES.REMOVE_TAXONOMY_FAILED:
        case TYPES.UPDATE_TAXONOMY_FAILED:
            return { ...state, fetching:false, error: payload };



        // Helpers
        case TYPES.SWIPE_TAXONOMY_SUCCESS_STATUS:
            return {...state, success: payload !== undefined ? payload : !state.success};

        default:
            return state
    }
};