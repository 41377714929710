import { protocol, ipAddress, CLIENT_BASE64, port } from '../configuration/network';
import JSONToURLEncoded from "../helpers/JSONToURLEncoded";
import {requestApi} from './requestApi';

export const DoLogin = async({ username, password }) => {
    const portString = port ? `:${port}`: '';
    const res = await fetch(`${protocol}://${ipAddress}${portString}/oauth2/token`,{
        method: 'POST',
        headers: {
            Authorization: `Basic ${CLIENT_BASE64}`,
            'Content-type': 'application/x-www-form-urlencoded'
        },
        body: JSONToURLEncoded({
            grant_type:"password",
            username,
            password
        })
    });
    const status = res.status;
    const data = await res.json();
    return { status: status , body: data }
};

export const GetMe = async({ access_token }) => {
    return await requestApi({
        method: 'GET',
        access_token,
        url: `api/v1/user/me`
    });
};

export const UpdateMe = async(userData) => {
    return await requestApi({
        method: 'PUT',
        body: JSON.stringify(userData),
        headers: {
            "Content-Type":"application/json"
        },
        url: `api/v1/user/me`
    });
};


export const GetMeRole = async({ access_token }, {id}) => {
    return await requestApi({
        method: 'GET',
        access_token,
        url: `api/v1/user/${id}/roles`
    });
};

export const getUserNoConfirmated = async ( hash ) => {
    return await requestApi({
        access_token: '0000',
        method: 'GET',
        url: `registry/${hash}`
    });

};

export const setUserNoConfirmated = async ( userData ) => {
    return await requestApi({
        access_token: '0000',
        method: 'POST',
        url: `registry`,
        headers: {
            "Content-Type":"application/json"
        },
        body: JSON.stringify(userData),
    });
};

export const validateUser = async ( data, hash ) => {
    return await requestApi({
        access_token: '0000',
        method: 'POST',
        headers: {
            "Content-Type":"application/json"
        },
        body: JSON.stringify(data),
        url: `registry/validate/${hash}`
    });
};

export const newPassword = async ( data, hash ) => {
    return await requestApi({
        access_token: '0000',
        method: 'POST',
        headers: {
            "Content-Type":"application/json"
        },
        body: JSON.stringify(data),
        url: `registry/resetPassword/${hash}`
    });
};