import React from 'react';
import InfoRow from './InfoRow';

export default ({ address, city, street, subUrb, zipCode }) =>{
    return(
        <section className="pdf-section pdf-section-info">
            <h3>Información general</h3>
            <InfoRow title="Ciudad" content={city}/>
            <InfoRow title="Urbanización" content={subUrb}/>
            <InfoRow title="Calle" content={street}/>
            <InfoRow title="Cod.Postal" content={zipCode}/>
            <InfoRow title="Dirección completa" content={address}/>
        </section>
    )
}