import React from 'react';
import RoundButton from '../../../elements/RoundButton';
import './index.scss'

class InputWithIcon extends React.PureComponent {
    render() {
        let { icon, tooltip, onIconClick, ...rest } = this.props;
        return(
            <>
                <input {...rest} />
                <RoundButton icon={icon} tooltip={tooltip} onClick={onIconClick}/>
            </>
        )
    }
}

export default InputWithIcon