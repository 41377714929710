import React from "react";
import Sidebar from '../Sidebar';
import { paths } from "../../configuration/paths";
import * as Icons from "react-icons/fa"
import MenuItem from "./MenuItem";
import TopStrip from "./TopStrip";
import Badge from "../../elements/Badge";
import { connect } from "react-redux";
import { FamilyIcon, SubFamilyIcon, HomeIcon, UserIcon, OrderTypeIcon, IncidentIcon } from "../../configuration/icons.js"
import './index.scss'
import ToCapitalize from "../../helpers/ToCapitalize";
import { family, orderType, subfamily } from "../../configuration/entitiesNames";
import { ForbiddenEmails } from "../../configuration/network";
import HasAllowedRole from "../../helpers/redux-helpers/HasAllowedRoleHOC";

class MainMenu extends React.Component {
    render() {
        const { open, openMenu, closeMenu } = this.props;
        const { totalIncidents,
            totalFamilies,
            totalSubFamilies,
            totalOrderType,
            totalUsers } = this.props;

        return(
          <Sidebar
            position = {'left'}
            closeSidebarFn = {closeMenu}
            openSidebarFn = {openMenu}
            open={open}
          >
              <div>
                  <TopStrip closeMenu={closeMenu}/>
                  <nav className={'menu-item-wrapper'}>
                      <HasAllowedRole allowedRoles={['administrador', 'sac', 'coordinador']}>
                          <MenuItem key={paths.Home} preText={Icons[HomeIcon]()} closeMenu={closeMenu} to={'/' + paths.Home}>Mapa</MenuItem>
                          {/*<MenuItem key={paths.Dashboard} preText={Icons[DashboardIcon]()} closeMenu={closeMenu} to={'/' + paths.Home + '/'+ paths.Dashboard}>Dashboard</MenuItem>*/}
                          <MenuItem key={paths.Incidents} preText={Icons[IncidentIcon]()} postText={<Badge number={totalIncidents || 0}/>} closeMenu={closeMenu} to={'/' + paths.Home + '/'+ paths.Incidents}>Incidencias</MenuItem>
                          <MenuItem key={paths.Families} preText={Icons[FamilyIcon]()} postText={<Badge number={totalFamilies || 0}/>} closeMenu={closeMenu} to={'/' + paths.Home + '/'+ paths.Families}>Familias</MenuItem>
                          <MenuItem key={paths.Subfamilies} preText={Icons[SubFamilyIcon]()} postText={<Badge number={totalSubFamilies || 0}/>} closeMenu={closeMenu} to={'/' + paths.Home + '/'+ paths.Subfamilies}>Subfamilias</MenuItem>
                          <MenuItem key={paths.OrderTypes} preText={Icons[OrderTypeIcon]()} postText={<Badge number={totalOrderType || 0}/>} closeMenu={closeMenu} to={'/' + paths.Home + '/'+paths.OrderTypes}>Tipos de Órdenes</MenuItem>
                      </HasAllowedRole>
                      <HasAllowedRole allowedRoles={['administrador', 'sac']}>
                          <MenuItem key={paths.Users} preText={Icons[UserIcon]()} postText={<Badge number={ totalUsers }/>} closeMenu={closeMenu} to={'/' + paths.Home + '/'+ paths.Users}>Usuarios</MenuItem>
                      </HasAllowedRole>
                  </nav>
              </div>
          </Sidebar>
        )
    }
}

const mapStateToProps = ({users,incidents, taxonomies}) => ({
    totalIncidents: incidents.total,
    totalFamilies: taxonomies[`total${ToCapitalize(family)}`],
    totalSubFamilies: taxonomies[`total${ToCapitalize(subfamily)}`],
    totalOrderType: taxonomies[`total${ToCapitalize(orderType)}`],
    totalUsers : (users.total && parseInt(users.total) > 0) ? parseInt(users.total) - ForbiddenEmails.length : 0,
});

export default connect(mapStateToProps)(MainMenu);