import React from 'react';
import Alert from "../../../elements/Alert";
import {StatusIcon} from "../../../configuration/icons";
import RoundButton from "../../../elements/RoundButton";
import STATUS from './../../../configuration/statusOptions';
import { updateStatusIncidents } from '../../../redux/incidents/action';

class OpenStatus extends React.PureComponent{

    state = {
        alertIsOpen: false
    };

    openAlert = () => {
        if(!this.state.alertIsOpen) this.setState({alertIsOpen: true})
    };

    closeAlert = () =>{
        if(this.state.alertIsOpen) this.setState({alertIsOpen: false})
    };

    render() {
        const { ids, isPlural } = this.props;
        const { alertIsOpen } = this.state;
        return(
            <>
                <RoundButton icon={StatusIcon} onClick={this.openAlert} tooltip={"Cambiar Estado"} />
                <Alert
                    title={isPlural ? "Incidencias abiertas" : "Incidencia abierta"}
                    text={"¿Qué desea hacer?"}
                    open={alertIsOpen}
                    onClose={this.closeAlert}
                    showCancelButton={true}
                    cancelButtonText={'Cancelar'}
                    extraButtons={Buttons(ids)}
                />
            </>
        )
    }
}

const Buttons = (idArrays) => {
    return [
        {
            label: "Cerrar",
            onClick: ()=> updateStatusIncidents(idArrays, STATUS.close.name)
        },
        {
            label: "Irrelevante",
            onClick: ()=> updateStatusIncidents(idArrays, STATUS.irrelevant.name)
        }
    ]

};

export default OpenStatus