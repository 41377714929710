/*Icons*/

/*Store*/
export default {
    false: {
        translated: 'No Impresa',
        icon: 'OPEN_STATUS_ICON'
    },

    true: {
        translated: 'Impresa',
        icon: 'CLOSE_STATUS_ICON'
    },
};
