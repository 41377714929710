import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { CheckIcon } from '../../../../../configuration/icons';
import RoundButton from '../../../../../elements/RoundButton';

import FilterConsumer from '../../../../../componentsScreens/FilterIncidentsSidebar/Context/Consumer';

const SacApprovedFilterButton = (props) => {

    const [apply, setApply] = useState(props.filters.sacApprovedFilter === true);
    const me = useSelector(state => state.auth.me);

    useEffect(() => {
        if (props.filters.sacApprovedFilter === true) setApply(true);
        else setApply(false);
    }, [props.filters.sacApprovedFilter])

    const checkFiltersApplied = () => {
        if (props.filters.sacApprovedFilter !== true) {
            props.setIn('sacApprovedFilter', true);
            props.applyFilter(true, () => console.info('Filtered apllied in SacApprovedFilterButton'));
        } else {
            props.setIn('sacApprovedFilter', undefined);
            props.applyFilter(true, () => console.info('Filtered apllied in SacApprovedFilterButton'));
        }
    }

    return (
        (me && me.roles[0].name !== 'SAC')
        ? (
        <>
            <RoundButton 
                icon={CheckIcon}
                className={`round-button ${apply ? 'active' : ''}`}
                tooltip={`${apply ? "Ver todas las incidencias" : "Ver solo incidencias aprobadas por el SAC"}`}
                onClick={() => checkFiltersApplied()}
            />
        </>)
        : null
    )
}

export default FilterConsumer(SacApprovedFilterButton);