import EditionPictures from "../EditionPictures";
import ImageWrapper from "../../../../../elements/ImageWrapper";
import MinioImage from "../../../../../components/MinioImage";
import Dropview from "../../../../../components/DropView";
import React from "react";
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { incident } from "../../../../../configuration/entitiesNames";

class ImagesBlock extends React.Component{

    static defaultProps =  {
        images: [],
        id: null
    };

    static propTypes = {
        images: PropTypes.array,
        id: PropTypes.string
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps.id !== this.props.id || nextProps.images !== this.props.images
    }


    render() {
        const { id, images } = this.props;

        return(
            <Dropview header={<h4 className={'m-0'}>Imágenes</h4>}>
                <div className={"p-1 picture-block"}>
                    <EditionPictures images={images} id={id}/>
                    { (images && images.length > 0) ? images.map(image =>
                            <ImageWrapper width={105} height={105} key={image}>
                                <MinioImage name={image}/>
                            </ImageWrapper>
                        ) :
                        <p style={{width: "100%"}}>
                            <i>Aún no se han añadido imágenes</i>
                        </p>
                    }
                </div>
            </Dropview>
        )
    }
}

const mapStateToProps = ({incidents}, {id}) => {
    const incidentInIndents = incidents[incident].find(inc => inc.id === id);
    const foundIncident = incidentInIndents ? incidentInIndents : incidents.filtered.find(inc => inc.id === id);

    if(foundIncident)
        return {
            images: foundIncident.images,
        };

    return ImagesBlock.defaultProps
};

export default connect(mapStateToProps)(ImagesBlock);