import React from 'react';
// import {Icon, Item, Picker, Text, View} from "native-base";
// import FieldError from "../FieldError";
// import {StyleSheet} from "react-native";
//
// const styles = StyleSheet.create({
//     errors: {
//         color: 'red',
//     },
//
//     field:{
//         width: '100%',
//         padding: 16,
//         display: 'flex',
//         flexDirection: 'column'
//     },
//
//     select:{
//         height: 40,
//         color: 'rgb(87, 87, 87)',
//         paddingRight: 5,
//         paddingTop: 5
//     }
// });

class Select extends React.PureComponent{
    render(){
        return ( <p>TODO SELECT</p>)
        // const { errors, label, options, name, value, onChange } = this.props;
        // return(
        //     <View style={styles.field}>
        //         <Text>{label}</Text>
        //         <Item picker error={ !!errors }>
        //             <Picker
        //                 mode="dropdown"
        //                 enabled={options && options.length > 1}
        //                 // iosIcon={<Icon name="arrow-down" />}
        //                 style={styles.select}
        //                 placeholder="Selecciona una familia"
        //                 // placeholderStyle={{ color: "#bfc6ea" }}
        //                 // placeholderIconColor="#007aff"
        //                 selectedValue={value || ''}
        //                 onValueChange={(txt)=>onChange && onChange(txt,name)}
        //             >
        //                 {options}
        //             </Picker>
        //         </Item>
        //         <FieldError error={errors}/>
        //     </View>
        // )
    }
}

export default Select