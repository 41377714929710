import React from 'react';
import { UserConsumer } from '../../Context';
import { resetUserPassword } from '../../functions';
import { HeaderSidebar as OriginalHeaderSidebar } from '../../../../components/Sidebar';
import RoundButton from "../../../../elements/RoundButton";
import { AddIcon, DeleteIcon, ResetPassword } from "../../../../configuration/icons";
import GoBackInMobile from './../../Components/GoBackInMobile';
import { deleteUsers } from "../../../../redux/users/action";

class HeaderSidebar extends React.Component {
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return false;
    }

    onPressDeleteButton = () => {
        const {lastElementSelected} = this.props;
        deleteUsers([lastElementSelected.id], this.deleteUserCallback)
    };

    deleteUserCallback = (res) => {
        const {setLastElementSelected, closeSidebar, sidebarIsOpen} = this.props;
        if(res.status ===204){
            sidebarIsOpen && closeSidebar();
            setLastElementSelected(null);
        }
    };

    onPressCreateButton = () => {};

    render(){
        const { userId } = this.props;
        return(
            <OriginalHeaderSidebar>
                <GoBackInMobile/>
                <RoundButton icon={DeleteIcon} onClick={this.onPressDeleteButton} tooltip={'Borrar este usuario'}/>
                <RoundButton icon={ResetPassword} onClick={(e)=>resetUserPassword(e, userId)} tooltip={'Reiniciar contraseña'}/>
                <RoundButton icon={AddIcon} onClick={this.onPressCreateButton}/>
            </OriginalHeaderSidebar>
        )
    }
}

export default UserConsumer(HeaderSidebar);