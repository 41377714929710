import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

class Button extends React.PureComponent{

    static propTypes = {
        color: PropTypes.string,
        fullWidth: PropTypes.bool,
        className: PropTypes.string,
        size: PropTypes.oneOf(['medium','small','big'])
    };

    static defaultProps = {
        color: 'primary',
        fullWidth: false,
        className: '',
        size: 'medium'
    };


    render() {
        const {fullWidth, color, children, size, className, ...rest} = this.props;
        const fullWidthClassName = fullWidth ? 'fullwidth' : '';
        const colorClassName = color;
        const sizeClassName = size === "small" ? "p-1" : size === "big" ? "p-3" : "p-1";
        const classes = [
            "button",
            "p-1",
            fullWidthClassName,
            colorClassName,
            sizeClassName,
            className
        ].join(' ').trim();

        return (
            <button className={classes} {...rest}>
                <div>{children}</div>
            </button>
        );
    }
}

export default Button;