import React from 'react';
import { Link } from '@reach/router';
import { paths } from "../../../configuration/paths";
import { ProfileIcon, LogoutIcon } from '../../../configuration/icons';
import { LogOut } from '../../../redux/auth/action';
import * as Icons from "react-icons/fa"
import './index.scss'
import {connect} from 'react-redux';

const GetMeItem = (me, item) => {
    return me && me[item] ? me[item] : ''
};

class TopStrip extends React.PureComponent {
    render() {
        const {closeMenu, me} = this.props;
        const name = GetMeItem(me, 'name') ;
        const surname = GetMeItem(me, 'surname');

        return(
            <div className={'top-strip mb-2'}>
                <p className={'user-name'}>{`${name} ${surname}`}</p>
                <div>
                    <Link className={"link"} to={paths.Profile} onClick={closeMenu}> {Icons[ProfileIcon]()} Mi Perfil</Link>
                    <div className={'logout-button link'} onClick={LogOut}>Cerrar Sesión {Icons[LogoutIcon]()}</div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({auth}) => ({
   me: auth.me
});

export default connect(mapStateToProps)(TopStrip);