/*Icons*/
export const OPEN_STATUS_ICON = "FaThumbsUp";
export const CLOSE_STATUS_ICON = "FaStopCircle";
export const PENDING_STATUS_ICON = "FaStopwatch"; //"md-clock";
export const REJECTED_STATUS_ICON = "FaThumbsDown";
export const IRRELEVANT_STATUS_ICON = "FaThumbsDown";

/*Colors*/
const OPEN_STATUS_COLOR = '#599e30';
const CLOSE_STATUS_COLOR = '#2f4eff';
const PENDING_STATUS_COLOR = '#424242';
const REJECTED_STATUS_COLOR = '#d1372e';
const IRRELEVANT_STATUS_COLOR = '#fec63e';
/*Store*/
export default {
    open:{
        name:'open',
        translated: 'abierta',
        color: OPEN_STATUS_COLOR,
        icon: OPEN_STATUS_ICON
    },

    close: {
        name:'close',
        translated: 'archivada',
        color: CLOSE_STATUS_COLOR,
        icon: CLOSE_STATUS_ICON
    },

    pending: {
        name:'pending',
        translated: 'pendiente',
        color: PENDING_STATUS_COLOR,
        icon: PENDING_STATUS_ICON
    },

    irrelevant: {
        name:'irrelevant',
        translated: 'no procede',
        color: IRRELEVANT_STATUS_COLOR,
        icon: IRRELEVANT_STATUS_ICON
    },

    rejected: {
        name:'rejected',
        translated: 'rechazada',
        color: REJECTED_STATUS_COLOR,
        icon: REJECTED_STATUS_ICON
    },
};
