import React, { Fragment } from 'react';
import '../index.scss';
import Button from "../../../../elements/Button";
import { update } from "../../../../configuration/operationNames";
import { subfamily } from "../../../../configuration/entitiesNames";
import HeaderSidebar from ".././Components/HeaderSidebar";
import BodySidebar from "../../../../components/Sidebar/Body";
import FooterSidebar from "../../../../components/Sidebar/Footer";
import Consumer from '../../../Taxonomies/SidebarContext/Consumer';
import { getTaxonomies, removeTaxonomies } from "../../../../redux/taxonomies/action";
import FamilyPopulated from './../../../../helpers/redux-helpers/PopulatedClass/FamilyPopulated';
import PrintingSubfamilies from '../../../Taxonomies/Components/PrintingSubfamilies';

const ENTITY = subfamily;

class View extends React.PureComponent{

    constructor(props){
        super(props);
        this.state = this.props.location ? {...this.props.location.state, } : {};
    }

    componentDidMount() {
        this.getSubfamily();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.location && prevProps.location.state !== this.props.location.state)
            this.setState({...this.props.location.state});
    }

    getSubfamily = () => {
        if( this.props.id && !this.props.location.state )
            getTaxonomies({type: ENTITY, id: this.props.id}, this.callbackGetSubfamily);
        else this.props.openSidebar();
    };

    callbackGetSubfamily = (res) => {
        if(res.status === 200 && res.data.length > 0) {
            this.props.setLastElementSelected({...res.data[0]});
            this.setState({...res.data[0]}, ()=> this.getParent() & this.props.openSidebar());
        }
        else this.props.closeSidebar()
    };

    goToUpdateFamily = () => {
        this.props.setLastElementSelected(this.state, () => this.props.setMode(update));
    };

    onPressDeleteButton = () => {
        removeTaxonomies([this.props.id], ENTITY, this.callbackDeleteFamily)
    };

    callbackDeleteFamily = (response) =>{
        if(response.status === 204) {
            this.props.closeSidebar();
            const URL = this.props.uri.replace(this.props.id, '');
            this.props.navigate(URL)
        }
    };

    render() {
        /** Miscellaneous**/
        const { parent, name } = this.state;
        const { navigate } = this.props;
        return(
            <Fragment>
                <HeaderSidebar onPressDeleteButton={this.onPressDeleteButton} navigate={navigate}/>
                <BodySidebar>
                    <h3 className={"pl-1 mb-3"}>Visualizando subfamilia</h3>
                    <h4 className={"pl-1"}>Nombre</h4>
                    <p className={'mt-0 ml-1'}> {name} </p>
                    <h4 className={"pl-1"}>Familia</h4>
                    <p className={'mt-0 ml-1'}> <FamilyPopulated id={parent}/> </p>
                    <h4 className={"pl-1"}>Asociada a: </h4>
                    <div className={"ml-1"}><PrintingSubfamilies id={this.state.assignedTo ?? []}/></div>
                </BodySidebar>
                <FooterSidebar>
                    <Button className={'native-base-button center-text'}  onClick={this.goToUpdateFamily} fullWidth >
                        Editar Subfamilia
                    </Button>
                </FooterSidebar>
            </Fragment>
        )
    }
}

export default Consumer(View)
