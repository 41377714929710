import React from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css'
import CONFIG from './config'
import { PulsingDot } from './Markers';
import './index.scss'

/** Map FN Helpers **/
import AddCluster, { RemoveCluster } from "./Helpers/Layers/AddCluster";
import AddSource from "./Helpers/AddSource";
import AddTexts , { RemoveTexts } from "./Helpers/Layers/AddTexts";
import AddDots , { RemoveAllDots } from "./Helpers/Layers/AddDots";
import ParseDataToGeoJson from "./Helpers/Parse/ParseDataToGeoJson";
import ParseLatLng from "../../helpers/geocoding/ParseLatLng";
import { AddMarkersCluster } from "./Helpers/Markers";

/** Map Name Layers Constants **/
const CLUSTER_NAME = "cluster";
const SOURCE_NAME = "incidences";
const TEXTS_NAME = "texts-count";
const USER_POSITION = "USER_POSITION";
const PULSE_DOT = "PULSE_DOT";

class MapBox extends React.Component{
    constructor(props){
        super(props);
        mapboxgl.accessToken = CONFIG.MapBoxAccessToken;
        this.Map = null;
        this.MarkersArray = [];
    }


    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return JSON.stringify(nextProps.data) !== JSON.stringify(this.props.data) ||
            nextProps.center !== this.props.center ||
            nextProps.userCenter !== this.props.userCenter ||
            nextProps.markers !== this.props.markers || nextProps.grouped !== this.props.grouped
    }

    componentDidMount() {
        this.mountMap();
        // this.getCurrentLocation();
        if(this.props.center && this.props.center.lat && this.props.center.lng)
            this.recenterMap(this.props.center);

        if(this.props.userCenter && this.props.userCenter.coords)
            this.addUserMarker({coords: ParseLatLng(this.props.userCenter.coords)});

        if(this.props.markers) {
            AddMarkersCluster(this.Map, this.MarkersArray, this.props.markers, 'INCIDENCES', ()=>{} )
        }
    }

    componentDidUpdate(prevProps) {
        if( this.props.data && this.props.data !== prevProps.data ){
            const GeoJsonData = this.props.data.map(data => ParseDataToGeoJson(data));
            this.ResetSourceShowInMap(GeoJsonData)
        }

        if( this.props.grouped !== prevProps.grouped){
            const GeoJsonData = this.props.data.map(data => ParseDataToGeoJson(data));
            this.ResetSourceShowInMap(GeoJsonData)
        }

        if(this.props.center !== prevProps.center && this.props.center && this.props.center.lat && this.props.center.lng){
            this.recenterMap(this.props.center)
        }

        if(this.props.userCenter !== prevProps.userCenter && this.props.userCenter && this.props.userCenter.coords){
            this.addUserMarker({coords: ParseLatLng(this.props.userCenter.coords)});
        }

        if(this.props.markers !== prevProps.markers) {
            AddMarkersCluster(this.Map, this.MarkersArray, this.props.markers, 'INCIDENCES', ()=>{} )
            // this.addMarkersCluster()
        }
    }

    repaintMap = () => {
        this.Map.resize();
    };

    addUserMarker = ({ coords }) => {
        const coordsParsed = ParseLatLng(coords);
        this.onLoadMap(() => {
            this.Map.addImage('pulsing-dot', PulsingDot(this.Map), {pixelRatio: 2});
            this.Map.addLayer({
                "id": USER_POSITION,
                "type": "symbol",
                "source": {
                    "type": "geojson",
                    "data": {
                        "type": "FeatureCollection",
                        "features": [{
                            "type": "Feature",
                            "geometry": {
                                "type": "Point",
                                "coordinates": [coordsParsed.lng, coordsParsed.lat]
                            }
                        }]
                    }
                },
                "layout": {
                    "icon-image": "pulsing-dot"
                }
            });
        });
    };

    // addMarkersCluster = () => {
    //     const {markers} = this.props;
    //     markers.forEach(inc=>{
    //         const coordsParsed = ParseLatLng(inc.coords);
    //         this.onLoadMap(() => {
    //             this.Map.addImage('pulsing-dot', PulsingDot(this.Map), {pixelRatio: 2});
    //             this.Map.addLayer({
    //                 "id": inc.id,
    //                 "type": "symbol",
    //                 "source": {
    //                     "type": "geojson",
    //                     "data": {
    //                         "type": "FeatureCollection",
    //                         "features": [{
    //                             "type": "Feature",
    //                             "geometry": {
    //                                 "type": "Point",
    //                                 "coordinates": [coordsParsed.lng, coordsParsed.lat]
    //                             }
    //                         }]
    //                     }
    //                 },
    //                 "layout": {
    //                     "icon-image": "pulsing-dot"
    //                 }
    //             });
    //         });
    //     });
    // };

    recenterMap = ( coords ) => this.Map && this.Map.flyTo({
        center: ParseLatLng(coords),
        zoom: 15
    });

    onLoadMap = (callback) => this.Map && this.Map.on('load', callback);

    // addImageToMap = ( name, image, pixelRatio=2 ) => this.onLoadMap(()=> {
    //     this.Map.addImage(name, image, { pixelRatio })
    // });
    //
    // addSymbolToMap = (coords, name) => this.onLoadMap(()=> {
    //     const LayerName = name + '-symbol';
    //     if(this.Map) {
    //         if (this.Map.getLayer(LayerName)) this.Map.removeLayer(LayerName);
    //         if (this.Map.getSource(LayerName)) this.Map.removeSource(LayerName);
    //         this.Map.addLayer({
    //             "id": LayerName,
    //             "type": "symbol",
    //             "source": {
    //                 "type": "geojson",
    //                 "data": {
    //                     "type": "FeatureCollection",
    //                     "features": [{
    //                         "type": "Feature",
    //                         "geometry": {
    //                             "type": "Point",
    //                             "coordinates": ParseLatLng(coords)
    //                         }
    //                     }]
    //                 }
    //             },
    //             "layout": {
    //                 "icon-image": name
    //             }
    //         })
    //     }
    // });

    mountMap = () => {
        const { containerId, zoom } = this.props;
        this.Map = new mapboxgl.Map({
            container: containerId,
            style: 'mapbox://styles/mapbox/streets-v10?optimize=true', // replace this with your style URL // dark | light | streets
            center: ParseLatLng(CONFIG.DEFAULT_MAP_LOCATION),
            zoom: zoom,
            // pitch: 60, // pitch in degrees
            // bearing: 60, // bearing in degrees
        });
        // this.onLoadMap(this.repaintMap);
        this.onLoadMap(this.handleClickOnMap);
        this.onLoadMap(this.addPulseDotSource);
    };

    handleClickOnMap = () => {
        const { onClickOnMap } = this.props;
        this.Map.on('click', (e) => {
            onClickOnMap && onClickOnMap(e)
        })
    };

    addPulseDotSource = () => {
        this.Map.addImage(PULSE_DOT, PulsingDot(this.Map))
    };

    ResetSourceShowInMap = (GeoJsonData) => {
        const data = GeoJsonData;
        this.RemoveAllLayer();
        AddSource(this.Map, SOURCE_NAME, data, this.props.grouped);
        this.AddAllLayer();
    };

    RemoveAllLayer = () => {
        RemoveCluster(this.Map, CLUSTER_NAME);
        RemoveTexts(this.Map, TEXTS_NAME);
        RemoveAllDots(this.Map);
    };

    AddAllLayer = () => {
        const {onClickDot} = this.props;
        AddCluster(this.Map, SOURCE_NAME, CLUSTER_NAME);
        AddTexts(this.Map, SOURCE_NAME, TEXTS_NAME);
        AddDots(this.Map, SOURCE_NAME, onClickDot);
    };

    render(){
        const { containerId, style, className } = this.props;
        return <div id={containerId} style={style} className={className}/>
    }
}

MapBox.defaultProps = {
    containerId: 'mapbox',
    onClickDot: () => {},
    zoom: 14,
    withSidebar: true,
    grouped: true,
    userCenter: {lat: 0, lng: 0}
};

export default MapBox;