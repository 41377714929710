import React from 'react';
import './index.scss';

class EmailRow extends React.Component {

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.props.data !== nextProps.data
    }

    render() {

        const { data } = this.props;
        let { email, customMessage , type} = data;

        return (
            <div className={'email-row-wrapper'}>
                <p className={'mb-0'}>
                    {type} - {email}
                </p>
                <p className={'m-0 additional-information'}>
                    {customMessage !== '' ? customMessage : 'Mensaje tipo'}
                </p>
            </div>
        );
    }
}

export default EmailRow;
