import React, {Fragment} from 'react';
import NewId from "../../helpers/NewId";
import ReactTooltip from 'react-tooltip';
import Icon from './../Icon/index';
import './index.scss';
import PropTypes from 'prop-types';


class RoundButton extends React.PureComponent {
    ref = NewId('round-button');

    static propTypes = {
        onClick: PropTypes.func,
        icon: PropTypes.string,
        tooltip: PropTypes.string
    };

    static defaultProps = {
        place: "top",
        type: "dark",
        effect: "float"
    };

    render(){
        const { onClick, icon, className, place, type, effect, tooltip } = this.props;
        return(
            <Fragment>
                <button data-tip={tooltip} type={'button'} data-for={this.ref} className={ `round-button ${className ? className : ''}`} onClick={onClick} tabIndex="-1">
                    {icon && <Icon name={icon}/>}
                </button>
                <ReactTooltip id={this.ref} place={place} type={type} effect={effect}/>
            </Fragment>
        )
    }
}

export default RoundButton