import './index.scss';
import React from 'react';
import Checkbox from './../../elements/CheckBox'

export class Ceil extends React.PureComponent {
    render() {
        const {children, last, header, className, ...rest} = this.props;
        return(
            <div {...rest} className={`ceil ${last ? 'last': ''} ${className ? className : ''}`}>
                {children}
                {/*{header && 'p'}*/}
            </div>
        )
    }
}

export class Row extends React.PureComponent {
    render() {
        const {children, last, banding, header, isMarked, ...rest} = this.props;
        const createClass = `
            row 
            ${last ? 'last': ''}
            ${header ? 'header': ''}
            ${banding ? 'banding': ''}
            ${isMarked ? 'is-marked': ''}
        `;

        return(
            <div {...rest} className={createClass}>
                {children}
            </div>
        )
    }
}

/*****************************************************************************************************/

const preventEvents = (e) =>{ if(e && e.preventDefault){ e.preventDefault(); e.stopPropagation();} };


const cleanBodyByHeader = ( header, body ) => {
    const bodyFiltered = [];

    body.forEach(b =>{
        const obj = {};

        header.map( k => k.key ).forEach( h => {
            if(b[h]) obj[h] = b[h];
            else obj[h] = '';
        });

        if(b['id']) obj['id'] = b['id'];

        bodyFiltered.push(obj)
    });

    return bodyFiltered
};

export default class Table extends React.Component {
    currentTable = null;
    static defaultProps = {
        body: [],
        selected:[],
        pxBeforeEnd: 1,
        gridX: false,
        gridY: false,
        banding: false,
        header: [],
        onAllSelection: () => {},
        onSingleChecked: () => {},
        onClickRow: () => {},
        onEndScroll: undefined
    };

    constructor(props){
        super(props);
        this.state = {
            body: []
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.body !== this.props.body)
            this.setState({
                body: cleanBodyByHeader(this.props.header, this.props.body)
            });
    }

    handleOnScroll = () => {
        if(this.props.onEndScroll && this.currentTable){
            const Diff =  this.currentTable.scrollHeight - (this.currentTable.scrollTop + this.currentTable.offsetHeight);
            if( Diff < this.props.pxBeforeEnd )
                this.props.onEndScroll(Diff);
        }
    };

    componentDidMount() {
        this.setState({
            body: cleanBodyByHeader(this.props.header, this.props.body)
        })
    }

    onHeaderCheckbox = (e) => {
        preventEvents(e);
        this.props.onAllSelection(this.state.body.map(b=>b.id))
    };

    handleOnClickRow = (event, id) => {
        if(!event.target.classList.contains('checkbox') && !event.target.classList.contains('checkmark-icon'))
            this.props.onClickRow(event,id);
    };

    render() {
        const { header, banding, onSingleChecked, onAllSelection, selected, body, gridX, gridY, onEndScroll, pxBeforeEnd, onClickRow, ...rest } = this.props;
        return(
            <div {...rest} onScroll={this.handleOnScroll} className={`fake-table ${onEndScroll ? 'scrolled' : ''} ${gridX ? 'horizontalGrid' : ''} ${gridY ? 'verticalGrid': ''}`} ref={node=>this.currentTable = node}>
                <Row header key={'header'}>
                    <Ceil onClick={this.onHeaderCheckbox} key={'checkbox'} className={'checkbox'}>
                        <Checkbox checked={selected.length === body.length && body.length > 0} name={'top-checkbox'}/>
                    </Ceil>
                    {
                        header && header.map( (h,index) =>
                            <Ceil header
                                  last={header.length - 1 === index} key={h.title}
                                  style={{
                                      width: h.width,
                                      maxWidth: h.width,
                                      ...h.style
                                  }}
                            >
                                <div>{h.title}</div>
                            </Ceil> )
                    }
                </Row>

                { this.state.body.map( (b, index) => {
                        return <Row onClick={(e) => this.handleOnClickRow(e, b.id)} banding={banding} last={body.length - 1 === index} key={b.id} data-key={b.id} isMarked={body[index] && body[index].isMarked}>
                            <Ceil onClick={(e) => onSingleChecked(e, b.id)} key={'checkbox'} className={'checkbox'}>
                                <Checkbox checked={selected.includes(b.id)}  name={'top-checkbox'}/>
                            </Ceil>
                            {
                                Object.entries(b).map((o, index) => o[0] !== 'id' &&
                                    <Ceil
                                        key={`ceil-${b.id}-${header[index].title}`} last={header.length - 1 === index}
                                        style={{
                                            width: header[index] && header[index].width,
                                            maxWidth: header[index] && header[index].width,
                                        }}
                                    >
                                        {o[1]}
                                    </Ceil>)
                            }
                        </Row>
                    }
                ) }

                {this.state.body.length === 0 && <p style={{textAlign: 'center', marginTop: '45px'}}>No hay datos que mostrar</p>}
            </div>
        )
    }
}