import React from 'react';
import { connect} from 'react-redux';
// import { getUsers } from "../../../redux/users/action";
import { user } from "../../../configuration/entitiesNames";

const TIME_TO_WAIT_BEFORE_DATA_BASE = 2000;
const DELETED_USER_TEXT = "El usuario ha sido borrado";
const NO_DATA_FROM_USER_TO_SHOW = "Sin datos que mostrar";

class UserPopulated extends React.PureComponent {
    static defaultProps = {
        show: "name",
    };
    _isMounted = false;
    timeOut = null;
    state = {
        searching: true,
        error: false,
    };

    componentWillUnmount() {
        this._isMounted = false;
        clearTimeout(this.timeOut);
    }

    componentDidMount() {
        this._isMounted = true;
        setTimeout(this.userIsMissing, TIME_TO_WAIT_BEFORE_DATA_BASE * 2);
        if(!this.props.userObject) this.timeOut = setTimeout(this.findUserInDataBase, TIME_TO_WAIT_BEFORE_DATA_BASE);
        else this.setState({ searching: false })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.userObject && this.state.searching && this._isMounted)
            this.setState({ searching:false }, () => clearTimeout(this.timeOut));

        if(!this.state.searching && this.timeOut)
            clearTimeout(this.timeOut);

    }

    userIsMissing = () => {
        if(!this.props.userObject && this._isMounted)
            this.setState({
                searching: false,
                error: DELETED_USER_TEXT
            });
    };

    findUserInDataBase = () => {
        //TODO: Abort until user-management has filtering

        // if(!this.props.fetching && this.state.searching) getUsers({ id: this.props.id }, this.callbackFindUser);
        // else this.timeOut = setTimeout(this.findUserInDataBase, 50);
    };

    callbackFindUser = (response) => {
        this.setState({searching: false});
        switch (response.status) {
            case 200: this.setState({
                searching: false,
                error: null,
            });
                break;

            case 404: this.setState({
                searching: false,
                error: DELETED_USER_TEXT
            });
                break;

            default: this.setState({
                searching: false,
                error: "Ha ocurrido un error inesperado"
            });
        }
    };

    render() {
        const { show } = this.props;
        const { searching, error } = this.state;

        if(error) return <i>{error}</i>;
        if(searching) return <i>Buscando...</i>;
        if( this.props.userObject && !this.props.userObject[show]) return <i>{NO_DATA_FROM_USER_TO_SHOW}</i>;

        const ToReturn = this.props.userObject && this.props.userObject[show];
        return ToReturn || error;
    }

}

const mapStateToProps = ({ users }, { id }) => {
    return ({
        userObject: users[user] ? users[user].find(u => u.id === id) : {},
        fetching: users.fetching
    });
};

export default connect(mapStateToProps)(UserPopulated);