import React from 'react';
import PropTypes from 'prop-types';
import { FooterSidebar as OriginalFooterSidebar } from '../../../components/Sidebar'
import Button from "../../../elements/Button";

class FooterSidebar extends React.Component {

    static defaultProps = {
        onClickFooterButton: () => {},
        buttonLabel: 'buttonLabel'
    };

    static propTypes = {
        onClickFooterButton: PropTypes.func,
        buttonLabel: PropTypes.string
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return false;
    }

    onClickFooterButton = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.onClickFooterButton();
    };

    render() {
        const { buttonLabel } = this.props;
        return(
            <OriginalFooterSidebar>
                <Button onClickCapture={ this.onClickFooterButton } fullWidth >
                    {buttonLabel}
                </Button>
            </OriginalFooterSidebar>
        )
    }
}

export default FooterSidebar;