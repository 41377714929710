import React from 'react';
import InfoRow from './InfoRow';
import { getTaxonomies } from '../../../api/taxonomies';
import { comment } from '../../../configuration/entitiesNames';

class Comments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comments: []
    }
  }

  async getComments() {
    try {
      const {data: comments} = await getTaxonomies(comment, undefined, { incidentId: this.props.id });
      this.setState({comments: comments});
    } catch (error) {
      console.error("Error: ", error);;
    }
  }

  render() {
    this.getComments()
    return(
      <section className="pdf-section pdf-section-info">
          <h3>Comentarios</h3>
          {this.state.comments.map(comment => <InfoRow content={comment.content}/>)}
      </section>
    ) 
  }

}

export default Comments;
