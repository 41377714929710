import React from "react";
import Consumer from "../../Context/Consumer";

class HeaderBlock extends React.Component {

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return false
    }

    render(){
        const { closeFilterSidebar } = this.props;
        return(
            <p className={'link mb-0 p-0'} onClick={closeFilterSidebar}>Cerrar</p>
        )
    }
}

export default Consumer(HeaderBlock);