import React from 'react';
import { subfamily } from "../../../configuration/entitiesNames";
import EntityPopulated from "./EntityPopulated";

const ENTITY = subfamily;

class SubfamilyPopulated extends React.PureComponent{
    render() {
        return <EntityPopulated {...this.props} ENTITY={ENTITY}/>
    }
}

export default SubfamilyPopulated