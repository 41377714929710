import React from 'react';
import ParseLatLng from "../../../helpers/geocoding/ParseLatLng";
import Map from "../../../components/Map";

const createMarker = (obj) => obj.coords ? {
    coords: ParseLatLng(obj.coords),
    popup: { ...obj, title: 'IncidentPosition'},
    key: 'IncidentPosition',
    color: "#444",
} : null ;

class MapSection extends React.PureComponent {
    mapReference = null;

    componentDidMount() {
        const {incident} = this.props;
        const MapInDom = document.querySelector(`#MapBoxPrintIncident-${incident.id} canvas`);
    }

    render() {
        const {incident} = this.props;

        return (
            <section className="pdf-section pdf-section-map">
                <h3>Ubicación de la incidencia</h3>
                <div style={{height: 300, width: "100%"}}>
                    {
                        (incident && incident.coords) &&
                        <Map
                            className={"map-canvas"}
                            zoom={16}
                            style={{width: "100%", height: "100%"}}
                            center={incident ? ParseLatLng(incident.coords) : {}}
                            containerId={`MapBoxPrintIncident-${incident.id}`}
                            ref={node => this.mapReference = node}
                            markers={[incident]}
                        />
                    }
                </div>
            </section>
        )
    }
}


export default MapSection;