const Get = (Map, Name) => Map.getLayer(Name);

const Add = (Map, SourceName, Name, Before) => Map.addLayer({
    id: Name,
    type: "symbol",
    source: SourceName,
    filter: ["has", "point_count"],
    layout: {
        "text-field": "{point_count_abbreviated}",
        "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
        "text-size": 12
    }
}, Before);

export const RemoveTexts = (Map, Name) => {
    if(Get(Map,Name))
        Map.removeLayer(Name);
};


export default (Map, SourceName, Name = "Cluster", Before = undefined) => {

    const OldLayer = Get(Map,Name);
    try {
        if(!OldLayer) Add(Map, SourceName, Name, Before);
        else{
            RemoveTexts(Map, Name);
            Add(Map, Name, Before)
        }
    }
    catch (e) {
        console.log(e, 'OldLayer', OldLayer);
    }


};