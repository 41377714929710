import React from 'react';
import { connect} from 'react-redux';
import { getTaxonomies } from "../../../redux/taxonomies/action";
import { incident } from "../../../configuration/entitiesNames";


class FamilyPopulated extends React.PureComponent {
    static defaultProps = {
        ENTITY: incident,
        show: "name",
        taxonomyObject: {}
    };

    // componentDidMount() {
    //     if(!this.props.taxonomyObject || !this.props.taxonomyObject[this.props.show])
    //         this.getTaxonomy()
    // }
    //
    // getTaxonomy = () => {
    //     getTaxonomies({id: this.props.id, type: this.props.ENTITY})
    // };

    render() {
        const {show} = this.props;
        const ToReturn = this.props.taxonomyObject && this.props.taxonomyObject[show];
        return ToReturn || null;
    }

}

const mapStateToProps = ({taxonomies}, {id, ENTITY}) => {
    return ({
        taxonomyObject: taxonomies[ENTITY] ? taxonomies[ENTITY].find(tax => tax.id === id) : {}
    });
};

export default connect(mapStateToProps)(FamilyPopulated);