import { all } from 'redux-saga/effects';
import users from './users/saga'
import incidents from './incidents/saga'
import taxonomies from './taxonomies/saga'
import auth from './auth/saga'
import roles from './roles/saga'

export default function* rootSaga() {
    yield all([
        incidents(),
        taxonomies(),
        auth(),
        users(),
        roles(),
    ])
}
