import React from 'react';
import './index.scss';

class CheckBox extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            checked: props.checked || false
        }
    }

    componentDidMount(){
        if(this.props.checked !== this.state.checked)
            this.setState({
                checked: this.props.checked
            })
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props.checked !== prevProps.checked && this.props.checked !== this.state.checked)
            this.setState({
                checked: this.props.checked
            })
    }

    handleOnChange = () => {
        const e = { target:{ name:this.props.name, checked: this.state.checked } };
        this.props.onChange && this.props.onChange(e)
    };

    toggleChecked = () => {
        if(!this.props.disabled)
            this.setState({
                checked: !this.state.checked
            }, this.handleOnChange)
    };


    render() {
        const { name, label, onClick, onChange, disabled, ...rest} = this.props;
        const { checked } = this.state;
        return (
            <div
                onClick={this.toggleChecked}
                className={`checkbox ${disabled ? 'isDisabled' :''}`}
            >
                <input
                    {...rest}
                    type={'checkbox'}
                    disabled={disabled}
                    name={name}
                    className={checked ? 'checked':''}
                    checked={checked}
                    onChange={onChange}
                />
                <div>
                    <span className="checkmark-icon">&#10003;</span>
                </div>
                {label && <label>{label}</label>}
            </div>
        );
    }
}

CheckBox.defaultProps = {
    name: 'name',
    onClick: () => {},
    checked: true,
    onChange: () =>{}
};

export default CheckBox;
