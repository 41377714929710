import React, {Fragment} from 'react';
import RoundButton from "../../../elements/RoundButton";
import { ForbiddenEmails } from './../../../configuration/network';
import {UserConsumer} from '../Context'
import Sidebar from '../../../components/Sidebar';
import Table from './../../../components/Table';
import { user } from './../../../configuration/entitiesNames';
import {create, update, view} from './../../../configuration/operationNames';
import { getUsers, deleteUsers } from "../../../redux/users/action";
import { connect } from "react-redux";
import List from "../../../template/List";
import {AddIcon, CloseIcon, DeleteIcon, ResetPassword} from "../../../configuration/icons";
import ReactDOM from "react-dom";
import Filter from './../Filter'
import { FilterConsumer } from './../Filter/Context'
import Create from '../Create'
import Update from '../Update'
import { UserAgreeWithString, resetUserPassword } from './../functions';
import FilterOpenerAndIndicator from "../Components/FilterOpenerAndIndicator";
import Swal from "sweetalert2";



const HEADERS = [
    {key: 'name', title: 'Nombre'},
    {key: 'surname', title: 'Apellidos'},
    {key: 'email', title: 'Email'},
    // {key: 'telephone', title: 'Teléfono'},
    {key: 'actions', title: 'Acciones', width: 80},
];

const createRow = (user, actions, isMarked) => {
    return {
        id: user.id,
        name: user.name,
        surname: user.surname,
        email: user.email,
        actions,
        isMarked
    }
};


class ListUsers extends List {
    static defaultProps = {
        [user]: [],
    };


    constructor(props){
        super(props);
        this.entity = user;
        this.state = {
            page: 0,
            sidebarMode: undefined,
            basic: true,
            allChecked: false,
            selected: [],
            openEditSidebar: false,
            lastRowSelected: null,
            errors:[],
            data: [],
            RightActionsIconsZone: null,
        }
    }

    actionsButtons = (user) =>{
        return(
            <Fragment>
                {/*<EditButton*/}
                {/*    onClickCapture={(e)=>this.onPressEditButton(e, user.id)}*/}
                {/*/>*/}
                {/*<DeleteButton/>*/}
                {/*<p className={'p-0 m-0'}>Buttons</p>*/}
                <RoundButton icon={ResetPassword} tooltip={'Generar nueva contraseña'} onClick={(e)=>resetUserPassword(e, user.id)}/>
            </Fragment>
        )
    };

    componentDidMount() {
        this.getTopRightLayoutContainer();
        this.setState({ data: this.parseUsersToRow() });
        if(!this.props[user] || this.props[user].length === 0) getUsers();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props[user] !== prevProps[user] || this.props.lastElementSelected !== prevProps.lastElementSelected)
            this.setState({ data: this.parseUsersToRow() });

        if(this.props.sidebarIsOpen && !prevProps.sidebarIsOpen && this.props.filterSidebarIsOpen)
            this.props.closeFilterSidebar();

        super.componentDidUpdate(prevProps, prevState, snapshot)
    }

    // onPressCreateButton = (e) => {
    //     e && e.preventDefault();
    //     e && e.stopPropagation();
    //     this.setMode(create);
    //     this.setUserSelected({});
    //     this.openCreateViewEditSidebar()
    // };
    //
    // onPressEditButton = (e, userID) => {
    //     e && e.preventDefault();
    //     e && e.stopPropagation();
    //     this.setMode(update);
    //     this.setUserSelected(userID);
    //     this.openCreateViewEditSidebar();
    // };

    // setMode = (mode = undefined) =>{
    //     this.setState({ sidebarMode: mode });
    // };

    openSidebar = () => {
        this.props.openSidebar();
        this.props.setMode(create);
    };

    // closeCreateViewEditSidebar = () => {
    //     this.setState({openEditSidebar: false});
    //     this.setMode(undefined)
    // };

    parseUsersToRow = () => {
        const { lastElementSelected } = this.props;
        const AllUser = this.props[user].filter(user => !ForbiddenEmails.includes(user.email));
        return AllUser.map( user => createRow(user, this.actionsButtons(user), lastElementSelected && user.id === lastElementSelected.id) );
    };

    onPressDeleteButton = (users = this.state.selected) => {
        deleteUsers(users, this.callBackOnDeleteUsers);
    };

    callBackOnDeleteUsers = (response) => {
        if(response.status === 204 && this.props.pendingRequests === 0) {
            // this.closeCreateViewEditSidebar();
            // this.setMode(view);
            // this.setState({
            //     selected: []
            // })
            this.props.closeSidebar();
        }
    };

    // onPressDetailButton = (userID) => {
    //     // this.props.navigation.navigate({routeName: ViewPage(user), params:{ id:userID, data: this.state.lastRowSelected }});
    //     navigate('/');
    //     this.closeCreateViewEditSidebar();
    // };
    //
    // footerContent = (allChecked) =>{
    //     const { selected } = this.state;
    //     const { navigation } = this.props;
    //     const { type } = navigation.state.params;
    //     return (
    //         <>
    //             <SelectAllButton
    //                 onPress={this.handleCheckAll}
    //                 badge={selected.length > 0}
    //                 badgeContent={selected.length}
    //                 allChecked={allChecked}
    //             />
    //
    //             <DeleteButton
    //                 onPress={()=>this.onPressDeleteButton(this.state.selected)}
    //                 disabled={selected.length < 1}
    //             />
    //
    //             {/* <EditButton
    //                 onPress={()=>this.setState({openEditSidebar: !this.state.openEditSidebar})}
    //                 disabled={selected.length < 1}
    //             /> */}
    //
    //
    //
    //             {/*<Button transparent disabled={selected.length < 1 || selected.length > 1}*/}
    //             {/*onPress={()=>navigation.navigate({routeName: EditPage(type), params:{ data: this.props[type].find(e => e.id === selected[0]) }})}*/}
    //             {/*>*/}
    //             {/*<Icon name='ios-create'/>*/}
    //             {/*<Text>Editar</Text>*/}
    //             {/*</Button>*/}
    //
    //             <Button transparent onPress={()=>navigation.navigate(CreatePage(type))}>
    //                 <Icon name='ios-add-circle-outline' />
    //             </Button>
    //
    //             <Button transparent onPress={this.onPressCreateButton}>
    //                 <Icon name='ios-add-circle-outline' />
    //             </Button>
    //         </>
    //     )
    // };
    //
    //
    // // TODO: Refacto as common title by this.title
    // headerContent = () =>{
    //     return (
    //         <Title>{this.props.navigation.getParam('title')}</Title>
    //     );
    // };
    //
    // headerRightContent = () => (
    //     <Button transparent>
    //         <Icon name='ios-search' />
    //     </Button>
    // );
    //

    //
    // getType = () => this.props.navigation.state.params.type;
    //
    // _handleOnPickerChange(value,name) {
    //     if(name !== 'orderType' && value)
    //         getTaxonomies({ type: CalcChildType(TranslateType(name)), parent: value });
    //     this.handleOnPickerChange(value,name);
    // }
    //
    // updateInBlock = () => {
    //     const { orderType, family, subfamily } = this.state;
    //     let fullIncidents = this.props[incident].filter(inc => this.state.selected.includes(inc.id));
    //     fullIncidents = fullIncidents.map(inc => ({...inc, orderType, family, subfamily, type: undefined}));
    //     updateUsers (fullIncidents);
    //     this.setState({
    //         // selected:[], //TODO deselected after press update incidents or keep selected?
    //         openEditSidebar: false,
    //         orderType: undefined,
    //         family: undefined,
    //         subfamily: undefined
    //     })
    // };
    //

    // // TODO: add a way to charge more user in table ( scroll for example or a button )
    // handleRefresh = () =>{
    //     getUsers();
    // };
    //
    //
    // setUserSelected = ( userID ) => this.setLastRowSelected(FindUserByIdInRedux(userID));

    onClickInRow = (e, userID) => {
        const { setMode, sidebarMode, sidebarIsOpen, openSidebar } = this.props;
        this.props.navigate(userID);
        if(sidebarMode !== view) setMode(view);
        if(!sidebarIsOpen) openSidebar();
    };

    // updateUserInState = (data) => {
    //     this.setState({
    //         lastRowSelected: {...this.state.lastRowSelected, ...data}
    //     })
    // };

    topNavButtons = () => {
        /** From Sidebar Context**/
        const { sidebarIsOpen, closeSidebar,  } = this.props;

        return (
            <div className={'top-var-buttons'}>
                <FilterOpenerAndIndicator/>
                <RoundButton
                    className={`${sidebarIsOpen ? 'sidebar-is-open' : ''} mr-1 create-incident`}
                    icon={sidebarIsOpen ? CloseIcon : AddIcon}
                    tooltip={sidebarIsOpen ? "Cerrar panel lateral" : "Crear nuevo usuario"}
                    onClick={sidebarIsOpen ? closeSidebar : this.openSidebar}/>
            </div>
        )
    };

    getTopRightLayoutContainer = () => {
        this.setState({ RightActionsIconsZone: document.getElementById('right-actions-icons-zone') });
    };


    preventDeleteUsersWithModalConfirmation = () => {
        Swal.fire( {
            title: '¡Cuidado!',
            text: "¿Estás seguro de que quieres borrar los usuarios seleccionados?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            confirmButtonText: 'Borrar',
            cancelButtonText: 'Cancelar',
            focusCancel: true,
            preConfirm: this.deleteUsersSelected
        });
    };

    deleteUsersSelected = () => {
        const { selected } = this.state;
        deleteUsers(selected, this.callBackOnDeleteUsersSelected)
    };

    callBackOnDeleteUsersSelected = (response) => {
        if(response.status === 204)
            this.setState({ selected: this.state.selected.filter(u => u !== response.user)})
    };

    render(){
        /** Portals Areas **/
        const { RightActionsIconsZone } = this.state;

        const {children} = this.props;

        /** From Sidebar **/
        const { sidebarIsOpen, sidebarMode, closeSidebar } = this.props;

        /** From Filter Sidebar **/
        const { filterSidebarIsOpen } = this.props;

        const { selected, data } = this.state;

        return(
            <>
                { RightActionsIconsZone && ReactDOM.createPortal(this.topNavButtons(), RightActionsIconsZone) }

                <div className={'title-row'}>
                    <h2>Listado de usuarios</h2>
                    <div className={'crud-buttons'}>
                        { selected.length > 0 && <RoundButton icon={DeleteIcon} tooltip={"Borrar los usuarios seleccionados"} onClick={this.preventDeleteUsersWithModalConfirmation}/> }
                    </div>
                </div>

                <Sidebar
                    key={'actions-sidebar'}
                    open={sidebarIsOpen}
                    closeSidebarFn={closeSidebar}
                    name={'CreateEditViewSidebar'}
                >
                    { sidebarMode === update && <Update/> }
                    { sidebarMode === create && <Create/> }
                    {(!sidebarMode || sidebarMode === view) && children}
                </Sidebar>

                <Sidebar
                    key={'filter-sidebar'}
                    open={filterSidebarIsOpen}
                >
                    <Filter/>
                </Sidebar>

                <div style={{height: '80vh', overflow: 'auto'}}>
                    <Table
                        banding
                        key={'Table'}
                        ref={node => this.table = node}
                        body={data}
                        header={HEADERS}
                        selected={selected}
                        onSingleChecked={this.onSingleChecked}
                        onAllSelection={this.handleCheckAll}
                        onClickRow={this.onClickInRow}
                    />
                </div>
            </>
        )
    }

}

const mapStateToProps = ({ users }, {filters}) => {
    let usersFiltered = users[user];
    if(filters.string.length > 0 || filters.selected.length > 0 || filters.roles.length > 0) {
        usersFiltered = [];
        if (filters.string.length > 0)
            usersFiltered = users[user].filter(u => UserAgreeWithString(u, filters.string));

        if (filters.selected.length > 0) {
            usersFiltered = [...usersFiltered, ...users[user].filter(u => filters.selected.includes(u.id) && !usersFiltered.includes(u))];
        }

        // if(filters.roles.length > 0){ TODO
        //     usersFiltered = usersFiltered.filter(u => u.roles.includes(filters.roles)  && !usersFiltered.includes(u)))
        // }
    }


    return ({
        [user] : usersFiltered,
        fetching: users.fetching,
        pendingRequests: users.pending,
        totalUsers: users.total,
    });
};

export default FilterConsumer(UserConsumer(connect(mapStateToProps)(ListUsers)))