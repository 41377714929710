export const getMarkerCategory = (name) => {
    var color = '';
    if (name === "rejected") {
        color = '#d1372e';
    } else if (name === "close") {
        color = '#2f4eff';
    } else if (name === "open") {
        color = '#599e30';
    } else if (name === "pending") {
        color = 'grey';
    } else if (name === "irrelevant") {
        color = '#fec63e';
    }
    return color;
}