import React  from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { incident } from "../../../../../configuration/entitiesNames";
import Dropview from "../../../../../components/DropView";

class ExpandedAddress extends React.Component {

    static defaultProps = {
        number: 'Sin número',
        street: 'Sin calle',
        city: 'Sin ciudad o barrio',
        zipCode: 'Sin código postal',
        zone: 'Sin Zona',
    };

    static propTypes = {
        number: PropTypes.string,
        street: PropTypes.string,
        city: PropTypes.string,
        zipCode: PropTypes.string,
        zone: PropTypes.string,
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        for (const prop in ExpandedAddress.defaultProps){
            if(nextProps[prop] !== this.props[prop])
                return true
        }
        return false;
    }

    render() {
        const { number, street, city, zipCode, zone } = this.props;
        return (
            <Dropview header={<h4 className={'m-0'}>Dirección Expandida</h4>} demountable>
                <div className={'pl-1 pr-1'}>
                    <h6 className={'label'}>Calle</h6><p>{ street ? street : ExpandedAddress.defaultProps.street }</p>
                    <h6 className={'label'}>Número</h6><p>{ number ? number : ExpandedAddress.defaultProps.number }</p>
                    <h6 className={'label'}>Ciudad o Barrio</h6><p>{ city ? city : ExpandedAddress.defaultProps.city }</p>
                    <h6 className={'label'}>Código Postal</h6><p>{ zipCode ? zipCode : ExpandedAddress.defaultProps.zipCode }</p>
                    <h6 className={'label'}>Zona</h6><p>{ zone ? zone : ExpandedAddress.defaultProps.zone }</p>
                </div>
            </Dropview>
        )
    }
}

const mapStateToProps = ({ incidents }, {id}) => {
    const incidentInIndents = incidents[incident].find(inc => inc.id === id);
    const foundIncident = incidentInIndents ? incidentInIndents : incidents.filtered.find(inc => inc.id === id);

    if(foundIncident)
        return {
            number: foundIncident.number,
            street: foundIncident.street,
            city:  foundIncident.city,
            zipCode: foundIncident.zipCode,
            zone:  foundIncident.zone,
        };

    return ExpandedAddress.defaultProps;
};

export default connect(mapStateToProps)(ExpandedAddress)