export const GET_HISTORICAL_INCIDENT_REQUEST = "GET_HISTORICAL_INCIDENT_REQUEST";
export const GET_HISTORICAL_INCIDENT_SUCCESS = "GET_HISTORICAL_INCIDENT_SUCCESS";
export const GET_HISTORICAL_INCIDENT_FAILED = "GET_HISTORICAL_INCIDENT_FAILED";

export const GET_EMAILS_INCIDENT_REQUEST = "GET_EMAILS_INCIDENT_REQUEST";
export const GET_EMAILS_INCIDENT_SUCCESS = "GET_EMAILS_INCIDENT_SUCCESS";
export const GET_EMAILS_INCIDENT_FAILED = "GET_EMAILS_INCIDENT_FAILED";

export const GET_INCIDENTS_REQUEST = "GET_INCIDENTS_REQUEST";
export const GET_INCIDENTS_SUCCESS = "GET_INCIDENTS_SUCCESS";
export const GET_INCIDENTS_FAILED = "GET_INCIDENTS_FAILED";

export const FILTER_INCIDENTS_REQUEST = "FILTER_INCIDENTS_REQUEST";
export const FILTER_INCIDENTS_SUCCESS = "FILTER_INCIDENTS_SUCCESS";
export const FILTER_INCIDENTS_FAILED = "FILTER_INCIDENTS_FAILED";

export const CREATE_INCIDENT_REQUEST = "CREATE_INCIDENT_REQUEST";
export const CREATE_INCIDENT_SUCCESS = "CREATE_INCIDENT_SUCCESS";
export const CREATE_INCIDENT_FAILED = "CREATE_INCIDENT_FAILED";

export const DELETE_PICTURE_INCIDENT_REQUEST = "DELETE_PICTURE_INCIDENT_REQUEST";
export const DELETE_PICTURE_INCIDENT_SUCCESS = "DELETE_PICTURE_INCIDENT_SUCCESS";
export const DELETE_PICTURE_INCIDENT_FAILED = "DELETE_PICTURE_INCIDENT_FAILED";

export const ADD_PICTURE_INCIDENT_REQUEST = "ADD_PICTURE_INCIDENT_REQUEST";
export const ADD_PICTURE_INCIDENT_SUCCESS = "ADD_PICTURE_INCIDENT_SUCCESS";
export const ADD_PICTURE_INCIDENT_FAILED = "ADD_PICTURE_INCIDENT_FAILED";

export const UPDATE_INCIDENTS_REQUEST = "UPDATE_INCIDENTS_REQUEST"; //Plural
export const UPDATE_INCIDENT_REQUEST = "UPDATE_INCIDENT_REQUEST";
export const UPDATE_INCIDENT_SUCCESS = "UPDATE_INCIDENT_SUCCESS";
export const UPDATE_INCIDENT_FAILED = "UPDATE_INCIDENT_FAILED";

export const UPDATE_STATUS_INCIDENTS_REQUEST = "UPDATE_STATUS_INCIDENTS_REQUEST"; //Plural
export const UPDATE_STATUS_INCIDENT_REQUEST = "UPDATE_STATUS_INCIDENT_REQUEST";
export const UPDATE_STATUS_INCIDENT_SUCCESS = "UPDATE_STATUS_INCIDENT_SUCCESS";
export const UPDATE_STATUS_INCIDENT_FAILED = "UPDATE_STATUS_INCIDENT_FAILED";

export const PRINTED_INCIDENTS_REQUEST = "PRINTED_INCIDENTS_REQUEST"; //Plural
export const PRINTED_INCIDENT_REQUEST = "PRINTED_INCIDENT_REQUEST";
export const PRINTED_INCIDENT_SUCCESS = "PRINTED_INCIDENT_SUCCESS";
export const PRINTED_INCIDENT_FAILED = "PRINTED_INCIDENT_FAILED";


export const SWIPE_SUCCESS_STATUS = "SWIPE_SUCCESS_STATUS";