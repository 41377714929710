import Input from './Input';
import Textarea from './Textarea';
import Select from './Select';
import InputStyled from './InputStyled';
import Form from './Form';

export {
    Select,
    Input,
    Textarea,
    InputStyled,
    Form
}

export default {
    Select,
    Input,
    Textarea,
    InputStyled,
    Form
}