import * as TYPES from './types';
import { store } from '../store';
import { navigate } from "@reach/router";
import {paths} from "../../configuration/paths";

export const Login = (credentials, callback) => store.dispatch({
    type: TYPES.LOGIN_REQUEST,
    credentials,
    callback,
});

export const Registration = (userData, callback) => store.dispatch({
    type: TYPES.REGISTRATION_REQUEST,
    userData,
    callback,
});
//
// export const SetCredentials = (res)=> store.dispatch({
//     type: TYPES.SET_CREDENTIALS,
//     payload: res
// });

export const LogOut = () => {
    navigate(`/${paths.Auth}/${paths.Login}`).then(()=>store.dispatch({ type: TYPES.LOGOUT }))
};

export const UpdateMe = (payload, callback) => store.dispatch({ 
    type: TYPES.UPDATE_ME_REQUEST,
    payload, callback
}); 