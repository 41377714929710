import React from 'react';
import { getHistorical } from "../../../../../redux/incidents/action";
import HistoryRow from './HistoryRow';
import { connect } from "react-redux";

class ActivityBlock extends React.PureComponent{

    componentDidMount(){
        getHistorical({ id: this.props.id })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.id !== prevProps.id)
            getHistorical({ id: this.props.id })
    }

    render() {
        const { historicals } = this.props.historical;
        return(
            <>
                {(historicals && historicals.length > 0) ?
                    historicals.map(h =>( <HistoryRow data={h} key={h.id} /> )) :
                    <p className={'pt-1 m-0'}>
                        <i>Parece que no se ha podido recuperar actividad para esta incidencia</i>
                    </p>
                }
            </>
        )
    }
}

const mapStateToProps = ({ incidents }) => ({
    historical: incidents.historical,
    fetching: incidents.fetching
});

export default connect(mapStateToProps)(ActivityBlock)