import React from 'react';
import { UserConsumer } from '../Context'
import {view} from "../../../configuration/operationNames";

class CancelButton extends React.Component{

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return false
    }

    onClick = (e) => {
        const {closeSidebar, setMode, lastElementSelected} = this.props;
        e.stopPropagation();
        e.preventDefault();
        setMode(view);
        if(!lastElementSelected) closeSidebar();
    };

    render(){
        return(
            <p className={'link p-0 m-0'} onClickCapture={this.onClick}>Cancelar</p>
        )
    }
}

export default UserConsumer(CancelButton);