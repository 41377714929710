export const GET_USERS_REQUEST = "GET_USERS_REQUEST";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USERS_FAILED = "GET_USERS_FAILED";

export const GET_USER_ROLES_REQUEST = "GET_USER_ROLES_REQUEST";
export const GET_USER_ROLES_SUCCESS = "GET_USER_ROLES_SUCCESS";
export const GET_USER_ROLES_FAILED = "GET_USER_ROLES_FAILED";


export const CREATE_USER_REQUEST = "CREATE_USER_REQUEST";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_FAILED = "CREATE_USER_FAILED";

export const UPDATE_USER_PASSWORD_REQUEST = "UPDATE_USER_PASSWORD_REQUEST";
export const UPDATE_USER_PASSWORD_SUCCESS = "UPDATE_USER_PASSWORD_SUCCESS";
export const UPDATE_USER_PASSWORD_FAILED = "UPDATE_USER_PASSWORD_FAILED";

export const UPDATE_USERS_REQUEST = "UPDATE_USERS_REQUEST"; //Plural
export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILED = "UPDATE_USER_FAILED";

export const DELETE_USERS_REQUEST = "DELETE_USERS_REQUEST"; //Plural
export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILED = "DELETE_USER_FAILED";

export const UPDATE_USER_ROLES_SUCCESS = "UPDATE_USER_ROLES_SUCCESS";

export const GET_USER_ORION_DATA_SUCCESS = "GET_USER_ORION_DATA_SUCCESS";

export const SWIPE_SUCCESS_STATUS_IN_USER = "SWIPE_SUCCESS_STATUS_IN_USER";