import React from 'react';
import { DeleteIcon, EditIcon, PasswordIcon } from '../../configuration/icons';
import { role } from '../../configuration/entitiesNames';
import './index.scss';
import Button from '../../elements/Button';
import ParseEditionToState from "../../helpers/ParseEditionToState";
import { UpdateMe } from '../../redux/auth/action';
import SuccessAnimation from '../../components/SweetAlert/Animations/SuccessAnimation'
import { getMeFromOrion } from '../../api/taxonomies';
import Sidebar from "../../components/Sidebar";
import UpdatePasswordSidebar from "../../componentsScreens/PasswordSidebar";
import Utils from "../../helpers/UtilClassWithCommonFunctions";
import RoundButton from '../../elements/RoundButton';
import { connect } from 'react-redux';
import { Form, Input } from './../../components/Form';
import Alert from "../../elements/Alert";
import { User } from '@secmotic/models';

class Profile extends Utils {

    constructor(props){
        super(props);
        this.state = {
            id: this.props.me.id,
            name : this.props.me.name,
            surname : this.props.me.surname,
            email : this.props.me.email,
            address : this.props.me.address,
            telephone : this.props.me.telephone,
            errors: [],
            edit: false,
            showSuccessAlert: false,
            changePasswordSidebar: false,
            deleteProfileAlertIsOpen: false,
        }
    }

    componentDidMount() {
        getMeFromOrion(this.props.me.id).then(this.setOrionDataInState);
    }

    setOrionDataInState = (response) => {
        if(response.status === 200 && response.data && response.data[0]) {
            const {id, ...rest} = response.data[0];
            this.setState({...rest})
        }
    };

    toggleEdit = () => {
        this.setState({edit: !this.state.edit})
    };

    saveUser = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const UserFromModel = new User({...ParseEditionToState(this.state)});
        const validation = UserFromModel.validate();
        delete validation.roles;
        Object.keys(validation).length > 0 ? this.setErrors(validation) : UpdateMe({...UserFromModel.getJSON(), id: this.props.me.id}, this.updateMeCallback);
    };

    updateMeCallback = (response) => {
        if(response.status === 200){
            this.setState({
                    ...ParseEditionToState(this.state),
                    edit: false,
                    showSuccessAlert: true
                },
                () => setTimeout(this.hideSuccessAlert, 3000))
        }
    };

    hideSuccessAlert = () =>  this.setState({ showSuccessAlert: false });

    toggleSidebar = () => {
        this.setState({
            changePasswordSidebar: !this.state.changePasswordSidebar
        })
    };

    showDeleteProfileAlert = () => {
      this.setState({
          deleteProfileAlertIsOpen: true
      })  
    };

    closeDeleteProfileAlert = () => {
        this.setState({
            deleteProfileAlertIsOpen: false
        })
    };

    render() {
        const { edit, showSuccessAlert, errors, changePasswordSidebar, deleteProfileAlertIsOpen } = this.state;
        const { name, surname, email, telephone } = this.state;

        return(
            <>
                <Alert
                    title="Contacta con un administrador"
                    text={"Por favor, para borrar su perfil de usuario, póngase en contacto con un administrador. Muchas gracias"}
                    open={deleteProfileAlertIsOpen}
                    onClose={this.closeDeleteProfileAlert}
                    onConfirm={this.closeDeleteProfileAlert}
                    showCancelButton={false}
                    confirmButtonText={'Entendido'}
                />
                <div className={'title-button-group'}>
                    <h2 className={'pl-2'}>Perfil de usuario</h2>
                    <RoundButton icon={DeleteIcon} tooltip={"Borrar mi perfil"} onClick={this.showDeleteProfileAlert} />
                    <RoundButton icon={EditIcon} tooltip={"Actualizar mi perfil"} onClick={this.toggleEdit} />
                    <RoundButton icon={PasswordIcon} tooltip={"Cambiar contraseña"} onClick={this.toggleSidebar} />
                </div>

                <Form className={'pl-2'}>
                    <Input
                        readOnly={!edit}
                        keepFeedbackSpace
                        name={'Nombre'}
                        value={edit ? this.state.edition_name : name}
                        onChange={(e)=>this.handleOnChange('edition_name', e.target.value)}
                        infoMsg={ edit ? 'Puedes editar el nombre' : null}
                        errorMsg={ edit ? errors['name'] : null}
                    />

                    <Input
                        readOnly={!edit}
                        keepFeedbackSpace
                        name={'Apellidos'}
                        value={edit ? this.state.edition_surname : surname}
                        onChange={(e)=>this.handleOnChange('edition_surname', e.target.value)}
                        infoMsg={ edit ? 'Puedes editar los apellidos' : null}
                        errorMsg={ edit ? errors['surname'] : null}
                    />

                    <Input
                        readOnly={!edit}
                        keepFeedbackSpace
                        name={'Email'}
                        value={edit ? this.state.edition_email : email}
                        onChange={(e)=>this.handleOnChange('edition_email', e.target.value)}
                        infoMsg={ edit ? 'Puedes editar el email' : null}
                        errorMsg={ edit ? errors['email'] : null}
                    />

                    {/*<Input*/}
                    {/*    readOnly={!edit}*/}
                    {/*    keepFeedbackSpace*/}
                    {/*    name={'Dirección'}*/}
                    {/*    value={edit ? this.state.edition_address : address}*/}
                    {/*    onChange={(e)=>this.handleOnChange('edition_address', e.target.value)}*/}
                    {/*    infoMsg={ edit ? 'Puedes editar la dirección' : null}*/}
                    {/*    errorMsg={ edit ? errors['address'] : null}*/}
                    {/*/>*/}

                    <Input
                        readOnly={!edit}
                        keepFeedbackSpace
                        name={'Teléfono'}
                        value={edit ? this.state.edition_telephone : telephone}
                        onChange={(e)=>this.handleOnChange('edition_telephone', e.target.value)}
                        infoMsg={ edit ? 'Puedes editar el teléfono' : null}
                        errorMsg={ edit ? errors['telephone'] : null}
                    />

                    {
                        showSuccessAlert &&
                        <SuccessAnimation>
                            <p>Se ha actualizado tu perfil con éxito</p>
                            <Button onClick={this.hideSuccessAlert}>Entendido</Button>
                        </SuccessAnimation>
                    }


                    { (edit && !showSuccessAlert) && <Button className={'mt-1'} onClick={this.saveUser}>Guardar</Button> }
                </Form>
                <Sidebar open={changePasswordSidebar}>
                    <UpdatePasswordSidebar closeSidebar={this.toggleSidebar}/>
                </Sidebar>
            </>
        )
    }
}

const mapStateToProps = ({roles, auth}) => ({
    me : auth.me,
    [role] : roles[role],
    // fetching: users.fetching
});

export default connect(mapStateToProps)(Profile)