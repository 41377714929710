import React from 'react'
import PropTypes from 'prop-types';

class SGILogoSection extends React.Component{
    static defaultProps= {
        code: 0
    };

    static propTypes = {
        code: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps.code !== this.props.code
    }

    render() {
        const { code } = this.props;
            return(
                <section className="pdf-section-sgi-logo mt-2">
                    <h1>Código: {code}</h1>
                </section>
            )
        }
    }

export default SGILogoSection