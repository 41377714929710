import React from 'react';
import {Router, Redirect} from '@reach/router';
import { paths } from '../configuration/paths';
// import Dashboard from './Dashboard';
import Home from './Home';
// import CitizenHome from './CitizenHome';
import Private from '../components/PrivateRoute';

/** TEMPLATES **/

import NotFound from './../template/NotFound';
import Auth from './../template/Auth';
import Layout from './../template/Layout';


/** Auth Routes */
import Login from './Login';
import Registry from './Registry';
import Confirmation from './Confirmation';
import Update from './Update';

/** Incidents Routes */
import Incidents from './Incidents';
import ListIncident from './Incidents/List';
import ViewIncident from './Incidents/List/Components/View';

/** Families Routes */
import Families from './Families';
import ListFamily from './Families/List';
import ViewFamily from './Families/List/Components/View';

/** Subfamilies Routes */
import Subfamilies from './Subfamilies';
import ListSubfamily from './Subfamilies/List';
import ViewSubfamily from './Subfamilies/List/Components/View';

/** OrderType Routes */
import OrderTypes from './OrderTypes';
import ListOrderType from './OrderTypes/List';
import ViewOrderType from './OrderTypes/List/Components/View';

/** User Routes */
import Users from './Users';
import ListUser from './Users/List';
import ViewUser from './Users/View';

/** Me Routes */
import Profile from './Profile';

class RelativeRedirect extends React.Component{

    render(){
        let { to, ...rest } = this.props;
        to = to.replace('/', '');
        const pathname = this.props.location.pathname;
        return <Redirect {...rest} to={`${pathname}/${to}`}/>;
    }
}


class Routes extends React.Component {
    render() {
        return(
          <Router>
              <NotFound default/>
              <Redirect from={"/"} to={paths.Home} default noThrow />
              <Confirmation path={`${paths.Auth}/${paths.Confirmation}/:id`} />
              <Update path={`${paths.Auth}/${paths.UpdatePassword}/:id`} />


              <Auth path={paths.Auth}>
                  <RelativeRedirect from="/" to={paths.Login} default noThrow />
                  <Login path={paths.Login}/>
                  <Registry path={`${paths.Registry}`}/>
              </Auth>

              <Private Component={Layout} path={paths.Home}>
                  <NotFound default/>
                  {/*<Dashboard path={paths.Dashboard}/>*/}

                  <Private
                    Component={Home}
                    path={'/'}
                    allowedRoles={['administrador', 'sac', 'coordinador']}
                    elseComponent={<Profile/>}
                  >
                      <ViewIncident path={`:id`}/>
                  </Private>

                  <Private Component={Incidents} path={paths.Incidents} allowedRoles={['administrador', 'sac', 'coordinador']}>
                      <RelativeRedirect from={"/"} to={`list`} default noThrow/>
                      <ListIncident path={`/list`}>
                          <ViewIncident path={`:id`}/>
                      </ListIncident>
                  </Private>

                  <Private Component={Families} path={paths.Families} allowedRoles={['administrador', 'sac', 'coordinador']}>
                      <RelativeRedirect from={"/"} to={`list`} default noThrow />
                      <ListFamily path={`list`}>
                          <ViewFamily path={`:id`}/>
                      </ListFamily>
                  </Private>

                  <Private Component={Subfamilies} path={paths.Subfamilies} allowedRoles={['administrador', 'sac', 'coordinador']}>
                      <RelativeRedirect from={"/"} to={`list`} default noThrow />
                      <ListSubfamily path={`list`}>
                          <ViewSubfamily path={`:id`}/>
                      </ListSubfamily>
                  </Private>

                  <Private Component={OrderTypes} path={paths.OrderTypes} allowedRoles={['administrador', 'sac', 'coordinador']}>
                      <RelativeRedirect from={"/"} to={`list`} default noThrow />
                      <ListOrderType path={`list`}>
                          <ViewOrderType path={`:id`}/>
                      </ListOrderType>
                  </Private>

                  <Private Component={Users} path={paths.Users} allowedRoles={['administrador', 'sac']}>
                      <RelativeRedirect from={"/"} to={`list`} default noThrow />
                      <ListUser path={`/list`}>
                          <ViewUser path={`:id`}/>
                      </ListUser>
                  </Private>

                  <Profile path={paths.Profile}/>
                  {/*<CitizenHome path={paths.Citizen}/>*/}

              </Private>

          </Router>
        )
    }
}
export default Routes;

