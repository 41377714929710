import React, {Fragment} from 'react';
import ReactDom from 'react-dom';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import './index.scss';

const ReactSwal = withReactContent(Swal);

export default class Alert extends React.PureComponent {
    static fn = ReactSwal;

    static propTypes = {
        open: PropTypes.bool,
        extraButtons: PropTypes.array,
        onOpen: PropTypes.func,
        onClose: PropTypes.func,
        onConfirm: PropTypes.func, //Deprecated
        title: PropTypes.string,
        footer: PropTypes.string,
        text: PropTypes.string,
        confirmButtonText: PropTypes.string,
        cancelButtonText: PropTypes.string,
        showCloseButton: PropTypes.bool,
        showConfirmButton: PropTypes.bool,
        showCancelButton: PropTypes.bool,
        reverseButtons: PropTypes.bool,
        showAnimation: PropTypes.bool,
        showLoaderOnConfirm: PropTypes.bool,
        focusConfirm: PropTypes.bool,
        focusCancel: PropTypes.bool
    };

    static defaultProps = {
        open: false,
        extraButtons: null,
        onOpen: ()=>{},
        onClose: ()=>{},
        onConfirm: ()=>{}, //Deprecated
        title: undefined,
        footer: undefined,
        text: undefined,
        confirmButtonText: undefined,
        cancelButtonText: undefined,
        showCancelButton: false,
        showCloseButton: false,
        showConfirmButton: false,
        reverseButtons: false,
        showAnimation: true,
        showLoaderOnConfirm: undefined,
        focusConfirm: false,
        focusCancel: true
    };

    state = {};

    /** Functions **/
    onOpen = () => this.props.onOpen && this.props.onOpen();
    onClose = () => {
        ReactSwal.close();
        this.props.onClose && this.props.onClose();
    };
    onConfirm = () => this.props.onConfirm && this.props.onConfirm(); //Deprecated
    onBeforeOpen = () => {};


    /** Props **/
    getTitle = () => this.props.title;
    getFooter = () => this.props.footer;
    getText = () => this.props.text;


    /** Buttons **/
    getConfirmButtonText = () => this.props.confirmButtonText || 'Ok';
    getCancelButtonText = () => this.props.cancelButtonText;

    /** Options **/
    getShowCloseButton = () => this.props.showCloseButton;
    getReverseButtons = () => this.props.reverseButtons;
    getShowAnimation = () => this.props.showAnimation;
    getShowCancelButton = () => this.props.showCancelButton || this.props.extraButtons;
    getShowConfirmButton = () => this.props.onConfirm && !this.props.extraButtons;
    getFocusConfirm = () => this.props.focusCancel;
    getFocusCancel = () => this.props.focusCancel;
    getShowLoaderOnConfirm = () => this.props.showLoaderOnConfirm || this.props.onConfirm;

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.open && !this.props.open){
            this.onClose();
        }
    }

    renderAlert = () => {
        ReactSwal.fire({

            /** Functions **/
            onClose: this.onClose,
            // onConfirm: this.onConfirm, //now is always preConfirm //Deprecated
            // onCancel: this.onCancel,
            onOpen: this.onOpen,
            preConfirm: this.onConfirm,
            onBeforeOpen: this.onBeforeOpen,

            /** Props **/
            title: this.getTitle(),
            footer: this.getFooter(),
            text: this.getText(),


            /** Buttons **/
            confirmButtonText: this.getConfirmButtonText(),
            cancelButtonText: this.getCancelButtonText(),
            showCloseButton: this.getShowCloseButton(),

            /** Options **/
            reverseButtons: this.getReverseButtons(),
            animation: this.getShowAnimation(),
            showLoaderOnConfirm: this.getShowLoaderOnConfirm(),
            showCancelButton: this.getShowCancelButton(),
            showConfirmButton: this.getShowConfirmButton(),
            focusConfirm: this.getFocusConfirm(),
            focusCancel: this.getFocusCancel(),
        });

        return null;
    };

    render() {
        const { extraButtons, open } = this.props;
        return(
            <Fragment>
                { open && this.renderAlert() }
                { (open && ReactSwal.getActions() && extraButtons) && ReactDom.createPortal( <ExtraButtons extraButtons={extraButtons} parentSwal={ReactSwal} onClose={this.onClose}/>, ReactSwal.getActions()) }
            </Fragment>
        )
    }
}


class ExtraButtons extends React.PureComponent{

    handleOnClick = (originalOnClick) => {
        originalOnClick();
        this.props.onClose();
    };

    render() {
        const { extraButtons, parentSwal } = this.props;

        const ConfirmButtonClassList = parentSwal.getConfirmButton().classList.value;

        return (
            extraButtons.map(b => <button key={b.label} type={'button'} className={`${ConfirmButtonClassList} ${b.className ? b.className : ''}`} onClick={()=>this.handleOnClick(b.onClick)}>{b.label}</button>)
        );
    }
}