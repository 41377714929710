import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { UserIcon } from '../../../../../configuration/icons';
import RoundButton from '../../../../../elements/RoundButton';

import FilterConsumer from '../../../../../componentsScreens/FilterIncidentsSidebar/Context/Consumer';

const IncidentsFilterButton = (props) => {

    const [apply, setApply] = useState(true);
    const me = useSelector(state => state.auth.me);
    const taxs = useSelector(state => state.taxonomies.subfamilia);

    const checkFiltersApplied = () => {
        if(apply){
            props.resetFilters();
            props.setIn('sacApprovedFilter', undefined);
            setApply(false);
            props.setFlagsAs(false);
        }
        else{
            if(props.filters.initialValueSetted === false){
                props.setIn('subfamilyFilters', taxs.filter((tax) => tax.assignedTo && tax.assignedTo.includes(me)).map(tax => tax.id));
                if (me && me.roles[0].name === 'SAC') props.setIn('sacApprovedFilter', false);
                props.applyFilter(true, () => console.info('Filtered apllied in map'));
                props.setFlagsAs(true);
                setApply(true);
            }
        }
    }

    return (
        <>
            <RoundButton 
                icon={UserIcon}
                className={`round-button ${apply ? 'active' : ''}`}
                tooltip={`${apply ? "Ver todas las incidencias" : me && me.roles[0].name !== 'SAC' ? "Ver incidencias asociadas" : "Ver incidencias por aprobar - SAC"}`}
                onClick={() => checkFiltersApplied()}
            />
        </>
    )
}

export default FilterConsumer(IncidentsFilterButton);