import React from 'react';
import { signFileForDownload } from '../../api/minio';
import BackgroundImage from './../../elements/BackgroundImage';
import Modal from '../Modal';
import './index.scss';
import PropTypes from 'prop-types';

class MinioPicture extends React.Component{

    static defaultProps = {
        name: '',
        lightBox: true
    };

    static propTypes = {
      name: PropTypes.string
    };

    constructor(props){
        super(props);
        this.state = {
            uri : props.name,
            openModal: false
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.name !== this.props.name)
            this.getUrlByName();
    }

    componentDidMount() {
        this.getUrlByName()
    }

    getUrlByName = () => {
        signFileForDownload(this.props.name)
            .then(res => this.setState({ uri: res.data }))
            .catch(err => console.log('MinioPicture getUrlByName', err))
    };

    closeModal = () =>{
      this.setState({
          openModal: false
      })
    };

    openModal = () =>{
        this.setState({
            openModal: true
        })
    };

    render(){
        const { uri, openModal } = this.state;
        const { name, lightBox } = this.props;
        return(
            <>
                {
                    lightBox &&
                    <Modal
                        modalContainer={'#root'}
                        closeModal={this.closeModal}
                        dismissOnBlank={true}
                        dismissOnEscape={true}
                        open={openModal}
                    >
                        <img src={uri} alt={name} style={{maxHeight: '90vh', maxWidth: '90vw'}}/>
                    </Modal>
                }
                <BackgroundImage
                    className={'Minio-Picture'}
                    url={uri}
                    alt={name}
                    onClick={this.openModal}
                />
            </>
        )
    }
}

export default MinioPicture;