import {put, call, all, fork, takeLatest, takeEvery} from 'redux-saga/effects';
import * as TYPES from './types';
import * as UserApi from './../../api/user';
import {clone} from "ramda";

function* getUserRoleThroughApi({ filters }){
    const { id } = filters;
    try {
        const response = yield call(UserApi.getUserRoles, id);
        if(response.status === 200 || response.status === 304)
            yield put( { type: TYPES.GET_USER_ROLES_SUCCESS, payload: { id: id, roles: response.data.roles }} );
        else yield put({ type: TYPES.GET_USER_ROLES_FAILED, payload: response });
    }
    catch (e) {
        yield put({ type: TYPES.GET_USER_ROLES_FAILED, payload: e})
    }
}

/** Get Users From DataBase **/
function* getUsersThroughApi({filters,callback}) {
    const { id, ...attr } = filters ? filters : {};
    try{
        const response = yield call(UserApi.getUsers, id, attr);
        if(response.status === 200 || response.status === 304) {
            if (!id) {
                yield put({
                    type: TYPES.GET_USERS_SUCCESS,
                    payload: {data: response.data.users, total: response.data.users.length}
                });
                callback && callback(response)
            } else {
                yield put({type: TYPES.GET_USER_SUCCESS, payload: {data: [response.data.user]}});
                callback && callback(response)
            }
        }
        else {
            yield put({type: TYPES.GET_USERS_FAILED, payload: response});
            callback && callback(response)
        }
    }
    catch (e) {
        yield put({ type: TYPES.GET_USERS_FAILED, payload: e});
        callback && callback(e)

    }
}

function* createUserThroughApi({ user, callback }){
    const roles = clone(user.roles).map(role => role.id);
    try{
        const response = yield call(UserApi.createUsers, {...user, roles});
        if(response.status === 201) {
            yield put({
                type: TYPES.CREATE_USER_SUCCESS,
                payload: {data: [{...response.data.user, roles: user.roles, password: response.data.password}] }
            });
            callback && callback(response)
        }
        else{
            yield put({ type: TYPES.CREATE_USER_FAILED, payload: response})
            callback && callback(response)
        }
    }
    catch (e) {
        yield put({ type: TYPES.CREATE_USER_FAILED, payload: e})
        callback && callback(e)
    }
}

function* updateUserThroughApi({ user, values, callback }){
    const roles = clone(values.roles).map(role => role.id);
    try{
        const response = yield call(UserApi.updateUser, user, {...values, roles});
        if(response.status === 200) {
            yield put({
                type: TYPES.UPDATE_USER_SUCCESS,
                payload: {user, values: {...response.data.user, roles: values.roles}}
            });
            callback && callback(response)
        }
        else {
            yield put({type: TYPES.UPDATE_USER_FAILED, payload: response});
            callback && callback(response)
        }
    }
    catch (e) {
        yield put({ type: TYPES.UPDATE_USER_FAILED, payload: e});
        callback && callback(e)
    }
}

function* deleteUserThroughApi ({ user, callback }){
    try{
        const response = yield call(UserApi.removeUser, user);
        if(response.status === 204) {
            yield put({type: TYPES.DELETE_USER_SUCCESS, payload: user});
            callback && callback({...response, user})
        }
        else {
            yield put({type: TYPES.DELETE_USER_FAILED, payload: response});
            callback && callback({...response, user})
        }
    }
    catch (e) {
        yield put({ type: TYPES.DELETE_USER_FAILED, payload: e});
        callback && callback(e)
    }
}

function* updateUsersPasswordThroughApi ({ payload, callback }){
    const {original_password, password, repassword} = payload;
    try{
        const response = yield call(UserApi.updatePasswordForUser, original_password, password, repassword);
        if(response.status === 201) {
            yield put({type: TYPES.UPDATE_USER_PASSWORD_SUCCESS});
            callback && callback(response)
        }
        else {
            yield put({type: TYPES.UPDATE_USER_PASSWORD_FAILED, payload: response});
            const status = response.status;
            response.json().then(data => callback({status,data}));
        }
    }
    catch (e) {
        yield put({ type: TYPES.UPDATE_USER_PASSWORD_FAILED, payload: e});
        callback && callback(e)
    }
}

function* watcherDeleteUsers() {
    yield takeEvery(TYPES.DELETE_USER_REQUEST, deleteUserThroughApi);
}

function* watcherCreateUsers() {
    yield takeLatest(TYPES.CREATE_USER_REQUEST, createUserThroughApi);
}

function* watcherUpdateUsers() {
    yield takeLatest(TYPES.UPDATE_USER_REQUEST, updateUserThroughApi);
}

/** Get Users From DataBase **/
function* watcherGetUsers() {
    yield takeLatest(TYPES.GET_USERS_REQUEST, getUsersThroughApi);
}

function* watcherGetUserRoles() {
    yield takeLatest(TYPES.GET_USER_ROLES_REQUEST, getUserRoleThroughApi);
}
function* watcherUpdateUsersPassword() {
    yield takeLatest(TYPES.UPDATE_USER_PASSWORD_REQUEST, updateUsersPasswordThroughApi);
}

export default function* rootSaga() {
    yield all([
        /** Get Users From DataBase **/
        fork(watcherGetUsers),
        fork(watcherCreateUsers),
        fork(watcherUpdateUsers),
        fork(watcherDeleteUsers),
        fork(watcherGetUserRoles),
        fork(watcherUpdateUsersPassword),
    ])
}