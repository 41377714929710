import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import RoundButton from "../../../../elements/RoundButton";
import { DeleteIcon, RechargeIcon } from "../../../../configuration/icons";
import Swal from 'sweetalert2';

class TopListButtons extends React.Component {
    static defaultProps = {
        synchronizeData: ()=>{},
        deleteSeveralTaxonomies: ()=>{},
        selected: []
    };

    static propTypes = {
        synchronizeData: PropTypes.func,
        deleteSeveralTaxonomies: PropTypes.func,
        selected: PropTypes.array
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        for (const prop in TopListButtons.defaultProps){
            if(nextProps[prop] !== this.props[prop])
                return true
        }
        return false;
    }

    preventDeleteTaxonomyWithModalConfirmation = () => {
        const { deleteSeveralTaxonomies } = this.props;
        Swal.fire( {
            title: '¡Cuidado!',
            text: "¿Estás seguro de que quieres borrar los elementos seleccionados?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            confirmButtonText: 'Borrar',
            cancelButtonText: 'Cancelar',
            focusCancel: true,
            preConfirm: deleteSeveralTaxonomies
        });
    };

    render() {
        const { synchronizeData, selected } = this.props;
        return (
            <>
                <Fragment>
                    <RoundButton icon={RechargeIcon} tooltip={"Sincronizar datos"} onClick={synchronizeData}/>
                    { selected.length > 0 && <RoundButton icon={DeleteIcon} tooltip={"Borrar seleccionadas"} onClick={this.preventDeleteTaxonomyWithModalConfirmation}/> }
                </Fragment>
            </>
        )
    }
}

export default TopListButtons