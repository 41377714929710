import React, { Fragment } from 'react';
import { removeTaxonomies } from "../../../redux/taxonomies/action";
import TopListButtons from "../../Taxonomies/Components/TopListButtons";

/** Config Vars **/
import {subfamily} from "../../../configuration/entitiesNames";
import { view, update, create } from "../../../configuration/operationNames";

/** Styles **/
import './index.scss'

/** Table Component connected with Redux special Taxonomies **/
import TaxonomyListView from './../../Taxonomies/List';

/** Content for render Views except List **/
import Sidebar from "../../../components/Sidebar";

/** General Family state context **/
import Consumer from '../../Taxonomies/SidebarContext/Consumer';

/** CRUD Views **/
import Create from "./Components/Create";
import Update from "./Components/Update";
import RoundButton from "../../../elements/RoundButton";
import {AddIcon, CloseIcon} from "../../../configuration/icons";
import ReactDOM from "react-dom";

const ENTITY = subfamily;
const HEADERS = [
    {key: 'name', title: 'Nombre'},
    {key: 'parent', title: 'Pertenece a'},
    {key: 'executor', title: 'Creador'},
    {key: 'associated', title: 'Asociada a'},
];
/** Main Class **/
class List extends React.PureComponent {

    state = {
        RightActionsIconsZone: null,
    };

    componentDidMount() {
        this.setState({ RightActionsIconsZone: document.getElementById('right-actions-icons-zone') });
    }

    goToCreateFamily = () => {
        const { setMode, openSidebar } = this.props;
        setMode(create);
        openSidebar();
    };

    topNavButtons = () => {
        const { sidebarIsOpen, closeSidebar } = this.props;
        return (
            <div className={'top-var-buttons'}>
                <RoundButton
                    className={`${sidebarIsOpen ? 'sidebar-is-open' : ''} mr-1 create-incident`}
                    icon={sidebarIsOpen ? CloseIcon : AddIcon}
                    tooltip={sidebarIsOpen ? "Cerrar panel lateral" : "Crear nueva subfamilia"}
                    onClick={sidebarIsOpen ? closeSidebar : this.goToCreateFamily}/>
            </div>
        )
    };

    onPressDeleteButton = (taxs) => {
        removeTaxonomies(taxs, ENTITY);
    };

    onPressUpdateButton = (tax) => {
        this.props.navigate(tax.id, {state: tax}).then(()=> {
            this.props.setLastElementSelected(tax);
            this.props.setMode(update);
            this.props.openSidebar()
        });
    };

    render() {
        /** PROPS-VARS FROM CONTEXT **/
        let { sidebarIsOpen, sidebarMode } = this.props;

        /** View Screen from Route **/
        const { children, navigate } = this.props;

        /** Portals Areas **/
        const { RightActionsIconsZone } = this.state;

        return(
            <Fragment>
                { RightActionsIconsZone && ReactDOM.createPortal(this.topNavButtons(), RightActionsIconsZone) }
                <TaxonomyListView
                    { ...this.props }
                    IconZone={ TopListButtons }
                    headers={HEADERS}
                    title={'Listado de Subfamilias'}
                    entity={ENTITY}
                    onPressDeleteButton={ this.onPressDeleteButton }
                    onPressUpdateButton={ this.onPressUpdateButton }
                />
                <Sidebar open={ sidebarIsOpen }>
                    <Fragment>
                        { sidebarMode === view && children }
                        { sidebarMode === create &&  <Create navigate={navigate} /> }
                        { sidebarMode === update && <Update navigate={navigate} id={this.props['*']}/> }
                    </Fragment>
                </Sidebar>
            </Fragment>
        )
    }
}

export default Consumer(List);