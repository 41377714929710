import React, {Fragment} from 'react';
import { UserConsumer } from '../../Context';
import { BodySidebar as OriginalBodySidebar } from '../../../../components/Sidebar'
import USER_FIELDS from "../../Components/UsersFields";
import { Input } from "../../../../components/Form";
import PropTypes from "prop-types";

class BodySidebar extends React.Component {
    static defaultProps = { lastElementSelected: undefined };
    static propTypes = { lastElementSelected: PropTypes.object };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const {lastElementSelected} = this.props;
        return lastElementSelected !== nextProps.lastElementSelected || nextProps.id !== this.props.id;
    }

    getValue = (key, elseValue = '') => {
        const { lastElementSelected } = this.props;
        if(lastElementSelected && lastElementSelected[key])
            return lastElementSelected[key];
        return elseValue
    };

    render(){
        return(
            <OriginalBodySidebar>
                <div className={'pl-1 pr-1'}>
                    { USER_FIELDS.map(field => (
                            <Fragment key={field.key}>
                                <h6 className={'label'} key={`labelFor-${field.key}`}>{ field.label }</h6>
                                <Input
                                    keepFeedbackSpace
                                    noLabel
                                    name={ field.key }
                                    value={ this.getValue(field.key) }
                                    readOnly
                                />
                            </Fragment>
                        )
                    )}
                </div>
            </OriginalBodySidebar>
        )
    }
}

export default UserConsumer(BodySidebar);