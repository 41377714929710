import React from 'react';
import './index.scss';
import { connect } from 'react-redux';
import { getIncidents } from "../../redux/incidents/action";
import { incident } from "../../configuration/entitiesNames";
import { INCIDENT_LIMIT_BY_REQUEST } from "../../configuration/network";
import RoundButton from "../../elements/RoundButton";
import {GroupedIcon, SearchIcon, DashboardIcon, CloseIcon, AddIcon} from "../../configuration/icons";
import DashboardNumberResume from './DashboardNumberResume';
import { paths } from "../../configuration/paths";

/** FilterSidebar **/
import Sidebar from '../../components/Sidebar';
import FilterSidebar from '../../componentsScreens/FilterIncidentsSidebar';
import FilterConsumer from "../../componentsScreens/FilterIncidentsSidebar/Context/Consumer";

/** ViewProvider **/
import ViewProvider from '../Taxonomies/SidebarContext/Provider'
import ViewConsumer from '../Taxonomies/SidebarContext/Consumer'
import GetCurrentPosition from "../../helpers/geocoding/GetCurrentPosition";

/* Google Maps */
import '../../components/Map/GMaps/Map.css';
import Gmap from '../../components/Map/GMaps/Gmap';



const INTERVAL_FOR_REQUEST_INCIDENTS_IN_HOME = 30000;

class Home extends React.Component {
    Interval = null;
    state = {
        grouped: true,
        userLocation: null,
        dashboardIsOpen: true
    };

    componentDidMount() {
        getIncidents({type:incident}, (res) => console.log('response', res));
        this.Interval = setInterval(this.requestIncidents, INTERVAL_FOR_REQUEST_INCIDENTS_IN_HOME);
        this.props.applyFilter(true, () => console.info('Filtered apllied in home did mount'));
        this.getUserLocation();
        getIncidents({ type: incident, limit: INCIDENT_LIMIT_BY_REQUEST })
    }

    componentWillUnmount() {
        this.Interval && clearInterval(this.Interval);
    }

    requestIncidents = () => {
        this.props.applyFilter(true, () => console.info('Filtered apllied in home requestIncidents'))
    };

    getUserLocation = () => {
        GetCurrentPosition(location => this.setState({
            userLocation: location
        }))
    };

    toggleGrouped = () => this.setState({ grouped: !this.state.grouped });

    toggleFilterSidebar = () => {
        const {closeFilterSidebar, closeSidebar, openFilterSidebar, filterSidebarIsOpen} = this.props;
        if(filterSidebarIsOpen)
            closeFilterSidebar();
        else{
            closeSidebar();
            openFilterSidebar();
        }
    };

    onClickOnMap = (e) => {
        this.props.closeSidebar();
    };

    onClickDotInMap = (e) => {
        const {navigate, openSidebar} = this.props;
        const data = e.features[0].properties;
        data.coords = JSON.parse(data.coords);
        data.images = JSON.parse(data.images);
        navigate(data.id, {state: {...data}});
        openSidebar();
    };

    toggleDashboardPanel = () => {
        this.setState({ dashboardIsOpen: !this.state.dashboardIsOpen})
    };

    goToCreateIncidence = () => {
        this.props.navigate(`/app/${paths.Incidents}/list?create`);
    }

    render() {
        const { incidentsFromRedux, children } = this.props;
        const { filterSidebarIsOpen, sidebarIsOpen } = this.props;
        const { grouped, userLocation, dashboardIsOpen } = this.state;

        return(
            <>
                <div className={'round-buttons-group'}>
                    <RoundButton
                        className={`round-button ${grouped ? 'active' : ''}`}
                        icon={GroupedIcon}
                        onClick={this.toggleGrouped}
                        tooltip={grouped ? "Desagrupar" : "Agrupar"}
                    />

                    <RoundButton
                        className={`round-button ${filterSidebarIsOpen ? 'active' : ''}`}
                        icon={SearchIcon}
                        onClick={this.toggleFilterSidebar}
                        tooltip={filterSidebarIsOpen ? "Cerrar Filtro" : "Abrir Filtro"}
                    />

                    <RoundButton
                        className={`round-button ${dashboardIsOpen ? 'active' : ''}`}
                        icon={DashboardIcon}
                        onClick={this.toggleDashboardPanel}
                        tooltip={dashboardIsOpen ? "Cerrar panel" : "Abrir panel"}
                    />

                    <RoundButton
                        className="mr-1 create-incident"
                        icon={AddIcon}
                        tooltip={"Crear nueva incidencia"}
                        onClick={this.goToCreateIncidence}
                    />
                </div>
       
                {dashboardIsOpen && <DashboardNumberResume />}
        
                <Gmap incidents={incidentsFromRedux} grouped={this.state.grouped} props={this.props} />

                <Sidebar open={filterSidebarIsOpen}>
                    <FilterSidebar />
                </Sidebar>

                <Sidebar open={sidebarIsOpen}>
                    {children}
                </Sidebar>
            </>
        )
    }
}


const mapStateToProps = ({incidents}) => ({
    incidentsFromRedux : incidents.filtered
});

const HomeWithConsumer = FilterConsumer(ViewConsumer(connect(mapStateToProps)(Home)));

export default (props)=> (
    <ViewProvider>
        <HomeWithConsumer {...props} />
    </ViewProvider>
)