import React from 'react';
import './index.scss';

class Footer extends React.PureComponent{
    render(){
        const {className, children, ...rest} = this.props;
        return(
            <div {...rest} className={`${className ? className : ''} footer-sidebar`}>
                {children}
            </div>
        )
    }
}

export default Footer;
