import React from 'react';
import './style.scss';
import CalcParentType from '../../../../../helpers/CalcParentType';
import { connect } from 'react-redux';
import { family, orderType, subfamily } from "../../../../../configuration/entitiesNames";
import Icon from "../../../../../elements/Icon";
import {CheckIcon} from "../../../../../configuration/icons";

class TaxonomyFilter extends React.Component{

    static defaultProps = {
        parentsSelected: [],
        superParentsSelected: [],
    };

    constructor(props){
        super(props);
        this.state = {
            color: '#ffffff',
            display: true,
        }
    }

    componentDidMount() {
        let Child = this.props.data;

        if(CalcParentType(Child.type) === subfamily) {
            Child = this.props.subfamilies.find(sub => sub.id === Child.parent);
        }

        if(Child && CalcParentType(Child.type) === family){
            const Family = this.props.families.find(fam => fam.id === Child.parent);
            if(Family && Family.color) this.setState({color: Family.color})
        }
        else
        if(this.props.data && this.props.data.color) this.setState({color: this.props.data.color})
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.selected !== this.props.selected && this.props.selected !== this.state.selected)
            this.setState({
                selected: this.props.selected
            });

        if(prevState.selected !== this.state.selected)
            this.setState({
                display: this.checkIfParentOrSuperParentIsSelected()
            });


        if(prevProps.superParentsSelected !== this.props.superParentsSelected || this.props.parentsSelected !== prevProps.parentsSelected ) {
            this.setState({
                display: this.checkIfParentOrSuperParentIsSelected()
            })
        }
    }


    checkIfParentOrSuperParentIsSelected = () => {

        let { superParentsSelected, parentsSelected } = this.props;
        superParentsSelected = superParentsSelected || [];
        parentsSelected = parentsSelected || [];
        const { parent, type } = this.props.data;
        let superParent = '';

        if(type === orderType) {
            const subfamily = this.findSubFamily(this.props.data);
            superParent = subfamily.parent
        }

        if(this.state.selected) {
            return true;

        }
        else
        if(superParentsSelected.length <= 0 && parentsSelected.length <= 0) {
            return true;

        }
        else
        if(parentsSelected.length > 0 && parentsSelected.includes(parent)) {
            return true;

        }
        if(parentsSelected.length <= 0 &&
            superParentsSelected.length > 0 && superParentsSelected.includes(superParent)) {
            return true;

        }
        else
        if(superParentsSelected.length > 0 && superParentsSelected.includes(superParent) &&
            parentsSelected.length > 0 && parentsSelected.includes(parent)) {
            return true;

        }
        return false;

    };

    findSubFamily = (obj) => this.props.subfamilies.find(sub => sub.id === obj.parent);


    toggleSelected = () => {
        this.setState({
            selected: !this.state.selected
        })
    };

    onClick = (e) => {
        const { onClick, data } = this.props;
        this.toggleSelected();
        onClick(e,data)
    };

    render() {
        const { data, selected } = this.props;
        let { color, display } = this.state;

        color = color.replace('#','');

        return (
            <div className={'Taxonomy-Filter'} onClick={ this.onClick } style={{display: display ? 'flex' : 'none'}}>
                <div className={'Taxonomy-Filter-Color-Circle'} style={{ backgroundColor: `#${color}` }}/>
                <div className={'Taxonomy-Filter-Name'}>
                    <p>{data.name}</p>
                </div>
                { selected && <div className={'Taxonomy-Filter-CheckMark'}><Icon name={CheckIcon}/></div> }
            </div>
        )
    }
}

const TaxStateToProps = ({taxonomies}) => ({
    subfamilies: taxonomies[subfamily],
    families: taxonomies[family],
});

export default connect(TaxStateToProps, {})(TaxonomyFilter)

