import React from 'react';
import UserProvider from '../Taxonomies/SidebarContext/Provider'
import { FilterProvider } from './Filter/Context';

class Users extends React.PureComponent {
    render() {
        const { children } = this.props;
        return (
            <FilterProvider>
                <UserProvider>
                    {children}
                </UserProvider>
            </FilterProvider>
        )
    }
}

export default Users;