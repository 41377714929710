"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Taxonomies = void 0;
var Taxonomies;

(function (Taxonomies) {
  Taxonomies["familia"] = "familia";
  Taxonomies["subfamilia"] = "subfamilia";
  Taxonomies["tipodeorden"] = "tipodeorden";
})(Taxonomies = exports.Taxonomies || (exports.Taxonomies = {}));