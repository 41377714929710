"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.inclusion = exports.maximum = exports.minimum = exports.presence = void 0;

exports.presence = function (message) {
  if (message === void 0) {
    message = 'no puede estar vacío';
  }

  return {
    message: message
  };
};

exports.minimum = function (number) {
  if (number === void 0) {
    number = 1;
  }

  return {
    minimum: number,
    tooShort: "Debes añadir al menos %{count} letras",
    tokenizer: function tokenizer(value) {
      return value.trim();
    }
  };
};

exports.maximum = function (number) {
  if (number === void 0) {
    number = 10;
  }

  return {
    maximum: number,
    tooLong: "Debes añadir como máximo %{count} letras",
    tokenizer: function tokenizer(value) {
      return value.trim();
    }
  };
};

exports.inclusion = function (Array) {
  return {
    within: Object.keys(Array),
    message: "debe ser una de las siguientes opciones: " + Object.keys(Array)
  };
};