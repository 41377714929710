import React from 'react';
import Provider from "../Taxonomies/SidebarContext/Provider";


class Families extends React.PureComponent{
    render() {
        const { children } = this.props;
        return <Provider>{children}</Provider>
    }
}

export default Families;