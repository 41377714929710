import locationIcon from '@iconify/icons-mdi/alert-circle';
import { Icon } from '@iconify/react';
import React from 'react';

export const LocationMarker = ({ color, onClick }) => {
    if (color === 'orange') {
        return (
            <div className="blob"></div>
        )
    }
    return (
        <div style={{color: color}} className="location-icon" onClick={onClick}>
            <Icon icon={locationIcon} className="location-info"></Icon>
        </div>
    )
}

export default LocationMarker;