import React from 'react';
import './index.scss';

class TopBar extends React.Component{
    render(){
        const {children} = this.props;
        return(
            <div className={'top-bar'}>
                {children}
            </div>
        )
    }
}

export default TopBar