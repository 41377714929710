
export const Libraries = ['fa','io', 'gi'];


export const UserIcon = 'FaUsers';
export const FamilyIcon = 'FaBookmark';
export const SubFamilyIcon = 'FaSwatchbook';
export const OrderTypeIcon = 'FaListUl';
export const IncidentIcon = 'FaUsers';
export const LogoutIcon = 'FaSignOutAlt'; //IoMdLogOut
export const DashboardIcon = 'FaTachometerAlt';
export const HomeIcon = 'FaHome';
export const ProfileIcon = 'FaUserAstronaut';
export const MenuIcon = 'FaBars';
export const CloseIcon = 'IoMdClose';
export const EditIcon = 'FaEdit'; //'IoMdCreate';
export const MoreIcon = 'IoMdMore'; //FaEllipsisV
export const MaximizeIcon = 'IoMdExpand';
export const MinimizeIcon = 'IoMdContract';
export const AddIcon = 'IoMdAdd';
export const ViewIcon = 'IoMdEye';
export const DeleteIcon = 'FaTrashAlt';
export const CancelIcon = 'IoMdArrowBack';
export const RechargeIcon = 'IoIosSync';
export const PrintIcon = 'IoMdPrint';
export const PrintedIcon = 'FaPrint';
export const NotPrintedIcon = 'FaPrint';
export const ViewPassword = 'IoMdEye';
export const HidePassword = 'IoMdEyeOff';
export const SearchIcon = 'FaSearch';
export const StatusIcon = 'FaTasks';
export const GroupedIcon = 'IoIosColorFilter';
export const FilterIcon = 'FaListUl';
export const CheckIcon = 'FaCheckCircle';
export const PasswordIcon = 'FaLock';
export const ResetPassword = 'GiLockedHeart';
