import React, {Fragment} from 'react';
import EntityOption from "../../../../components/EntityOption";
import PRINTED_OPTIONS from "../../../../configuration/printedOptions";
import Divider from "../../../../elements/Divider";
import Consumer from "../../Context/Consumer";

const PRINTED_STATUS = Object.keys(PRINTED_OPTIONS);

class StatusBlock extends React.Component {

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps.filters.printedFilters !== this.props.filters.printedFilters;
    }

    generatePrintedFilter = (bool) => {
        const { filters, setIn } = this.props;
        const { printedFilters } = filters;
        if(printedFilters.includes(bool)) setIn('printedFilters', printedFilters.filter(z => z !== bool ));
        else setIn('printedFilters', [bool]);
    };

    render(){
        const { filters } = this.props;
        const { printedFilters } = filters;
        return(
            <Fragment>
                <p className={'Filter-sidebar-subtitle'}>Filtrar por estado de impresión</p>
                { PRINTED_STATUS.length > 0 ?
                    PRINTED_STATUS.map(bool => <EntityOption
                            key={PRINTED_OPTIONS[bool].translated}
                            selected={printedFilters.includes(bool)}
                            onClick={()=>this.generatePrintedFilter(bool)}
                            data={PRINTED_OPTIONS[bool].translated}
                        />
                    ) :
                    <i> No se han encontrado estados para mostrar </i> }
                <Divider/>
            </Fragment>
        )
    }
}

export default Consumer(StatusBlock);