import React from 'react';
import MinioImage from '../../../../../../../../components/MinioImage';
import ConfirmationModal from '../../../../../../../../componentsScreens/ConfirmationModal';
export default ({image, onDelete}) => {
    return (
        <div className={'content-modal-image-wrapper'}>
            <MinioImage name={image} lightBox={true}/>
            <ConfirmationModal onDelete={onDelete} image={image}/>
        </div>
    )
};