import React from 'react';
import Dropview from "../../../../../components/DropView";
import FamilyPopulated from "../../../../../helpers/redux-helpers/PopulatedClass/FamilyPopulated";
import SubfamilyPopulated from "../../../../../helpers/redux-helpers/PopulatedClass/SubfamilyPopulated";
import OrderTypePopulated from "../../../../../helpers/redux-helpers/PopulatedClass/OrderTypePopulated";
import {incident} from "../../../../../configuration/entitiesNames";
import {connect} from "react-redux";
import PropTypes from "prop-types";


class TaxonomiesBlock extends React.Component {

  static defaultProps = {
    family: undefined,
    subfamily: undefined,
    orderType: undefined,
  };

  static propTypes = {
    family: PropTypes.string,
    subfamily: PropTypes.string,
    orderType: PropTypes.string,
  };

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    for (const prop in TaxonomiesBlock.defaultProps){
      if(nextProps[prop] !== this.props[prop])
        return true
    }
    return false;
  }

  render() {
    const {family, subfamily, orderType} = this.props;
    return(
      <Dropview header={<h4 className={'m-0'}>Familias y Tipos</h4>}>
        <div className={'pl-1 pr-1'}>
          <h6 className={"label"}>Familia</h6><p><FamilyPopulated id={family}/></p>
          <h6 className={"label"}>Subfamilia</h6><p><SubfamilyPopulated id={subfamily}/></p>
          <h6 className={"label"}>Tipo de Orden</h6><p><OrderTypePopulated id={orderType}/></p>
        </div>
      </Dropview>
    )
  }

}


const mapStateToProps = ({ incidents }, {id}) => {
  const incidentInIndents = incidents[incident].find(inc => inc.id === id);
  const foundIncident = incidentInIndents ? incidentInIndents : incidents.filtered.find(inc => inc.id === id);

  if(foundIncident)
    return {
      family: foundIncident.family,
      subfamily: foundIncident.subfamily,
      orderType: foundIncident.orderType,
    };

  return TaxonomiesBlock.defaultProps;
};

export default connect(mapStateToProps)(TaxonomiesBlock)