import React from 'react';
import './index.scss';

class Main extends React.Component{
    render(){
        const {children, className} = this.props;
        return(
           <main className={`main ${className ? className : ''}`}>
               {children}
           </main>
        )
    }
}

export default Main