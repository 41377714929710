import React from 'react';
import Divider from "../../../elements/Divider";
import './index.scss';

class HeaderSidebar extends React.PureComponent{
    render() {
        const { children } = this.props;
        return(
            <div className={'header-sidebar'}>
                <div className={'header-sidebar-content'}>
                    { children }
                </div>
                <Divider/>
            </div>
        )
    }
}

export default HeaderSidebar;