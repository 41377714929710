import React from 'react';
import './index.css';

class SuccessAnimation extends React.PureComponent{
    render() {
        const {children} = this.props;

        return(
            
            <div className="wa">
                <div class="wa-warning">
                    <div class="wa-warning-body"></div>
                    <div class="wa-warning-dot"></div>
                </div>
                <div className={'wa-content'}>
                    {children}
                </div>
            </div>
        )
    }
}

export default SuccessAnimation;