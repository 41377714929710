import ParseLatitudeLongitude from "../../../../helpers/geocoding/ParseLatitudeLongitude";

export const ParseDataToGeoJson = ({ coords, ...properties }) => {
    const fullCoords = ParseLatitudeLongitude(coords);
    return (
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [fullCoords.longitude, fullCoords.latitude]
            },
            "properties": {...properties, coords:coords }
        }
    );
};

export const ParseCustomDataToGeoJson = (data) => {
    data = data.filter(d => d);
    return {
        "type": "geojson",
        "data": {
            "type": "FeatureCollection",
            "features": data.map(d=>ParseDataToGeoJson(d))
        }
    }
};

export default ParseDataToGeoJson