import React from 'react';
import { User } from '@secmotic/models';
import { validate } from "validate.js";

const ValidatePassword = (passwordValue, keyName="password") => {
    const passwordConstraint = new User({}).Constraints.password;
    if(!passwordValue || passwordValue.trim() === "")
        return {[keyName]: ["Este campo no puede estar vacío"] };
    return validate( {[keyName]: passwordValue}, {[keyName]: passwordConstraint}, {fullMessages: false} );
};

export default ValidatePassword;