import React from 'react';
import Alert from "../../../elements/Alert";
import {StatusIcon} from "../../../configuration/icons";
import RoundButton from "../../../elements/RoundButton";
import STATUS from './../../../configuration/statusOptions';
import { updateStatusIncidents } from '../../../redux/incidents/action';

class PendingStatus extends React.PureComponent{

    state = {
        alertIsOpen: false
    };

    openAlert = () => {
        if(!this.state.alertIsOpen) this.setState({alertIsOpen: true})
    };

    closeAlert = () =>{
        if(this.state.alertIsOpen) this.setState({alertIsOpen: false})
    };
    
    render() {
        const { ids, isPlural } = this.props;
        const { alertIsOpen } = this.state;
        return(
            <>
                <RoundButton icon={StatusIcon} onClick={this.openAlert} tooltip={"Cambiar Estado"} />
                <Alert
                    title={isPlural ? "Incidencias pendientes" : "Incidencia pendiente"}
                    text={"¿Qué desea hacer?"}
                    open={alertIsOpen}
                    onClose={this.closeAlert}
                    showCancelButton={true}
                    cancelButtonText={'Cancelar'}
                    extraButtons={Buttons(ids)}
                />
            </>
        )
    }
}

const Buttons = (idArrays) => {
  return [
      {
          label: "Rechazar",
          onClick: ()=> updateStatusIncidents(idArrays, STATUS.rejected.name)
      },
      {
          label: "Aprobar",
          onClick: ()=> updateStatusIncidents(idArrays, STATUS.open.name)
      }
  ]

};

export default PendingStatus