import React from 'react';
import './App.scss';
import Routes from './screens';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import FilterIncidentProvider from "./componentsScreens/FilterIncidentsSidebar/Context/Provider";


const registerServiceWorker = () => {
    if ('serviceWorker' in navigator) {
        window.addEventListener('load', function() {
            navigator.serviceWorker.register('/sw.js', {scope: '.'})
                .then(function() {
                    console.log('ServiceWorker registered!');
                })
                .catch(function(err) {
                    console.log('ServiceWorker failed :(', err);
                });
        });
    }
};

const catchFutureInstallEvent = () => {
    window.addEventListener('beforeinstallprompt', (e) => {
// Stash the event so it can be triggered later.
//         let deferredPrompt = e;
        console.log('showInstallPromotion?', e)
    });
};

const checkIfAppIsInstalled = () => {
    window.addEventListener('appinstalled', (evt) => {
        console.log('a2hs installed', evt);
    });
};

class App extends React.Component {
    componentDidMount() {
        registerServiceWorker();
        catchFutureInstallEvent();
        checkIfAppIsInstalled();
    }

    render() {
        return (
            <Provider store={store}>
                <FilterIncidentProvider>
                    <Routes/>
                </FilterIncidentProvider>
            </Provider>
        );
    }
}

export default App;
