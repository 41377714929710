import React from 'react';
import PropTypes from 'prop-types';
import './styles/style.scss'

export default class Form extends React.Component{
    static defaultProps = {
        themeName: 'MagicForm',
        className: ''
    };

    static propTypes = {
        themeName:PropTypes.string,
        className:PropTypes.string
    };

    render(){
        const { themeName, className, children, ...rest } = this.props;
        return(
            <form {...rest} className={`${themeName} ${className}`} noValidate={true}>
                {children}
                <input type="submit" style={{display: 'none'}} />
            </form>
        )
    }
}

