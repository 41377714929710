import './index.scss'
import React from 'react';
import Icon from '../../../../../elements/Icon';
import { Input } from '../../../../../components/Form';
import {SearchIcon} from "../../../../../configuration/icons";

export default ({onChange, value, label}) => (
    <div className={'wrapper-taxonomy-input-filter'}>
        <Input
            autoComplete='off'
            groupClassName={'group-taxonomy-input-filter'}
            name={label}
            className={'taxonomy-input-filter'}
            type="text"
            value={value}
            onChange={onChange}
        />
        <div className={value ? 'filter-typed' : ''}>
            <Icon name={SearchIcon}/>
        </div>
    </div>
);
