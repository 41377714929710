import React, {Fragment} from 'react';
import Button from "../../../../elements/Button";
import HeaderSidebar from './HeaderSidebar';
import { CirclePicker } from 'react-color';
import { Form, Input } from '../../../../components/Form';
import {create, update, view} from "../../../../configuration/operationNames";
import { updateTaxonomy, createTaxonomy } from "../../../../redux/taxonomies/action";
import { family } from "../../../../configuration/entitiesNames";
import Consumer from '../../../Taxonomies/SidebarContext/Consumer';
import BodySidebar from '../../../../components/Sidebar/Body';
import FooterSidebar from '../../../../components/Sidebar/Footer';
import { Taxonomy } from '@secmotic/models';
import Utils from "./../../../../helpers/UtilClassWithCommonFunctions";

const DEFAULT_COLOR = "#f44336";

class Create extends Utils {
    constructor(props){
        super(props);
        this.state = {
            errors: [],
            color: this.getColor(),
            name: this.getName(),
            id: this.props.id || null
        }
    }

    componentDidUpdate(prevProps, prevState, prevSnap) {
        if(this.props.lastElementSelected !== prevProps.lastElementSelected){
            this.setState({
                color: this.getColor(),
                name: this.getName(),
                id: this.props.id
            });
        }
    }

    getColor = () => {
        const {sidebarMode, lastElementSelected} = this.props;
        if(sidebarMode === update && lastElementSelected)
            return lastElementSelected.color || DEFAULT_COLOR;
        return  DEFAULT_COLOR;
    };

    getName = () => {
        const {sidebarMode, lastElementSelected} = this.props;
        if(sidebarMode === update && lastElementSelected)
            return lastElementSelected.name || '';
        return  '';
    };

    onChangeColor = ({ hex }) => this.setState({color: hex});
    onChangeName = ({ target }) => {
        this.handleOnChange(target.name, target.value)
    };

    submit = (e) => {
        e && e.preventDefault();
        e && e.stopPropagation();
        ( this.props.sidebarMode === update && this.props.id ) ? this.updateFamily() : this.createFamily();
    };

    updateFamily = () => {
        const TaxonomyFromModel = new Taxonomy({ ...this.state, type: family });
        const validation = TaxonomyFromModel.validate();
        if( validation && Object.keys(validation).length > 0) this.setErrors(validation);
        else updateTaxonomy(TaxonomyFromModel.getJSON(), (res)=>this.callbackUpdateTaxonomy(res, { ...TaxonomyFromModel.getJSON() }));
    };

    createFamily = () => {
        const TaxonomyFromModel = new Taxonomy({ ...this.state, type: family });
        const validation = TaxonomyFromModel.validate();
        if( validation && Object.keys(validation).length > 0) this.setErrors(validation);
        else createTaxonomy(TaxonomyFromModel.getJSON(), this.callbackCreateTaxonomy)
    };

    callbackCreateTaxonomy = (response) => {
        if(response.status === 200) {
            this.props.navigate(response.data[0].id, {state:response.data[0]});
            this.props.setMode(view);
        }
    };

    callbackUpdateTaxonomy = (response, data) => {
        if(response.status === 200 || response.status === 204 ) {
            this.props.setLastElementSelected(data);
            this.props.navigate(data.id, { state: data });
            this.props.setMode(view);
        }
    };

    render() {
        /** Miscellaneous**/
        const { sidebarMode, navigate } = this.props;
        const { color, name, errors } = this.state;

        return(
            <Fragment>
                <HeaderSidebar navigate={navigate}/>
                <BodySidebar>
                    <Form onSubmit={this.submit}>
                        <h4 className={'mt-0'}>Selecciona un color</h4>
                        <CirclePicker
                            circleSize={40}
                            circleSpacing={5}
                            className={'color-circle-picker'}
                            color={color}
                            onChangeComplete={this.onChangeColor}
                        />

                        <h4 className={'mt-3'}>Añade un nombre a la familia</h4>
                        <Input
                            noLabel
                            onChange={this.onChangeName}
                            name={'name'}
                            value={name}
                            infoMsg={'Añade un nombre descriptivo'}
                            errorMsg={errors['name']}
                        />
                    </Form>
                </BodySidebar>
                <FooterSidebar>
                    <Button className={'native-base-button center-text'}  onClick={this.submit} fullWidth >
                        {sidebarMode === create ? "Crear familia" : "Guardar Familia"}
                    </Button>
                </FooterSidebar>
            </Fragment>
        )
    }
}

export default Consumer(Create)