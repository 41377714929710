import React, { useRef, useState, useEffect } from 'react'

/* Google Maps */
import GoogleMapReact from 'google-map-react';
import { LocationMarker } from '../../../../../components/Map/GMaps/LocationMarker';
import { getMarkerCategory } from '../../../../../components/Map/GMaps/functions/GetMarkerColor';
import { FindInArray as GeneraFinderInRedux } from '../../../../../helpers/redux-helpers/GeneralFindInRedux';
import { API_KEY } from '../../../../../configuration/mode';

const GmapSection = ({ incident }) => {

    const mapRef = useRef();
    const [status, setStatus] = useState('pending');
    const [latitude, setLatitude] = useState();
    const [longitude, setLongitude] = useState();

    function getIncidents() {
        const IncidentInRedux = findIncidentInReduxStore();
        if(IncidentInRedux !== undefined){
            setLatitude(parseFloat(IncidentInRedux.coords.lat));
            setLongitude(parseFloat(IncidentInRedux.coords.lng));
            setStatus(IncidentInRedux.status);
        }
    };

    function findIncidentInReduxStore() {
        return GeneraFinderInRedux(incident, {
            reducer: 'incidents',
            property: 'incidencia',
            findBy: 'id'
        })
    };

    useEffect(() => {
        getIncidents();
    }, [incident])

    const [zoom, setZoom] = useState(10);
    const [bounds, setBounds] = useState(null);
    
    return (
        <div style={{height: '40vh', width: "100%", position: 'relative'}}>
            <GoogleMapReact
                bootstrapURLKeys={{key: API_KEY}}
                defaultZoom={18}
                center={{ lat: latitude, lng: longitude }}
                // options={{disableDefaultUI: true}}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map }) => {
                    mapRef.current = map;
                }}
                onChange={({ zoom, bounds }) => {
                    setZoom(zoom);
                    setBounds([
                        bounds.nw.lng,
                        bounds.se.lat,
                        bounds.se.lng,
                        bounds.nw.lat
                    ]);
                }}
                >
                <LocationMarker lat={ latitude } lng={ longitude } color={getMarkerCategory( status )}/>
            </GoogleMapReact>
        </div>
    )
}

export default GmapSection
