import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import RoundStatusIndicator from "../../../../../elements/RoundStatusIndicator";
import RoundPrintedIndicator from "../../../../../elements/RoundPrintedIndicator";
import {incident} from "../../../../../configuration/entitiesNames";
import {connect} from 'react-redux';

class CodeAndStatusHeader extends React.Component{

    static defaultProps = {
        code: "0",
        status: '',
        printed: false
    };

    static propTypes = {
        code: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string
        ]),
        status: PropTypes.string,
        printed: PropTypes.bool
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps.code !== this.props.code ||
            nextProps.status !== this.props.status ||
            nextProps.printed !== this.props.printed
    }

    render() {
        const { className,
            code,
            status,
            printed } = this.props;
        return(
            <div className={`${className ? className : ''} code-and-status-header`}>
                Código { code }
                <div>
                    <RoundStatusIndicator status={status}/>
                    <RoundPrintedIndicator className={'ml-1'} printed={printed}/>
                </div>
            </div>
        )
    }
}

const mapStateToProp = ({incidents}, {id, code, status, printed}) => {

    const incidentInIndents = incidents[incident].find(inc => inc.id === id);
    const foundIncident = incidentInIndents ? incidentInIndents : incidents.filtered.find(inc => inc.id === id);

    return {
        code: foundIncident ? foundIncident.code : code,
        status: foundIncident ? foundIncident.status : status,
        printed: foundIncident ? foundIncident.printed : printed,
    }
};

export default connect(mapStateToProp)(CodeAndStatusHeader);


