import React, {Fragment} from 'react';
import QRCode from 'qrcode';
import { paths } from "../../../configuration/paths";

class QRSection extends React.Component {
    state = {
        QRImage: ''
    };

    componentDidMount() {
        const {id} = this.props;
        const options = {
            errorCorrectionLevel : 'M'
        };

        QRCode.toDataURL(`http://192.168.0.31:3008/${paths.Home}/${paths.Incidents}/list/${id}`, options, this.callback)
    }

    callback = (error, url) => {
        if(error) console.log('Error in QR', error);
        this.setState({ QRImage: url })
    };

    render() {
        const { QRImage } = this.state;
        return (
            <section className="pdf-section pdf-section-QR">
                { QRImage && (
                    <Fragment>
                        <img src={QRImage} alt={QRImage}/>
                        <small>Puedes acceder a la versión digital de esta incidencia escaneando el código QR con tu móvil</small>
                    </Fragment>
                )}
            </section>
        );
    }
}

export default QRSection;
