import React from 'react';
import './index.scss';

class Badge extends React.Component {
    render(){
        const {number} = this.props;
        return(
            <div className={'badge'}>
                <p>{number}</p>
            </div>
        )
    }
}

export default Badge