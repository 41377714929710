import React from 'react';
import Context from './Context'

const Consumer = (Component) => {
    class withContext extends React.Component{
        render(){
            /** Prop Value passed from Provider*/
            const value = this.context;
            const { filters, handleIncidentFilters, setFlagsAs, loadMoreIncidents, setIn, resetFilters, applyFilter, ...rest } = value;
            return(
                <Component {...this.props}
                           {...rest}
                           handleIncidentFilters={ handleIncidentFilters }
                           loadMoreIncidents={ loadMoreIncidents }
                           setFlagsAs={ setFlagsAs }
                           filters={ filters }
                           setIn={ setIn }
                           resetFilters={ resetFilters }
                           applyFilter={ applyFilter }
                />
            )
        }
    }

    withContext.contextType = Context;

    return withContext;
};

export default Consumer