/*Store*/
import ParseObjToOption from "../components/Form/Helpers/Select/ParseObjectToOption";

const ZONES = {
    LR: { code: 'LR', name: 'La Rinconada' },
    SLR: { code: 'SLR', name: 'San José de la Rinconada' },
    OTR: { code: 'OTR', name: 'Otra' },
};

export const ParseZoneToObjectByCode = ( zoneCode ) => ZONES[Object.keys(ZONES).find(key => ZONES[key].code === zoneCode)];

export const ParseZoneToOption = ( zoneCode ) => {
    const Obj = ParseZoneToObjectByCode(zoneCode);
    return ParseObjToOption(Obj);
};

export const ParseToAppropriateZone = (address, attr='code') => {
    if(address.suburb && address.suburb.toLowerCase() === "San José de La Rinconada".toLowerCase())
        return ZONES.SLR[attr];
    else if(address.town && address.town.toLowerCase() === 'La Rinconada'.toLowerCase())
        return ZONES.LR[attr];

    return ZONES.OTR[attr];
};

export const ZONE_OPTIONS = Object.keys(ZONES).map(key => (ParseObjToOption(ZONES[key])));

export default ZONES;