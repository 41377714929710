import { ipAddress, port, protocol } from "../configuration/network";
import { LogOut } from '../redux/auth/action';
import ExtractIdCreatedFromOrionPostEntity from '../helpers/ExtractIdCreatedFromOrionPostEntity';
import { whitoutValidation } from './../configuration/mode'


export const requestApi = async({ url, method, headers, body, access_token }) => {
    let Token  = access_token ;
    const LocalStorage = JSON.parse(localStorage.getItem('auth'));
    let ExpireTime = LocalStorage ? LocalStorage.expires_in : new Date().getTime();
    if(access_token) ExpireTime = new Date().getTime() + 10000;
    
    if(!Token && LocalStorage) Token = LocalStorage.access_token;

    const controller = new AbortController();
    const signal = controller.signal;
    const timeoutId = setTimeout(() => controller.abort(), 20000);

    if(new Date().getTime() > ExpireTime || !Token) {
        !whitoutValidation && LogOut();
    }
    else {
        const portString = port ? `:${port}`: '';
        const response = await fetch(`${protocol}://${ipAddress}${portString}/${url}`,{
            method: method,
            headers: {
                'fiware-service': 'rinconada' ,
                'fiware-servicepath': '/',
                'X-Auth-Token': Token,
                ...headers,
            },
            body: body,
            signal
        });

        if( response.status >= 200 && response.status < 300) {
            clearTimeout(timeoutId);
            const res = {};
            res.status = response.status;

            if(method=== 'POST' && response.status === 201 && url.includes('v2/entities') ) {
                res.idCreated = ExtractIdCreatedFromOrionPostEntity(response.headers.get('location'));
            }

            else if(response.status === 200 && url.includes('v2/entities') )
                res.total = response.headers.get('Fiware-Total-Count');

            res.data = [];
            try { res.data = await response.clone().json() }
            catch (e) {
                try{ res.data = await response.clone().text() }
                catch (e) {
                    console.error('Error in Request Api Fn', `URL: ${protocol}://${ipAddress}:${port}/${url}`, e);
                    res.data = response;
                }
            }

            return res
        }
        else {
            console.log('Request API ERROR', response)
        }

        return response;

    }

    return 'You dont have permission'

};
