import { Libraries } from "../../configuration/icons";

function getIconLibrary (name) {
    switch (name) {
        case 'fa':
            return require(`react-icons/fa`);
        case 'io':
            return require(`react-icons/io`);
        case 'md':
            return require(`react-icons/md`);
        case 'ti':
            return require(`react-icons/ti`);
        case 'go':
            return require(`react-icons/go`);
        case 'fi':
            return require(`react-icons/fi`);
        case 'gi':
            return require(`react-icons/gi`);
        case 'wi':
            return require(`react-icons/wi`);
        case 'di':
            return require(`react-icons/di`);
        default:
            return {}
    }
}

let Icons = {};

Libraries.forEach(libName => {
    const Imp = getIconLibrary(libName);
    Icons = {...Icons, ...Imp}
});

export default ({name}) => Icons[name]()