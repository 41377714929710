import { combineReducers } from 'redux';
import users from './users/reducer'
import incidents from './incidents/reducer'
import taxonomies from './taxonomies/reducer'
import auth from './auth/reducer'
import roles from './roles/reducer'
import router from './router/reducer'

export default combineReducers({
    users,
    incidents,
    taxonomies,
    auth,
    roles,
    router
})