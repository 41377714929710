
const withOutPriority = (list, newItems) => {
    return Object.values(list.concat(newItems).reduce((acc, x) => {
        acc[x.id] = Object.assign(acc[x.id] || {}, x);
        return acc;
    }, {}))
};

const withPriority = (list, newItems) =>
    withOutPriority(withOutPriority(list, newItems), list);

/**
 *
 * list: Primer Array pasado
 *
 * newItems: Segundo Array pasado, que será añadido al primero,
 * añadiendo los valores de los que no estén por id y pisando los valores de los que si estén.
 *
 * priority: Si la flag está activa, mantiene el orden de list + newItems, pero mantiene los valores de list.
 *
 * */
export default ( list, newItems, priority=false ) =>
    priority ? withPriority(list, newItems) : withOutPriority(list, newItems);
