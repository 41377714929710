"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var Commons_1 = require("./Commons");

var UserContstraints = {
  _id: {},
  password: {
    format: {
      pattern: /.{8,}/i,
      message: "Debe tener al menos 8 caracteres"
    }
  },
  name: {
    presence: Commons_1.presence(),
    type: "string",
    length: __assign(__assign({}, Commons_1.minimum(3)), Commons_1.maximum(100))
  },
  surname: {
    presence: Commons_1.presence(),
    type: "string",
    length: __assign(__assign({}, Commons_1.minimum(3)), Commons_1.maximum(100))
  },
  email: {
    email: {
      message: "Debes introducir un email válido"
    },
    presence: {
      message: "Debes introducir un email"
    }
  },
  zone: {},
  roles: {
    presence: {
      message: "Debes introducir al menos un rol"
    }
  },
  address: function address(value, attributes, attributeName) {
    if (!attributes.roles || attributes.roles.find(function (r) {
      return r.name !== "Ciudadano";
    })) return null;
    return {
      presence: {
        message: "Debes introducir una direción"
      }
    };
  },
  telephone: function telephone(value, attributes, attributeName) {
    if (!attributes.roles || attributes.roles.find(function (r) {
      return r.name !== "Ciudadano";
    })) return null;
    return {
      presence: {
        message: "Debes introducir un número de teléfono"
      },
      format: {
        pattern: /^(\+34|0034|34)?[6|7|8|9][0-9]{8}$/,
        message: "El número de teléfono es inválido"
      }
    };
  }
};
exports.default = UserContstraints;