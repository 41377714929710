import React, {Fragment} from 'react';
import EntityOption from "../../../../components/EntityOption";
import ZONES from "../../../../configuration/zonesOptions";
import Divider from "../../../../elements/Divider";
import Consumer from "../../Context/Consumer";

const ZONES_IN_ARRAY = Object.keys(ZONES);

class ZonesBlock extends React.Component {

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps.filters.zonesFilters !== this.props.filters.zonesFilters;
    }

    handleZoneFilterChange = (zoneCode) => {
        const { filters, setIn } = this.props;
        const { zonesFilters } = filters;
        if(zonesFilters.includes(zoneCode)) setIn('zonesFilters',zonesFilters.filter(z => z !== zoneCode ));
        else setIn('zonesFilters', [...zonesFilters, zoneCode]);
    };

    render(){
        const { filters } = this.props;
        const { zonesFilters } = filters;

        return(
            <Fragment>
                <p className={'Filter-sidebar-subtitle'}>Filtrar por zona</p>
                { ZONES_IN_ARRAY.length > 0 ?
                    ZONES_IN_ARRAY.map(zoneKey => <EntityOption
                            key={zoneKey}
                            selected={zonesFilters.includes(ZONES[zoneKey].code)}
                            onClick={()=>this.handleZoneFilterChange(ZONES[zoneKey].code)}
                            data={ZONES[zoneKey].name}
                        />
                    ) :
                    <i> No se han encontrado zonas para mostrar </i> }
                <Divider/>
            </Fragment>
        )
    }
}

export default Consumer(ZonesBlock);