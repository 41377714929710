const Get = (Map, Name) => Map.getLayer(Name);

const Add = (Map, SourceName, Name, Before) => Map.addLayer({
    id: Name,
    type: "circle",
    source: SourceName,
    filter: ["has", "point_count"],
    paint: {
        "circle-color": [
            "step",
            ["get", "point_count"],
            "#51bbd6", 25,
            "#f1f075", 50,
            "#f22e24"
        ],
        "circle-radius": [
            "step",
            ["get", "point_count"],
            20, 100,
            30, 750,
            40
        ]
    }
},Before);

export const RemoveCluster = (Map, Name) => {
    if(Get(Map,Name))
        Map.removeLayer(Name);
};


export default (Map, SourceName, Name = "Cluster", Before = undefined) => {

    const OldLayer = Get(Map,Name);
    try {
        if(!OldLayer) Add(Map, SourceName, Name, Before);
        else{
            RemoveCluster(Map, Name);
            Add(Map, Name, Before)
        }
    }
    catch (e) {
        console.log(e, 'OldLayer', OldLayer);
    }


};