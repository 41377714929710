import {create, update, view} from "../../../../configuration/operationNames";
import RoundButton from "../../../../elements/RoundButton";
import Divider from "../../../../elements/Divider";
import { AddIcon, CancelIcon, DeleteIcon } from "../../../../configuration/icons";
import React from "react";
import Consumer from '../../../Taxonomies/SidebarContext/Consumer';
import Swal from "sweetalert2";

class HeaderSidebar extends React.Component {

    handleCancel = () => {
        const paths = window.location.pathname.split('/');
        const { setMode, closeSidebar } = this.props;
        if(paths[paths.length -1] === 'list' || !paths[paths.length -1]) closeSidebar();
        else setMode(view);
    };

    handleClose = () => {
        const { closeSidebar } = this.props;
        closeSidebar();

        const paths = window.location.pathname.split('/');
        if(!(paths[paths.length -1] === 'list' || !paths[paths.length -1]))
        this.props.navigate('../');
    };

    preventDeleteOrderType = () => {
        const {onPressDeleteButton} = this.props;

        Swal.fire({
            title: '¡Cuidado!',
            text: "¿Estás seguro de que quieres borrar este tipo de orden?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            confirmButtonText: 'Borrar',
            cancelButtonText: 'Cancelar',
            focusCancel: true,
            preConfirm: onPressDeleteButton
        });
    };

    render() {
        /** Functions **/
        const { setMode, sidebarMode } = this.props;

        switch ( sidebarMode ) {
            case update:
            case create:
                return (
                    <div className={'header-sidebar'}>
                        <div className={'header-sidebar-content'}>
                            <RoundButton icon={CancelIcon} onClick={this.handleClose} tooltip={"Cerrar Panel"} className={'close-panel'}/>
                            <p onClick={this.handleCancel} className={'link m-0 p-0'}>Cancelar</p>
                        </div>
                        <Divider/>
                    </div>
                );
            default:
                return (
                    <div className={'header-sidebar'}>
                        <div className={'header-sidebar-content'}>
                            <RoundButton icon={CancelIcon} onClick={this.handleClose} tooltip={"Cerrar Panel"} className={'close-panel'}/>
                            <RoundButton icon={DeleteIcon} onClick={this.preventDeleteOrderType} tooltip={"Borrar tipo de orden"}/>
                            <RoundButton icon={AddIcon} onClick={()=>setMode(create)} tooltip={"Añadir nuevo tipo de orden"}/>
                        </div>
                        <Divider/>
                    </div>
                );
        }
    }
}

export default Consumer(HeaderSidebar);