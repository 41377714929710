import * as TYPES from './types';
import { store } from '../store';
import STATUS from "../../configuration/statusOptions";

export const swipeSuccessStatus = (status) => store.dispatch({
    type: TYPES.SWIPE_SUCCESS_STATUS,
    payload: status
});

export const getIncidents = (filters, callback) => store.dispatch({
    type: TYPES.GET_INCIDENTS_REQUEST,
    filters: filters,
    callback
});

export const filterIncidents = (filters, callback) => store.dispatch({
    type: TYPES.FILTER_INCIDENTS_REQUEST,
    filters: filters,
    callback
});

export const createIncident = (incident, callback) => store.dispatch({
    type: TYPES.CREATE_INCIDENT_REQUEST,
    incident,
    callback
});

export const getHistorical = ({ id, size, page }) => store.dispatch({
    type: TYPES.GET_HISTORICAL_INCIDENT_REQUEST,
    filters: { id, size, page },
});

export const getEmailsByIncidenceId = ({ id}) => store.dispatch({
    type: TYPES.GET_EMAILS_INCIDENT_REQUEST,
    payload: { id },
});

export const addPicturesToIncident = ({incident, images}, callback) => store.dispatch({
    type: TYPES.ADD_PICTURE_INCIDENT_REQUEST,
    payload: {incident, images},
    callback
});

export const deletePicturesIncident = ({incident, image}) => store.dispatch({
    type: TYPES.DELETE_PICTURE_INCIDENT_REQUEST,
    payload: {incident, image},
});

export const updateIncident = (incident, callback) => {
    store.dispatch({
        type: TYPES.UPDATE_INCIDENT_REQUEST,
        incident,
        callback
    })
};

export const updateIncidents = (incidents) => {
    store.dispatch({
        type: TYPES.UPDATE_INCIDENTS_REQUEST,
        payload: incidents,
    });

    incidents.forEach(incident => updateIncident(incident));
};

export const updateStatusIncidents = (incidents, status = STATUS.close.name,  callback = ()=>{}) => {
    store.dispatch({
        type: TYPES.UPDATE_STATUS_INCIDENTS_REQUEST,
        payload: incidents,
    });

    incidents.forEach(incident => store.dispatch({
        type: TYPES.UPDATE_STATUS_INCIDENT_REQUEST,
        incident,
        status,
        callback
    }));
};

export const printIncidents = (incidents, callback = ()=>{}) => {
    store.dispatch({
        type: TYPES.PRINTED_INCIDENTS_REQUEST,
        payload: incidents,
    });

    incidents.forEach(incident => store.dispatch({
        type: TYPES.PRINTED_INCIDENT_REQUEST,
        incident,
        callback
    }));
};