import React from 'react';
import './index.scss'

class Card extends React.PureComponent{

    render(){
        const {children} = this.props;
        return(
            <div className={'card-wrapper'}>{children}</div>
        )
    }
}

export default Card;