import React from 'react';
import { Input } from './../../components/Form';
import DatePicker, { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";

registerLocale('es', es);

export default ({onChange, name, label, value, ...rest}) => {
    return (
        <DatePicker
            dateFormat="dd/MM/yyyy"
            selected={value}
            onChange={date => onChange(name, date)}
            customInput={<Input label={label} name={name}/>}
            locale={'es'}
            {...rest}
        />
    );
};