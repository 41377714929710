import React from 'react';
import './index.scss';
import Number from "../../../components/Widgets/Number";
import STATUS from "../../../configuration/statusOptions";
import { incident } from "../../../configuration/entitiesNames";
import { getTaxonomies } from "../../../api/taxonomies";

class NumberForDashboard extends React.Component{
    state={number:0};

    componentDidMount() {
        this.getTotalIncidentByStatus();
    }

    getTotalIncidentByStatus() {
        const {status} = this.props;
        getTaxonomies(incident, undefined, {status: status.name}, 0, 1)
            .then(this.requestCallback)
            .catch(e => console.log(e))
    }

    requestCallback = (response) => {
        if(response.status === 200)
            this.setState({ number: response.total });
    };

    render() {
        const { number } = this.state;
        const { text, status } = this.props;
        return <Number number={number} text={text} className={'number-widget'} style={{backgroundColor: status.color}} backgroundIcon={status.icon}/>
    }
}


class DashboardNumberResume extends React.Component {

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return false;
    }

    render() {
        return(
            <div className={'dashboard-number-resume'}>
                <NumberForDashboard text={'Cerradas'} status={STATUS.close}/>
                <NumberForDashboard text={'Abiertas'}  status={STATUS.open}/>
                <NumberForDashboard text={'Rechazadas'}  status={STATUS.rejected}/>
                <NumberForDashboard text={'No proceden'} status={STATUS.irrelevant}/>
                <NumberForDashboard text={'Pendientes'}  status={STATUS.pending}/>
            </div>
        )
    }
}

export default DashboardNumberResume;

