import React from 'react';
import Context from "./Context";
import { view } from "../../../configuration/operationNames";

class Provider extends React.Component {
    constructor(props){
        super(props);
        this.functions = {
            openSidebar: this.openSidebar,
            closeSidebar: this.closeSidebar,
            setMode: this.setMode,
            setLastElementSelected: this.setLastElementSelected
        };

        this.state = {
            lastElementSelected: null,
            sidebarIsOpen: false,
            sidebarMode: view, //create, view, update
            ...this.functions
        };
    }

    openSidebar = () => { if(!this.state.sidebarIsOpen) this.setState({sidebarIsOpen: true}) };
    closeSidebar = () => { if(this.state.sidebarIsOpen) this.setState({sidebarIsOpen: false, lastElementSelected: null}) };
    setMode = (mode) => { if(this.state.sidebarMode !== mode) this.setState({sidebarMode : mode}) };
    setLastElementSelected = (element, callback) => { this.setState({lastElementSelected: {...element}}, callback && callback) };

    render() {
        const { children } = this.props;
        return (
            <Context.Provider value={{...this.state}}>
                { children }
            </Context.Provider>
        );
    }
}

export default Provider
