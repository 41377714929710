import React from 'react';
import Icon from '../../../elements/Icon';
import './index.scss';




class Number extends React.Component{
    render() {
        const {number, text, className, backgroundIcon, ...rest} = this.props;
        return (
            <div {...rest} className={`widget-wrapper ${className ? className : ''}`}>
                { backgroundIcon && <Icon name={backgroundIcon}/> }
                <p className={'title'}>{text}</p>
                <p className={'content'}>{number}</p>
            </div>
        )
    }
}



export default Number;