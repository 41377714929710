import React from 'react';
import {Input} from '../../components/Form'
import {DeleteIcon} from "../../configuration/icons";

class Edition extends React.PureComponent{

    onChange = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const {onChange} = this.props;
        onChange(e)
    };

    onDelete = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const {onDelete} = this.props;
        onDelete(e)
    };

    render() {
        const {value} = this.props;
        return (
            <Input
                textarea //TODO custom inputs cant be textarea too.
                icon={DeleteIcon}
                onIconClick={this.onDelete}
                custom
                onChange={this.onChange}
                value={value}
            />
        )
    }
}

export default Edition;