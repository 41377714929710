import React from 'react';
import { getUsers } from "../../redux/users/action";


class InitialRequests extends React.Component {

    state = {
        userIsCorrect: false
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextState !== this.state;
    }

    componentDidMount() {
        getUsers({}, this.checkIfGetUsersIsCorrect);
    }

    checkIfGetUsersIsCorrect = (response) => {
        if(response.status === 200)
            this.setState({ userIsCorrect: true })
    };

    render(){
        return null
    }
}

export default InitialRequests