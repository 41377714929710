import { put, call, all, fork, takeLatest, takeEvery } from 'redux-saga/effects';
import STATUS from './../../configuration/statusOptions';
import { store } from '../store';
import * as TYPES from './types';
import * as MINIO from './../../api/minio';
import * as EntitiesApi from './../../api/taxonomies';
import * as Miscellaneous from './../../api/miscellaneous';
import { incident as incidentString } from "../../configuration/entitiesNames";
import { getUsers, getUserRoles, sendEmailToUser, getEmailsByIncidenceID } from '../../api/user';
import { getHistorical } from './../../api/taxonomies';
import { notifications } from '../../configuration/notificationMail';

function* getIncidentsThroughApi({ filters, callback }){
    const {type, id, page, limit, ...attr} = filters;
    try{
        const response = yield call(EntitiesApi.getTaxonomies, type, id, attr, page, limit);
        if(response.status === 200 || response.status === 304) {
            yield put({
                type: TYPES.GET_INCIDENTS_SUCCESS,
                payload: {data: response.data, type: type, total: response.total}
            });
            callback && callback(response);
        }
        else {
            yield put({type: TYPES.GET_INCIDENTS_FAILED, payload: response});
            callback && callback(response);
        }
    }
    catch (e) {
        yield put({ type: TYPES.GET_INCIDENTS_FAILED, payload: e});
        callback && callback(e);
    }
}

function* filterIncidentsThroughApi({ filters, callback }){
    const {type, id, page, limit, ...attr} = filters;
    try{
        const response = yield call(EntitiesApi.getTaxonomies, type, id, attr, page, limit);
        if(response.status === 200 || response.status === 304) {
            yield put({
                type: TYPES.FILTER_INCIDENTS_SUCCESS,
                payload: {data: response.data, type: type, total: response.total, page}
            });
            callback && callback(response);
        }
        else {
            yield put({type: TYPES.FILTER_INCIDENTS_FAILED, payload: response});
            callback && callback(response);
        }
    }
    catch (e) {
        yield put({ type: TYPES.FILTER_INCIDENTS_FAILED, payload: e});
        callback && callback(e);
    }
}

function callBackOfUploadFilesOnIncidentCreated(resultOfUploadFiles) {
    store.dispatch({
        type: TYPES.CREATE_INCIDENT_SUCCESS,
        payload: {data: [resultOfUploadFiles] }
    })
}

function callBackOfUploadFilesOnCompleteUpload(resultOfUploadSinglePicture) {
    store.dispatch({
        type: TYPES.UPDATE_INCIDENT_SUCCESS,
        payload: resultOfUploadSinglePicture
    })
}

function* createIncidentThroughApi({ incident, callback }){
    try{
        incident.type = incidentString;
        const files = incident.images || [];
        incident.images = [];
        incident.printed = false;
        incident.status = incident.status || STATUS.pending.name;

        const response = yield call(EntitiesApi.createTaxonomy, incident);

        if(response.status === 201) {
            const getCreatedEntity = yield call(EntitiesApi.getSingleEntity, response.idCreated);
            const newEntity = getCreatedEntity.status === 200 ? getCreatedEntity.data : incident;
            if(files.length > 0) {
                yield call(
                    MINIO.uploadFiles,
                    {...newEntity, id:response.idCreated},
                    files,
                    callBackOfUploadFilesOnIncidentCreated
                );
            }

            const createdIncident = yield call(EntitiesApi.getTaxonomies, incidentString, newEntity.idCreated, undefined, 0, 1 );

            if(createdIncident.status === 200) {
                yield put({
                    type: TYPES.CREATE_INCIDENT_SUCCESS,
                    payload: {data: createdIncident.data, type: incidentString, total: createdIncident.total}
                });
                callback && callback(createdIncident)
            }

            else {
                yield put({type: TYPES.CREATE_INCIDENT_FAILED, payload: createdIncident});
                callback && callback(createdIncident)
            }
        }
        else {
            yield put({ type: TYPES.CREATE_INCIDENT_FAILED, payload: response});
            callback && callback(response);
        }
    }
    catch (e) {
        yield put({ type: TYPES.CREATE_INCIDENT_FAILED, payload: e});
        callback && callback(e);
    }
}

function* updateAndAppendIncidentThroughApi({ incident, callback }){
    try{
        delete incident.type;
        delete incident.operation;
        delete incident.operator;
        delete incident.creDate;
        delete incident.printed;
        delete incident.executor;

        incident.modDate = new Date().getTime();

        const response = yield call(EntitiesApi.appendTaxonomy, incident);
        if(response.status === 204) {
            callback && callback(response);
            yield put({ type: TYPES.UPDATE_INCIDENT_SUCCESS, payload: incident});
        }
        else{
            callback && callback(response);
            yield put({ type: TYPES.UPDATE_INCIDENT_FAILED, payload: response})
        }
    }
    catch (e) {
        callback && callback(e);
        yield put({ type: TYPES.UPDATE_INCIDENT_FAILED, payload: e})

    }
}

function* updateIncidentThroughApi({ incident, callback }){
    try{
        delete incident.type;
        delete incident.operation;
        delete incident.operator;
        delete incident.creDate;
        delete incident.printed;
        delete incident.executor;

        incident.modDate = new Date().getTime();

        const response = yield call(EntitiesApi.patchTaxonomy, incident);
        if(response.status === 204) {
            callback && callback(response);
            yield put({ type: TYPES.UPDATE_INCIDENT_SUCCESS, payload: incident});
        }
        else{
            callback && callback(response);
            yield put({ type: TYPES.UPDATE_INCIDENT_FAILED, payload: response})
        }
    }
    catch (e) {
        callback && callback(e);
        yield put({ type: TYPES.UPDATE_INCIDENT_FAILED, payload: e})

    }
}

function* updateStatusIncidentThroughApi({ incident, status, callback }){
    try{
        const getIncident = yield call(EntitiesApi.getSingleEntity,incident);
        const DATA = 0;
        if(getIncident.status === 200){
            yield put({ type: TYPES.GET_INCIDENTS_SUCCESS, payload: {data: [getIncident.data], type: incidentString}});
            try {
                if (status === 'open') {
                    getHistorical(getIncident.data.id, 1, 0, 'asc')
                        .then(({ status, data: { historicals } }) => {
                            if (status !== 200 && historicals && historicals.length === 0) return Promise.reject('Error');
                            return getUsers(historicals[0].executor, null);
                        })
                        .then(({ status, data: { user: { email, name, id }}}) => {
                            if (status === 200) {
                                getUserRoles(id).then((role => {
                                    if (role.status === 200 && role.data.roles[DATA].name === 'Ciudadano') {
                                        sendEmailToUser(notifications.OPEN, email ? email : 'chema@secmotic.com', getIncident.data.id.toString(), name ? name : 'Ciudadano', '', getIncident.data.description.toString());
                                    }
                                }));
                            }
                        })
                        .catch((e) => console.error(e));
                } else if (status === 'close') {
                    getHistorical(getIncident.data.id, 1, 0, 'asc')
                        .then(({ status, data: { historicals } }) => {
                            if (status !== 200 && historicals && historicals.length === 0) return Promise.reject('Error');
                            return getUsers(historicals[0].executor, null);
                        })
                        .then(({ status, data: { user: { email, name, id }}}) => {
                            if (status === 200) {
                                getUserRoles(id).then((role => {
                                    if (role.status === 200 && role.data.roles[DATA].name === 'Ciudadano') {
                                        sendEmailToUser(notifications.CLOSED, email ? email : 'chema@secmotic.com', getIncident.data.id.toString(), name ? name : 'Ciudadano', '', getIncident.data.description.toString());
                                    }
                                }));
                            }
                        })
                        .catch((e) => console.error(e));
                }
            } catch (error) {
                return error;
            }
            const updatedIncident = {...getIncident.data, status: status};
            yield call(updateIncidentThroughApi, { incident: updatedIncident, callback })
        }
        else
            yield put({ type: TYPES.UPDATE_STATUS_INCIDENT_FAILED, payload: getIncident})
    }
    catch (e) {
        yield put({ type: TYPES.UPDATE_STATUS_INCIDENT_FAILED, payload: e})
    }
}

function* printIncidentThroughApi({ incident, callback }){
    try{
        const printed = yield call(Miscellaneous.updatePrintedStatus, { ...incident, type: incidentString });
        if(printed.status === 204){
            yield put({ type: TYPES.PRINTED_INCIDENT_SUCCESS, payload: incident });
            callback && callback(printed);
        }
        else {
            yield put({type: TYPES.PRINTED_INCIDENT_FAILED, payload: incident});
            callback && callback(printed);
        }
    }
    catch (e) {
        yield put({ type: TYPES.PRINTED_INCIDENT_FAILED, payload: e});
        callback && callback(e);
    }
}

function* deletePictureThroughApi({payload}){
    const {incident, image} = payload;
    try{
        const imageDeleted = yield call(MINIO.deleteFile,image);
        if(imageDeleted.status === 200) {
            const gotIncident = yield call(EntitiesApi.getSingleEntity,incident);
            if(gotIncident.status === 200){
                const updatedIncident = gotIncident.data;
                updatedIncident.images = updatedIncident.images.filter(e => e !== image);
                yield call(updateIncidentThroughApi, {incident: updatedIncident})
            }
            else yield put({ type: TYPES.GET_INCIDENTS_FAILED, payload: gotIncident})
        }
        else yield put({ type: TYPES.DELETE_PICTURE_INCIDENT_FAILED, payload: imageDeleted})
    }
    catch (e) {
        yield put({ type: TYPES.DELETE_PICTURE_INCIDENT_FAILED, payload: e})
    }
}

function* addPicturesThroughApi({payload, callback}) {
    const {incident, images} = payload;
    try{
        const gotIncident = yield call(EntitiesApi.getSingleEntity,incident);
        if(gotIncident.status === 200) {
            yield call(
                MINIO.uploadFiles,
                gotIncident.data,
                images,
                callBackOfUploadFilesOnCompleteUpload,
                callback
            );
        } else yield put({ type: TYPES.GET_INCIDENTS_FAILED, payload: gotIncident})
    }
    catch (e) {
        yield put({ type: TYPES.ADD_PICTURE_INCIDENT_FAILED, payload: e})
    }

}

function* getHistoricalThroughApi({ filters }){
    const {id, size, page} = filters;
    try{
        const response = yield call(EntitiesApi.getHistorical, id, size, page);
        if(response.status === 200)
            yield put({ type: TYPES.GET_HISTORICAL_INCIDENT_SUCCESS, payload: response});
        else
            yield put({ type: TYPES.GET_HISTORICAL_INCIDENT_FAILED, payload: response})
    }
    catch (e) {
        yield put({ type: TYPES.GET_HISTORICAL_INCIDENT_FAILED, payload: e})

    }
}

function* getEmailsThroughApi({ payload }){
    try{
        const response = yield call(getEmailsByIncidenceID, payload);
        if(response.status === 200)
            yield put({ type: TYPES.GET_EMAILS_INCIDENT_SUCCESS, payload: response});
        else
            yield put({ type: TYPES.GET_EMAILS_INCIDENT_FAILED, payload: response})
    }
    catch (e) {
        yield put({ type: TYPES.GET_EMAILS_INCIDENT_FAILED, payload: e})

    }
}


/***** Functions for changes status of incidents *********/
function* watcherUpdateStatusIncidents() {
    yield takeEvery(TYPES.UPDATE_STATUS_INCIDENT_REQUEST, updateStatusIncidentThroughApi);
}
/***** End of Functions fot changes status of incidents ********/


function* watcherCreateIncidents() {
    yield takeLatest(TYPES.CREATE_INCIDENT_REQUEST, createIncidentThroughApi);
}

function* watcherUpdateIncidents() {
    yield takeEvery(TYPES.UPDATE_INCIDENT_REQUEST, updateAndAppendIncidentThroughApi);
}

function* watcherGetIncidents() {
    yield takeLatest(TYPES.GET_INCIDENTS_REQUEST, getIncidentsThroughApi);
}

function* watcherFilterIncidents() {
    yield takeEvery(TYPES.FILTER_INCIDENTS_REQUEST, filterIncidentsThroughApi);
}

function* watcherGetHistorical() {
    yield takeLatest(TYPES.GET_HISTORICAL_INCIDENT_REQUEST, getHistoricalThroughApi);
}

function* watcherGetEmails() {
    yield takeLatest(TYPES.GET_EMAILS_INCIDENT_REQUEST, getEmailsThroughApi);
}

function* watcherDeletePicture() {
    yield takeLatest(TYPES.DELETE_PICTURE_INCIDENT_REQUEST, deletePictureThroughApi);
}

function* watcherAddPictures() {
    yield takeEvery(TYPES.ADD_PICTURE_INCIDENT_REQUEST, addPicturesThroughApi);
}

function* watcherPrintIncident() {
    yield takeEvery(TYPES.PRINTED_INCIDENT_REQUEST, printIncidentThroughApi);
}

export default function* rootSaga() {
    yield all([
        fork(watcherGetIncidents),
        fork(watcherFilterIncidents),
        fork(watcherGetHistorical),
        fork(watcherCreateIncidents),
        fork(watcherUpdateIncidents),
        fork(watcherUpdateStatusIncidents),
        fork(watcherDeletePicture),
        fork(watcherAddPictures),
        fork(watcherPrintIncident),
        fork(watcherGetEmails),
    ])
}

