import React from 'react';
import Context from './Context';

const Consumer = (Component) => {
    class withContext extends React.Component {
        render() {
            /** Prop Value passed from Provider*/
            const value = this.context;
            const {
                openSidebar,
                closeSidebar,
                setMode,
                setLastElementSelected,
                lastElementSelected,
                sidebarIsOpen,
                sidebarMode
            } = value;
            return (
                <Component
                    {...this.props}
                    openSidebar = {openSidebar}
                    closeSidebar = {closeSidebar}
                    setMode = {setMode}
                    setLastElementSelected = {setLastElementSelected}
                    lastElementSelected = {lastElementSelected}
                    sidebarIsOpen = {sidebarIsOpen}
                    sidebarMode = {sidebarMode}
                />
            )
        }
    }

    withContext.contextType = Context;

    return withContext;
};

export default Consumer