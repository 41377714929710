import React from 'react';
import Context from './Context'
import { filterIncidents } from "../../../redux/incidents/action";
import { connect } from 'react-redux';
import { family, incident, orderType, subfamily } from "../../../configuration/entitiesNames";
import { INCIDENT_LIMIT_BY_REQUEST } from "../../../configuration/network";

import ConvertDateToIso  from './../../../helpers/ConvertDateToIso'

const INITIAL_FILTERS_STATE = {
    entitiesIncludes:[],
    zonesFilters:[],
    printedFilters:[],
    familyFinder:'',
    familyFilters: [],
    subfamilyFinder: '',
    subfamilyFilters: [],
    orderTypeFinder: '',
    orderTypeFilters: [],
    sacApprovedFilter: true,
    startDateFilter: undefined,
    endDateFilter: undefined,
    statusFilters: [],
    initialValueSetted: false
};

class Provider extends React.Component {
    constructor(props){
        super(props);
        this.functions = {
            loadMoreIncidents: this.loadMoreIncidents,
            setIn: this.setIn,
            resetFilters: this.resetFilters,
            openFilterSidebar: this.openFilterSidebar,
            closeFilterSidebar: this.closeFilterSidebar,
            applyFilter: this.applyFilter,
            setFlagsAs: this.setFlagsAs,
        };

        this.state = {
            filterSidebarIsOpen: false,
            filters: {...INITIAL_FILTERS_STATE },
            page: 0,
            ...this.functions,
        };
    }

    closeFilterSidebar = () =>{
        if(this.state.filterSidebarIsOpen)
            this.setState({
                filterSidebarIsOpen: false
            })
    };

    openFilterSidebar = () =>{
        if(!this.state.filterSidebarIsOpen)
            this.setState({
                filterSidebarIsOpen: true
            })
    };

    setFlagsAs = (bool) => {
        this.setState({
            initialValueSetted: bool,
        })
    }

    setIn = (key, value) => {
        const {filters} = this.state;
        if(filters[key] !== value)
            this.setState({filters: {...filters, [key]: value}}, this.applyFilter)
    };
    resetFilters = () => {
        this.setState({
            filters: {...INITIAL_FILTERS_STATE },
            page: 0,
        }, this.applyFilter)
    };

    applyFilter = (byPage, callback) => {
        const page = byPage ? this.state.page : 0;
        const { filters } = this.state;
        const filtersToApply = {};
        const orderTypeToFilter = [];
        if(filters.statusFilters.length > 0)
            filtersToApply.status = filters.statusFilters.join(',');

        if(filters.zonesFilters.length > 0) {
            filtersToApply.zone = filters.zonesFilters.join(',');
        }

        if(filters.printedFilters.length > 0) {
            filtersToApply.printed = filters.printedFilters.join(',');
        }

        if(filters.startDateFilter && filters.endDateFilter) {
            const newEndDate = new Date(filters.endDateFilter.getTime() + (24*60*60*1000));
            filtersToApply.dateCreated = `${ConvertDateToIso(filters.startDateFilter)}..${ConvertDateToIso(newEndDate)}`;
        }

        // //Filtros simples sobre taxonomías individuales
        if(filters.familyFilters.length > 0 && filters.subfamilyFilters.length === 0 && filters.orderTypeFilters.length === 0)
            filtersToApply.family = filters.familyFilters.join(',');
        else
        if(filters.subfamilyFilters.length > 0 && filters.familyFilters.length === 0 && filters.orderTypeFilters.length === 0)
            filtersToApply.subfamily = filters.subfamilyFilters.join(',');
        else
        if(filters.orderTypeFilters.length > 0 && filters.familyFilters.length === 0 && filters.familyFilters.length === 0)
            filtersToApply.orderType = filters.orderTypeFilters.join(',');
        // else // Filtro complejo por orderType (SOLO)
        //     filters.orderType = this.filterOnlyByOrderType( this.state.familyFilters, this.state.subfamilyFilters, this.state.orderTypeFilters );

        if(filters.orderType && filters.orderType.length === 0)
            delete filters.orderType;

        if(filters.entitiesIncludes.length > 0) {
            filtersToApply.code = filters.entitiesIncludes.map(ent => ent.code).join(',');
        }

        if(filters.sacApprovedFilter === false) filtersToApply.sac_approved = false;
        if(filters.sacApprovedFilter === true) filtersToApply.sac_approved = true;

        // console.info(filters.sacApprovedFilter, filtersToApply);

        // TODO: Review if it goes well or we must increase/decrease it.
        filterIncidents({
            type: incident,
            page,
            limit: 50,
            ...filtersToApply
        }, callback);

        if(!byPage) this.setState({page:0})
    };

    loadMoreIncidents = ( page, callback ) => {
        if (!this.props.fetching && this.props.filtered.length < this.props.totalIncidents ){
            this.setState({
                page: this.state.page + 1
            }, () => this.applyFilter(true, callback))
        }
    };

    render() {
        const {children, ...rest} = this.props;
        return (
            <Context.Provider value={{...this.state, ...rest}}>
                { children }
            </Context.Provider>
        );
    }
}

const mapStateToProps = ({incidents,taxonomies}) => ({
    [family] : taxonomies[family],
    [subfamily] : taxonomies[subfamily],
    [orderType] : taxonomies[orderType],
    [incident] : incidents[incident],
    filtered : incidents.filtered,
    fetching: incidents.fetching,
    totalIncidents: incidents.total,
});

export default connect(mapStateToProps)(Provider);
