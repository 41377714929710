export const paths = {
    /** *
     * Authorization routes
     ** */
    Auth: 'auth',
    Login: 'login' ,
    AppLogin: 'https://app.larinconada.es/login',
    Registry: 'registry',
    Confirmation: 'confirmation',
    UpdatePassword: 'update',


    Dashboard: 'dashboard',
    Citizen: 'citizen',
    Home: 'app',
    Incidents: 'incidents',
    Taxonomies: 'taxonomies',
    Users: 'users',
    Profile: 'profile',

    Families: 'families',
    Subfamilies: 'subfamilies',
    OrderTypes: 'orderTypes'
};