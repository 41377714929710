import { store } from "../../redux/store";
import { incident } from "../../configuration/entitiesNames";

export default (incidences) => {
    const STORE = store.getState();
    const IncidenceList = STORE.incidents[incident];
    const FilteredIncidenceList = IncidenceList.filter(inc => incidences.includes(inc.id));
    let StatusIncidenceList = FilteredIncidenceList.map(inc => inc.status);
    StatusIncidenceList = [...new Set(StatusIncidenceList)];
    if(StatusIncidenceList.length === 1) return StatusIncidenceList[0];
    return undefined
}