import React from 'react';
import InfoRow from './InfoRow';
import { user } from "../../../configuration/entitiesNames";
import { getTaxonomies } from '../../../api/taxonomies';
import { getUsers } from '../../../api/user';

class ExecutorSection extends React.Component {
    state = {
        name: 'No disponible',
        email: 'No disponible',
        telephone: 'No disponible',
        address: 'No disponible',
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextState !== this.state || nextProps.executor !== this.props.executor
    }

    componentDidMount() {
        this.getUserFromOrion();
        this.getUserFromDDBB()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.executor !== this.props.executor)
            this.getUserFromOrion()
    }

    getUserFromOrion = () => {
        const { executor } = this.props;
        getTaxonomies(user, undefined, {userId: executor}, 0, 1)
            .then(res => {
                if(res.status === 200 && res.data[0])
                    this.setState({
                        telephone: res.data[0].telephone,
                        address: res.data[0].address
                    });
            }).catch(e => console.error(e))
    };

    getUserFromDDBB = () => {
        const { executor } = this.props;
        getUsers( executor )
            .then(res => {
                if(res.status === 200 && res.data.user) {
                    const Data = res.data.user;
                    this.setState({
                        name: `${Data.name} ${Data.surname}`,
                        email: Data.email
                    });
                }
            })
    };

    render() {
        const { name, email, telephone } = this.state;
        return (
            <section className="pdf-section pdf-section-title">
                <h3>Contacto</h3>
                <InfoRow title='Email' content={email}/>
                <InfoRow title='Nombre' content={name}/>
                <InfoRow title='Teléfono' content={telephone}/>
                {/*<InfoRow title='Dirección' content={address}/>*/}
            </section>
        )
    }
}

export default ExecutorSection;