"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var validate_js_1 = require("validate.js");

var Utils = function () {
  function Utils(arg) {
    var _this = this;

    this.validate = function (Constraints) {
      if (Constraints === void 0) {
        Constraints = _this.Constraints;
      }

      return validate_js_1.validate(_this.getJSON(), Constraints, {
        fullMessages: false
      });
    };

    this.getJSON = function () {
      var obj = {};
      var instance = _this;
      var utilsInstance = new Utils({
        model: {}
      });
      var modelInstance = new _this.Model(__assign({}, _this));
      Object.keys(_this).map(function (key) {
        if (typeof instance[key] !== 'function' && !Object.getOwnPropertyNames(utilsInstance).includes(key) && Object.getOwnPropertyNames(modelInstance).includes(key)) {
          obj[key] = instance[key];
        }
      });
      return obj;
    };

    this.Constraints = arg.constraints;
    this.Model = arg.model;
  }

  return Utils;
}();

exports.default = Utils;