const isExpirated = () => {
    const LocalStorage = localStorage.getItem('auth');
    if (!LocalStorage) return false;
    return new Date() > new Date(LocalStorage.expires_in);
};

export default () => {
    if (isExpirated()) {
        localStorage.clear();
        return false;
    }
    else return localStorage.getItem('auth') && localStorage.getItem('auth').length > 0;
};
