import React, { useEffect, useState }  from 'react';
import { user } from "../../../../../configuration/entitiesNames";
import Dropview from "../../../../../components/DropView";
import {
    getTaxonomies as getTaxonomiesFromApi,
    getHistorical as getHistoricalFromApi,
} from "../../../../../api/taxonomies";
import { getUsers } from '../../../../../api/user';

const UserDataBlock = ({ id }) => {
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [telephone, setTelephone] = useState('');

    
    useEffect(() => {
        const findOrionUserData = () => {
            getHistoricalFromApi(id, 1, 0, 'asc')
                .then(({ status, data: { historicals } }) => {
                    if (status !== 200 && historicals && historicals.length === 0) return Promise.reject('Error');
                    return Promise.all([
                        getTaxonomiesFromApi(user, undefined, { userId: historicals[0].executor }, 0, 1),
                        getUsers(historicals[0].executor, null),
                    ]);
                })
                .then(([telephoneData, nameAndEmailData]) => {
                    if (telephoneData.status === 200 && telephoneData.data.length > 0) {
                        setTelephone(telephoneData.data[0].telephone)
                    }
                    if (nameAndEmailData.status === 200 && nameAndEmailData.data && nameAndEmailData.data.user) {
                        setEmail(nameAndEmailData.data.user.email);
                        setName(nameAndEmailData.data.user.name);
                        setSurname(nameAndEmailData.data.user.surname);
                    }
                })
                .catch((e) => console.error(e));
        };
        findOrionUserData();
    }, [id]);

    return (
        <Dropview header={<h4 className={'m-0'}>Datos del usuario</h4>}>
            <div className={'pl-1 pr-1'}>
                <h6 className={"label"}>Nombre</h6>
                <p>{name} {surname}</p>
                <h6 className={"label"}>Email</h6>
                <p><a href={`mailto:${email}`}>{email}</a></p>
                <h6 className={"label"}>Teléfono</h6>
                <p><a href={`tel:+34${telephone}`}>{telephone}</a></p>
            </div>
        </Dropview>
    );
}

export default UserDataBlock;
