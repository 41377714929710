import React from 'react';
import './RowInFilterSidebar.scss';

class RowInFilterSidebar extends React.PureComponent {
    render() {

        const {children} = this.props;

        return (
            <div className={'row-filter-sidebar-wrapper'}>
                {children}
            </div>
        );
    }
}

export default RowInFilterSidebar;