import React from 'react';
import { UserConsumer } from '../Context';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Header from './Components/HeaderSidebar'
import Footer from '../Components/FooterSidebar'
import Body from "./Components/BodySidebar";
import { user } from "../../../configuration/entitiesNames";
import { getTaxonomies } from "../../../api/taxonomies";
import { getUserOrionInforSuccess } from "../../../redux/users/action";
import { update } from "../../../configuration/operationNames";

class View extends React.Component {

    static defaultProps = { userFromRedux: undefined };
    static propTypes = { userFromRedux: PropTypes.object };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return JSON.stringify(nextProps.userFromRedux) !== JSON.stringify(this.props.userFromRedux) || (!this.props.sidebarIsOpen && nextProps.sidebarIsOpen)
    }

    componentDidMount() {
        this.checkUserInReduxOrInDataBase();
        this.checkIfSidebarIsOpen();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { setLastElementSelected } = this.props;
        if(!prevProps.userFromRedux || (this.props.userFromRedux && prevProps.userFromRedux.id !== this.props.userFromRedux.id) || (this.props.sidebarIsOpen && !prevProps.sidebarIsOpen)) {
            setLastElementSelected({...this.props.userFromRedux}, this.getOrionDataFromOrionService)
        }
    }

    getOrionDataFromOrionService = () => {
        const {id, setLastElementSelected, lastElementSelected} = this.props;
        if(!lastElementSelected || !lastElementSelected.telephone || !lastElementSelected.address)
        getTaxonomies(user, undefined, {userId: id}, 0, 1)
            .then(res => {
                if (res.status === 200 && res.data && res.data.length > 0) {
                    const {address, telephone} = res.data[0];
                    getUserOrionInforSuccess(id, res.data[0]);
                    setLastElementSelected({...lastElementSelected, address, telephone, orionId: res.data[0].id})
                }
            })
    };

    // Cannot be obtained from database because all users would have to be brought.
    // The user management service does not filter or page.
    // All users are already brought to the beginning.
    checkUserInReduxOrInDataBase() {
        const { userFromRedux, setLastElementSelected } = this.props;
        if(userFromRedux) setLastElementSelected({...userFromRedux}, this.getOrionDataFromOrionService)
    }

    checkIfSidebarIsOpen(){
        const { openSidebar, sidebarIsOpen } = this.props;
        if(!sidebarIsOpen)
            openSidebar();
    }

    render() {
        return(
            <>
                <Header userId={this.props.id} />
                <Body />
                <Footer onClickFooterButton={()=>this.props.setMode(update)} buttonLabel={'Editar Usuario'} />
            </>
        )
    }
}

const mapStateToProps = ({users}, {id}) => ({
    userFromRedux: users[user].find(u => u.id === id)
});

export default UserConsumer(connect(mapStateToProps)(View));