import React from 'react';
import Context from "./Context";

const FILTERS_BASE = {
    string: '',
    roles: [],
    selected: []
};

class Provider extends React.Component {
    constructor(props){
        super(props);
        this.functions = {
            openFilterSidebar: this.openSidebar,
            closeFilterSidebar: this.closeSidebar,
            setInFilter: this.setInFilter,
            clearFilter: this.clearFilter,
        };

        this.state = {
            filterSidebarIsOpen: false,
            filters: {
               ...FILTERS_BASE
            },
            ...this.functions
        };
    }

    openSidebar = () => { if(!this.state.filterSidebarIsOpen) this.setState({filterSidebarIsOpen: true}) };
    closeSidebar = () => { if(this.state.filterSidebarIsOpen) this.setState({filterSidebarIsOpen: false}) };
    setInFilter = (key, value) => {
        const { filters } = this.state;
        if(filters[key] !== value)
            this.setState({ filters: {...filters, [key]: value }})
    };
    clearFilter = () => {
        this.setState({ filters: {...FILTERS_BASE} })
    };

    render() {
        const { children } = this.props;
        return (
            <Context.Provider value={{...this.state}}>
                { children }
            </Context.Provider>
        );
    }
}

export default Provider
