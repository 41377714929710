import React from 'react';
import { connect } from "react-redux";
import { getEmailsByIncidenceId } from "../../../../../redux/incidents/action";
import EmailRow from "./EmailRow";

class EmailBlock extends React.PureComponent {

    componentDidMount() {
        getEmailsByIncidenceId({ id: this.props.id })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.id !== prevProps.id)
            getEmailsByIncidenceId({ id: this.props.id })
    }

    render() {
        const emails = this.props.emails;
        return (
            <>
                {(emails && emails.length > 0) ?
                    emails.map(h => (<EmailRow data={h} key={h.id} />)) :
                    <p className={'pt-1 m-0'}>
                        <i>Parece que no se ha podido recuperar los mensajes enviados para esta incidencia</i>
                    </p>
                }
            </>
        )
    }
}

const mapStateToProps = ({ incidents }) => ({
    emails: incidents.emails,
    fetching: incidents.fetching
});

export default connect(mapStateToProps)(EmailBlock)