import React, {Fragment} from 'react';
import Button from "../../../../elements/Button";
import HeaderSidebar from './HeaderSidebar';
import {  Input } from '../../../../components/Form';
import { create, update, view } from "../../../../configuration/operationNames";
import { getTaxonomies as getEntities } from "../../../../redux/taxonomies/action";
import { createIncident, updateIncident } from "../../../../redux/incidents/action";
import { family, incident, orderType, subfamily } from "../../../../configuration/entitiesNames";
import {ZONE_OPTIONS, ParseToAppropriateZone} from "../../../../configuration/zonesOptions";
import Consumer from '../../../Taxonomies/SidebarContext/Consumer';
import { connect } from 'react-redux';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import SearchTaxonomyThroughData from "../../../../helpers/redux-helpers/SearchTaxonomyThroughData";
import MapContainer from '../../../../components/MapContainer';
import GetAddressByCoords from '../../../../helpers/geocoding/GetAddressByCoords';
import GetCoordsByAddress from '../../../../helpers/geocoding/GetCoordsByAddress';
import ParseLatLng from '../../../../helpers/geocoding/ParseLatLng';
import GetCurrentPosition from '../../../../helpers/geocoding/GetCurrentPosition';
import Divider from '../../../../elements/Divider';
import BodySidebar from "../../../../components/Sidebar/Body";
import FooterSidebar from "../../../../components/Sidebar/Footer";
import Dropview from "../../../../components/DropView";
import AddressOption from './AddressOption';
import ImageWrapper from "../../../../elements/ImageWrapper";
import MinioImage from "../../../../components/MinioImage";
import {Incident, Comment} from "@secmotic/models";
import Utils from "../../../../helpers/UtilClassWithCommonFunctions";
import CommentsBlock from "./CommentsBlock";
import {createTaxonomy} from "../../../../api/taxonomies";
import Loading from "../../../../elements/Loading";
import DropZone from '../../../../components/Dropzone'
import Search from './createSearchUpdateList/Search';
import './createSearchUpdateList/Search.css';

const ENTITY = incident;
let TIMEOUT_FINDER = null;

class Create extends Utils {
    constructor(props){
        super(props);
        this.state = {
            userCenter: {},
            description: this.setStringFromLastElementSelectedObjectInContext('description'),
            city: this.setStringFromLastElementSelectedObjectInContext('city'),
            code: this.setStringFromLastElementSelectedObjectInContext('code'),
            coords: this.setStringFromLastElementSelectedObjectInContext('coords'),
            street: this.setStringFromLastElementSelectedObjectInContext('street'),
            zipCode: this.setStringFromLastElementSelectedObjectInContext('zipCode'),
            address: this.setStringFromLastElementSelectedObjectInContext('address'),
            images: this.setStringFromLastElementSelectedObjectInContext('images'),
            family: undefined,
            subfamily:undefined,
            orderType: undefined,
            id: this.props.id || null,
            zone: this.setStringFromLastElementSelectedObjectInContext('zone'),
            number: this.setStringFromLastElementSelectedObjectInContext('number'),
            creDate: this.setNullFromLastElementSelectedObjectInContext('creDate'),


            editAddress: false,
            controllerFinderAddress: null,
            addressFinderInputValue: '',
            addressOptions: [],
            loadingAddressOptions: false,
            errors: [],

            currentLocationIsReady: false,

            comments: this.setArrayFromLastElementSelectedObjectInContext('comments'),
            newComments: [],
            addingNewComment: false
        }
        this.setState = this.setState.bind(this);
    }

    componentDidMount() {
        this.setCurrentUserPosition();
        this.setTaxonomy('family', family);
        this.setTaxonomy('subfamily', subfamily);
        this.setTaxonomy('orderType', orderType);
    }
    
    componentDidUpdate(prevProps, prevState, prevSnap) {
        if(this.props.lastElementSelected !== prevProps.lastElementSelected){
            this.setTaxonomy('family', family);
            this.setTaxonomy('subfamily', subfamily);
            this.setTaxonomy('orderType', orderType);
            this.setState({
                id: this.props.id || null,
                description: this.setStringFromLastElementSelectedObjectInContext('description'),
                code: this.setStringFromLastElementSelectedObjectInContext('code') || { lat: 0, lng: 0 },
                coords: this.setStringFromLastElementSelectedObjectInContext('coords'),
                street: this.setStringFromLastElementSelectedObjectInContext('street'),
                zipCode: this.setStringFromLastElementSelectedObjectInContext('zipCode'),
                zone: this.setStringFromLastElementSelectedObjectInContext('zone'),
                images: this.setStringFromLastElementSelectedObjectInContext('images'),
                comments: this.setArrayFromLastElementSelectedObjectInContext('comments'),
                creDate: this.setNullFromLastElementSelectedObjectInContext('creDate'),
            });
        }
    }

    setCurrentUserPosition = () => {
        GetCurrentPosition(response => {
            if(response && response.coords && response.coords.latitude && response.coords.longitude)
                this.setState({
                    userCenter: response
                })
        })
    };

    setStringFromLastElementSelectedObjectInContext = (key) => {
        const {sidebarMode, lastElementSelected} = this.props;
        if(sidebarMode === update && lastElementSelected) return lastElementSelected[key] || '';
        return  '';
    };

    setArrayFromLastElementSelectedObjectInContext = (key) => {
        const {sidebarMode, lastElementSelected} = this.props;
        if(sidebarMode === update && lastElementSelected) return lastElementSelected[key] || [];
        return  [];
    };

    setNullFromLastElementSelectedObjectInContext = (key) => {
        const {sidebarMode, lastElementSelected} = this.props;
        if(sidebarMode === update && lastElementSelected) return lastElementSelected[key] || null;
        return  null;
    };

    setTaxonomy = ( attrName = 'family', type = family ) => {
        const { sidebarMode, lastElementSelected } = this.props;
        if(sidebarMode === update && lastElementSelected) {
            SearchTaxonomyThroughData(lastElementSelected[attrName], type).then(tax => {
                if(tax) this.setState({[attrName]:{label: tax.name, value: lastElementSelected[attrName]}});
            });
        }
    };

    parseTaxonomiesToOrion = () => {
        let { family, subfamily, orderType } = this.state;
        family = family ? family.value : undefined;
        subfamily = subfamily ? subfamily.value : undefined;
        orderType = orderType ? orderType.value : undefined;
        return {family, subfamily, orderType};
    };


    submit = (e) => {
        const { editAddress, address } = this.state;
        e && e.preventDefault();
        e && e.stopPropagation();

        const Incidence = new Incident({
            ...this.state,
            ...this.parseTaxonomiesToOrion(),
            address: !editAddress ? address : this.recreateAddress()
        });
        const val = Incidence.validate();
        val ? this.setErrors(val) : ( this.props.sidebarMode === update && this.props.id ) ? this.updateIncident(Incidence.getJSON()) : this.createIncident(Incidence.getJSON());
    };

    updateIncident = (Incidence) => {
        /** Check if there is something different with original **/
        const IncidenceWithValuesForUpdate = {
            type: incident,
            id: this.props.id
        };

        for (const attr in Incidence){
            // console.info(
            //     'attr: ',
            //     attr,
            //     '\nLast selected: ',
            //     this.props.lastElementSelected[attr],
            //     '\nIncidence: ',
            //     Incidence[attr],
            //     '\nTypeof: ',
            //     typeof Incidence[attr],
            //     '\nFirst IF: ',
            //     JSON.stringify(this.props.lastElementSelected[attr]) !== JSON.stringify(Incidence[attr]),
            //     '\nSecond IF: ',
            //     (typeof this.props.lastElementSelected[attr] === 'string' ? this.props.lastElementSelected[attr] === '' : false),
            // );
            // if(
            //     this.props.lastElementSelected[attr]
            //     && (
            //         JSON.stringify(this.props.lastElementSelected[attr]) !== JSON.stringify(Incidence[attr])
            //         || (typeof this.props.lastElementSelected[attr] === 'string' ? this.props.lastElementSelected[attr] === '' : false)
            //     )
            // ) {
            IncidenceWithValuesForUpdate[attr] = Incidence[attr]
            // }
        }

        updateIncident({ type: ENTITY, ...IncidenceWithValuesForUpdate }, (res)=> this.callbackUpdateTaxonomy(res, { ...IncidenceWithValuesForUpdate }))
    };

    createIncident = (Incidence) => {
        Incidence.code = 0;
        Incidence.id = undefined;
        createIncident({ type: ENTITY, ...Incidence }, this.callbackCreateTaxonomy)
    };

    addCommentsToIncident = async (incidentId) => {
        const { newComments } = this.state;
        const promiseArray = [];
        newComments.forEach( (item) => {
            promiseArray.push(new Promise(async (resolve) => {
                const com = new Comment({ ...item, incidentId });
                const createComment = await createTaxonomy({...com.getJSON()});
                if(createComment.status === 201)
                    resolve({...com.getJSON(), _id:createComment.idCreated});
                resolve(null);
            }));
        });

        return await Promise.all(promiseArray);
    };

    callbackCreateTaxonomy = (response) => {
        const { newComments } = this.state;
        if(response.status === 200) {
            this.addCommentsToIncident(response.data[0].id)
                .then((res) => {
                    res = res.filter(e => e);
                    if(newComments.length === res.length) {
                        this.props.navigate(response.data[0].id, {state: {...response.data[0], comments: [...res]}});
                        this.props.setMode(view);
                    }
                })
        }
    };

    callbackUpdateTaxonomy = (response, data) => {
        const { newComments } = this.state;

        if(response.status === 204) {
            this.addCommentsToIncident(this.props.id)
                .then((res) => {
                    res = res.filter(e => e);
                    if(newComments.length === res.length) {
                        this.props.setLastElementSelected({...this.props.lastElementSelected, ...data,  comments: [...res]});
                        this.props.navigate(this.props.id, {state: {...this.props.lastElementSelected, ...data, comments: [...res]}});
                        this.props.setMode(view);
                    }
                });
        }
    };

    loadFamilies = (inputValue, callback) => {
        getEntities({type: family, name: inputValue}, (response) => this.callbackGetTaxonomy(response, callback));
    };

    loadSubfamilies = (inputValue, callback) => {
        const family = this.state.family || {};
        getEntities({type: subfamily, name: inputValue, parent: family.value}, (response) => this.callbackGetTaxonomy(response, callback));
    };

    loadOrdertype = (inputValue, callback) => {
        const subfamily = this.state.subfamily || {};
        getEntities({type: orderType, name: inputValue, parent: subfamily.value}, (response) => this.callbackGetTaxonomy(response, callback));
    };

    callbackGetTaxonomy = (response, selectCallback) => {
        if(response.status === 200) {
            return selectCallback(response.data.map(taxonomy => ({value: taxonomy.id, label: taxonomy.name})));
        }
        return selectCallback([])
    };

    onSelectChangeFamily = (key,value) => {
        // getEntities({type: subfamily, parent: value.value});
        this.setState({ [key]: value });
        return value;
    };

    onSelectChangeSubFamily = (key,value) => {
        // getEntities({type: orderType, parent: value.value});
        this.setState({ [key]: value });
        return value;
    };

    onSelectChangeOrderType = (key,value) => {
        this.setState({ [key]: value });
        return value;
    };

    onFocusFamily = () => getEntities({ type:family});
    onFocusSubfamily = () => getEntities({ type:subfamily, parent: this.state.family ? this.state.family.value : undefined });
    onFocusOrderType = () => getEntities({ type:orderType, parent: this.state.subfamily ? this.state.subfamily.value : undefined });

    handleOnClickInMap = (mapboxObject) => {
        const { lngLat } = mapboxObject;
        const coords = ParseLatLng(lngLat);
        this.setState({
            coords
        })
    };

    onChangeStreet = ({target}) => {
        this.setState({ addressFinderInputValue : target.value }, this.setTimeOutForSearchAddress)
    };

    setTimeOutForSearchAddress = () => {
        const { addressFinderInputValue } = this.state;
        if(addressFinderInputValue.length > 2) {
            if (TIMEOUT_FINDER) clearTimeout(TIMEOUT_FINDER);
            TIMEOUT_FINDER = setTimeout(this.searchAddressByInput, 1000);
        }
    };

    searchAddressByInput = () => {
        const { addressFinderInputValue, controllerFinderAddress } = this.state;
        if(controllerFinderAddress) controllerFinderAddress.abort(); // Abort and ignore previous request.
        const newControllerFinderAddress = new AbortController();
        const signal = newControllerFinderAddress.signal;

        this.setState({ controllerFinderAddress: newControllerFinderAddress, loadingAddressOptions: true },
            () => GetCoordsByAddress(addressFinderInputValue, signal, newControllerFinderAddress)
                .then(response => {
                    if(response.status === 200)
                        this.setState({
                            addressOptions: [...response.data],
                            loadingAddressOptions: false,
                            controllerFinderAddress: null
                        })
                })
                .catch(Error => {
                    this.setState({
                        errorSearchingAddresses: true,
                        street: addressFinderInputValue,
                        addressOptions: this.state.addressOptions,
                        loadingAddressOptions: false,
                        controllerFinderAddress: null
                    })
                })
        );
    };

    parseOSMAddressToState = (OSMAddress) => {
        return {
            zone: ParseToAppropriateZone(OSMAddress),
            city: OSMAddress.village || OSMAddress.town || OSMAddress.city || OSMAddress.county,
            number: OSMAddress.number || 'Sin Número',
            street: OSMAddress.road || 'Sin Calle',
            zipCode: OSMAddress.postcode || 'Sin Código Postal',
        }
    };

    resetAddressInState = () => {
        this.setState({
            errorSearchingAddresses: false,
            zone: '',
            coords: {},
            city: '',
            number: '',
            street: '',
            zipCode: '',
            address: '',
        })
    };

    handleSelectAddress = (e, address) => {
        e.preventDefault();
        e.stopPropagation();
        if(address.lon && address.lat) {
            const coords = { lng: address.lon, lat: address.lat };
            this.SearchAddressByCoordsAndParseToState(coords)
        }
    };

    SearchAddressByCoordsAndParseToState = (coords) => {
        coords = ParseLatLng(coords);
        GetAddressByCoords(coords)
            .then(response => {
                if(response.status === 200) {
                    this.setState({
                        address: response.data.display_name,
                        coords,
                        ...this.parseOSMAddressToState(response.data.address)
                    });
                }
            })
    };

    setMyLocationAsIncidentAddress = () => {
        GetCurrentPosition(response => {
            if(response && response.coords && response.coords.latitude && response.coords.longitude) {
                this.SearchAddressByCoordsAndParseToState(response.coords);
            }
        });
    };

    changeToManualAddress = () => {
        this.setState({
            address: 'Manual',
            editAddress: true
        })
    };

    onChange = (e) => {
        this.handleOnChange(e.target.name,e.target.value)
    };

    onChangeZone = (option) => {
        this.setState({
            zone : option.value
        })
    };

    recreateAddress = () => {
        const {number, street, zipCode, city} = this.state;
        return  `${street} ${number} ${city} ${zipCode}`
    };

    concordanceComments = (comments) => {
        this.setState({
            newComments: comments
        })
    };

    handleFiles = (files) => {
        let error = false;
        if(this.state.images !== files) {
            const fileList = [];

            //TODO: Uncomment to control repeated names

            files.forEach(file => {
                fileList.push(file);
            });

            this.setState({
                images: [...fileList],
                error:error
            })
        }
    };
    render() {
        const {
            sidebarMode,
            defaultFamilyOptions,
            defaultSubfamilyOptions,
            defaultOrderTypeOptions,
            navigate,
            fetching
        } = this.props;

        const {
            description,
            family,
            subfamily,
            orderType,
            images
        } = this.state;

        /** Address Data **/
        const {
            zone,
            coords,
            city,
            number,
            street,
            zipCode,
            address
        } = this.state;


        /** Address Finder **/
        const {
            addressOptions, // Contain an array of object address from OpenStreetMaps (OSM),
            addressFinderInputValue, // Current value of the finder input,
            loadingAddressOptions // Flag for report to user activity in the network
        } = this.state;

        /** MISC **/
        const {
            userCenter,
            editAddress,
            errorSearchingAddresses,
            errors,
            comments,
            newComments
        } = this.state;

        return(
            <Fragment>
                { fetching && <Loading text={ sidebarMode === create ? 'Creando incidencia' : 'Actualizando incidencia'}/> }
                <HeaderSidebar navigate={navigate}/>
                <BodySidebar className={'bottom-button'}>
                    <div>
                        <div className={'pl-1 pr-1'}>
                            <h4 className={'mt-0 mb-1'}>¿Qué ha ocurrido?</h4>
                            <Input
                                readOnly={sidebarMode === update}
                                groupClassName={'mt-0'}
                                noLabel
                                textarea
                                onChange={this.onChange}
                                name={'description'}
                                value={description}
                                infoMsg={'Explica brevemente la causa de la incidencia'}
                                errorMsg={errors['description']}
                            />
                        </div>
                        <Divider/>



                        <div className={'pl-1 pr-1'}>

                            <div className={'wrapper-title-with-button'}>
                                <h4 className={'mt-0 mb-0'}>¿Dónde ha ocurrido?</h4>
                                <button className="my-loc-button" onClick={() => {
                                    this.setState({currentLocationIsReady: true})
                                }}><h5 className={'mt-0 mb-0'}>En mi ubicación</h5></button>
                            </div>
                            <div className={'mt-1'}></div>
                                <Search className="mainSearch" state={this.state} setState={this.setState}/>
                            <div className="div-p-search-address"><p className="p-search-address">Indique la dirección dónde ha ocurrido la incidencia</p></div>

                        </div>

                        <Divider/>

                        <Dropview
                            open={editAddress}
                            header={<h4 className={'mt-0 mb-0'}>Dirección expandida</h4>}
                        >

                            {address ?
                                <div className={'pl-1 pr-1'}>
                                    {!editAddress && <p className={'w-100 text-right link'} onClick={this.changeToManualAddress}>Editar</p>}
                                    <h6 className={'label'}>Calle</h6>
                                    <Input
                                        noLabel
                                        readOnly={!editAddress}
                                        name={"street"}
                                        value={street}
                                        onChange={this.onChange}
                                    />

                                    <h6 className={'label'}>Número</h6>
                                    <Input
                                        noLabel
                                        readOnly={!editAddress}
                                        name={"number"}
                                        value={number}
                                        onChange={this.onChange}
                                    />

                                    <h6 className={'label'}>Ciudad o Barrio</h6>
                                    <Input
                                        noLabel
                                        readOnly={!editAddress}
                                        name={"city"}
                                        value={city}
                                        onChange={this.onChange}
                                    />

                                    <h6 className={'label'}>Código Postal</h6>
                                    <Input
                                        noLabel
                                        readOnly={!editAddress}
                                        name={"zipCode"}
                                        value={zipCode}
                                        onChange={this.onChange}
                                    />

                                    <h6 className={'label'}>Zona</h6>
                                    {editAddress ?
                                        <>
                                            <Select
                                                value={ZONE_OPTIONS.find(z => z.value === zone)}
                                                onChange={this.onChangeZone}
                                                options={ZONE_OPTIONS}
                                                className={errors["zone"] ? "" : 'mb-1'}
                                            />
                                            {errors["zone"] && <p className={'error-msg mb-1 mt-1'}>Debes seleccionar una zona válida</p>}
                                        </>
                                        : <p>{zone}</p>}

                                    <MapContainer
                                        markers={[this.state]}
                                        userCenter={userCenter}
                                        containerId={'CreateUpdateIncident'}
                                        style={{width: '100%', height: 300, margin: 0}}
                                        onClickOnMap={this.handleOnClickInMap}
                                        center={{...coords}}
                                    />
                                    {(errors["coords.lat"] && !coords) && <p className={'error-msg'} >Debes seleccionar la ubicación de la incidencia en el mapa</p>}
                                </div> : <p className={'pl-1 pr-1'}><i>Busca la dirección en la sección previa, o <span
                                    onClick={this.changeToManualAddress} className={'link'}>Asígnala de forma manual</span></i></p>
                            }

                        </Dropview>
                        <Divider/>

                        <Dropview header={<h4 className={'m-0'}>Familias y Tipos</h4>}>
                            <div className={'pl-1 pr-1'}>
                                <h6 className={'label'}>Familia</h6>
                                <AsyncSelect
                                    onFocus={this.onFocusFamily}
                                    key={'family'}
                                    name={'family'}
                                    loadOptions={this.loadFamilies}
                                    value={family}
                                    defaultOptions={defaultFamilyOptions}
                                    cacheOptions
                                    placeholder={'Puedes buscar por nombre'}
                                    onChange={(value)=>this.onSelectChangeFamily('family',value)}
                                />

                                <h6 className={'label'}>Subfamilia</h6>
                                <AsyncSelect
                                    onFocus={this.onFocusSubfamily}
                                    key={'subfamily'}
                                    name={'subfamily'}
                                    loadOptions={this.loadSubfamilies}
                                    value={subfamily}
                                    defaultOptions={defaultSubfamilyOptions}
                                    cacheOptions
                                    placeholder={'Puedes buscar por nombre'}
                                    onChange={(value)=>this.onSelectChangeSubFamily('subfamily',value)}
                                />

                                <h6 className={'label'}>Tipo de orden</h6>
                                <AsyncSelect
                                    onFocus={this.onFocusOrderType}
                                    key={'orderType'}
                                    name={'orderType'}
                                    loadOptions={this.loadOrdertype}
                                    value={orderType}
                                    defaultOptions={defaultOrderTypeOptions}
                                    cacheOptions
                                    placeholder={'Puedes buscar por nombre'}
                                    onChange={(value)=>this.onSelectChangeOrderType('orderType',value)}
                                />
                            </div>
                        </Dropview>
                        <Divider/>
                        <Dropview header={<h4 className={'mt-0 mb-0'}>Comentarios</h4>}>
                            <div className={'pl-1 pr-1'}>
                                <CommentsBlock
                                    newComments={newComments}
                                    oldComments={comments}
                                    updateComments={this.concordanceComments}
                                />
                            </div>
                        </Dropview>
                        <Divider/>

                        <Dropview header={<h4 className={'m-0'}>Imágenes</h4>}>
                            <div className={"pl-1"}>
                                { (images && images.length >= 0 && this.props.id) ? images.map(image =>
                                        <ImageWrapper width={100} height={100}>
                                            <MinioImage name={image}/>
                                        </ImageWrapper>
                                    ) :
                                    <DropZone
                                        errorFileExtension={"Solo puedes subir archivos con las siguientes extensiones"}
                                        errorFileName={"Ya hay uno o varios archivos con el mismo nombre"}
                                        error={"Error"} //error
                                        loading={false} //loading
                                        onFilesChanged={this.handleFiles} //this.handleFiles
                                        fileList={images} //fileList
                                        accept={['.png', '.jpg', '.jpeg', '.mp4', '.mov']}
                                        title={"Añadir imágenes"}
                                    />
                                }
                            </div>
                        </Dropview>
                        <Divider/>
                    </div>
                </BodySidebar>
                <FooterSidebar>
                    <Button className={'native-base-button center-text'}  onClick={this.submit} fullWidth >
                        {sidebarMode === create ? "Crear incidencia" : "Guardar incidencia"}
                    </Button>
                </FooterSidebar>
            </Fragment>
        )
    }
}


const mapStateToProps = ({taxonomies, incidents}) => ({
    defaultFamilyOptions: taxonomies[`filtered${family}`].map(tax => ({label: tax.name, value: tax.id })),
    defaultSubfamilyOptions: taxonomies[`filtered${subfamily}`].map(tax => ({label: tax.name, value: tax.id })),
    defaultOrderTypeOptions: taxonomies[`filtered${orderType}`].map(tax => ({label: tax.name, value: tax.id })),
    fetching: incidents.fetching
});

export default Consumer(connect(mapStateToProps)(Create))