import * as TYPES from './types'
import { incident } from './../../configuration/entitiesNames';
// import STATUS from './../../configuration/statusOptions';
import mergeList from './../../helpers/MergeList'
import sortBy from './../../helpers/SortBy'

const initialState = {
    [incident]: [],
    filtered:[],
    historical: [],
    emails: [],
    fetching: false,
    pending: 0,
    success: false,
    error: null,
    total: 0,
};

export default (state = initialState, { type, payload }) => {

    let index = -1;
    let aux, auxFiltered, indexFiltered;
    let pending = 0;

    switch (type) {

        //All Request
        case TYPES.GET_INCIDENTS_REQUEST:
        case TYPES.CREATE_INCIDENT_REQUEST:
        case TYPES.UPDATE_INCIDENT_REQUEST:
        case TYPES.GET_HISTORICAL_INCIDENT_REQUEST:
        case TYPES.FILTER_INCIDENTS_REQUEST:
        case TYPES.DELETE_PICTURE_INCIDENT_REQUEST:
        case TYPES.ADD_PICTURE_INCIDENT_REQUEST:
        case TYPES.GET_EMAILS_INCIDENT_REQUEST:
            return { ...state, fetching:true, };

        case TYPES.UPDATE_STATUS_INCIDENTS_REQUEST:
        case TYPES.PRINTED_INCIDENTS_REQUEST:
        case TYPES.UPDATE_INCIDENTS_REQUEST:
            return { ...state, fetching:true, pending: payload.length };

        // Singular Cases
        case TYPES.GET_HISTORICAL_INCIDENT_SUCCESS:
            return { ...state, fetching: false, historical: payload.data };

        case TYPES.GET_EMAILS_INCIDENT_SUCCESS:
            return { ...state, fetching: false, emails: payload.data };

        case TYPES.GET_INCIDENTS_SUCCESS:
            return {
                ...state,
                fetching:false,
               [incident]: sortBy(mergeList(state[incident], payload.data), 'creDate', "number"),
                filtered: sortBy(mergeList(state[incident], payload.data), 'creDate', "number"),
                total: payload.total ? payload.total : state.total,
                success: true };

        case TYPES.FILTER_INCIDENTS_SUCCESS:
            if (payload.page !== 0) aux = mergeList(state.filtered, payload.data);
            else aux = payload.data;
            return { ...state, fetching:false, filtered: [...aux], [incident]: [...aux], total: payload.total ? payload.total : state.total, success: true };

        case TYPES.CREATE_INCIDENT_SUCCESS:
            return {
                ...state,
                [incident]: mergeList(payload.data,state[incident]),
                filtered: mergeList(payload.data,state[incident]),
                total: parseInt(payload.total),
                fetching:false,
                success: true
            };

        case TYPES.UPDATE_INCIDENT_SUCCESS:
            aux = state[incident];
            index = aux.indexOf(aux.find(incident => incident.id === payload.id));
            aux[index] = {...aux[index], ...payload};
            pending = state.pending !== 0 ? --state.pending : 0;
            return { ...state, [incident]:[...aux], filtered:[...aux], fetching: pending !== 0, success: pending !== 0};


        case TYPES.PRINTED_INCIDENT_SUCCESS:
            aux = state[incident];
            auxFiltered = state['filtered'];

            index = state[incident].findIndex(inc => inc.id === payload.id );
            indexFiltered = state['filtered'].findIndex(inc => inc.id === payload.id );

            if(index !== -1) aux[index].printed = true;
            if(indexFiltered !== -1) auxFiltered[indexFiltered].printed = true;

            pending = state.pending !== 0 ? --state.pending : 0;
            return { ...state, [incident]:[...aux], filtered:[...auxFiltered], fetching: pending !== 0, success: pending !== 0};

        //All Errors
        case TYPES.GET_INCIDENTS_FAILED:
            console.log(TYPES.GET_INCIDENTS_FAILED, payload);
            return { ...state, fetching:false, error: payload };

        case TYPES.PRINTED_INCIDENT_FAILED:
            console.log(TYPES.PRINTED_INCIDENT_FAILED, payload);
            pending = state.pending !== 0 ? --state.pending : 0;
            return { ...state, fetching: pending !== 0 , error: payload };

        case TYPES.CREATE_INCIDENT_FAILED:
            console.log(TYPES.CREATE_INCIDENT_FAILED, payload);
            return { ...state, fetching:false, error: payload };

        case TYPES.UPDATE_STATUS_INCIDENT_SUCCESS:
            console.log(TYPES.UPDATE_STATUS_INCIDENT_FAILED, payload);
            return { ...state, fetching:false, error: payload };

        case TYPES.UPDATE_INCIDENT_FAILED:
            console.log(TYPES.UPDATE_INCIDENT_FAILED, payload);
            return { ...state, fetching:false, error: payload };

        case TYPES.GET_HISTORICAL_INCIDENT_FAILED:
            console.log(TYPES.GET_HISTORICAL_INCIDENT_FAILED, payload);
            return { ...state, fetching:false, error: payload };

        case TYPES.GET_EMAILS_INCIDENT_FAILED:
            console.log(TYPES.GET_EMAILS_INCIDENT_FAILED, payload);
            return { ...state, fetching:false, error: payload };

        case TYPES.FILTER_INCIDENTS_FAILED:
            console.log(TYPES.GET_HISTORICAL_INCIDENT_FAILED, payload);
            return { ...state, fetching:false, error: payload };

        case TYPES.DELETE_PICTURE_INCIDENT_FAILED:
            console.log(TYPES.DELETE_PICTURE_INCIDENT_FAILED, payload);
            return { ...state, fetching:false, error: payload };

        case TYPES.ADD_PICTURE_INCIDENT_FAILED:
            console.log(TYPES.ADD_PICTURE_INCIDENT_FAILED, payload);
            return { ...state, fetching:false, error: payload };



        // Helpers
        case TYPES.SWIPE_SUCCESS_STATUS:
            return {...state, success: payload ? payload : !state.success};

        default:
            return state
    }
};