import mapboxgl from 'mapbox-gl';
import { ParseCustomDataToGeoJson } from '../Parse/ParseDataToGeoJson';
import { PulsingDot } from './../../Markers';

export const RemoveAllMarkers = ( markersArray ) => {
    markersArray.forEach(marker => {
        marker.remove()
    })
};

export const AddMarkersCluster = (map, markersArray, data, name, onPressMarker) => {
    const geoJson = ParseCustomDataToGeoJson(data);
    RemoveAllMarkers(markersArray);
    geoJson.data.features.forEach(d => AddMarker(map, markersArray, d, onPressMarker))
};

export const AddMarker = (map, markersArray, data , onPressMarker) => {
    const { properties, geometry } = data;

    if(geometry.coordinates[0] && geometry.coordinates[1]) {
        const MarkerStyle = PulsingDot(properties);
        const marker = new mapboxgl.Marker(MarkerStyle)
            .setLngLat(geometry.coordinates)
            .addTo(map);

        markersArray.push(marker);
        marker.getElement().addEventListener('click', (e) => onPressMarker(e, data, marker), true)
    }
};