import React from 'react';
import Icon from "../../../../elements/Icon";
import './option.scss';
import { CheckIcon } from "../../../../configuration/icons";

export default class StatusFilter extends React.Component{
    onClick = (e) => {
        const { onClick, data } = this.props;
        onClick(e,data)
    };

    render() {
        const { data, selected } = this.props;
        return (
            <div className={'Status-Filter'} onClick={ this.onClick }>
                <div className={'Status-Filter-Color-Circle'}>
                    <div className={'Status-Filter-Name'}>
                        <p>{data.code}</p>
                        <p>{data.description}</p>
                        <p>{data.address}</p>
                    </div>
                </div>
                { selected && <div className={'Status-Filter-CheckMark'}><Icon name={CheckIcon}/></div> }
            </div>
        )
    }
};

