import * as TYPES from './types'

const initialState = {
    previousRoute: null,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        //All Request
        case TYPES.SET_PREVIOUS_ROUTE:
            return { ...state, previousRoute: payload, };

        default:
            return state
    }
};