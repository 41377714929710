import React, {Fragment} from 'react';
import EntityOption from "../../../../components/EntityOption";
import STATUS from "../../../../configuration/statusOptions";
import Divider from "../../../../elements/Divider";
import Consumer from "../../Context/Consumer";

const STATUS_IN_ARRAY = Object.keys(STATUS);

class StatusBlock extends React.Component {

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps.filters.statusFilters !== this.props.filters.statusFilters;
    }

    generateStatusFilter = (statusCode) => {
        const { filters, setIn } = this.props;
        const { statusFilters } = filters;
        if(statusFilters.includes(statusCode)) setIn('statusFilters',statusFilters.filter(z => z !== statusCode ));
        else setIn('statusFilters', [...statusFilters, statusCode]);
    };

    render(){
        const { filters } = this.props;
        const { statusFilters } = filters;
        return(
            <Fragment>
                <p className={'Filter-sidebar-subtitle'}>Filtrar por estado de la incidencia</p>
                { STATUS_IN_ARRAY.length > 0 ?
                    STATUS_IN_ARRAY.map(statusKey => <EntityOption
                            key={statusKey}
                            selected={statusFilters.includes(STATUS[statusKey].name)}
                            onClick={()=>this.generateStatusFilter(STATUS[statusKey].name)}
                            data={STATUS[statusKey].translated}
                        />
                    ) :
                    <i> No se han encontrado estados para mostrar </i> }
                <Divider/>
            </Fragment>
        )
    }
}

export default Consumer(StatusBlock);