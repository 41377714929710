import React from 'react';
import Icon from '../../elements/Icon';
import './index.scss';
import PropTypes from 'prop-types';

const ICON_IS_OPEN = "FaCaretDown";
const ICON_IS_CLOSED = "FaCaretUp";


class Dropview extends React.Component{
    static defaultProps = {
        open: false,
        demountable: false,
        header: null
    };

    static propTypes = {
        open: PropTypes.bool,
        demountable: PropTypes.bool,
        header: PropTypes.element
    };

    state = {
        open: this.props.open
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        for (const prop in Dropview.defaultProps){
            if(nextProps[prop] !== this.props[prop])
                return true
        }

        return nextState !== this.state;
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.open !== this.props.open)
            this.setState({
                open: this.props.open
            })
    }


    toggleDropView = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            open: !this.state.open
        })
    };

    render() {
        const { open } = this.state;
        const { header, children, demountable } = this.props;

        return (
            <div className={'drop-view'} >
                <div className={`handler ${open ? 'is-open' : ''}`} onClick={ this.toggleDropView }>
                    <div>{ header }</div>
                    <Icon name={open ? ICON_IS_CLOSED : ICON_IS_OPEN}/>
                </div>
                { (demountable && !open) ? null : <div className={`content ${open ? 'is-open' : ''}`}> { children } </div> }
            </div>
        )
    }
}

export default Dropview;