export const GET_TAXONOMIES_REQUEST = "GET_TAXONOMIES_REQUEST";
export const GET_TAXONOMIES_SUCCESS = "GET_TAXONOMIES_SUCCESS";
export const GET_TAXONOMIES_FAILED = "GET_TAXONOMIES_FAILED";

export const CREATE_TAXONOMY_REQUEST = "CREATE_TAXONOMY_REQUEST";
export const CREATE_TAXONOMY_SUCCESS = "CREATE_TAXONOMY_SUCCESS";
export const CREATE_TAXONOMY_FAILED = "CREATE_TAXONOMY_FAILED";

export const UPDATE_TAXONOMY_REQUEST = "UPDATE_TAXONOMY_REQUEST";
export const UPDATE_TAXONOMY_SUCCESS = "UPDATE_TAXONOMY_SUCCESS";
export const UPDATE_TAXONOMY_FAILED = "UPDATE_TAXONOMY_FAILED";

export const REMOVE_TAXONOMIES_REQUEST = "REMOVE_TAXONOMIES_REQUEST"; //Plural
export const REMOVE_TAXONOMY_REQUEST = "REMOVE_TAXONOMY_REQUEST";
export const REMOVE_TAXONOMY_SUCCESS = "REMOVE_TAXONOMY_SUCCESS";
export const REMOVE_TAXONOMY_FAILED = "REMOVE_TAXONOMY_FAILED";

export const SWIPE_TAXONOMY_SUCCESS_STATUS = "SWIPE_TAXONOMY_SUCCESS_STATUS";