const ObtainAddress = ({ results, lat, lng }) => {
    let street, city, zone;
    const len = (results[0].address_components).length;
    
    // Obtaning each parameter of the address.
    const number = results[0].address_components[0].types[0] === "street_number" ? results[0].address_components[0].long_name  : '';
    const zipCode = results[0].address_components[len-1].types[0] === "postal_code" ? results[0].address_components[len-1].long_name  : '';

    results[0].address_components.map((address) => { 
    if(address.types[0] === "route") {
        street = address.long_name
    } else if (address.types[0] === "locality") {
        city = address.long_name
    } else { return null }
    });

    if(city === "San José de la Rinconada"){zone = 'SLR'}
    else if(city === "La Rinconada"){zone = 'LR'}
    else{zone = 'OTR'}

    return {street, city, zone, number, zipCode};
}

export default ObtainAddress
