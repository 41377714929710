 import * as TYPES from './types'
import { user } from "../../configuration/entitiesNames";
import mergeList from '../../helpers/MergeList'

const initialState = {
    [user]: [],
    fetching: false,
    pending: 0,
    success: false,
    deleteSuccess: false,
    createSuccess: false,
    error: null,
    total: 0,
};

export default (state = initialState, { type, payload }) => {
    let index = -1, aux, singleUser, pending = 0;
    switch (type) {

        //All Request
        case TYPES.CREATE_USER_REQUEST:
            return { ...state, fetching:true, createSuccess: false };

        case TYPES.GET_USERS_REQUEST:
        case TYPES.UPDATE_USER_REQUEST:
        case TYPES.GET_USER_ROLES_REQUEST:
            return { ...state, fetching:true, };

        case TYPES.DELETE_USER_REQUEST:
            return { ...state, fetching:true, deleteSuccess: false };

        case TYPES.DELETE_USERS_REQUEST:
        case TYPES.UPDATE_USERS_REQUEST:
            return { ...state, fetching:true, pending: payload.length };

        case TYPES.GET_USERS_SUCCESS:
            return { ...state, fetching:false, [user]: payload.data, total: payload.data.length, success: true };

        case TYPES.GET_USER_SUCCESS:
            return { ...state, fetching:false, [user]: mergeList( state[user], payload.data ), success: true };

        case TYPES.GET_USER_ROLES_SUCCESS:
            singleUser = state[user].find(u => u.id === payload.id);
            singleUser.roles = payload.roles;
            return { ...state, fetching: false, [user]: mergeList( state[user], [singleUser] ), success: true};

        case TYPES.CREATE_USER_SUCCESS:
            return {
                ...state,
                [user]: mergeList( payload.data, state[user] ),
                total: payload.total,
                fetching:false,
                createSuccess: true
            };

        case TYPES.DELETE_USER_SUCCESS:
            aux = state[user];
            index = aux.indexOf(aux.find(user => user.id === payload));
            aux.splice(index,1);
            pending = state.pending !== 0 ? --state.pending : 0;
            return { ...state, [user]:[...aux], fetching: pending !== 0, success: pending === 0, deleteSuccess: pending === 0};

        case TYPES.UPDATE_USER_SUCCESS:
            aux = state[user];
            index = aux.indexOf(aux.find(user => user.id === payload.user));
            aux[index] = {...payload.values, id: payload.user};
            pending = state.pending !== 0 ? --state.pending : 0;
            return { ...state, [user]:[...aux], fetching: pending !== 0, success: pending === 0};

        case TYPES.UPDATE_USER_ROLES_SUCCESS:
            aux = state[user];
            index = aux.indexOf(aux.find(user => user.id === payload.id));
            aux[index] = {...aux[index], roles: payload.roles, id: payload.id};
            console.log(aux[index]);
            return { ...state, [user]:[...aux], fetching: pending !== 0, success: pending === 0};

        case TYPES.GET_USER_ORION_DATA_SUCCESS:
            aux = state[user];
            index = aux.indexOf(aux.find(user => user.id === payload.id));
            aux[index] = {...aux[index], telephone: payload.data.telephone, address: payload.data.address, id: payload.id};
            return { ...state, [user]:[...aux], fetching: pending !== 0, success: pending === 0};

        //All Errors
        case TYPES.GET_USERS_FAILED:
            console.log(TYPES.GET_USERS_FAILED, payload);
            return { ...state, fetching:false, error: payload };

        case TYPES.CREATE_USER_FAILED:
            console.log(TYPES.CREATE_USER_FAILED, payload);
            return { ...state, fetching:false, error: payload };

        case TYPES.DELETE_USER_FAILED:
            console.log(TYPES.DELETE_USER_FAILED, payload);
            return { ...state, fetching:false, error: payload };

        case TYPES.UPDATE_USER_FAILED:
            console.log(TYPES.UPDATE_USER_FAILED, payload);
            return { ...state, fetching:false, error: payload };

        case TYPES.GET_USER_ROLES_FAILED:
            console.log(TYPES.GET_USER_ROLES_FAILED, payload);
            return { ...state, fetching:false, error: payload };
        

        // Helpers
        case TYPES.SWIPE_SUCCESS_STATUS_IN_USER:
            return {...state, [payload.prop]: payload.status};

        default:
            return state
    }
};