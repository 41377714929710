import React from 'react';
import './style.css';
import RinconadaLogoSection from './sections/RinconadaLogoSection';
import SGILogoSection from './sections/SGILogoSection';
import ExecutorSection from './sections/ExecutorSection';
import TaxSection from './sections/TaxSection';
import QRSection from './sections/QRSection';
import DescriptionSection from './sections/DescriptionSection';
import InfoSection from './sections/InfoSection';
import MapSection from './sections/MapSection';
import PictureSection from './sections/PictureSection';
import CommentsSection from './sections/CommentsSection';
import {incident} from "../../configuration/entitiesNames";
import {connect} from "react-redux";
import GmapSection from '../../screens/Incidents/List/Components/GmapsSection/index';

class TemplateToPrint extends React.Component {
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps.incident !== this.props.incident
    }

    render(){
        const { incident } = this.props;
        { if (incident) {
            return(
                <div id={`print-template-${incident.id}`} key={`template-to-print-${incident.id}`}>
                    {/*** PAGE 1 ***/}
                    <section className="pdf-container" key={'first-page'}>
                        <div className="pdf-row pdf-row-logos separator" key={'first-section'}>
                            <RinconadaLogoSection/>
                            <SGILogoSection {...incident}/>
                        </div>
                        <div className="pdf-row separator" key={'second-section'}>
                            <ExecutorSection {...incident}/>
                            <TaxSection {...incident}/>
                            <QRSection {...incident}/>
                        </div>
                        <div className="pdf-row separator" key={'third-section'}>
                            <MapSection incident ={incident}/>
                            {/* TODO: Must be tested in Gmaps */}
                            {/* <GmapSection incident={incident.id} /> */}
                            <InfoSection {...incident}/>
                        </div>
                        <div key={'fourth-section'}>
                            <DescriptionSection {...incident}/>
                        </div>
                    </section>
    
                    {/*** PAGE 2 ***/}
                    {incident.images.length > 0 &&
                        <section className = "pdf-container" key={'second-page'}>
                            <div className="pdf-row pdf-row-logos separator" key={'logo-section'}>
                                <RinconadaLogoSection/>
                                <SGILogoSection {...incident}/>
                            </div>
                            <div className="pdf-row" key={'pictures-section'}>
                                <PictureSection {...incident}/>
                            </div>
                            <div className="pdf-row" key={'comments-section'}>
                                <CommentsSection {...incident}/>
                            </div>
                        </section>
                    }
                </div>
            )
        }}
        return null;
    }
}

const mapStateToProps = ({ incidents }, { id }) => ({
    incident: incidents[incident].find(inc => inc.id === id)
});

                        {/* <MapSection incident ={incident}/> */}
export default connect(mapStateToProps)(TemplateToPrint);