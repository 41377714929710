import { requestApi } from "./requestApi";

export const getUsers = async ( idUser, filters ) => {
    let url = `api/v1/user/`;
    url += idUser ? idUser : '';
    return await requestApi({
        method: 'GET',
        url: url
    });
};

export const getUserRoles = async ( idUser ) => {
    let url = `api/v1/user/${idUser}/roles`;
    return await requestApi({
        method: 'GET',
        url: url
    });
};

export const removeUser = async ( user ) => {
    return await requestApi({
        method: 'DELETE',
        url: `api/v1/user/${user}`
    });
};

export const createUsers = async ( user  ) => {
    let url = `api/v1/user/`;
    return await requestApi({
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(user),
        url: url
    });
};
export const updateUser = async ( user, values  ) => {
    let url = `api/v1/user/${user}`;
    return await requestApi({
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(values),
        url: url
    });
};

export const resetPasswordForUser = async ( user ) => {
    let url = `api/v1/user/${user}/password/reset`;
    return await requestApi({
        method: 'POST',
        url: url
    })
};

export const updatePasswordForUser = async ( original_password, password, repassword ) => {
    let url = `api/v1/user/me/password/update`;
    return await requestApi({
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            original_password, password, repassword
        }),
        url: url
    })
};

export const sendEmailToUser = async ( type, email, incidenceId, name, customMessage, incidenceDescription ) => {
    return await requestApi({
        url: 'notify',
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            type,
            email,
            incidenceId,
            incidenceDescription,
            name,
            customMessage
        }),
    });
};


export const getEmailsByIncidenceID = async ( {id} ) => {
    return await requestApi({
        url: `notify/${id}`,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    });
};