import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { incident } from "../../../../../configuration/entitiesNames";
import {Link} from "@reach/router";

class RelatedIncident extends React.Component {

    static defaultProps = {
        relatedIncident: undefined,
    };

    static propTypes = {
        relatedIncident: PropTypes.string
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        for (const prop in RelatedIncident.defaultProps){
            if(nextProps[prop] !== this.props[prop])
                return true
        }
        return false;
    }

    render() {
            const { relatedIncident } = this.props;
            return relatedIncident ? (
                <Fragment>
                    <h6 className={"label pl-1"}>Esta incidencia proviene de otra relacionada</h6>
                    <p className={'mt-0 ml-1'}> <Link to={`../${relatedIncident}`} className={'link'}>Ver incidencia relacionada</Link> </p>
                </Fragment>
            ) : null;
        }
}

const mapStateToProps = ({ incidents }, {id}) => {
    const incidentInIndents = incidents[incident].find(inc => inc.id === id);
    const foundIncident = incidentInIndents ? incidentInIndents : incidents.filtered.find(inc => inc.id === id);

    if(foundIncident)
        return {
            relatedIncident: foundIncident.relatedIncident,
        };

    return RelatedIncident.defaultProps;
};

export default connect(mapStateToProps)(RelatedIncident)