import React from 'react';
import { family } from "../../../configuration/entitiesNames";
import EntityPopulated from "./EntityPopulated";

const ENTITY = family;

class FamilyPopulated extends React.PureComponent{
    render() {
        return <EntityPopulated {...this.props} ENTITY={ENTITY}/>
    }
}

export default FamilyPopulated