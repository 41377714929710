import * as TYPES from './types';
import { store } from '../store';

export const swipeSuccessStatus = (status) => store.dispatch({
    type: TYPES.SWIPE_TAXONOMY_SUCCESS_STATUS,
    payload: status
});

export const getTaxonomies = (filters, callback) => store.dispatch({
    type: TYPES.GET_TAXONOMIES_REQUEST,
    filters: filters,
    callback
});

export const createTaxonomy = (taxonomy, callback) => store.dispatch({
    type: TYPES.CREATE_TAXONOMY_REQUEST,
    taxonomy: taxonomy,
    callback
});

export const updateTaxonomy = (taxonomy, callback) => store.dispatch({
    type: TYPES.UPDATE_TAXONOMY_REQUEST,
    taxonomy: taxonomy,
    callback
});

export const removeTaxonomies = (taxonomies, type, callback) => {
    store.dispatch({
        type: TYPES.REMOVE_TAXONOMIES_REQUEST,
        payload: taxonomies,
    });

    taxonomies.forEach(taxonomy => store.dispatch({
        type: TYPES.REMOVE_TAXONOMY_REQUEST,
        taxonomy: {id: taxonomy, type: type},
        callback
    }));
};