import {put, call, all, fork, takeLatest} from 'redux-saga/effects';
import * as TYPES from './types';
import * as RoleApi from './../../api/role';

function* getRolesThroughApi({ filters, callback }){
    const { id } = filters ? filters : {};
    try {
        const response = yield call(RoleApi.getRoles, id);
        if(response.status === 200 || response.status === 304) {
            if (!id) yield put({
                type: TYPES.GET_ROLES_SUCCESS,
                payload: {id: id, data: response.data.roles, total: response.data.roles.length}
            });
            else yield put({type: TYPES.GET_ROLE_SUCCESS, payload: {data: response.data.roles}});
            callback && callback(response)
        }
        else {
            yield put({ type: TYPES.GET_ROLES_FAILED, payload: response });
            callback && callback(response)
        }
    }
    catch (e) {
        yield put({ type: TYPES.GET_ROLES_FAILED, payload: e});
        callback && callback(e)
    }
}

function* watcherGetUserRoles() {
    yield takeLatest(TYPES.GET_ROLES_REQUEST, getRolesThroughApi);
}

export default function* rootSaga() {
    yield all([
        fork(watcherGetUserRoles),
    ])
}

