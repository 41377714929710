import React  from 'react';
import Loading from '../../../elements/Loading';
import List from "../../../template/List";
import Table from "../../../components/Table";
import {family, incident, subfamily} from "../../../configuration/entitiesNames";
import { connect } from 'react-redux';
import {getTaxonomies, removeTaxonomies} from '../../../redux/taxonomies/action';
import { getIncidents } from '../../../redux/incidents/action';
import RoundButton from "../../../elements/RoundButton";
import {
    DeleteIcon,
    EditIcon,
    ViewIcon,
    PrintIcon,
} from "../../../configuration/icons";
import './index.scss'
import FindTaxonomyByIdInRedux from "../../../helpers/redux-helpers/FindTaxonomyByIdInRedux";
import FamilyPopulated from "../../../helpers/redux-helpers/PopulatedClass/FamilyPopulated";
import { view } from "../../../configuration/operationNames";
import { navigate } from '@reach/router'
import SidebarConsumer from "../SidebarContext/Consumer";
import DropDownMenu from '../../../components/DropDown';
import RoundStatusIndicator from "../../../elements/RoundStatusIndicator";
import RoundPrintedIndicator from "../../../elements/RoundPrintedIndicator";
import CalcParentType from "../../../helpers/CalcParentType";
import SubfamilyPopulated from "../../../helpers/redux-helpers/PopulatedClass/SubfamilyPopulated";
import FilterConsumer from '../../../componentsScreens/FilterIncidentsSidebar/Context/Consumer';
import UserPopulated from "../../../helpers/redux-helpers/PopulatedClass/UserPopulated";
import FindIncidentForPrint from "./../Components/FindIncidentForPrint"
import PrintingSubfamilies from '../Components/PrintingSubfamilies';
import AnimationSpinner from "./AnimationSpinner";

const CalcPopulateType = (parent, type) => {
    switch(CalcParentType(type)) {
        case subfamily: return <SubfamilyPopulated id={parent}/>;
        case family: return <FamilyPopulated id={parent}/>;
        default: return 'No encontrado'
    }
};

const createRow = (tax, type, actions, isMarked) => {
    return {
        id: tax.id,
        name: tax.name,
        color: tax.color,
        description: tax.description,
        associated: tax.assignedTo ? <PrintingSubfamilies id={tax.assignedTo}/> : null,
        creDate: new Date(tax.creDate).toLocaleString(),
        // status: <StatusCeil inc={inc}/> ,
        status: <RoundStatusIndicator status={tax.status}/>,
        code: tax.code,
        // parent: populateTaxonomy(tax, type),
        parent: CalcPopulateType(tax.parent, type),
        // family: populateFamily(inc),
        family: <FamilyPopulated id={tax.family}/>,
        address: tax.address,
        executor: <p><UserPopulated id={tax.executor} show={'name'}/> <UserPopulated id={tax.executor} show={'surname'}/></p>,
        // populateExecutor(tax),
        printed: <RoundPrintedIndicator printed={tax.printed}/>,
        // actions,
        isMarked
    }
};

let aux = 0;

class Taxonomies extends List {

    static defaultProps = {
        entity: family,
        limit: 15,
    };

    constructor(props){
        super(props);
        this.entity = this.props.entity;
    }

    componentDidMount() {
        this.recreateTableRowsFromTaxonomies();
        this.props.applyFilter(true, () => console.info('Filtered apllied in List did mount'));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const status = [];
        const selectedBefore = [...this.state.selected];

        this.props[this.entity].map((entity) => {
            if (this.state.selected.includes(entity.id)) {
                status.push(entity.status);
            };
        })
        prevProps[this.entity].map((entity) => {
            if (this.state.selected.includes(entity.id) && entity.status !== status[0]) {
                this.state.selected.pop();
                this.state.selected = [...selectedBefore];
            }
        })
        
        if(prevProps[this.entity] !== this.props[this.entity] || prevProps.lastElementSelected !== this.props.lastElementSelected)
        this.recreateTableRowsFromTaxonomies();
        
        if(this.props.lastElementSelected && prevProps[this.entity] !== this.props[this.entity] ){
            const LastElementInRedux = FindTaxonomyByIdInRedux(this.props.lastElementSelected.id, this.entity);
            if(LastElementInRedux !== this.props.lastElementSelected){
                this.props.setLastElementSelected({...LastElementInRedux});
            }
        }
        
        if(this.props[this.entity].length !== prevProps[this.entity].length){this.props.applyFilter(true, () => console.info('Filtered apllied in List did update'))}
        super.componentDidUpdate(prevProps, prevState, snapshot);
    }

    recreateTableRowsFromTaxonomies = () => {
        const data = this.props[this.entity] && this.props[this.entity].map( ent => {
            const isMarked = this.props.lastElementSelected && ent.id === this.props.lastElementSelected.id;
            return createRow(ent, this.entity, this.actionsButtons(ent), isMarked)
        });
        data && this.setState({ data } );
    };

    getTaxonomies = () => {
        const filters = {
            type: this.entity,
            page: this.state.page,
            limit: this.state.limit
        };

        if(this.entity === incident) {
            const { loadMoreIncidents } = this.props;
            loadMoreIncidents(this.state.page, this.callbackGet);
            getIncidents({ type: incident, page: this.state.page, limit: this.state.limit }, () => {});
        }
        else getTaxonomies(filters, this.callbackGet);
    };

    callbackGet = (res) => {
        if(res.status === 200)
            this.setState({ page : this.state.page + 1 });
    };

    onScrollEnd  = () => {
        const { total, fetching } = this.props;
        const items = this.props[this.entity];
        if(items.length < total && !fetching)
            this.getTaxonomies();
    };

    removeSeveralTaxonomies = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.onPressDeleteButton(this.state.selected);
    };

    printSeveralTaxonomies = (e) =>{
        e.preventDefault();
        e.stopPropagation();
        this.props.onPressPrintButton(this.state.selected);
    };

    onPressDeleteButton = (e, tax) =>{
        e.preventDefault();
        e.stopPropagation();
        this.props.onPressDeleteButton([tax.id]);
    };

    onPressPrintButton = (e, tax) =>{
        e.preventDefault();
        e.stopPropagation();
        this.props.onPressPrintButton([tax.id]);
    };

    onPressUpdateButton = (e, tax) =>{
        e.preventDefault();
        e.stopPropagation();
        this.props.onPressUpdateButton(tax);
    };

    actionsButtons = (tax) =>{
        return(
            <DropDownMenu>
                <RoundButton icon={DeleteIcon} className={''} onClick={(e)=>this.onPressDeleteButton(e,tax)} tooltip={"Borrar Familia"}/>
                <RoundButton icon={EditIcon} className={'ml-1'} onClick={(e)=>this.onPressUpdateButton(e,tax)} tooltip={"Editar Familia"}/>
                <RoundButton icon={ViewIcon} className={'ml-1'} tooltip={"Ver Detalles"}/>
                {this.entity === incident && <RoundButton icon={PrintIcon} onClick={(e)=>this.onPressPrintButton(e,tax)} className={'ml-1'} tooltip={"Imprimir"}/>}
            </DropDownMenu>
        )
    };

    synchronizeData = () => {
        this.props.applyFilter(true, () => console.info('Filtered apllied in sync data'));
    };

    onClickInRow = (e, taxID) => {
        const {uri, setLastElementSelected, setMode, openSidebar, filterSidebarIsOpen, closeFilterSidebar, assignedTo } = this.props;
        const state = FindTaxonomyByIdInRedux(taxID, this.entity);
        setLastElementSelected(state);
        navigate(`${uri}/${taxID}`,{ state });
        setMode(view);
        filterSidebarIsOpen && closeFilterSidebar();
        openSidebar()
    };

    deleteSeveralTaxonomies = () => {
        const {selected} = this.state;
        aux = selected.length;
        removeTaxonomies(selected, this.entity, this.callbackDeleteSeveralTaxonomies);
    };

    callbackDeleteSeveralTaxonomies = (res) => {
        if(res.status === 204) {
            this.setState({ selected: this.state.selected.filter( t => t !== res.taxonomy.id )})
        }
    };

    render() {
        const { title, headers, IconZone } = this.props;
        const { selected, data } = this.state;
        return (
            <>
                <div className={'title-row'}>
                    <h2>{title}</h2>
                    <div className={'crud-buttons'}>
                        { IconZone && <IconZone
                            synchronizeData = {this.synchronizeData}
                            printSeveralTaxonomies = {this.printSeveralTaxonomies}
                            deleteSeveralTaxonomies = {this.deleteSeveralTaxonomies}
                            selected = { selected }
                        /> }
                    </div>
                </div>
                {this.props.fetching && <AnimationSpinner />}
                <div style={{height: '80vh', position: 'relative'}}>
                    <Table
                        banding
                        key={'Table'}
                        ref={node => this.table = node}
                        body={data}
                        header={headers}
                        selected={selected}
                        onSingleChecked={this.onSingleChecked}
                        onAllSelection={this.handleCheckAll}
                        onClickRow={this.onClickInRow}
                        pxBeforeEnd = {2}
                        onEndScroll={this.onScrollEnd}
                    />
                </div>
                {(selected.length > 0 && this.entity === incident) && selected.map(id => <FindIncidentForPrint id={id} key={`Finder-${id}`}/>)}
            </>
        )

    }
}

const mapStateToProps = ({ taxonomies, incidents }, { entity }) => {
    if(entity === incident)
        return ({ [entity]: incidents.filtered,
            fetching: incidents.fetching,
            total: incidents.total,
        });

    return ({ [entity]: taxonomies[entity],
        fetching: taxonomies.fetching,
        total: taxonomies.total,
    });
};

export default FilterConsumer(SidebarConsumer(connect(mapStateToProps)(Taxonomies)));