import React from 'react';

export default class Textarea extends React.PureComponent{
    element = React.createRef();

    componentDidMount() {
        const savedValue = this.element.current.value;
        this.element.current.value = '';
        this.element.current.baseScrollHeight = this.element.current.scrollHeight;
        this.element.current.value = savedValue;
    }

    handleChange = (e) => {
        const minRows = this.element.current.dataset.minRows|0;
        let rows;
        this.element.current.rows = minRows;
        rows = Math.floor((this.element.current.scrollHeight - this.element.current.baseScrollHeight) / 21 );
        this.element.current.rows = minRows + rows;
        this.props.onChange(e);
    };

    render() {
        const { ...rest } = this.props;
        return(
            <textarea {...rest} rows='1' data-min-rows='1' ref={this.element} onChange={this.handleChange}/>
        )
    }
}