import React from 'react'
import Modal from '../../../../../components/Modal';
import ContentModalEditionPictures from './ContentModalEditionPictures/index';
import OpenButtonModalEditionPictures from './OpenButtonModalEditionPictures/index';

class Index extends React.Component{

    state = { openModal: false };
    closeModal = () => this.setState({ openModal: false });
    openModal = () =>  this.setState({ openModal: true });

    render(){
        const { openModal } = this.state;
        const { images, id } = this.props;
        return(
            <>
                <Modal
                    id={'modal-edition-pictures'}
                    modalContainer={'#root'}
                    closeModal={this.closeModal}
                    dismissOnBlank={true}
                    dismissOnEscape={true}
                    open={openModal}
                >
                    <ContentModalEditionPictures
                        incidentId={id}
                        imagesFromIncident={images}
                        closeModal={this.closeModal}
                    />
                </Modal>
                <OpenButtonModalEditionPictures onClick={this.openModal} />
            </>
        )
    }
}

export default Index;