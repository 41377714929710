import React from 'react'
import { Form, Input } from "../../components/Form";
import SuccessAnimation from "../../components/SweetAlert/Animations/SuccessAnimation";
import Button from "../../elements/Button";
import './index.css';
import Utils from "../../helpers/UtilClassWithCommonFunctions";
import ValidatePassword from "../../helpers/validations/validatePassword";
import { updateUserPassword } from "../../redux/users/action";
import { HeaderSidebar, BodySidebar, FooterSidebar } from '../../components/Sidebar/index'
import RoundButton from '../../elements/RoundButton';
import {CancelIcon} from "../../configuration/icons";

class UpdatePasswordSidebar extends Utils{

    constructor(props){
        super(props);
        this.state = {
            errors: [],
            successMsg: null,
            original_password: '',
            password: '',
            repassword: ''
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.open !== prevProps.open && !this.props.open)
            this.setState({successMsg : null})
    }

    changePassword = () => {
        const { original_password, password, repassword } = this.state;
        let val = {
            ...ValidatePassword(original_password, 'original_password'),
            ...ValidatePassword(password, 'password'),
            ...ValidatePassword(repassword, 'repassword')
        };

        if(password !== repassword) {
            if(val['repassword']) val['repassword'] = [...val['repassword'], 'Las contraseñas no coinciden'];
            else val['repassword'] = ['Las contraseñas no coinciden']
        }

        Object.entries(val).length > 0 ? this.setErrors(val) : updateUserPassword({original_password, password, repassword}, this.changePassWordCallback) ;

    };

    changePassWordCallback = (response) => {
        if ( response.status === 500 && response.data.errors[0].code === 400 && response.data.errors[0].originalError.error === 'invalid_grant' )
            this.setErrors({original_password: ['La contraseña antigua es incorrecta']});
        else if ( response.status === 201 ) {
            this.setState({
                successMsg: true,
                original_password: '',
                password: '',
                repassword: ''
            }, this.resetSuccessMsg)
        }
    };

    resetSuccessMsg = () => setTimeout(()=>this.setState({successMsg: false} ), 3000);

    render() {
        const { closeSidebar } = this.props;
        const {original_password, password, repassword, successMsg, errors} = this.state;

        return(
            <>
                <HeaderSidebar>
                    <RoundButton icon={CancelIcon} toogle={'Cerrar Sidebar'} onClick={closeSidebar}/>
                    <p className={"m-0 p-0 link"} onClick={closeSidebar}>Cancelar</p>
                </HeaderSidebar>
                <BodySidebar>
                    <Form noValidate>
                        <h3 className={'mb-4'}>Cambiar contraseña</h3>
                        <Input
                            required
                            keepFeedbackSpace
                            groupClassName={'profile-change-password-input mb-3'}
                            type={'password'}
                            name={'Contraseña actual'}
                            value={original_password}
                            onChange={(e)=>this.handleOnChange('original_password', e.target.value)}
                            infoMsg={ 'Introduce la contraseña que utilizas actualmente para acceder a la plataforma'}
                            errorMsg={ errors['original_password']}
                        />

                        <Input
                            required
                            keepFeedbackSpace
                            groupClassName={'profile-change-password-input mb-3'}
                            type={'password'}
                            name={'Nueva contraseña'}
                            value={password}
                            onChange={(e)=>this.handleOnChange('password', e.target.value)}
                            infoMsg={ 'Introduce la nueva contraseña que usarás a partir de ahora' }
                            errorMsg={ errors['password']}
                        />

                        <Input
                            required
                            keepFeedbackSpace
                            groupClassName={'profile-change-password-input mb-3'}
                            type={'password'}
                            name={'Confirmar nueva contraseña'}
                            value={repassword}
                            onChange={(e)=>this.handleOnChange('repassword', e.target.value)}
                            infoMsg={ 'Confirma la contraseña escribiendo la misma que antes'}
                            errorMsg={ errors['repassword']}
                        />
                    </Form>

                    { successMsg &&
                    <div className={'profile-change-password-wrapper-msg mb-1'}>
                        <SuccessAnimation>
                            <h3>La contraseña se ha actualizado correctamente</h3>
                        </SuccessAnimation>
                    </div>
                    }
                </BodySidebar>
                <FooterSidebar>
                    <Button
                        title={'Actualizar Contraseña'}
                        onClick={this.changePassword}
                        fullWidth
                    >
                        Actualizar Contraseña
                    </Button>
                </FooterSidebar>
            </>
        )
    }
}

export default UpdatePasswordSidebar;