import React from 'react';
import './index.scss'
import { family, subfamily, orderType } from "../../configuration/entitiesNames";
import HeaderSidebar from "../../components/Sidebar/Header";
import Body from "../../components/Sidebar/Body";
import Footer from "../../components/Sidebar/Footer";
import TaxonomyBlock from './Blocks/TaxonomyBlocks';
import DateBlock from './Blocks/DateBlock';
import PrintedBlock from './Blocks/PrintedBlock';
import ZonesBlock from './Blocks/ZonesBlock';
import StatusBlock from './Blocks/StatusBlock';
import FinderInputBlock from './Blocks/FinderInputBlock';
import HeaderBlock from './Blocks/HeaderBlock';
import { connect } from 'react-redux';
import Button from './../../elements/Button';
import { getTaxonomies } from "../../redux/taxonomies/action";
import Consumer from "./Context/Consumer";

export class FilterSidebar extends React.Component {

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return (
            nextProps.families !== this.props.families ||
            nextProps.subfamilies !== this.props.subfamilies ||
            nextProps.orderTypes !== this.props.orderTypes
        )
    }

    componentDidMount() {
        getTaxonomies({ type:family });
        getTaxonomies({ type:subfamily });
        getTaxonomies({ type:orderType });
    }

    render(){
        /** Taxonomies from redux **/
        const { families, subfamilies, orderTypes } = this.props;

        /** State and funtions form Context **/
        const { resetFilters } = this.props;
        const { withOutFinder } = this.props; // Flag in props;

        return (
            <>
                <HeaderSidebar>
                    <HeaderBlock />
                </HeaderSidebar>
                <Body>
                    <div className={'body-wrapper'}>
                        <FinderInputBlock withOutFinder={withOutFinder}/>
                        <DateBlock/>
                        <PrintedBlock/>
                        <ZonesBlock/>
                        <StatusBlock/>
                        <TaxonomyBlock data={families} type={'family'}/>
                        <TaxonomyBlock data={subfamilies} type={'subfamily'}/>
                        <TaxonomyBlock data={orderTypes} type={'orderType'}/>
                    </div>
                </Body>
                <Footer>
                    <Button onClick={resetFilters} fullWidth> Limpiar Filtros </Button>
                </Footer>
            </>
        );
    }
}

const mapStateToProps = ({ taxonomies }) => ({
    families: taxonomies[family],
    subfamilies: taxonomies[subfamily],
    orderTypes: taxonomies[orderType],
});

export default Consumer(connect(mapStateToProps)(FilterSidebar));