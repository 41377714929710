import React from "react";
import ReactDOM from "react-dom";
import './index.scss';

const defaultSelectedMethod = (id) => document.querySelector(id);

class Modal extends React.Component{
    static defaultProps = {
        modalContainer: null,
        dismissOnBlank: false,
        dismissOnEscape: false,
        SelectedMethod: defaultSelectedMethod
    };

    constructor(props){
        super(props);
        this.modalContainer = null;
        this.me = null;
    }

    componentDidMount() {
        this.props.dismissOnEscape && window.addEventListener('keyup', this.pressEscape, false);
        this.setModalContainer();
    }

    componentWillUnmount(){
        this.props.dismissOnEscape && this.removeListenerEscape();
    }

    removeListenerEscape = () =>{
        this.props.dismissOnEscape && window.removeEventListener('keyup', this.pressEscape, false)
    };

    pressEscape = (e) => {
        if(e.key === "Escape" && this.props.closeModal) {
            this.props.closeModal()
        }
    };

    setModalContainer = () => {
        this.modalContainer = this.props.modalContainer ? this.props.SelectedMethod(this.props.modalContainer) : document.getElementsByTagName('BODY')[0];
    };

    dismissOnMask = (e) =>{
        if(
            e.target.classList.contains('Modal-Content') &&
            this.props.closeModal && this.props.dismissOnBlank &&
            (this.props.id && e.target.parentNode.id === this.props.id || !this.props.id)
        )
            this.props.closeModal()
    };

    ModalContainer = () => {
        const { children, open, className, closeModal, ...rest } = this.props;
        delete rest.modalContainer;
        delete rest.dismissOnBlank;
        delete rest.dismissOnEscape;
        delete rest.SelectedMethod;
        return (
            <div {...rest} className={`Modal-Container ${open ? 'active' : ''} ${className ? className : ''}`} ref={ node => this.me = node} >
                <div className={'Modal-Mask'}/>
                <div className={'Modal-Content'} onClick={this.dismissOnMask}>
                    {children}
                </div>
            </div>)
    };

    render(){
        return (
            this.modalContainer ? ReactDOM.createPortal(this.ModalContainer(),this.modalContainer) : null
        )
    }
}

export default Modal